import React from 'react';
import { styled } from '@mui/material/styles';
import LinearProgress, {
	linearProgressClasses,
} from '@mui/material/LinearProgress';

// local styles
import styles from './index.module.scss';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
	height: 13,
	borderRadius: 15,
	flex: 1,
	[`&.${linearProgressClasses.colorPrimary}`]: {
		backgroundColor:
			theme.palette.grey[theme.palette.mode === 'light' ? 200 : 200],
	},
	[`& .${linearProgressClasses.bar}`]: {
		borderRadius: 15,
		backgroundColor: theme.palette.mode === 'light' ? '#FFBB00' : '#FFBB00',
	},
}));

function ProgressBar({cardInfo}) {
	return (
		<div className={styles.container}>
			<span>{cardInfo.title}</span>
			<BorderLinearProgress variant="determinate" value={cardInfo.result ?? 0} />
			<span>{`${cardInfo.result ?? '0'} %`}</span>
		</div>
	);
}

export default ProgressBar;
