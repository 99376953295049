import React from 'react';
import Dashboard from 'components/dashboard';
import useTitle from 'hooks/useTitle';

const Dash = () => {
	useTitle('Dashboard');
	return <Dashboard />;
};

export default Dash;
