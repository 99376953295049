import React, { useState } from 'react';
import {
	Container,
	Grid,
	Paper,
	FormControlLabel,
	FormGroup,
	Checkbox,
	Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useSnackbar } from 'notistack';
import apiRequest from 'helpers/apiRequest';
import useSetUiLoadingError from 'hooks/useSetUiLoadingError';

const useStyles = makeStyles((theme) => ({
	headerSection: {
		margin: '3.125rem 0px',
		paddingBottom: '11.25rem',
	},
	inputLabel: {
		display: 'block',
		color: theme.palette.text.primary,
		...theme.typography.subtitle7,
		textTransform: 'capitalize',
		marginBottom: '0.625rem',
	},
	inputFiled: {
		outline: 'none',
		border: 0,
		font: 'normal normal normal 15px/24px Poppins',
		padding: '0.625rem 0px 0.625rem 0.9375rem',
		margin: 'auto',
		width: '100%',

		backgroundColor: '#F7F7F7 ',
		borderRadius: '5px',
		// color: "#B3B3B3",
	},
	labelAsterisk: {
		color: '#DC0000',
	},
	//   checkBox*********************
	root: {
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	icon: {
		borderRadius: 3,
		width: 16,
		height: 16,
		boxShadow:
			'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
		backgroundColor: '#f5f8fa',
	},
	checkedIcon: {
		backgroundColor: '#137cbd',
		backgroundImage:
			'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
		'&:before': {
			display: 'block',
			width: 16,
			height: 16,
			backgroundImage:
				"url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
				" fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
				"1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
			content: '""',
		},
	},
	checkboxPlaceholder: {
		label: {},
		...theme.typography.body1,
		color: theme.palette.text.dark,
	},
	textArea: {
		outline: 'none',
		border: 0,
		backgroundColor: '#F7F7F7',
		padding: '20px 0px 0.625rem 1.5625rem',
		margin: 'auto',
		width: '100%',
		height: '8rem',
		resize: 'none',
		borderRadius: '5px',
		...theme.typography.body4,
	},
	buttonStyle: {
		boxShadow: '0px 3px 6px #0000005C',
		borderRadius: '4px',
		width: '50%',
		padding: '0.75rem 0px',
		backgroundColor: theme.palette.background.primary,
		'&:hover': {
			backgroundColor: theme.palette.background.primary,
		},
		color: theme.palette.text.white,
	},
	countryIconStyle: {},
	containerStyle: {
		[theme.breakpoints.up('md')]: {
			paddingRight: theme.spacing(15),
			paddingLeft: theme.spacing(15),
		},
		paddingRight: theme.spacing(4),
	},
}));
const ContactForm = () => {
	const { enqueueSnackbar } = useSnackbar();
	const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
	const [isLoading, successData, backendError, setUiLoadingError] =
		useSetUiLoadingError();

	const classes = useStyles();


	const handlePhoneNumberChange = (phone, country) => {
		console.log('phone', phone)
		console.log('country', country)
		const isValid = /^(20|0)?(10|11|12|15)[0-9]{8}$/.test(phone) || // Egypt
			/^(966|00966|0)?(5|5|5)[0-9]{8}$/.test(phone) || // Saudi Arabia
			/^(971|00971|0)?(5|5|5)[0-9]{8}$/.test(phone);  //UAE
		setIsPhoneNumberValid(isValid);
		setFormData({
			...formData,
			phone,
			country_code: country.countryCode
		});

		// Check phone number validity here
	};

	const contact_data = {
		first_name: '',
		last_name: '',
		email: '',
		phone: '',
		company_name: '',
		website_url: '',
		channels: [],
		message: '',
		country_code: '',
	}

	const [formData, setFormData] = useState(contact_data);

	const handleChangeInputValue = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value
		})
	}

	const handleSubmit = () => {
		if (!formData.first_name || !formData.last_name || !formData.email || !formData.phone) {
			return enqueueSnackbar('Please fill the required fields', {
				variant: 'error',
				autoHideDuration: 3000,
			});
		}

		const onSuccess = async (responseData) => {
			setFormData(contact_data);
			return enqueueSnackbar('Sent email succefully', {
				variant: 'success',
				autoHideDuration: 3000,
			});
		};

		const onError = (e) => {
			return Object.values(e?.response?.data?.errors).flatMap(error => enqueueSnackbar(error, {
				variant: 'error',
				autoHideDuration: 3000,
			}));

		};

		apiRequest.post({ url: '/api/contact_us', bodyData: formData, onSuccess, onError, setUiLoadingError });
	};


	return (
		<Paper className={classes.headerSection} elevation={0}>
			<Container className={classes.containerStyle} maxWidth={false}>
				<form className={classes.root} noValidate autoComplete="off">
					<Grid container justifyContent="space-around" spacing={4}>
						<Grid item xs={10} md={5}>
							<label className={classes.inputLabel}>
								First name{' '}
								<span className={classes.labelAsterisk}>*</span>
							</label>
							<input
								name="first_name"
								value={formData.first_name}
								onChange={(e) => handleChangeInputValue(e)}
								placeholder="First Name"
								required
								type="text"
								className={classes.inputFiled}
							/>
						</Grid>
						<Grid item xs={10} md={5}>
							<label className={classes.inputLabel}>
								Last Name{' '}
								<span className={classes.labelAsterisk}>*</span>
							</label>
							<input
								name="last_name"
								value={formData.last_name}
								onChange={(e) => handleChangeInputValue(e)}
								placeholder="Last Name"
								required
								type="text"
								className={classes.inputFiled}
							/>
						</Grid>
						<Grid item xs={10} md={5}>
							<label className={classes.inputLabel}>
								Work Email{' '}
								<span className={classes.labelAsterisk}>*</span>
							</label>
							<input
								name="email"
								value={formData.email}
								onChange={(e) => handleChangeInputValue(e)}
								placeholder="Work Email"
								required
								type="email"
								className={classes.inputFiled}
							/>
						</Grid>
						<Grid item xs={10} md={5}>
							<label className={classes.inputLabel}>
								Phone{' '}
								<span className={classes.labelAsterisk}>*</span>
							</label>
							<PhoneInput
								value={formData.phone}
								onChange={(phone, country) => handlePhoneNumberChange(phone, country)}
								country={'eg'}
								inputStyle={{
									width: '100%',
									border: 0,
									font: 'normal normal normal 15px/24px Poppins',
									padding: '22px 0px 22px 45px',
									backgroundColor: '#F7F7F7 ',
									borderRadius: '5px',
								}}
								buttonClass={classes.countryIconStyle}
							/>
							{!isPhoneNumberValid && (
								<span style={{ color: 'red', fontSize: '12px' }}>
									You must enter a valid phone number.
								</span>)}
						</Grid>
						<Grid item xs={10} md={5}>
							<label className={classes.inputLabel}>
								Company Name
							</label>
							<input
								name="company_name"
								value={formData.company_name}
								onChange={(e) => handleChangeInputValue(e)}
								placeholder="Company Name"
								type="text"
								className={classes.inputFiled}
							/>
						</Grid>
						<Grid item xs={10} md={5}>
							<label className={classes.inputLabel}>
								Website URL
							</label>
							<input
								name="website_url"
								value={formData.website_url}
								onChange={(e) => handleChangeInputValue(e)}
								placeholder="Website URL"
								type="text"
								className={classes.inputFiled}
							/>
						</Grid>
						<Grid item md={5} xs={11}>
							<label className={classes.inputLabel}>
								Channels
							</label>
							<FormGroup>
								<FormControlLabel
									className={classes.checkboxPlaceholder}
									control={
										<Checkbox
											name="whatsapp"
											checked={formData.channels.includes('whatsapp')}
											onChange={(e) => setFormData({
												...formData,
												channels: e.target.checked ? [...formData.channels, e.target.name] : formData.channels.filter(channel => channel !== e.target.name)
											})}
											className={classes.root}
											checkedIcon={
												<span
													className={clsx(
														classes.icon,
														classes.checkedIcon
													)}
												/>
											}
											icon={
												<span
													className={classes.icon}
												/>
											}
										/>
									}
									label="Whatsapp"
								/>
							</FormGroup>
							<FormGroup>
								<FormControlLabel
									className={classes.checkboxPlaceholder}
									control={
										<Checkbox
											name="facebook"
											checked={formData.channels.includes('facebook')}
											onChange={(e) => setFormData({
												...formData,
												channels: e.target.checked ? [...formData.channels, e.target.name] : formData.channels.filter(channel => channel !== e.target.name)
											})}
											className={classes.root}
											checkedIcon={
												<span
													className={clsx(
														classes.icon,
														classes.checkedIcon
													)}
												/>
											}
											icon={
												<span
													className={classes.icon}
												/>
											}
										/>
									}
									label="Facebook messenger"
								/>
							</FormGroup>

							<FormGroup>
								<FormControlLabel
									className={classes.checkboxPlaceholder}
									control={
										<Checkbox
											name="website"
											checked={formData.channels.includes('website')}
											onChange={(e) => setFormData({
												...formData,
												channels: e.target.checked ? [...formData.channels, e.target.name] : formData.channels.filter(channel => channel !== e.target.name)
											})}
											className={classes.root}
											checkedIcon={
												<span
													className={clsx(
														classes.icon,
														classes.checkedIcon
													)}
												/>
											}
											icon={
												<span
													className={classes.icon}
												/>
											}
										/>
									}
									label="Website"
								/>
							</FormGroup>

							<FormGroup>
								<FormControlLabel
									className={classes.checkboxPlaceholder}
									control={
										<Checkbox
											name="mobile_app"
											checked={formData.channels.includes('mobile_app')}
											onChange={(e) => setFormData({
												...formData,
												channels: e.target.checked ? [...formData.channels, e.target.name] : formData.channels.filter(channel => channel !== e.target.name)
											})}
											className={classes.root}
											checkedIcon={
												<span
													className={clsx(
														classes.icon,
														classes.checkedIcon
													)}
												/>
											}
											icon={
												<span
													className={classes.icon}
												/>
											}
										/>
									}
									label="Mobile app"
								/>
							</FormGroup>
						</Grid>
						<Grid item xs={5}></Grid>
						<Grid item md={5} xs={11}>
							<label className={classes.inputLabel}>
								Message{' '}
							</label>
							<textarea
								name="message"
								value={formData.message}
								onChange={(e) => handleChangeInputValue(e)}
								className={classes.textArea}
								placeholder="Message"
							></textarea>
						</Grid>
						<Grid item xs={5}></Grid>
						<Grid item md={5} xs={11}>
							<Button
								onClick={handleSubmit}
								className={classes.buttonStyle}
								variant="contained"
							>
								SEND
							</Button>
						</Grid>
						<Grid item xs={5}></Grid>
					</Grid>
				</form>
			</Container>
		</Paper>
	);
};
export default ContactForm;
