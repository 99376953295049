// TODO: is to make an api call to fetch theme object dynamically

import {
	ThemeProvider,
	createTheme,
	withStyles,
	responsiveFontSizes,
} from '@material-ui/core/styles';

const theme = createTheme({
	// spacing: (factor) => `${factor / 16}rem`,
	spacing: (factor) => `${0.5 * factor}rem`,

	palette: {
		// type:"dark",
		primary: {
			main: '#43348B',
		},
		secondary: {
			main: '#A157FF',
		},
		neutral: {
			main: '#5c6ac4',
		},
		icon: {
			red: '#378D00',
			lightRed: '#FF3B3B',
		},
		text: {
			primary: '#43348B',
			primaryShad5: '#7B3DD9',
			primaryShad6: '#16CABD',
			redShad4: '#FE3754',
			dark: '#040505',
			white: '#fff',
		},
		background: {
			white: '#fff',
			primary: '#43348B',
			primaryOpacity: 'rgba(245,245,245,1)',
		},
	},
	// shadows:[
	//   {25:"0px 6px 12px #00000029"},
	// ],
	typography: {
		fontFamily: "'Poppins', sans-serif !important",
		fontSize: 16,
		title: {
			fontWeight: 400,
			fontSize: '3rem', // 48px
			letterSpacing: '3px',
			// lineHeight: 1.5,
			textTransform: 'uppercase',
		},
		title1: {
			fontWeight: 600,
			fontSize: '2.8125rem', // 45px
			// lineHeight: 1.5,
			textTransform: 'capitalize',
		},
		title2: {
			fontWeight: 700,
			fontSize: '2.5rem', // 40px
			// lineHeight: 1.5,
			textTransform: 'uppercase',
		},
		subtitle3: {
			fontFamily: "'Poppins', sans-serif",
			fontWeight: 600,
			fontSize: '1.875rem', // 30px
			// lineHeight: 2.5,
			textTransform: 'capitalize',
		},
		subtitle4: {
			fontFamily: "'Poppins', sans-serif",
			fontWeight: 500,
			fontSize: '1.75rem', // 28px
			// lineHeight: 1.5,
			textTransform: 'capitalize',
		},
		subtitle5: {
			fontFamily: "'Poppins', sans-serif",
			fontWeight: 500,
			fontSize: '1.375rem', // 22px
			// lineHeight: 1.5,
			textTransform: 'capitalize',
		},
		subtitle6: {
			fontFamily: "'Poppins', sans-serif",
			fontWeight: 400,
			fontSize: '1.25rem', // 20px
			// lineHeight: 1.5,
			textTransform: 'capitalize',
		},
		subtitle7: {
			fontFamily: "'Poppins', sans-serif",
			fontWeight: 400,
			fontSize: '.875rem', // 14px
			// lineHeight: 1.5,
			textTransform: 'uppercase',
		},
		body1: {},
		body2: {
			body2: {
				fontFamily: "'Poppins', sans-serif",
				fontSize: '1.25rem', // 20px
			},
		},
		body3: {
			fontFamily: "'Poppins', sans-serif",
			fontWeight: 400,
			fontSize: '1.125rem', // 18px
			// lineHeight: 1.5,
		},
		body4: {
			fontFamily: "'Poppins', sans-serif",
			fontWeight: 400,
			fontSize: '1rem', // 16px
			// lineHeight: 1.5,
		},
		//   body5:{
		//     fontFamily: "'Poppins', sans-serif",
		//   },
	},
	// spacing:10,
});

// resposive typo
// theme.typography.title1 = {
//   [theme.breakpoints.down('sm')]: {
//     fontSize: '3rem',
//   },
// };
// theme.typography.title2 = {
//   [theme.breakpoints.down('sm')]: {
//     fontSize: '1.8rem',
//   },
// };
// theme.typography.subtitle3 = {
//   [theme.breakpoints.down('sm')]: {
//     fontSize: '1.5rem',
//   },
// };
// theme.typography.subtitle4 = {
//   [theme.breakpoints.down('sm')]: {
//     fontSize: '1.1rem',
//   },
// };
// theme.typography.subtitle5 = {
//   [theme.breakpoints.down('sm')]: {
//     fontSize: '1rem',
//   },
// };
// theme.typography.subtitle6 = {
//   [theme.breakpoints.down('sm')]: {
//     fontSize: '.8rem',
//   },
// };
// theme.typography.subtitle7 = {
//   [theme.breakpoints.down('sm')]: {
//     fontSize: '.7rem',
//   },
// };
// theme.typography.body3 = {
//   [theme.breakpoints.down('sm')]: {
//     fontSize: '.8rem',
//   },
// };
// theme.typography.body4 = {
//   [theme.breakpoints.down('sm')]: {
//     fontSize: '.7rem',
//   },
// };

// const newTheme = responsiveFontSizes(theme);
export const getContainerSize = () => {
	return 'xl';
};
export default theme;
