/** ================= Scenarios actions Types ================== */


export const GET_ALL_SCENARIOS = 'GET_ALL_SCENARIOS';
export const GET_ALL_SCENARIOS_ERROR = 'GET_ALL_SCENARIOS_ERROR';
export const CREATE_NEW_SCENARIO = 'CREATE_NEW_SCENARIO';
export const CREATE_NEW_SCENARIO_ERROR = 'CREATE_NEW_SCENARIO_ERROR';
export const UPDATE_SCENARIO = 'UPDATE_SCENARIO';
export const DELETE_SCENARIO = 'DELETE_SCENARIO';
export const DELETE_SCENARIO_ERROR = 'DELETE_SCENARIO_ERROR';
export const UPDATE_SCENARIO_ERROR = 'UPDATE_SCENARIO_ERROR';