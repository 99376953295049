import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as AdminAuthActions from 'store/actions/creators/adminPanel';
import * as authActions from 'store/actions/creators/auth';

/**
 *
 * @returns Navigate user to the correct page based on the user token [Admin or User]
 */
const EntryPoint = () => {
	const dispatch = useDispatch();
	const location = useLocation();

	// ------------------ [User , Admin] tokens from localstorage ------//
	const [tokenData, setTokenData] = useState(
		localStorage.getItem('tokenData')
			? JSON.parse(localStorage.getItem('tokenData'))
			: null
	);
	const [adminTokenData, setAdminTokenData] = useState(
		localStorage.getItem('adminToken')
			? JSON.parse(localStorage.getItem('adminToken'))
			: null
	);

	useEffect(() => {
		const tryLogin = async () => {
			//--------------four cases for admin and user ------------------
			// 1 - adminToken true && tokenData true
			if (adminTokenData && tokenData) {
				if (location.pathname.includes('admin')) {
					dispatch(
						AdminAuthActions.authenticate(adminTokenData.token)
					);
				} else {
					dispatch(
						authActions.authenticate(tokenData.token)
					);
				}
			}
			// 2 - tokenData  true
			else if (tokenData && !adminTokenData) {
				if (!location.pathname.includes('admin')) {
					dispatch(
						authActions.authenticate(tokenData.token)
					);
				}
			}
			// 3 - tokenData false
			else if (!tokenData && adminTokenData) {
				if (location.pathname.includes('admin')) {
					dispatch(
						AdminAuthActions.authenticate(adminTokenData.token)
					);
				}
			}
		};

		tryLogin();
	}, []);

	return <div />;
};

export default EntryPoint;
