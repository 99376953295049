import { Fragment, useEffect, useState } from 'react';
// local component
import AnalyticsCard from 'components/dashboard/Shared/AnalyticsCard';
// mui import
import { Skeleton } from '@material-ui/lab';
import { Box } from '@material-ui/core';
import { Tab, Tabs } from '@mui/material';
import { ReactComponent as NoOfUniqueUser } from 'assets/images/Dashboard/NoOfUniqueUser.svg';
import ChatbotLineCharts from 'components/dashboard/Shared/chatbotCharts/lineChart/ChatbotLineCharts';
import ChatbotPieCharts from 'components/dashboard/Shared/chatbotCharts/pieChart/ChatbotPieCharts';
import useSetUiLoadingError from 'hooks/useSetUiLoadingError';
// local styles
import styles from './index.module.scss';
import { Link } from 'react-router-dom';


const Component = ({
	setCurrentView,
	currentView,
	statusDurationValue,
	statusChannelValue,
	// action from redux
	getBotInteractions,
	getMostFrequentQuestions,
	getMostUsedScenarios,
	getStatementRecognation,
	getCompletionScenarios,
	getBotEscalation,
	// state from reducer
	most_frequent_questions,
	most_used_scenarios,
	StatementRecognationState,
	ChatbotCardInfo,
	completion_scenarios,
	///
	talkybotId,
}) => {
	// get current media query
	const [matches, setMatches] = useState(
		window.matchMedia('(max-width: 1600px)').matches
	);
	useEffect(() => {
		window
			.matchMedia('(max-width: 1600px)')
			.addEventListener('change', (e) => setMatches(e.matches));
	}, []);

	const [isLoadingQuestions, successDataQuestions, backendErrorQuestions, setUiLoadingErrorQuestions] =
		useSetUiLoadingError();
	const [isLoadingMostUsedScenarios, successDataMostUsedScenarios, backendErrorMostUsedScenarios, setUiLoadingErrorMostUsedScenarios] =
		useSetUiLoadingError();
	const [isLoadingStatementRecognation, successDataStatementRecognation, backendErrorStatementRecognation, setUiLoadingErrorStatementRecognation] =
		useSetUiLoadingError();
	const [isLoadingCompletionScenarios, successDataCompletionScenarios, backendErrorCompletionScenarios, setUiLoadingErrorCompletionScenarios] =
		useSetUiLoadingError();
	const [isLoadingBotEscalation, successDataBotEscalation, backendErrorBotEscalation, setUiLoadingErrorBotEscalation] =
		useSetUiLoadingError();

	useEffect(() => {
		getBotInteractions({
			talkyBotID: talkybotId,
			duration_filter: statusDurationValue,
			provider_filter: statusChannelValue,
			setUiLoadingError: setUiLoadingErrorQuestions,
		});
		getMostFrequentQuestions({
			talkyBotID: talkybotId,
			duration_filter: statusDurationValue,
			provider_filter: statusChannelValue,
			setUiLoadingError: setUiLoadingErrorQuestions,
		});
		getBotEscalation({
			talkyBotID: talkybotId,
			duration_filter: statusDurationValue,
			provider_filter: statusChannelValue,
			setUiLoadingError: setUiLoadingErrorBotEscalation,
		});
		getMostUsedScenarios({
			talkyBotID: talkybotId,
			duration_filter: statusDurationValue,
			provider_filter: statusChannelValue,
			setUiLoadingError: setUiLoadingErrorMostUsedScenarios,
		});
		getStatementRecognation({
			talkyBotID: talkybotId,
			duration_filter: statusDurationValue,
			provider_filter: statusChannelValue,
			setUiLoadingError: setUiLoadingErrorStatementRecognation,
		});
		getCompletionScenarios({
			talkyBotID: talkybotId,
			duration_filter: statusDurationValue,
			provider_filter: statusChannelValue,
			setUiLoadingError: setUiLoadingErrorCompletionScenarios,
		});
	}, [statusDurationValue, statusChannelValue]);


	/** ********************** Start Tabs Setting ************* */

	const [tabValue, setTabValue] = useState(0)

	const handleTabsChange = (event, newValue) => {
		setTabValue(newValue);
		localStorage.setItem('dashboardTabIndex', parseInt(newValue))
	};

	function TabPanel(props) {
		const { children, value, index, ...other } = props;
		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`simple-tabpanel-${index}`}
				aria-labelledby={`simple-tab-${index}`}
				{...other}
			>
				{value === index && (
					<Box sx={{ p: 3 }}>
						<div>{children}</div>
					</Box>
				)}
			</div>
		);
	}

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	}
	const tabs = ['AR', 'EN'];
	/** ********************** End Tabs Setting ************* */
	return (
		<div className={styles.Container}>
			<div className={styles.Wrapper}>
				{ChatbotCardInfo.map((card) => (
					<Fragment key={card.id}>
						<AnalyticsCard
							cardInfo={card}
							// TODO: updata isLoading
							isLoading={isLoadingQuestions}
						/>
					</Fragment>
				))}

				{/* Statement Recognition */}
				{
					isLoadingStatementRecognation ? (
						<Skeleton className={styles.StatementRecognationSkeleton} variant="rect" animation="wave" height={matches ? 232 : 253} />
					) : (
						<div className={styles.statementRecognition}>
							<ChatbotPieCharts
								ChartsInfo={StatementRecognationState}
								isLoading={isLoadingStatementRecognation}
							/>
						</div>
					)
				}

				{/* Most Scenarios Used */}
				{
					isLoadingMostUsedScenarios ? (
						<Skeleton className={styles.MostUsedScenariosSkeleton} variant="rect" animation="wave" height={matches ? 232 : 253} />
					) : (
						<div className={styles.mostScenariosUsed}>
							<h3>Most Scenarios Used</h3>
							{
								most_used_scenarios?.length ? (
									<ul>
										{most_used_scenarios?.slice(0, 5)?.map((item, index) => (
											<li key={index}>
												<span>{item.title}</span>
												<span>{item.value}</span>
											</li>
										))}
									</ul>
								) : (
									<NoOfUniqueUser />
								)
							}
						</div>
					)
				}

				{/* Most Frequent Questions */}
				{
					isLoadingQuestions ? (
						<Skeleton className={styles.quistionSkeleton} variant="rect" animation="wave" height={matches ? 232 : 253} />
					) : (
						<div className={styles.mostFrequentQuestions}>
							<div className={styles.header}>
								<h3>Most Frequent Questions</h3>
								<div className={styles.tabsContainer}>
									<Tabs
										value={tabValue}
										onChange={handleTabsChange}
										aria-label="basic tabs example"
										classes={{ indicator: styles.tabIndicator }}
										textColor="inherit"
									>
										{tabs.map((tab, index) => (
											<Tab
												sx={{
													fontWeight:
														tabValue === index ? 'bold' : '300',
													color:
														tabValue === index
															? '#000000'
															: '#787878',
												}}
												className={styles.tabLabel}
												label={tab}
												{...a11yProps(index)}
												key={tab}
											/>
										))}
									</Tabs>
								</div>
							</div>
							<div className={styles.body}>
								<TabPanel value={tabValue} index={0}>
									{
										most_frequent_questions['ar']?.length ? (
											<ul>
												{
													most_frequent_questions['ar']?.slice(0, 5)?.map((item) => (
														<li key={item.intent_id} title={item.faq_body}>
															<Link to={`/talkybots/${talkybotId}/bot-builder/${item.intent_id}/faqs/create`}>
																<span>{item.faq_body}</span>
																<span>{item.usage}</span>
															</Link>
														</li>
													))
												}
											</ul>
										) : (
											<NoOfUniqueUser />
										)
									}
								</TabPanel>
								<TabPanel value={tabValue} index={1}>
									{
										most_frequent_questions['en']?.length ? (
											<ul>
												{
													most_frequent_questions['en']?.slice(0, 5)?.map((item) => (
														<li key={item.intent_id} title={item.faq_body}>
															<Link to={`/talkybots/${talkybotId}/bot-builder/${item.intent_id}/faqs/create`}>
																<span>{item.faq_body}</span>
																<span>{item.usage}</span>
															</Link>
														</li>
													))
												}
											</ul>
										) : (
											<NoOfUniqueUser />
										)
									}
								</TabPanel>
							</div>
						</div>
					)
				}

				{/* Completion scenarios */}
				{
					isLoadingCompletionScenarios ? (
						<Skeleton className={styles.CompletionScenariosSkeleton} variant="rect" animation="wave" height={matches ? 232 : 253} />
					) : (
						<div className={styles.completionScenarios}>
							<ChatbotLineCharts
								setCurrentView={setCurrentView}
								currentView={currentView}
								InfoData={completion_scenarios}
								statusDurationValue={statusDurationValue}
							/>
						</div>
					)
				}
			</div>
		</div>
	);
};

export default Component;
