import { authPath, notificationsPath } from 'constants/apis';
import toTimestamp from 'helpers/to-timestamp';

import axios from 'helpers/axios';


import setAuthToken from 'helpers/set-auth-token';
import {
	SEND_RESET_PASSWORD_INSTRUCTIONS_SUCCESS,
	SEND_RESET_PASSWORD_INSTRUCTIONS_FAIL,
	RESET_PASSWORD,
	GET_CURRENT_USER,
	AUTHENTICATE,
	REGISTER_USER_FAILED,
	REGISTER_USER_SUCCESS,
	LOG_USER_IN_FAILED,
	SAVE_USER_PERMISSION_TOKEN_SUCCESS,
	SAVE_USER_PERMISSION_TOKEN_FAILED,
	EDIT_CURRENT_USER_PROFILE_SUCCESS,
	EDIT_CURRENT_USER_PROFILE_FAIL,
	EDIT_PASSWORD_FAIL,
	EDIT_PASSWORD_SUCCESS,
	DELETE_USER_PERMISSION_TOKEN_SUCCESS,
	DELETE_USER_PERMISSION_TOKEN_FAILED,
	SEND_TENANT_NAME_REMINDER_EMAIL_SUCCESS,
	SEND_TENANT_NAME_REMINDER_EMAIL_FAIL,
	CLEAR_IS_AUTHENTICATED,
	SEND_RESET_PASSWORD_FAIL,
	RESEND_CONFIRMATION_SIGNUP_SUCCESS,
	RESEND_CONFIRMATION_SIGNUP_FAIL,
	SEND_CONFIRM_USER_SUCCESS,
	SEND_CONFIRM_USER_FAIL,
	UPLOAD_AVATAR_FAIL,
	UPLOAD_AVATAR_SUCCESS,
	NOTIFICATION_STATUS_CHANGE_SUCCESS,
	NOTIFICATION_STATUS_CHANGE_FAIL,
	LOGED_IN_USER_NOTIFICATION_STATUS_CHANGE,
	GET_CURRENT_USER_FAILED,
} from 'store/actions/types/auth';

import apiRequest from 'helpers/apiRequest';
import { requestForToken } from 'config/firebase';

let timer;
/**
 *
 * @param token
 * @param expiresAt
 */
const saveDataToStorage = (token, expiresAt) => {
	localStorage.setItem(
		'tokenData',
		JSON.stringify({
			token,
			expiresAt,
		})
	);
};

const clearLogoutTimer = () => {
	if (timer) {
		clearTimeout(timer);
	}
};

export const deleteUserPermissionTokenAction = (tokenConfig) => {
	return async (dispatch) => {
		try {
			await axios.delete('/api/tokens', { params: tokenConfig });
			dispatch({ type: DELETE_USER_PERMISSION_TOKEN_SUCCESS });
		} catch (e) {
			dispatch({ type: DELETE_USER_PERMISSION_TOKEN_FAILED });
		}
	};
};

/**
 *
 * @returns {{type: string}}
 */
export const logUserOutAction = () => {
	localStorage.removeItem('tokenData');
	localStorage.removeItem('dashboardTabIndex');
	localStorage.setItem('dashboardView', 'main')
	localStorage.removeItem('firebase_token');
	// localStorage.removeItem('persist:root');
	// read persisted state from localStorage and remove auth state from it before rehydrating
	// const persistedState = localStorage.getItem('persist:root');
	// if (persistedState) {
	// 	const state = JSON.parse(persistedState);
	// 	delete state.auth;
	// 	localStorage.setItem('persist:root', JSON.stringify(state));
	// }
	clearLogoutTimer();
	window.location.assign('/login');
};

export const logUserOutWithoutRouteAction = () => {
	console.log('remove', 'log user out without route');
	localStorage.removeItem('tokenData');
	localStorage.removeItem('firebase_token');
	// localStorage.removeItem('persist:root');
	clearLogoutTimer();
};

/**
 *
 * @param expirationTime
 * @returns {(function(*): void)|*}
 */
// const setLogoutTimer = (expirationTime) => {
// 	return (dispatch) => {
// 		const expiresIn = new Date(expirationTime) - new Date();
// 		timer = setTimeout(() => {
// 			dispatch(logUserOutAction());
// 		}, expiresIn);
// 	};
// };

/**
 *
 * @returns {(function(*): Promise<void>)|*}
 */

export const getCurrentUser = (setUiLoadingError = () => { }) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			const { attributes } = responseData.data;
			dispatch({
				type: GET_CURRENT_USER,
				payload: { ...attributes },
			});
		};
		const onError = (e) => {
			dispatch({
				type: GET_CURRENT_USER_FAILED,
				payload: 'error',
			});
		};
		apiRequest.get({
			url: `${authPath}`,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};

/**
 *
 * @param token
 * @param expiresAt
 * @returns {(function(*): Promise<void>)|*}
 */
export const authenticate = (token) => {
	return async (dispatch) => {
		const firebaseToken = localStorage.getItem('firebase_token');
		setAuthToken(token);
		if (token) {
			const jwtPayload = JSON.parse(window.atob(token.split('.')[1]));
			const exp = new Date(jwtPayload.exp * 1000);
			if (exp < Date.now()) {
				console.log('token expired');
			} else {
				saveDataToStorage(token, exp);
			}
			if(!firebaseToken){

				requestForToken(dispatch);
			}
			// firebase token request for push notification
		}
		await dispatch(getCurrentUser());
	};
};
export const clearAuthenticateAction = () => {
	return (dispatch) => {
		dispatch({ type: CLEAR_IS_AUTHENTICATED });
	};
};

/**
 *
 * @param userData
 * @param history
 * @returns {(function(*, *): Promise<void>)|*}
 */

export const logUserInAction = (userData, history, setUiLoadingError) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			const { token } = responseData.data;
			await dispatch(authenticate(token));
		};
		const onError = (e) => {
			dispatch({
				type: LOG_USER_IN_FAILED,
			});
		};

		apiRequest.post({
			url: `${authPath}/sign_in`,
			bodyData: userData,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};

/**
 *
 * @param confirmationToken
 * @param history
 * @returns {(function(*): Promise<void>)|*}
 */

export const confirmUserAction = (
	confirmationToken,
	setUiLoadingError,
	history,
	enqueueSnackbar
) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			dispatch({
				type: SEND_CONFIRM_USER_SUCCESS,
				payload: responseData.data,
			});
			setTimeout(() => {
				history.push('/login')
			}, 2000)
			// localStorage.removeItem('tokenData')
			enqueueSnackbar('Your account has been confirmed', {
				variant: 'success',
			});
		};
		const onError = (e) => {
			dispatch({
				type: SEND_CONFIRM_USER_FAIL,
			});
			// remove tokenData
			enqueueSnackbar('Your account has not been confirmed', {
				variant: 'error',
			});
		};

		apiRequest.get({
			url: `${authPath}/confirmation?confirmation_token=${confirmationToken}`,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};

/**
 *
 * @param payload
 * @returns {(function(*): Promise<void>)|*}
 */
export const sendResetPasswordInstructionsAction = (
	data,
	history,
	setUiLoadingError
) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			dispatch({ type: SEND_RESET_PASSWORD_INSTRUCTIONS_SUCCESS });
		};
		const onError = (e) => {
			dispatch({
				type: SEND_RESET_PASSWORD_INSTRUCTIONS_FAIL,
			});
		};

		apiRequest.post({
			url: `${authPath}/password`,
			bodyData: data,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};

/**
 *
 * @param payload
 * @param history
 * @returns {(function(*): Promise<void>)|*}
 */

export const resetPasswordAction = (data, history, setUiLoadingError, enqueueSnackbar) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			dispatch({ type: RESET_PASSWORD });
			setTimeout(() => {
				history.push('/login')
			}, 2000)
			enqueueSnackbar('Your password has changed successfully.', {
				variant: 'success',
			});
		};
		const onError = (e) => {
			dispatch({
				type: SEND_RESET_PASSWORD_FAIL,
			});
		};

		apiRequest.put({
			url: `${authPath}/password`,
			bodyData: data,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};
/**
 *
 * @param payload
 * @returns {(function(*): Promise<void>)|*}
 */

export const sendTenantNameReminderEmailAction = (data, setUiLoadingError) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			dispatch({ type: SEND_TENANT_NAME_REMINDER_EMAIL_SUCCESS });
		};
		const onError = (e) => {
			dispatch({
				type: SEND_TENANT_NAME_REMINDER_EMAIL_FAIL,
			});
		};

		apiRequest.post({
			url: `/api/reminders`,
			bodyData: data,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};

/**
 *
 * @param userData
 * @param history
 * @returns {(function(*): Promise<void>)|*}
 */

export const registerUserAction = (userData, history, setUiLoadingError) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			dispatch({
				type: REGISTER_USER_SUCCESS,
			});
			history.push(`/confirmation-signup/${responseData?.data?.id}`);
		};
		const onError = (e) => {
			dispatch({
				type: REGISTER_USER_FAILED,
			});
		};
		apiRequest.post({
			url: `${authPath}`,
			bodyData: userData,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};

/**
 *
 * @param userId
 * @returns {(function(*): Promise<void>)|*}
 */

export const resendConfirmSignUpAction = (userId, setUiLoadingError) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			dispatch({ type: RESEND_CONFIRMATION_SIGNUP_SUCCESS });
		};
		const onError = (e) => {
			dispatch({
				type: RESEND_CONFIRMATION_SIGNUP_FAIL,
			});
		};
		apiRequest.post({
			url: `${authPath}/confirmation?id=${userId}`,
			bodyData: '',
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};

export const saveUserPermissionTokenAction = (tokenConfig) => {
	return async (dispatch) => {
		try {
			await axios.post(`/api/tokens`, tokenConfig);
			dispatch({ type: SAVE_USER_PERMISSION_TOKEN_SUCCESS });
		} catch (e) {
			dispatch({ type: SAVE_USER_PERMISSION_TOKEN_FAILED });
		}
	};
};

export const editUserProfile = (name, setUiLoadingError, enqueueSnackbar) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			dispatch({
				type: EDIT_CURRENT_USER_PROFILE_SUCCESS,
				payload: responseData.data.attributes,
			});
			enqueueSnackbar(`Username changed successfuly`, {
				variant: 'success',
				autoHideDuration: 2000,
			});
		};
		const onError = (e) => {
			dispatch({
				type: EDIT_CURRENT_USER_PROFILE_FAIL,
			});
		};
		apiRequest.put({
			url: `${authPath}/update_profile`,
			bodyData: { name },
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};

export const editPassword = (data, setUiLoadingError, enqueueSnackbar) => {
	const Data = {
		old_password: data.oldpassword,
		new_password: data.newpassword,
		password_confirmation: data.confirmpassword,
	};

	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			dispatch({
				type: EDIT_PASSWORD_SUCCESS,
				payload: responseData.data.attributes,
			});
			enqueueSnackbar(`Password changed successfuly`, {
				variant: 'success',
				autoHideDuration: 2000,
			});
		};
		const onError = (e) => {
			dispatch({
				type: EDIT_PASSWORD_FAIL,
			});
		};
		apiRequest.put({
			url: `${authPath}/update_password`,
			bodyData: Data,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};

export const editUserProfileImage = (formData, setUiLoadingError, config, enqueueSnackbar) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			dispatch({
				type: UPLOAD_AVATAR_SUCCESS,
				payload: responseData.data.attributes,
			});
			enqueueSnackbar('Profile picture changed successfuly', {
				variant: 'success',
				autoHideDuration: 2000
			});
		};
		const onError = (e) => {
			dispatch({
				type: UPLOAD_AVATAR_FAIL,
			});
		};

		apiRequest.put({
			url: `${authPath}/update_profile_image`,
			bodyData: formData,
			onSuccess,
			onError,
			setUiLoadingError,
			config,
		});
	};
};

// notification status change action

export const notificationStatusChangeAction = (
	setUiLoadingError = () => { }
) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			dispatch({
				type: NOTIFICATION_STATUS_CHANGE_SUCCESS,
				payload: {
					seen: responseData.data,
				},
			});
		};
		const onError = (e) => {
			dispatch({
				type: NOTIFICATION_STATUS_CHANGE_FAIL,
			});
		};

		apiRequest.put({
			url: `${notificationsPath}/read_notifications`,
			bodyData: {},
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};

// notification status change action
export const changeLoginUserNotificationStatus = () => {
	// set loggedin User notification status to false ... means that the user not reed his/her new notificaions
	return async (dispatch) => {
		dispatch({
			type: LOGED_IN_USER_NOTIFICATION_STATUS_CHANGE,
		});
	};
};
