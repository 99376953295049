import { useEffect, useState } from 'react';
// mui
import { ReactComponent as NoOfUniqueUser } from 'assets/images/Dashboard/NoOfUniqueUser.svg';
import { Cell, Legend, Pie, PieChart } from 'recharts';
import { Skeleton } from '@material-ui/lab';
// local styles
import styles from './index.module.scss';
function OverViewCharts({ ChartsInfo, isLoading }) {
    // get current media query
    const [matches, setMatches] = useState(
        window.matchMedia('(max-width: 1600px)').matches
    );
    useEffect(() => {
        window
            .matchMedia('(max-width: 1600px)')
            .addEventListener('change', (e) => setMatches(e.matches));
        return () => window.removeEventListener('change', (e) => setMatches(e.matches));
    }, []);
    const renderLegendVertically = (props) => {
        const { payload } = props;
        return (
            <ul className={styles.verticalListContainer}>
                {payload.map((entry, index) => (
                    <li key={index} className={styles.item}>
                        <span
                            className={styles.colorBox}
                            style={{
                                backgroundColor: `${entry.color}`,
                            }}
                        ></span>
                        <div className={styles.itemInfo}>
                            <span className={styles.title}>{entry.value}</span>
                            <span className={styles.result}>
                                {parseInt(entry.payload.value).toString()}
                            </span>
                        </div>
                    </li>
                ))}
            </ul>
        );
    };
    const renderLegendHorizontally = (props) => {
        const { payload } = props;
        return (
            <ul className={styles.horizontalListContainer}>
                {payload.map((entry, index) => (
                    <li key={index} className={styles.item}>
                        <span
                            className={styles.colorBox}
                            style={{
                                backgroundColor: `${entry.color}`,
                            }}
                        ></span>
                        <div className={styles.itemInfo}>
                            <span className={styles.title}>{entry.value}</span>
                            <span className={styles.result}>
                                {parseInt(entry.payload.value)}
                            </span>
                        </div>
                    </li>
                ))}
            </ul>
        );
    };
    return (
        <>
            {
                isLoading ? (
                    <Skeleton className={styles.skeleton} variant="rect" animation="wave" height={matches ? 237 : 281} />
                ) : (
                    <div className={styles.container}>
                        <h3>{ChartsInfo.title}</h3>
                        {
                            (ChartsInfo.total !== 0 && !isLoading) ? (
                                <PieChart
                                    width={matches ? 350 : 520}
                                    height={matches ? 200 : 230}
                                    className={ChartsInfo.data?.length > 2 ? styles.wrapper : styles.wrapperV}
                                    key={ChartsInfo.data?.id}
                                >
                                    <Legend
                                        layout={
                                            ChartsInfo.data?.length <= 2 ? 'vetical' : 'horizontal'
                                        }
                                        verticalAlign={
                                            ChartsInfo.data?.length <= 2 ? 'middle' : 'bottom'
                                        }
                                        align={ChartsInfo.data?.length <= 2 ? 'right' : 'center'}
                                        width={
                                            ChartsInfo.data?.length <= 2 && matches
                                                ? '40%'
                                                : ChartsInfo.data?.length <= 2 && !matches
                                                    ? '225px'
                                                    : '100%'
                                        }
                                        content={
                                            ChartsInfo.data?.length <= 2
                                                ? renderLegendVertically
                                                : renderLegendHorizontally
                                        }
                                    />
                                    <text
                                        x={
                                            ChartsInfo.data?.length <= 2
                                                ? 95
                                                : ChartsInfo.data?.length > 2 && matches
                                                    ? 175
                                                    : ChartsInfo.data?.length > 2 && !matches && 240
                                        }
                                        y={
                                            ChartsInfo.data?.length <= 2 && matches
                                                ? 95
                                                : ChartsInfo.data?.length <= 2 && !matches
                                                    ? 110
                                                    : ChartsInfo.data?.length > 2 && matches
                                                        ? 75
                                                        : ChartsInfo.data?.length > 2 && !matches && 85
                                        }
                                        textAnchor="middle"
                                    >
                                        Total
                                    </text>
                                    <text
                                        x={
                                            ChartsInfo.data?.length <= 2
                                                ? 95
                                                : ChartsInfo.data?.length > 2 && matches
                                                    ? 175
                                                    : ChartsInfo.data?.length > 2 && !matches && 240
                                        }
                                        y={
                                            ChartsInfo.data?.length <= 2 && matches
                                                ? 115
                                                : ChartsInfo.data?.length <= 2 && !matches
                                                    ? 130
                                                    : ChartsInfo.data?.length > 2 && matches
                                                        ? 95
                                                        : ChartsInfo.data?.length > 2 && !matches && 105
                                        }
                                        textAnchor="middle"
                                        fontWeight="bold"
                                    >
                                        {ChartsInfo.total}
                                    </text>
                                    <Pie
                                        data={ChartsInfo.data}
                                        cx={
                                            ChartsInfo.data?.length <= 2
                                                ? 90
                                                : ChartsInfo.data?.length > 2 && matches
                                                    ? 170
                                                    : ChartsInfo.data?.length > 2 && !matches && 240
                                        }
                                        innerRadius={50}
                                        outerRadius={70}
                                        legendType="square"
                                        fill="#8884D8"
                                        key={Math.random()}
                                    >
                                        {ChartsInfo.data?.map((entry, index) => (
                                            <Cell
                                                key={`cell-${index}`}
                                                fill={ChartsInfo.colors[index]}
                                            />
                                        ))}
                                    </Pie>
                                </PieChart>
                            ) : (
                                <NoOfUniqueUser />
                            )
                        }
                    </div>
                )
            }
        </>
    );
}
export default OverViewCharts;