import React from 'react';
import styles from './index.module.scss';

const SentimentCard = ({ positive, negative, neutral }) => {
  const total = positive + negative + neutral;
  const positivePercentage = (positive / total) * 100
  const negativePercentage = (negative / total) * 100
  const neutralePercentage = (neutral / total) * 100
  return (
    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '20px' }}>
      <div
        style={{
          backgroundColor: '#E5484D  ',
          width: `${negativePercentage}%`,
          borderRadius: '4px',
					height: '10px',
          marginRight: '2px',
        }}
      ></div>
      <div
        style={{
          backgroundColor: '#FFA01C',
          width: `${neutralePercentage}%`,
          borderRadius: '4px',
					height: '10px',
          marginRight: '2px',
        }}
      ></div>
      <div
        style={{
          backgroundColor: '#46A758',
          width: `${positivePercentage}%`,
          borderRadius: '4px',
					height: '10px',
        }}
      ></div>

    </div>
  );
};

export default SentimentCard;