import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

const GridContainer = (props) => {
	const {
		className,
		children,
		spacing,
		alignItems,
		direction,
		justifyContent,
		...rest
	} = props;
	return (
		<Grid
			container
			spacing={spacing}
			justifyContent={justifyContent}
			alignItems={alignItems}
			direction={direction}
			{...rest}
			className={className}
			item
		>
			{children}
		</Grid>
	);
};

GridContainer.propTypes = {
	children: PropTypes.node,
	alignItems: PropTypes.oneOf([
		'flex-start',
		'center',
		'flex-end',
		'stretch',
		'baseline',
	]),
	justifyContent: PropTypes.oneOf([
		'flex-start',
		'center',
		'flex-end',
		'space-between',
		'space-around',
		'space-evenly',
	]),
	direction: PropTypes.oneOf([
		'row',
		'row-reverse',
		'column',
		'column-reverse',
	]),
	spacing: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
	classes: PropTypes.object,
	lg: PropTypes.oneOf([
		false,
		'auto',
		true,
		1,
		2,
		3,
		4,
		5,
		6,
		7,
		8,
		9,
		10,
		11,
		12,
	]),
	xl: PropTypes.oneOf([
		false,
		'auto',
		true,
		1,
		2,
		3,
		4,
		5,
		6,
		7,
		8,
		9,
		10,
		11,
		12,
	]),
	xs: PropTypes.oneOf([
		false,
		'auto',
		true,
		1,
		2,
		3,
		4,
		5,
		6,
		7,
		8,
		9,
		10,
		11,
		12,
	]),
	md: PropTypes.oneOf([
		false,
		'auto',
		true,
		1,
		2,
		3,
		4,
		5,
		6,
		7,
		8,
		9,
		10,
		11,
		12,
	]),
	sm: PropTypes.oneOf([
		false,
		'auto',
		true,
		1,
		2,
		3,
		4,
		5,
		6,
		7,
		8,
		9,
		10,
		11,
		12,
	]),
};

export default GridContainer;
