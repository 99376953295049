import React from 'react';
import { CardContent, Grid, Typography } from '@material-ui/core';
import renderDate from 'helpers/renderDate';
import useStyles from './styles';

const UpdatePersonBody = ({ userName, personName, content }) => {
	const classes = useStyles();
	const hourFormate = 'h:mm am/pm';
	// Mai Salah updated Shrouk Ahmed's profile
	return (
		<Grid container className={classes.root}>
			<Grid item xs={9} className={classes.statusWrapper}>
				<CardContent className={classes.content}>
					<Typography variant="body" className={classes.activity}>
						<Typography display="inline" className={classes.action}>
							{userName && userName}{' '}
						</Typography>
						Updated{' '}
						{/* <Typography display="inline" className={classes.action}> */}
						{/* <Typography display="inline">
							{content.fieldName && content.fieldName}
						</Typography> */}
						{/* {' of '} */}
						<Typography
							display="inline"
							className={classes.action}
							title={personName || ''}
						>
							{`${personName}  `}
						</Typography>
						<Typography display="inline">
							{' '}
							{` 's (${content.fieldName.replace("_", " ")})`}
						</Typography>
						{/* {content.from && (
							<>
								From{' '}
								<Typography
									display="inline"
									className={classes.action}
								>
									{content.from.slice(0, 16)}{' '}
									{content.from.length > 16 && '...'}{' '}
								</Typography>
							</>
						)}
						{content.to && (
							<>
								To{' '}
								<Typography
									display="inline"
									className={classes.messageContainer}
								>
									{content.to}
								</Typography>
							</>
						)} */}
					</Typography>
				</CardContent>
				<div style={{ display: 'flex', gap: '5px' }}>
					<span style={{ fontWeight: 600 }}>From</span>
					<span title={content.from}>{content.from}</span>
					<span style={{ fontWeight: 600 }}>To</span>
					<span title={content.to}>{content.to}</span>
				</div>
			</Grid>
			{/* for the date */}
			<Grid item xs={3} className={classes.dateWrapper}>
				<CardContent className={classes.content}>
					<Typography variant="body2" className={classes.date}>
						{renderDate(content.date, hourFormate)}
						{/* {content.date} */}
					</Typography>
				</CardContent>
			</Grid>
		</Grid>
	);
};

export default UpdatePersonBody;
