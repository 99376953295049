/* eslint-disable import/prefer-default-export */
export const SET_CRM_TAB_PERSON_PROFILE =
	'[CRM][PersonProfile] set crm tab person profile for reloading';
export const SET_BOT_BUILDER_TAB =
	'[BotBuilder] set bot builder tab for reloading';
export const SET_SETTINGS_TAB = '[Settings] set settings tab';
export const SET_BOT_GATES_TAB = '[BotGates] set bot social tabs for reloading';
export const SET_CONVERSATION_TAB =
	'[conversation] set bot social tabs for reloading';
export const SET_FALLBACK_TAB = '[Fallbacks] set fallback tab for reloading';
export const SET_BOT_BUILDER_SETTINGS_TAB =
	'[BotSettingsTabs] set bot settings tab for reloading';
export const SET_CRM_SETTINGS_TAB =
	'[CrmSettingsTabs] set crm settings tab for reloading';
