import { Container, Grid, Card, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	mainCard: {
		overflow: 'visible',
		padding: '1.875rem 1.25rem',
		borderRadius: ' 1.5625rem 0px',
		boxShadow: '0px 6px 12px #00000029',
		textAlign: 'center',
	},
	cardHeader: {
		...theme.typography.subtitle4,
		color: theme.palette.text.primary,
	},
	SubCardPerMonth: {
		borderRadius: '0px 1.625rem',
		textAlign: 'center',
		width: '9.375rem',
		padding: '.5rem',
		backgroundColor: theme.palette.background.primary,
		margin: '1.875rem 0px 0px 0px',
		transform: 'translateX(-40%)',
	},
	subCardNum: {
		...theme.typography.subtitle4,
		color: theme.palette.text.white,
		lineHeight: 0.9,
	},
	subCardDesc: {
		...theme.typography.body4,
		color: theme.palette.text.white,
	},
	checkIcon: {
		...theme.typography.body4,
		marginRight: '1rem',
		fontWeight: 'bold',
		color: theme.palette.red,
	},
	clearIcon: {
		...theme.typography.body4,
		marginRight: '1rem',
		color: theme.palette.lightRed,
	},
	listItemText: {
		...theme.typography.subtitle4,
		color: theme.palette.text.dark,
	},
	readMoreBtn: {
		...theme.typography.body3,
		textAlign: 'center',
		textDecoration: 'none',
	},
	readMoreIcon: {
		...theme.typography.body3,
		padding: '0px',
		lineHeight: 0,
		padding: '0px 0.625rem ',
	},
	getStartBtn: {
		...theme.typography.body4,
		border: '2px solid #43348B',
		borderRadius: ' 4px',
		color: theme.palette.text.primary,
		backgroundColor: 'white',

		margin: '1.875rem auto',
	},
}));

const SinglePricingFrame = ({
	listObj = [],
	header,
	price,
	ReadMoreLink = '/',
}) => {
	const classes = useStyles();
	return (
		<Card className={classes.mainCard}>
			<Typography className={classes.cardHeader}>{header}</Typography>
			<Card className={classes.SubCardPerMonth}>
				<Typography className={classes.subCardNum}>{price}</Typography>
				<Typography className={classes.subCardDesc}>
					per Month
				</Typography>
			</Card>
			<List>
				{listObj.map((obj) => (
					<ListItem disableGutters={true} dense={true}>
						{obj.status && (
							<CheckIcon className={classes.checkIcon} />
						)}
						{!obj.status && (
							<ClearIcon className={classes.clearIcon} />
						)}
						<ListItemText className={classes.listItemText}>
							{obj.text}
						</ListItemText>
					</ListItem>
				))}
			</List>
			<Link to={ReadMoreLink} className={classes.readMoreBtn}>
				<Typography align="center" alignItem="center">
					Read More
					<DoubleArrowIcon className={classes.readMoreIcon} />
				</Typography>
			</Link>
			<Button className={classes.getStartBtn} variant="outlined">
				Get started
			</Button>
		</Card>
	);
};

export default SinglePricingFrame;
