/* eslint-disable import/prefer-default-export */

import {
	SET_CRM_TAB_PERSON_PROFILE,
	SET_BOT_BUILDER_TAB,
	SET_SETTINGS_TAB,
	SET_BOT_GATES_TAB,
	SET_FALLBACK_TAB,
	SET_BOT_BUILDER_SETTINGS_TAB,
	SET_CRM_SETTINGS_TAB,
	SET_CONVERSATION_TAB,
} from 'store/actions/types/tabs';

// action creator for tabs to persist the current tab when the user reload the page
export const setCrmTabPersonProfile = (tab) => {
	return async (dispatch, getState) => {
		dispatch({
			type: SET_CRM_TAB_PERSON_PROFILE,
			payload: tab,
		});
	};
};

// action creator for tab to persist the current tab when reload the page [bot builder]
export const setBotBuilderTab = (tab) => {
	return async (dispatch, getState) => {
		dispatch({
			type: SET_BOT_BUILDER_TAB,
			payload: tab,
		});
	};
};

// action creator for tabs to persist the current tab when the user reload the page (settings)
export const setSettingsTab = (tab) => {
	return async (dispatch, getState) => {
		dispatch({
			type: SET_SETTINGS_TAB,
			payload: tab,
		});
	};
};

export const setBotGatesTab = (tab) => {
	return async (dispatch, getState) => {
		dispatch({
			type: SET_BOT_GATES_TAB,
			payload: tab,
		});
	};
};

export const setConversationTab = (tab) => {
	return async (dispatch, getState) => {
		dispatch({
			type: SET_CONVERSATION_TAB,
			payload: tab,
		});
	};
};

export const setFallBackTab = (tab) => {
	return async (dispatch, getState) => {
		dispatch({
			type: SET_FALLBACK_TAB,
			payload: tab,
		});
	};
};

export const setBotBuilderSettingsTab = (tab) => {
	return async (dispatch, getState) => {
		dispatch({
			type: SET_BOT_BUILDER_SETTINGS_TAB,
			payload: tab,
		});
	};
};
export const setCrmSettingsTab = (tab) => {
	return async (dispatch, getState) => {
		dispatch({
			type: SET_CRM_SETTINGS_TAB,
			payload: tab,
		});
	};
};
