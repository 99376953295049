import { unRegisterServiceWorker } from 'serviceWorker';
import talkybotAxios from './axios';

export const RemoveTokenAndRedirectToLogin = (e) => {
	if (e?.response?.data?.error?.code === 'un_authenticated') {
		if (window.location.pathname.includes('admin')) {
			localStorage.removeItem('adminToken');
			window.location.href = '/admin';
		} else {
			localStorage.removeItem('tokenData');
			localStorage.removeItem('firebase_token');
			unRegisterServiceWorker();
			window.location.href = '/login';
		}
	} else if (e?.response?.data?.error?.code === 'un_authorized') {
		localStorage.removeItem('tokenData');
		localStorage.removeItem('firebase_token');
		unRegisterServiceWorker();
		window.location.href = '/login';
	}	else if (e?.response?.data?.error?.code === 'un_authorized_operation') {
    window.location.href = '/talkybots';
	}
};

const apiRequest = {
	post: async ({ url, bodyData, onSuccess, onError, setUiLoadingError }) => {
		try {
			setUiLoadingError({ loading: true, error: null });
			const { data } = await talkybotAxios.post(url, bodyData);
			console.log('data...........', data);
			onSuccess(data);
			setUiLoadingError({
				loading: false,
				success: data ? data : true,
				error: null,
			});
		} catch (e) {
			RemoveTokenAndRedirectToLogin(e);
			setUiLoadingError({
				loading: false,
				success: null,
				error: e?.response?.data?.error?.message || 'Failed try Again',
				code: e?.response?.data?.error?.code,
			});
			onError(e);
		}
	},
	patch: async ({ url, bodyData, onSuccess, onError, setUiLoadingError }) => {
		try {
			setUiLoadingError({ loading: true, error: null });
			const { data } = await talkybotAxios.patch(url, bodyData);
			onSuccess(data);
			setUiLoadingError({
				loading: false,
				success: data ? data : true,
				error: null,
			});
		} catch (e) {
			RemoveTokenAndRedirectToLogin(e);
			setUiLoadingError({
				loading: false,
				success: null,
				error: e?.response?.data?.error?.message || 'Failed try Again',
				code: e?.response?.data?.error?.code,
			});
			onError(e);
		}
	},
	delete: async ({ url, params, bodyData, onSuccess, onError, setUiLoadingError }) => {
		try {
			setUiLoadingError({ loading: true, error: null });
			const { data } = await talkybotAxios.delete(url, {
				params,
				data: bodyData
			});
			onSuccess(data);
			setUiLoadingError({
				loading: false,
				success: data ? data : true,
				error: null,
			});
		} catch (e) {
			RemoveTokenAndRedirectToLogin(e);
			setUiLoadingError({
				loading: false,
				success: null,
				error: e?.response?.data?.error?.message || 'Failed try Again',
				code: e?.response?.data?.error?.code,
			});
			onError(e);
		}
	},
	put: async ({
		url,
		bodyData,
		onSuccess,
		onError,
		setUiLoadingError,
		config = null,
	}) => {
		try {
			setUiLoadingError({ loading: true, error: null });

			const response = await talkybotAxios.put(url, bodyData, config);
			const data = response.data;
			onSuccess(data);

			setUiLoadingError({
				loading: false,
				success: data ? data : true,
				error: null,
			});
		} catch (e) {
			RemoveTokenAndRedirectToLogin(e);
			setUiLoadingError({
				loading: false,
				success: null,
				error: e?.response?.data?.error?.message || 'Failed try Again',
				code: e?.response?.data?.error?.code,
			});

			onError(e);
		}
	},
	get: async ({
		url,
		options = {},
		onSuccess,
		onError,
		setUiLoadingError = () => {},
	}) => {
		try {
			setUiLoadingError({ loading: true, error: null });
			const { data } = await talkybotAxios.get(url, options);
			onSuccess(data);
			setUiLoadingError({
				loading: false,
				success: data ? data : true,
				error: null,
			});
		} catch (e) {
			RemoveTokenAndRedirectToLogin(e);
			setUiLoadingError({
				loading: false,
				success: null,
				error: e?.response?.data?.error?.message || 'Failed try Again',
				code: e?.response?.data?.error?.code,
			});
			onError(e);
		}
	},
};
export default apiRequest;

export const getErrorMessageAndCode = (e, defaultMessage) => {
	return e.response?.data?.error || { message: defaultMessage, code: null };
};
