export const SEND_OTP_TO_ADMIN_SUCCESS = 'send_otp_to_admin_success';
export const SEND_OTP_TO_ADMIN_FAILED = 'send_otp_to_admin_failed';
export const VALIDATE_OTP_SUCCESS = 'validate_otp_success';
export const VALIDATE_OTP_FAILED = 'validate_otp_failed';
export const RESEND_OTP_SUCCESS = 'resend_otp_success';
export const RESEND_OTP_FAILED = 'resend_otp_failed';
export const CREATE_ORGANIZATION_SUCCESS = '[adminPanel] create organization success';
export const CREATE_ORGANIZATION_FAILED = '[adminPanel] create organization failed';
export const GET_USAGE_OF_SINGLE_TENANT_SUCCESS = 'get_usage_of_single_tenant_success';
export const GET_USAGE_OF_SINGLE_TENANT_FAILED = 'get_usage_of_single_tenant_success_failed';
export const TOGGLE_ACTIVITY_SUCCESS = 'toggle_activity_success';
export const TOGGLE_ACTIVITY_FAILED = 'toggle_activity_failed';
export const TOGGLE_BLOCKED_SUCCESS = 'toggle_blocked_success';
export const TOGGLE_BLOCKED_FAILED = 'toggle_blocked_failed';
export const EDIT_CURRENT_SUBSCRIBTION_SUCCESS = 'edit_current_subscribtion_success';
export const EDIT_CURRENT_SUBSCRIBTION_FAILED = 'edit_current_subscribtion_failed';
export const EDIT_NEXT_SUBSCRIBTION_SUCCESS = 'edit_next_subscribtion_success';
export const EDIT_NEXT_SUBSCRIBTION_FAILED = 'edit_next_subscribtion_failed';
export const GET_ALL_ORGANIZATIONS_SUCCESS = '[adminPanel] get all organizations success';
export const GET_ALL_ORGANIZATIONS_FAILED = '[adminPanel] get all organizations failed';
export const GET_CURRENT_ADMIN_USER_SUCCESS = '[adminPanel] get current admin user success';
export const GET_CURRENT_ADMIN_USER_FAILED = '[adminPanel] get current admin user failed';
// get the next subscripe usage
export const GET_NEXT_SUBSCRIBTION_USAGE_SUCCESS = '[adminPanel] get next subscribtion usage success';
export const GET_NEXT_SUBSCRIBTION_USAGE_FAILED = '[adminPanel] get next subscribtion usage failed'; 
// logout admin panel
export const LOGOUT_ADMIN_SUCCESS = 'logout_admin_success';
export const LOGOUT_ADMIN_FAILED = 'logout_admin_failed';
