import { connect } from 'react-redux';
import { activateEntityAction, addNewEntityAction, addPredefinedAction, deleteEntityAction, getAllCategoriesAction, getAllCategoryEntitiesAction, getPredefinedListAction, updateEntityAction } from 'store/actions/creators/entities';
import { setBotBuilderTab } from 'store/actions/creators/tabs';

const mapStateToProps = (state) => {
	return {
		activeTalkybot: state?.activeTalkybot,
		activeEntity: state?.entities?.activeEntity,
		categories: state?.entities?.categories,
		entities: state?.entities?.entities,
		defaultCategory: state.entities.defaultCategory,
		predefinedList: state?.entities?.predefinedList,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setActiveTab: (tabIndex) => dispatch(setBotBuilderTab(tabIndex)),
		deleteEntity: ({
			entityId,
			botBuilderAppId,
			history,
			enqueueSnackbar,
			setUiLoadingError,
		}) =>
			dispatch(
				deleteEntityAction({
					entityId,
					botBuilderAppId,
					history,
					enqueueSnackbar,
					setUiLoadingError,
				})
			),
		editEntity: ({
			entityId,
			botBuilderAppId,
			payload,
			enqueueSnackbar,
			setUiLoadingError,
		}) =>
			dispatch(
				updateEntityAction({
					entityId,
					botBuilderAppId,
					payload,
					enqueueSnackbar,
					setUiLoadingError,
				})
			),
		addEntity: ({
			entity,
			botBuilderAppId,
			enqueueSnackbar,
			setUiLoadingError,
		}) =>
			dispatch(
				addNewEntityAction({
					entity,
					botBuilderAppId,
					enqueueSnackbar,
					setUiLoadingError,
				})
			),
		activateEntity: (entityId, botBuilderId) =>
			dispatch(activateEntityAction(entityId, botBuilderId)),
		getAllCategoryEntities: ({
			botBuilderAppId,
			categoryId,
			keyword,
			scrolling,
			page,
			perPage,
			setUiLoadingError
		}) => {
			dispatch(getAllCategoryEntitiesAction({
				botBuilderAppId,
				keyword,
				scrolling,
				categoryId,
				page,
				perPage,
				setUiLoadingError
			}))
		},
		getAllCategories: (botBuilderId, setUiLoadingError) =>
			dispatch(getAllCategoriesAction(botBuilderId, setUiLoadingError)),
			getPredefinedList: () => dispatch(getPredefinedListAction()),
			addPredefined: ({botBuilderAppId, entityId, predefinedId, enqueueSnackbar, setUiLoadingError}) => dispatch(addPredefinedAction({botBuilderAppId, entityId, predefinedId, enqueueSnackbar, setUiLoadingError}))
	};
};

export default connect(mapStateToProps, mapDispatchToProps);
