import {
	SET_CRM_TAB_PERSON_PROFILE,
	SET_BOT_BUILDER_TAB,
	SET_SETTINGS_TAB,
	SET_BOT_GATES_TAB,
	SET_CONVERSATION_TAB,
	SET_FALLBACK_TAB,
	SET_BOT_BUILDER_SETTINGS_TAB,
	SET_CRM_SETTINGS_TAB,
} from 'store/actions/types/tabs';

const INIT_STATE = {
	crmProfileTab: 0,
	botBuilderTab: 0,
	settingsTab: 0,
	botGatesTab: 0,
	conversationTab: 0,
	fallbackTab: 0,
	botBuilderSettingsTab: 0,
	crmSettingsTab: 0,
};

export default (state = INIT_STATE, { type, payload }) => {
	switch (type) {
		case SET_CRM_TAB_PERSON_PROFILE: {
			return {
				...state,
				crmProfileTab: payload,
			};
		}
		case SET_BOT_BUILDER_TAB: {
			return {
				...state,
				botBuilderTab: payload,
			};
		}
		case SET_SETTINGS_TAB: {
			return {
				...state,
				settingsTab: payload,
			};
		}
		case SET_BOT_GATES_TAB: {
			return {
				...state,
				botGatesTab: payload,
			};
		}
		case SET_CONVERSATION_TAB: {
			return {
				...state,
				conversationTab: payload,
			};
		}
		case SET_FALLBACK_TAB: {
			return {
				...state,
				fallbackTab: payload,
			};
		}
		case SET_BOT_BUILDER_SETTINGS_TAB: {
			return {
				...state,
				botBuilderSettingsTab: payload,
			};
		}
		case SET_CRM_SETTINGS_TAB: {
			return {
				...state,
				crmSettingsTab: payload,
			};
		}
		default:
			return state;
	}
};
