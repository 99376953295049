import {
	Box,
	Button,
	Divider,
	Grid,
	makeStyles,
	Typography
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ExitModalIcon from 'assets/icons/Exit-Modal-Icon.svg';
import NoCustomField from 'assets/icons/nocustomfield.svg';
import TextInput from 'components/common/Inputs/textInput/TextInput';
import NoDataComponent from 'components/NoData/Component';
import useSetUiLoadingError from 'hooks/useSetUiLoadingError';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AddSynonyms from './AddSynonym';


export default function SynonymList({
	synonyms,
	addSynonym,
	talkybotId,
	activateSynonym,
	getAllSynonyms,
	activeTalkybot,
}) {
	const classes = useStyles();
	const [filteredSynonyms, setFilteredSynonyms] = React.useState(synonyms);
	const [searchValue, setSearchValue] = React.useState(null);
	const [open, setOpen] = React.useState(false);
	const [openSynonymsModel, setOpenSynonymsModel] = useState(false)

	const [
		isLoading,
		successData,
		backendError,
		setUiLoadingError,
		setBackendError,
	] = useSetUiLoadingError();

	const history = useHistory();
	const botBuilderAppId = activeTalkybot?.bot_builder_id;

	useEffect(() => {
		getAllSynonyms({
			botBuilderAppId,
			page: 1,
			perPage: 100,
			keyword: '',
			setUiLoadingError
		})
		setFilteredSynonyms(synonyms)
	}, [])
	useEffect(() => {
		setFilteredSynonyms(synonyms)
	}, [synonyms])

	// on searching
	const handleSearchChange = (value) => {
		if (value) {
			setFilteredSynonyms(
				synonyms?.filter((synonym) =>
					synonym?.attributes?.title.toLowerCase().includes(value?.toLowerCase())
				)
			);
		} else {
			setFilteredSynonyms(synonyms);
		}
	};

	const renderSynonyms = () => {
		if (
			(filteredSynonyms && filteredSynonyms.length > 0) ||
			(!searchValue && synonyms.length > 0)
		) {
			const ArrToLoop = filteredSynonyms || synonyms;
			return ArrToLoop?.map((synonym, i) => (
				<>
					<div className={classes.header} key={i}>
						<div>
							<span
								className={classes.FieldName}
								title={synonym?.attributes?.name}
								onClick={() => {
									activateSynonym(synonym?.id, botBuilderAppId);
									history.push(`/talkybots/${talkybotId}
									/bot-builder/synonyms/${synonym?.id}`);
								}}
							>
								{synonym?.attributes?.title}
							</span>
						</div>
					</div>
					<Divider className={classes.customFieldItemDivider} />
				</>
			));
		}
		return (
			<Grid
				container
				direction="column"
				alignItems="center"
				justifyContent="center"
				style={{ minHeight: '60vh' }}
			>
				<NoDataComponent image={NoCustomField} title="No Synonyms" />
			</Grid>
		);
	};
	return (
		<>
			{!openSynonymsModel && (
				<Button
					variant="outlined"
					className={classes.showFlowsBtn}
					onClick={() => setOpenSynonymsModel(true)}
				>
					Show All Synonyms
				</Button>
			)}

			{/* [CRUD] : List all Intenst */}
			{openSynonymsModel && (
				<div className={classes.listContainer}>
					<img
						src={ExitModalIcon}
						alt="exit modal"
						className={classes.ExitModalIcon}
						onClick={() => setOpenSynonymsModel(false)}
					/>
					<Typography className={classes.nodeListHeader}>
						All Synonyms
					</Typography>
					<Divider className={classes.divider} />
					<div style={{ width: '100%', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
						<AddSynonyms
							addSynonym={addSynonym}
							activeTalkybot={activeTalkybot}
							synonyms={synonyms}
						/>
					</div>
					{/* Search in the entities.... */}
					<Box>
						<TextInput
							onChange={(e) => {
								handleSearchChange(e.target.value);
								setSearchValue(e.target.value);
							}}
							placeHolder="Search By Synonym Title"
							type="text"
							iconPosition="start"
							icon={<SearchIcon style={{ fill: '#909090' }} />}
							style={classes.searchInput}
						/>
					</Box>
					{/* Show all the synonyms and filtered ones */}
					{renderSynonyms()}
				</div>
			)}
		</>
	);
}

const useStyles = makeStyles((theme) => ({
	header: {
		...theme.typography.h6,
		display: 'flex',
		alignItems: 'center',
		marginTop: theme.spacing(10),
		justifyContent: 'space-between',
		color: theme.palette.primary.main,
		// background: 'green',
		padding: theme.spacing(10),
	},
	listContainer: {
		background: '#ffffff',
		flexWrap: 'nowrap',
		overflowY: 'scroll',
		'&::-webkit-scrollbar': {
			width: '0.3em',
			height: '0.2rem',
		},
		'&::-webkit-scrollbar-track': {
			boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
			webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: 'blue',
		},
		position: 'fixed',
		zIndex: 999999,
		right: 0,
		top: 15,
		width: theme.spacing(450),
		height: '100vh',
		paddingBottom: '3vh',
		boxShadow: '0px 3px 40px #00000029',
		padding: `${theme.spacing(45)} ${theme.spacing(13)} 0 ${theme.spacing(
			13
		)}`,
	},

	Field: {
		border: '1px solid #b4b4b4',
		borderRadius: theme.spacing(5.92),
		padding: '0 2rem 0 1rem',
	},
	FieldType: {
		...theme.typography.subtitle3,
		color: theme.palette.common.grayish,
		fontSize: theme.spacing(16),
		marginLeft: theme.spacing(48),
		marginBottom: theme.spacing(12),
	},
	FieldName: {
		...theme.typography.subtitle2,
		marginLeft: theme.spacing(4),
		color: 'black',
		textTransform: 'capitalize',
		display: 'block',
		width: '100%',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		cursor: 'pointer',
	},
	nodeListHeader: {
		...theme.typography.h7,
		color: theme.palette.primary.main,
		textAlign: 'center',
	},
	customFieldItemDivider: {
		margin: theme.spacing(0, 15),
	},
	editPermissionitemText: {
		...theme.typography.body2,
		width: theme.spacing(150),
	},
	showFlowsBtn: {
		border: `1px solid ${theme.palette.primary.main}`,
		color: theme.palette.primary.main,
		height: theme.spacing(53),
		padding: `0 ${theme.spacing(25)}`,
		borderRadius: '8px'
	},

	root: {
		padding: '3rem 0rem',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
	rootTable: {
		borderRadius: '0.875rem 0.875rem 0px 0px;',
		marginTop: '2rem',
		boxShadow: 'none',
	},
	talkybotNavbar: {
		width: '100%',
		maxHeight: '100px',
	},
	viewButton: {
		fontFamily: 'Poppins',
		fontSize: '1.125rem',
		marginRight: '1rem',
		cursor: 'pointer',
		padding: '0.3125rem 1rem',
		'&:hover': {
			background: '#37295E',
			color: '#fff',
			borderRadius: '0.6875rem',
		},
	},
	active: {
		background: '#37295E',
		color: '#fff',
		borderRadius: '0.6875rem',
	},
	formControl: {
		borderRadius: '0.625rem',
		minWidth: '15.625rem',
		boxShadow: '0px 3px 16px #CECECE29',
	},
	container: {
		flexGrow: 1,
	},
	notchedOutline: {
		borderColor: 'transparent',
	},
	list: {
		fontFamily: 'Poppins',
	},
	gifImage: {
		width: '100%',
	},
	alignCenter: {
		textAlign: 'center',
	},
	ourBrand: {
		borderLeft: '1px solid #ddd',
		paddingLeft: '0.3125rem',
		textTransform: 'lowercase',
		fontFamily: 'Poppins',
	},
	fitHeight: {
		height: 'fit-content',
	},
	talkybotsContainer: {
		flexDirection: 'column',
		marginTop: '2rem',
	},
	buttonsWrapper: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		marginTop: '2rem',
	},
	buttonContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: theme.spacing(20),
		marginBottom: theme.spacing(15),
	},
	yesBtn: {
		borderRadius: '1rem',
		width: theme.spacing(130),
	},
	noBtn: {
		marginRight: '1rem',
		borderRadius: '1rem',
		width: theme.spacing(140),
	},
	deleteBotDescription: {
		...theme.typography.body2,
		marginTop: theme.spacing(15),
		marginBottom: theme.spacing(35),
		color: theme.palette.common.dark,
	},
	searchInput: {
		background: '#EBEBEB 0% 0% no-repeat padding-box',
		boxShadow: '0px 3px 16px #BABABA29',
		borderRadius: '1.875rem',
		width: '100%',
		marginBottom: '30px',
		paddingLeft: '15px',
		border: 'none',
	},
	ExitModalIcon: {
		position: 'absolute',
		right: theme.spacing(35),
		cursor: 'pointer',
	},
	divider: {
		marginTop: theme.spacing(36),
		marginBottom: theme.spacing(20),
	},
}));
