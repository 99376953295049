const getUsageName = (el) => {
	if (!el?.nameOfMeter) {
		return '';
	}

	if (el?.nameOfMeter === 'Bots') {
		return 'Bots';
	} else if (el?.nameOfMeter === 'Re-Engagements') {
		return 'Re-Engagements';
	} else if (el?.nameOfMeter === 'Custom Variables') {
		return 'Custom Variables';
	} else if (el?.nameOfMeter === 'Team Members') {
		return 'Team Members';
	}

	return '';
};

function numFormatter(num) {
	if (+num > 999 && +num < 1000000) {
		return (+num / 1000).toFixed(1) + ' K';
		// convert to K for number from > 1000 < 1 million
	} else if (+num > 1000000) {
		return (+num / 1000000).toFixed(1) + ' M';
		// convert to M for number from > 1 million
	} else if (+num < 900) {
		return +num;
		// if value < 1000, nothing to do
	}
}

export { getUsageName, numFormatter };
