import { connect } from 'react-redux';
import { addNewEntityListAction, addNewListValueAction, deleteEntityListAction, deleteListValueAction, getAllEntityListsAction, updateEntityListAction, updateListValueAction } from 'store/actions/creators/entities';
import {
	clearActiveIntentAction,
} from 'store/actions/creators/faqs';
import { getAllSynonymsAction } from 'store/actions/creators/synonyms';

const mapStateToProps = (state) => {
	return {
		activeTalkybot: state?.activeTalkybot,
		activeEntity: state?.entities?.activeEntity,
		lists: state?.entities?.lists,
		synonyms: state.synonyms.synonyms,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getAllEntityLists: ({entityId, botBuilderAppId}) => dispatch(getAllEntityListsAction({entityId, botBuilderAppId})),
		addNewEntityList: ({entityId, language, threshold, enqueueSnackbar, setUiLoadingError}) => dispatch(addNewEntityListAction({entityId, language, threshold, enqueueSnackbar, setUiLoadingError})),
		updateEntityList: ({entityId, listId, language, threshold, enqueueSnackbar, setUiLoadingError}) => dispatch(updateEntityListAction({entityId, listId, language, threshold, enqueueSnackbar, setUiLoadingError})),
		deleteEntityList: ({entityId, listId, enqueueSnackbar,setUiLoadingError}) => dispatch(deleteEntityListAction({entityId, listId, enqueueSnackbar,setUiLoadingError})),
		addNewListValue: ({listId, value, synonymId, enqueueSnackbar, setUiLoadingError}) => dispatch(addNewListValueAction({listId, value, synonymId, enqueueSnackbar, setUiLoadingError})),
		deleteListValue: ({listId, valueId, enqueueSnackbar, setUiLoadingError}) => dispatch(deleteListValueAction({listId, valueId, enqueueSnackbar, setUiLoadingError})),
		EditListValue: ({listId, valueId, synonymId, value, enqueueSnackbar, setUiLoadingError}) => dispatch(updateListValueAction({listId, valueId, synonymId, value, enqueueSnackbar, setUiLoadingError})),
		getAllSynonyms: ({
			botBuilderAppId,
		}) => {
			dispatch(getAllSynonymsAction({
				botBuilderAppId,
			}))
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps);
