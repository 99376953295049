import apiRequest from "helpers/apiRequest";
import { ACTIVATE_SYNONYM, ADD_NEW_SYNONYM, ADD_NEW_SYNONYM_VALUE, CLEAR_ACTIVATE_SYNONYM, DELETE_SYNONYM, DELETE_SYNONYM_FAIL, DELETE_SYNONYM_VALUE, GET_ALL_SYNONYMS, GET_ALL_SYNONYM_VALUES, SYNONYM_ERROR, UPDATE_SYNONYM, UPDATE_SYNONYM_FAIL, UPDATE_SYNONYM_VALUE } from "../types/synonyms";
import { SET_BOT_BUILDER_TAB } from "../types/tabs";
import axios from 'helpers/axios';

// ------------------------ get all synonyms ---------------------------------
export const getAllSynonymsAction = ({
  botBuilderAppId,
  keyword,
  page,
  perPage,
  scrolling,
  setUiLoadingError = () => {},
}) => {
  return async (dispatch) => {
    const onSuccess = async (responseData) => {
      dispatch({
        type: GET_ALL_SYNONYMS,
        payload: {
          synonyms: responseData?.data,
          meta: responseData?.meta,
					isScrolling: scrolling,
        },
      });
    };
    const onError = (e) => {
      dispatch({ type: SYNONYM_ERROR, payload: { e } });
      throw new Error(e.message.toString());
    };
    if (!page && !perPage) {
      apiRequest.get({
        url: `/api/${botBuilderAppId}/synonyms`,
        onSuccess,
        onError,
        setUiLoadingError,
      });
    } else {
      apiRequest.get({
        url: `/api/${botBuilderAppId}/synonyms?page=${page}&per_page=${perPage}&title=${keyword}`,
        onSuccess,
        onError,
        setUiLoadingError,
      });
    }


  };
};

// ------------------------ Add synonym ---------------------------------

export const addNewSynonymAction = ({
  title,
  threshold,
  botBuilderAppId,
  enqueueSnackbar,
  setUiLoadingError,
}) => {
  return async (dispatch) => {
    const requestPayload = {
      title: title,
      threshold: threshold,
    };
    console.log('first', requestPayload)

    const onSuccess = async (responseData) => {
      enqueueSnackbar(`Created Synonym successfully`, {
        variant: 'success',
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
      });


      dispatch({
        type: ADD_NEW_SYNONYM,
        payload: {
          synonym: responseData?.data,
        },
      });
    };
    const onError = (e) => {
      enqueueSnackbar(getErrorMessageAndCode(e)?.message, {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
      });
      dispatch({ type: SYNONYM_ERROR, payload: { e } });
    };

    apiRequest.post({
      url: `/api/${botBuilderAppId}/synonyms`,
      bodyData: requestPayload,
      onSuccess,
      onError,
      setUiLoadingError,
    });
  };
};

// ------------------------ Delete synonym ---------------------------------

export const deleteSynonymAction = ({
  synonymId,
  botBuilderAppId,
  history,
  setUiLoadingError,
  enqueueSnackbar,
}) => {
  return async (dispatch) => {
    const onSuccess = async (responseData) => {
      enqueueSnackbar(`Synonym deleted successfully`, {
        variant: 'success',
        autoHideDuration: 3000,
      });

      dispatch({ type: DELETE_SYNONYM, payload: { entityId: responseData?.data?.id } })
      dispatch({ type: SET_BOT_BUILDER_TAB, payload: 7 });

      history.push(`/talkybots/${botBuilderAppId}/bot-builder`);
    };
    const onError = (e) => {
      enqueueSnackbar(getErrorMessageAndCode(e)?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      dispatch({
        type: DELETE_SYNONYM_FAIL,
      });
    };
    apiRequest.delete({
      url: `/api/${botBuilderAppId}/synonyms/${synonymId}`,
      onSuccess,
      onError,
      setUiLoadingError,
    });
  };
};

// ------------------------ Update synonym ---------------------------------

export const updateSynonymAction = ({
  synonymId,
  botBuilderAppId,
  payload,
  selectedCategory,
  enqueueSnackbar,
  setUiLoadingError,
}) => {
  return async (dispatch) => {
    const requestPayload = {
      title: payload?.name,
      threshold: payload?.threshold,
    }
    const onSuccess = async (responseData) => {
      dispatch({ type: UPDATE_SYNONYM,
        payload: { updatedSynonym: responseData?.data } });
      enqueueSnackbar(`Synonym updated successfully`, {
        variant: 'success',
        autoHideDuration: 3000,
      });
    };
    const onError = (e) => {
      enqueueSnackbar(getErrorMessageAndCode(e)?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      dispatch({
        type: UPDATE_SYNONYM_FAIL,
      });
    };
    apiRequest.patch({
      url: `/api/${botBuilderAppId}/synonyms/${synonymId}`,
      bodyData: requestPayload,
      onSuccess,
      onError,
      setUiLoadingError,
    });
  };
};

// ------------------------ Activate synonym ---------------------------------
export const activateSynonymAction = (synonymId, botBuilderId) => {
	return async (dispatch) => {
		try {
			const response = await axios.get(
				`/api/${botBuilderId}/synonyms/${synonymId}`
			);



			dispatch({
				type: ACTIVATE_SYNONYM,
				payload: { activatedSynonym: response?.data?.data },
			});
		} catch (error) {
			throw new Error(error.message.toString());
		}
	};
};

// ------------------------ clear active synonym ---------------------------------
export const clearActiveSynonymAction = () => {
	return (dispatch) => {
		dispatch({
			type: CLEAR_ACTIVATE_SYNONYM
		})
	}
}


// ------------------------ get all synonym values ---------------------------------

export const getAllSynonymValuesAction = ({
	synonymId,
	botBuilderAppId
}) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			dispatch({
				type: GET_ALL_SYNONYM_VALUES,
				payload: {
					values: responseData?.data,
					synonymId,
				},
			});
		};
		const onError = (e) => {
			dispatch({ type: SYNONYM_ERROR, payload: { e } });
			throw new Error(e.message.toString());
		};

		// TODO: pending on the backend to filter only with lang
		apiRequest.get({
			url: `/api/${botBuilderAppId}/synonyms/${synonymId}/synonym_values`,
			onSuccess,
			onError,
		});
	};
};

// ------------------------ Create synonym value ---------------------------------

export const addNewSynonymValueAction = ({
	synonymId,
  botBuilderAppId,
	value,
	enqueueSnackbar,
	setUiLoadingError,
}) => {
	return async (dispatch) => {
		const requestPayload = {
			value
		};

		const onSuccess = async (responseData) => {
			enqueueSnackbar(` Value created successfully`, {
				variant: 'success',
				autoHideDuration: 3000,
			});


			dispatch({
				type: ADD_NEW_SYNONYM_VALUE,
				payload: {
					value: responseData?.data,
					synonymId,
				},
			});
		};
		const onError = (e) => {
			enqueueSnackbar(getErrorMessageAndCode(e)?.message, {
				variant: 'error',
				autoHideDuration: 3000,
			});
			dispatch({ type: SYNONYM_ERROR, payload: { e } });
		};

		apiRequest.post({
			url: `/api/${botBuilderAppId}/synonyms/${synonymId}/synonym_values`,
			bodyData: requestPayload,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};

// ------------------------ Update synonym value ---------------------------------

export const updateSynonymValueAction = ({
	synonymId,
  botBuilderAppId,
	valueId,
	value,
	enqueueSnackbar,
	setUiLoadingError,
}) => {
	const requestPayload = {
		value
	};
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			dispatch({ type: UPDATE_SYNONYM_VALUE, payload: { synonymId, valueId, updatedValue: responseData?.data } });
			enqueueSnackbar(`Value updated successfully`, {
				variant: 'success',
				autoHideDuration: 3000,
			});
		};
		const onError = (e) => {
			enqueueSnackbar(getErrorMessageAndCode(e)?.message, {
				variant: 'error',
				autoHideDuration: 3000,
			});
		};
		apiRequest.patch({
			url: `/api/${botBuilderAppId}/synonyms/${synonymId}/synonym_values/${valueId}`,
			bodyData: requestPayload,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};

// ------------------------ Delete synonym value ---------------------------------

export const deleteSynonymValueAction = ({
	synonymId,
  botBuilderAppId,
	valueId,
	setUiLoadingError,
	enqueueSnackbar,
}) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			enqueueSnackbar(`Value deleted successfully`, {
				variant: 'success',
				autoHideDuration: 3000,
			});

			dispatch({ type: DELETE_SYNONYM_VALUE, payload: { synonymId, valueId } })

		};
		const onError = (e) => {
			enqueueSnackbar(getErrorMessageAndCode(e)?.message, {
				variant: 'error',
				autoHideDuration: 3000,
			});
		};
		apiRequest.delete({
			url: `/api/${botBuilderAppId}/synonyms/${synonymId}/synonym_values/${valueId}`,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};
