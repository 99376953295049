import {
	ADD_NEW_ACCOUNT,
	GET_ALL_ACCOUNTS,
	REMOVE_ACCOUNT,
	LIST_ACCOUNT_CHANNELS,
	GET_ALL_WHATSAPP_CHANNELS,
	GET_ALL_ACCOUNTS_ERROR,
	GET_ALL_INSTAGRAM_ACCOUNTS,
	REMOVE_INSTAGRAM_ACCOUNT,
	TOGGLE_WHATSAPP_CHANNEL,
	REMOVE_WHATSAPP_ACCOUNT,
	GET_ALL_INSTAGRAM_ACCOUNTS_ERROR,
} from 'store/actions/types/accounts';
import AccountModel from 'models/AccountModel';
import {
	DISCONNECT_PAGE_CHANNEL,
	REMOVE_FACEBOOK_CHANNEL,
} from 'store/actions/types/tenants';
import {
	getConnectedChannels,
	getConnectedChannelsToAnotherOrganization,
} from 'helpers/settingsHelpers';

const initialState = {
	facebookAccounts: [],
	whatsappChannels: {
		whatsappChannels: [],
		accountId: null,
	},
	instagramAccounts: [],
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case GET_ALL_ACCOUNTS_ERROR: {
			return {
				...state,
				error: payload,
				facebookAccounts: [],
				whatsappChannels: {},
				instagramAccounts: [],
			};
		}
		case GET_ALL_INSTAGRAM_ACCOUNTS_ERROR: {
			return {
				...state,
				error: payload,
				instagramAccounts: [],
			};
		}
		case GET_ALL_ACCOUNTS: {
			const accounts = [];
			payload.forEach((account) => {
				const accountModel = new AccountModel({
					id: account.id,
					name: account.name,
					avatar: account.avatar,
					provider: account.provider,
					channels: account.channels,
					tenant: account.tenant,
				});
				accounts.push(accountModel);
			});

			const connectedChannels = getConnectedChannels(accounts);
			localStorage.setItem(
				'connectedChannels',
				JSON.stringify(connectedChannels)
			);

			const connectedChannelsToAnotherOrganization =
				getConnectedChannelsToAnotherOrganization(accounts);
			localStorage.setItem(
				'connectedChannelsToAnotherOrganization',
				JSON.stringify(connectedChannelsToAnotherOrganization)
			);

			return { ...state, facebookAccounts: accounts };
		}

		case DISCONNECT_PAGE_CHANNEL: {
			// Recieve unique id [accountId,channelId,talkbotId]
			// Recieve the changed data  [isconnected_before,theSametenant]
			const { accountId, channelId, talkybotId, accounts } = payload;
			const newAccounts = state.facebookAccounts;
			// loop in omash to get specific account to fuck it
			const accountIndex = newAccounts.findIndex(
				(account) => account.id === accountId
			);
			const targetAccount = accounts[accountIndex];
			const targetAccountChannels = targetAccount.channels;
			const channelIndex = targetAccountChannels.findIndex(
				(channel) => channel.id === channelId
			);
			const targetChannel = targetAccountChannels[channelIndex];
			const targetChannelConnectionState =
				!targetChannel.attributes.is_connected.is_connected_before;

			targetChannel.attributes.is_connected.is_connected_before =
				targetChannelConnectionState;

			targetAccount.channels[channelIndex] = targetChannel;
			newAccounts[accountIndex] = targetAccount;

			return { ...state, facebookAccounts: newAccounts };
		}
		case ADD_NEW_ACCOUNT: {
			const account = new AccountModel({
				id: payload.id,
				name: payload.name,
				avatar: payload.avatar,
				provider: payload.provider,
				channels: payload.channels,
				tenant: payload.tenant,
			});

			return {
				...state,
				facebookAccounts: [...state.facebookAccounts, account],
			};
		}
		case REMOVE_ACCOUNT: {
			const accounts = state.facebookAccounts.filter(
				(account) => account.id !== payload.id
			);

			const connectedChannels = getConnectedChannels(accounts);
			localStorage.setItem(
				'connectedChannels',
				JSON.stringify(connectedChannels)
			);

			const connectedChannelsToAnotherOrganization =
				getConnectedChannelsToAnotherOrganization(accounts);

			localStorage.setItem(
				'connectedChannelsToAnotherOrganization',
				JSON.stringify(connectedChannelsToAnotherOrganization)
			);

			return { ...state, facebookAccounts: accounts };
		}
		// NOTE: check that I think that is useless case
		case LIST_ACCOUNT_CHANNELS: {
			return state;
		}
		case REMOVE_FACEBOOK_CHANNEL: {
			const connectedChannels = getConnectedChannels(payload);
			localStorage.setItem(
				'connectedChannels',
				JSON.stringify(connectedChannels)
			);
			const connectedChannelsToAnotherOrganization =
				getConnectedChannelsToAnotherOrganization(payload);
			localStorage.setItem(
				'connectedChannelsToAnotherOrganization',
				JSON.stringify(connectedChannelsToAnotherOrganization)
			);
			return { ...state, facebookAccounts: payload };
		}

		// ******************************************** whatsapp **********************************************
		case GET_ALL_WHATSAPP_CHANNELS: {
			console.log('payload', payload);
			return {
				...state,
				whatsappChannels: {
					...state.whatsappChannels,
					whatsappChannels: payload.whatsappChannels,
					accountId: payload.accountId,
				},
			};
		}

		case TOGGLE_WHATSAPP_CHANNEL: {
			return {
				...state,
				whatsappChannels: {
					...state.whatsappChannels,
					whatsappChannels: payload.whatsappChannels,
					accountId: payload.accountId,
				},
			};
		}

		case REMOVE_WHATSAPP_ACCOUNT: {
			return {
				...state,
				whatsappChannels: {
					whatsappChannels: [],
					accountId: null,
				},
			};
		}
		// ******************************************** instagram **********************************************
		case GET_ALL_INSTAGRAM_ACCOUNTS: {
			const accounts = [];
			payload.forEach((account) => {
				const accountModel = new AccountModel({
					id: account.id,
					name: account.name,
					avatar: account.avatar,
					provider: account.provider,
					channels: account.channels,
					tenant: account.tenant,
				});
				accounts.push(accountModel);
			});

			const connectedChannels = getConnectedChannels(accounts);
			localStorage.setItem(
				'connectedChannels',
				JSON.stringify(connectedChannels)
			);

			const connectedChannelsToAnotherOrganization =
				getConnectedChannelsToAnotherOrganization(accounts);
			localStorage.setItem(
				'connectedChannelsToAnotherOrganization',
				JSON.stringify(connectedChannelsToAnotherOrganization)
			);

			return { ...state, instagramAccounts: accounts };
		}
		case REMOVE_INSTAGRAM_ACCOUNT: {
			const accounts = state.instagramAccounts.filter(
				(account) => account.id !== payload.id
			);

			const connectedChannels = getConnectedChannels(accounts);
			localStorage.setItem(
				'connectedChannels',
				JSON.stringify(connectedChannels)
			);

			const connectedChannelsToAnotherOrganization =
				getConnectedChannelsToAnotherOrganization(accounts);

			localStorage.setItem(
				'connectedChannelsToAnotherOrganization',
				JSON.stringify(connectedChannelsToAnotherOrganization)
			);

			return { ...state, instagramAccounts: accounts };
		}
		default:
			return state;
	}
};
