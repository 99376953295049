import { Button, Grid, makeStyles, Paper, useTheme } from '@material-ui/core';
import deleteTopicFlow from 'assets/icons/deleteTopicFlow.svg';
import editTopicFlow from 'assets/icons/editTopicFlow.svg';
import goBack from 'assets/icons/goBack.svg';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import EntityList from './CRUD/EntityList';
import EditEntity from '../../CRUD/EditEntity';
import DeleteEntityComponent from '../../CRUD/DeleteEntity';
import Modal from 'components/common/ModalContainer/Modal';
import plusIcon from 'assets/icons/intentPlus.svg';
import useSetUiLoadingError from 'hooks/useSetUiLoadingError';
import ModalContainer from 'components/common/ModalContainer/Modal';
import AddPredefined from './CRUD/AddPredefined';

const Header = ({
  entities,
  activeTalkybot,
  setActiveTab,
  categories,
  defaultCategory,
  activateEntity,
  editEntity,
  deleteEntity,
  activeEntity,
  addEntity,
  getAllCategoryEntities,
  getAllCategories,
  getPredefinedList,
  predefinedList,
  addPredefined,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const talkybotId = activeTalkybot?.attributes?.bot_builder_id;


  const [confirmDeleteEntity, setConfirmDeleteEntity] = useState(false);
  const [entityToEdit, setEntityToEdit] = useState(null);
  const [openEditEntity, setOpenEditEntity] = React.useState(false);
  const [entityToDelete, setEntityToDelete] = React.useState(null);
  const [openAddPredefined, setOpenAddPredefined] = useState(false)

  const [
		isLoading,
		successData,
		backendError,
		setUiLoadingError,
		setBackendError,
	] = useSetUiLoadingError();


	useEffect(() => {
		const { bot_builder_id: botBuilderId } = activeTalkybot;
		getAllCategories(activeTalkybot?.bot_builder_id, setUiLoadingError);
    getPredefinedList()
	}, []);



  const handleCancelDeleteEntity = () => {
    // reset error
    // setBackendError(null);
    setConfirmDeleteEntity(false);
  };

  const handleCloseEditEntityModal = () => setOpenEditEntity(false);

  const handleOpenEditEntityModal = () => setOpenEditEntity(true);


  const handleCloseAddPredefinedModal = () => setOpenAddPredefined(false);

  const handleOpenAddPredefinedModal = () => setOpenAddPredefined(true);

  const renderConfirmDeleteEntity = () => (
    <Modal
      title="Delete Entity"
      open={Boolean(confirmDeleteEntity)}
      onClose={handleCancelDeleteEntity}
      // width={theme.spacing(600)}
      component={
        <DeleteEntityComponent
          entityToDelete={entityToDelete}
          activeTalkybot={activeTalkybot}
          deleteEntity={deleteEntity}
          setEntityToDelete={setEntityToDelete}
          handleCloseModal={handleCancelDeleteEntity}
        />
      }
    />
  );

  const renderEditEntityModal = () => (
    <Modal
      title="Edit Entity"
      onClose={handleCloseEditEntityModal}
      open={openEditEntity}
      // width={theme.spacing(600)}
      component={
        <EditEntity
          entityToEdit={entityToEdit}
          setEntityToEdit={setEntityToEdit}
          editEntity={editEntity}
          activeTalkybot={activeTalkybot}
          handleCloseModal={handleCloseEditEntityModal}
          categories={categories}
        />
      }
    />
  );

  const renderAddPredefined = () => (
    <ModalContainer
      title="Add Predefined"
      onClose={handleCloseAddPredefinedModal}
      open={openAddPredefined}
      component={
        <AddPredefined
          handleCloseModal={handleCloseAddPredefinedModal}
          activeTalkybot={activeTalkybot}
          predefinedList={predefinedList}
          activeEntity={activeEntity}
          addPredefined={addPredefined}
        />
      }
    />
  )





  const handleBackToTopicFlow = () => {
    history.push(`/talkybots/${talkybotId}/bot-builder`)
    // localStorage.setItem('dashboardTabIndex', 1)
    setActiveTab(6);
  };

  return (
    <Paper elevation={0} className={classes.headerWrapper}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Grid
            container
            alignItems="center"
            style={{
              fontSize: '18px',
              fontWeight: '500',
              fontFamily: 'poppins',
            }}
          >
            <img
              className={classes.goBackIcon}
              src={goBack}
              alt="back to intent lists"
              onClick={handleBackToTopicFlow}
            />
            {activeEntity?.attributes?.name}
          </Grid>
        </Grid>
        <Grid item>
          <Grid container alignItems="center">
            <img
              className={classes.editDeleteIcon}
              src={deleteTopicFlow}
              alt="delete topic flow"
              onClick={() => {
                setEntityToDelete(activeEntity);
                setConfirmDeleteEntity(true)
              }}
            />
            <img
              className={classes.editDeleteIcon}
              src={editTopicFlow}
              alt="edit Intent"
              onClick={() => {
                setEntityToEdit(activeEntity);
                handleOpenEditEntityModal()
              }}
            />
            {/* Done: CRUD : list all intents */}
            <EntityList
              entities={entities}
              activateEntity={activateEntity}
              talkybotId={talkybotId}
              categories={categories}
              addEntity={addEntity}
              activeTalkybot={activeTalkybot}
              defaultCategory={defaultCategory}
              getAllCategoryEntities={getAllCategoryEntities}
            />
            <Button
              variant="contained"
              color="primary"
              className={classes.addPredefined}
              onClick={() => handleOpenAddPredefinedModal()}
            >
              <img
                src={plusIcon}
                alt="add topic flow"
                style={{ width: '0.8rem', marginRight: '0.3rem' }}
              />
              Add Predefined
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {renderEditEntityModal()}
      {renderConfirmDeleteEntity()}
      {renderAddPredefined()}
    </Paper>
  );
};
export default Header;

const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    padding: theme.spacing(10),
    width: '100%',

    // boxShadow: '0px 3px 16px #CECECE29',
    borderRadius: theme.borderRadius.borderRadius10px,
  },

  editDeleteIcon: {
    height: theme.spacing(53),
    cursor: 'pointer',
    marginRight: theme.spacing(16),
  },
  goBackIcon: {
    cursor: 'pointer',
    marginRight: theme.spacing(16),
  },
  addPredefined: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    height: theme.spacing(53),
    padding: `0 ${theme.spacing(25)}`,
    borderRadius: '8px',
    boxShadow: 'none',
    marginLeft: '10px'
  },
}));
