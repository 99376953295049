// local styles
import { useState, useEffect } from 'react';
import DetailsView from './DetailsView';
import MainView from './MainView';
// local assets
import { ReactComponent as Facebook } from 'assets/images/Dashboard/facebook.svg';
import { ReactComponent as Whatsapp } from 'assets/images/Dashboard/whatsapp.svg';
import { ReactComponent as Instagram } from 'assets/images/Dashboard/instagram.svg';
import { ReactComponent as SelfImport } from 'assets/images/Dashboard/SelfImport.svg';
// local styles
import styles from './index.module.scss';
import useSetUiLoadingError from 'hooks/useSetUiLoadingError';

const channelData = [
	{
		id: 1,
		icon: <Facebook />,
		title: `Facebook`,
		value: 'facebook',
	},
	{
		id: 2,
		icon: <Whatsapp />,
		title: 'Whatsapp',
		value: 'whatsapp',
	},
	{
		id: 3,
		icon: <Instagram />,
		title: 'Instagram',
		value: 'instagram',
	},

];
const DurationData = [
	{
		id: 1,
		title: 'This Month',
		value: 'this_month',
	},
	{
		id: 2,
		title: 'Last Month',
		value: 'last_month',
	},
	{
		id: 3,
		title: `This Week`,
		value: 'this_week',
	},
	{
		id: 4,
		title: 'Last Week',
		value: 'last_week',
	},
	{
		id: 5,
		title: 'Overall',
		value: 'overall',
	},
];

const Dashboard = ({
	// state from reducer
	talkybotId,
	// action from redux
	getMessagesIn,
	getMessagesOut,
	getClosedConversation
}) => {

	// change view according to state (main / details)
	const [currentView, setCurrentView] = useState(localStorage.getItem('dashboardView') ?? 'main');
	// handle dashboard filter state
	const [statusDurationValue, setStatusDurationValue] = useState(
		DurationData[0].value
	);
	const [statusChannelValue, setStatusChannelValue] = useState(
		channelData[0].value
	);

	const [isLoadingAnalyticsCards, successDataAnalyticsCards, backendErrorAnalyticsCards, setUiLoadingErrorAnalyticsCards] =
		useSetUiLoadingError();
	const [isLoadingCharts, successDataCharts, backendErrorCharts, setUiLoadingErrorCharts] =
		useSetUiLoadingError();

	/** ********************** Start Tabs index Setting ************* */
	const [tabValue, setTabValue] = useState(JSON.parse(localStorage.getItem('dashboardTabIndex')) ?? 0);

	useEffect(() => {
		return () => {
			localStorage.setItem('dashboardTabIndex', 0)
			localStorage.setItem('dashboardView', 'main')
		}
	}, [])
	/** ********************** End Tabs index Setting ************* */

	useEffect(() => {
		getMessagesIn({
			talkyBotID: talkybotId,
			duration_filter: statusDurationValue,
			provider_filter: statusChannelValue,
			setUiLoadingError: setUiLoadingErrorAnalyticsCards
		});

		getMessagesOut({
			talkyBotID: talkybotId,
			duration_filter: statusDurationValue,
			provider_filter: statusChannelValue,
			setUiLoadingError: setUiLoadingErrorAnalyticsCards
		});

		getClosedConversation({
			talkyBotID: talkybotId,
			duration_filter: statusDurationValue,
			provider_filter: statusChannelValue,
			setUiLoadingError: setUiLoadingErrorCharts
		});

	}, [statusDurationValue, statusChannelValue])

	return (
		<div className={styles.Container}>
			{currentView === 'main' ? (
				<MainView
					currentView={currentView}
					setCurrentView={setCurrentView}
					statusDurationValue={statusDurationValue}
					setStatusDurationValue={setStatusDurationValue}
					statusChannelValue={statusChannelValue}
					setStatusChannelValue={setStatusChannelValue}
					channelData={channelData}
					DurationData={DurationData}
					tabValue={tabValue}
					setTabValue={setTabValue}
				/>
			) : (
				<DetailsView
					currentView={currentView}
					setCurrentView={setCurrentView}
					statusDurationValue={statusDurationValue}
					setStatusDurationValue={setStatusDurationValue}
					statusChannelValue={statusChannelValue}
					setStatusChannelValue={setStatusChannelValue}
					channelData={channelData}
					DurationData={DurationData}
					setTabValue={setTabValue}
				/>
			)}
		</div>
	);
};

export default Dashboard;
