/* eslint-disable no-case-declarations */
/* eslint-disable no-restricted-syntax */
import {
  ACTIVATE_ENTITY,
  ADD_NEW_ENTITIY_LIST,
  ADD_NEW_ENTITIY_REGEX,
  ADD_NEW_ENTITY,
  ADD_NEW_ENTITY_CATEGORY,
  ADD_NEW_LIST_VALUE,
  ADD_NEW_REGEX_VALUE,
  ADD_PREDEFINED,
  CHANGE_PRIORITY_POSITION,
  CLEAR_ACTIVE_ENTITY,
  DELETE_ENTITY,
  DELETE_ENTITY_CATEGORY,
  DELETE_ENTITY_LIST,
  DELETE_ENTITY_REGEX,
  DELETE_LIST_VALUE,
  DELETE_REGEX_VALUE,
  GET_ALL_CATEGORY_ENTITIES,
  GET_ALL_ENTITIES_CATEGORIES,
  GET_ALL_ENTITY_LISTS,
  GET_ALL_ENTITY_REGEX,
  GET_ALL_LIST_VALUES,
  GET_ALL_REGEX_VALUES,
  GET_PREDEFINED_LIST,
  GET_PRIORITY_LIST,
  SET_DEFAULT_ENTITY_CATEGORY,
  UPDATE_ENTITY,
  UPDATE_ENTITY_CATEGORY,
  UPDATE_ENTITY_LIST,
  UPDATE_ENTITY_REGEX,
  UPDATE_LIST_VALUE,
  UPDATE_REGEX_VALUE
} from 'store/actions/types/entities';

const initialState = {
  categories: [],
  entities: [],
  activeTab: 0,
  defaultCategory: null,
  entitiesCount: 0,
  meta: {},
  activeEntity: {},
  lists: [],
  listsCount: 0,
  regexes: [],
  regexesCount: 0,
  priorityList: [],
  predefinedList: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {

    case GET_ALL_ENTITIES_CATEGORIES:
      const { categories } = payload;
      return {
        ...state,
        categories,
      };
    case DELETE_ENTITY_CATEGORY:
      const { deletedCategory } = payload;
      const updatedCategories = [...state.categories];
      const categoryIndex = updatedCategories.findIndex(
        (category) => category.id === deletedCategory.id
      );
      if (categoryIndex >= 0) {
        updatedCategories.splice(categoryIndex, 1);
      }
      return {
        ...state,
        categories: [...updatedCategories],
      };


    case UPDATE_ENTITY_CATEGORY:
      const { updatedCategory } = payload;
      const updatedCategoriesArr = state.categories;
      const updatedCategoryIndex = updatedCategoriesArr.findIndex(
        (category) => Number(category.id) === Number(updatedCategory.id)
      );
      updatedCategoriesArr[updatedCategoryIndex] = updatedCategory;
      return {
        ...state,
        categories: [...updatedCategoriesArr],
      };


    case ADD_NEW_ENTITY_CATEGORY:
      const { category } = payload;
      return {
        ...state,
        categories: [...state.categories, category],
      };


    case SET_DEFAULT_ENTITY_CATEGORY:
      const { categoryId: defaultCategory } = payload;
      return {
        ...state,
        defaultCategory,
      };

    case ADD_NEW_ENTITY:
      const { entity, selectedCategory } = payload;
      return {
        ...state,
        entities: selectedCategory === undefined ? [entity, ...state.entities] : selectedCategory == entity?.attributes?.entity_category_id || selectedCategory === null ? [entity, ...state.entities] : [...state.entities],
      };

    case GET_ALL_CATEGORY_ENTITIES:
      const {
        entities,
        meta,
        isScrolling,
      } = payload;

      if (!isScrolling) {
        return {
          ...state,
          entities,
          meta
        };
      } else {
        return {
          ...state,
          entities: [...state.entities, ...entities],
          meta: meta,
        };
      }


    case UPDATE_ENTITY: {
      const { updatedEntity, selectedCategory } = payload;
      const newEntities = [...state.entities];
      const updatedentityIndex = newEntities.findIndex(
        (entity) => entity.id === updatedEntity.id
      );

      if (selectedCategory == updatedEntity.attributes.entity_category_id || selectedCategory === null) {
        newEntities[updatedentityIndex] = updatedEntity;
        return {
          ...state,
          entities: newEntities,
          activeEntity: updatedEntity,
        };
      } else {
        newEntities.splice(updatedentityIndex, 1)
        return {
          ...state,
          entities: newEntities,
          activeEntity: updatedEntity,
        };
      }

    }
    case DELETE_ENTITY:
      const { entityId } = payload;
      const updatedEntities = [...state.entities];
      const entityIndex = updatedEntities.findIndex(
        (entity) => entity.id === entityId
      );
      if (entityIndex >= 0) {
        updatedEntities.splice(entityIndex, 1);
      }
      return {
        ...state,
        entities: [...updatedEntities],
        entitiesCount: state.entitiesCount - 1,
      };

    case ACTIVATE_ENTITY:
      const { activatedEntity } = payload;

      return {
        ...state,
        activeEntity: activatedEntity,
      };

    case CLEAR_ACTIVE_ENTITY:
      return {
        ...state,
        activeEntity: {},
      }

    //! **************************************************** entity list start *******************************************************

    case GET_ALL_ENTITY_LISTS:
      const { lists } = payload;
      return {
        ...state,
        lists: lists
      }

    case ADD_NEW_ENTITIY_LIST:
      const { list } = payload;
      return {
        ...state,
        lists: [list, ...state.lists],
      };

    case UPDATE_ENTITY_LIST:
      const { updatedList } = payload;
      const newLists = [...state.lists];
      const updatedListIndex = newLists.findIndex(
        (list) => list.id === updatedList.id
      );
      newLists[updatedListIndex] = updatedList;

      return {
        ...state,
        lists: [...newLists],
      };

    case DELETE_ENTITY_LIST:{
      const { listId } = payload;
      const updatedLists = [...state.lists];
      const listIndex = updatedLists.findIndex(
        (list) => list.id === listId
      );
      if (listIndex >= 0) {
        updatedLists.splice(listIndex, 1);
      }
      return {
        ...state,
        lists: [...updatedLists],
        listsCount: state.listsCount - 1,
      };
}
    case ADD_NEW_LIST_VALUE: {
      const newLists = [...state.lists]
      const { value } = payload
      const selectedListIndex = newLists.findIndex(
        (list) => list.id === payload.listId
      );
      if (selectedListIndex !== -1) {
        // Copy the selected list and its values
        const selectedList = { ...newLists[selectedListIndex] };
        const updatedValues = [value, ...selectedList.attributes.values.data,];

        // Update the values array with the new value
        selectedList.attributes.values.data = updatedValues;

        // Update the list in the newLists array
        newLists[selectedListIndex] = selectedList;
      }

      return {
        ...state,
        lists: newLists,
      }
    }
    case DELETE_LIST_VALUE: {
      const { listId, valueId } = payload;
      const updatedLists = [...state.lists]
      const updatedListIndex = updatedLists.findIndex(
        (list) => list.id === listId
      );
      const newValues = updatedLists[updatedListIndex].attributes.values.data
      const newValueIndex = newValues.findIndex(
        (value) => value.id === valueId
      )
      if (newValueIndex >= 0) {
        updatedLists.map((list) => {
          if (list.id === listId) {
            list.attributes.values.data.splice(newValueIndex, 1)
          }
        }
        )
      }

      return {
        ...state,
        lists: updatedLists
      };
    }



    case UPDATE_LIST_VALUE: {
      const { listId, valueId, updatedValue } = payload;

      // Find the list by listId
      const updatedLists = state.lists.map(list => {
        if (list.id === listId) {
          // Update the value in the list's values array
          const updatedValues = list.attributes.values.data.map(value => {
            if (value.id === valueId) {
              return updatedValue; // Replace the entire value object
            }
            return value;
          });

          // Return the updated list object with the updated values
          return {
            ...list,
            attributes: {
              ...list.attributes,
              values: {
                ...list.attributes.values,
                data: updatedValues
              }
            }
          };
        }
        return list;
      });

      // Return the new state with updated values
      return {
        ...state,
        lists: updatedLists
      };
    }

    //! **************************************************** entity list end *******************************************************
    //! **************************************************** entity regex start ****************************************************

    case GET_ALL_ENTITY_REGEX: {
      const { regex } = payload;
      return {
        ...state,
        regexes: regex
      }
    }
    case ADD_NEW_ENTITIY_REGEX: {
      const { regex } = payload;
      return {
        ...state,
        regexes: [regex, ...state.regexes],
      }
    }

    case UPDATE_ENTITY_REGEX: {
      const { updatedRegex } = payload;
      const newRegexes = [...state.regexes];
      const updatedRegexIndex = newRegexes.findIndex(
        (regex) => regex.id === updatedRegex.id
      );
      newRegexes[updatedRegexIndex] = updatedRegex;

      return {
        ...state,
        regexes: [...newRegexes],
      }
    }

    case DELETE_ENTITY_REGEX: {
      const { regexId } = payload;
      const updatedRegexes = [...state.regexes];
      const regexIndex = updatedRegexes.findIndex(
        (regex) => regex.id === regexId
      );
      if (regexIndex >= 0) {
        updatedRegexes.splice(regexIndex, 1);
      }
      return {
        ...state,
        regexes: [...updatedRegexes],
        regexesCount: state.regexesCount - 1,
      }
    }

    case GET_ALL_REGEX_VALUES: {
      const { regexValues, regexId } = payload;

      const newRegexes = [...state.regexes]
      const updatedRegexes = newRegexes.map(regex => {
        if (regex.id === regexId) {
          return {
            ...regex,
            attributes: {
              ...regex.attributes,
              values: regexValues
            }
          }
        }
        else {
          return regex
        }
      })

      return {
        ...state,
        regexes: updatedRegexes
      }
    }

    case ADD_NEW_REGEX_VALUE: {
      const newRegexes = [...state.regexes]
      const { value } = payload
      const selectedRegexIndex = newRegexes.findIndex(
        (regex) => regex.id === payload.regexId
      );
      if (selectedRegexIndex !== -1) {
        // Copy the selected regex and its values
        const selectedRegex = { ...newRegexes[selectedRegexIndex] };
        const updatedValues = [value, ...selectedRegex.attributes.values.data,];

        // Update the values array with the new value
        selectedRegex.attributes.values.data = updatedValues;

        // Update the regex in the newRegexes array
        newRegexes[selectedRegexIndex] = selectedRegex;
      }

      return {
        ...state,
        regexes: newRegexes,
      }
    }

    case DELETE_REGEX_VALUE: {
      const { regexId, valueId } = payload;
      const updatedregexes = [...state.regexes]
      const updatedRegexIndex = updatedregexes.findIndex(
        (regex) => regex.id === regexId
      );
      const newValues = updatedregexes[updatedRegexIndex].attributes.values.data
      const newValueIndex = newValues.findIndex(
        (value) => value.id === valueId
      )
      if (newValueIndex >= 0) {
        updatedregexes.map((regex) => {
          if (regex.id === regexId) {
            regex.attributes.values.data.splice(newValueIndex, 1)
          }
        }
        )
      }

      return {
        ...state,
        regexes: updatedregexes
      };
    }



    case UPDATE_REGEX_VALUE: {
      const { regexId, valueId, updatedValue } = payload;

      // Find the regex by regexId
      const updatedRegexes = state.regexes.map(regex => {
        if (regex.id === regexId) {
          // Update the value in the regex's values array
          const updatedValues = regex.attributes.values.data.map(value => {
            if (value.id === valueId) {
              return updatedValue; // Replace the entire value object
            }
            return value;
          });

          // Return the updated regex object with the updated values
          return {
            ...regex,
            attributes: {
              ...regex.attributes,
              values: {
                ...regex.attributes.values,
                data: updatedValues
              }
            }
          };
        }
        return regex;
      });

      // Return the new state with updated values
      return {
        ...state,
        regexes: updatedRegexes
      };
    }

    //! **************************************************** entity regex end *******************************************************


    //! **************************************************** config priority start *****************************************************

    case GET_PRIORITY_LIST: {
      const { entityId, priorityList } = payload;
      return {
        ...state,
        priorityList,
      }
    }
    case CHANGE_PRIORITY_POSITION: {
      const { entityId, priorityList } = payload;
      return {
        ...state,
        priorityList,
      }
    }

    //! **************************************************** config priority end *******************************************************


    //! **************************************************** Predefined start *******************************************************

    case GET_PREDEFINED_LIST: {
      const { predefinedList } = payload;
      return {
        ...state,
        predefinedList,
      }
    }

    case ADD_PREDEFINED: {
      const { predefined } = payload
      if (predefined?.type === 'entity_regex') {
        return {
          ...state,
          regexes: [predefined, ...state.regexes]
        }
      } else if (predefined?.type === 'entity_list') {
        return {
          ...state,
          lists: [predefined, ...state.lists]
        }
      }
    }

    //! **************************************************** Predefined end *******************************************************



    default:
      return state;
  }
};
