import { yupResolver } from '@hookform/resolvers/yup';
import { Controller } from 'react-hook-form';

import {
	CircularProgress,
	Grid,
	makeStyles,
	Paper,
	Typography,
} from '@material-ui/core';
import completeUserProfileBG from 'assets/images/completeUserProfileBG.png';
import completeUserProfileImg from 'assets/images/completeUserProfileImg.png';
import GridContainer from 'components/common/Grid/GridContainer';
import GridItem from 'components/common/Grid/GridItem';
import FormTextInput from 'controllers/FormTextInput';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import setAuthToken from 'helpers/set-auth-token';
import useQuery from 'hooks/useQuery';
// local assets (images)
import { ReactComponent as EyeIconClose } from 'assets/icons/eyeclose.svg';
import { ReactComponent as EyeIconOpen } from 'assets/icons/eyeopen.svg';

const Component = ({
	// userEmail,
	userData,
	sentCompleteUserProfile,
	loading,
	error,
	auth,
	isAuthenticated,
	passwordsValidationSchema,
	completeProfileNames,
}) => {
	const query = useQuery();
	const token = query.get('token');
	const email = query.get('email');
	const expiresAt = query.get('expiresAt');
	const history = useHistory();

	const classes = useStyles();
	const [firstPage, setFirstPage] = useState(true);
	const [showPass, setShowPass] = useState(false);
	const [showPass2, setShowPass2] = useState(false);
	const {
		formState: { errors },
		handleSubmit,
		trigger,
		control,
		setError,
		getValues,
		clearErrors,
	} = useForm({
		shouldFocusError: true,
		resolver: yupResolver(passwordsValidationSchema),
		mode: 'all',
		defaultValues: {
			[completeProfileNames.full_name]: '',
			[completeProfileNames.email]: email,
			[completeProfileNames.password]: '',
			[completeProfileNames.password_confirmation]: '',
		},
	});

	const handleNextBtn = async () => {
		await trigger();

		if (!getValues(completeProfileNames.full_name).length) {
			setError(completeProfileNames.full_name, 'email is required');
		} else {
			clearErrors(completeProfileNames.password);
			clearErrors(completeProfileNames.password_confirmation);
			setFirstPage(false);
		}
	};
	const onSubmit = (data) => {
		if (!Object.keys(errors).length > 0) {
			// set header
			setAuthToken(token);
			// dispatch data
			sentCompleteUserProfile(data);
		}
	};
	// save token & go to system
	useEffect(() => {
		const navigateToTalkybot = async () => {
			// when api return success
			if (!error && !loading && userData?.success) {
				await auth(token);
				history.push('/talkybots');
			}
		};
		navigateToTalkybot();
	}, [loading]);

	// if go to this route from url direct him to login
	useEffect(() => {
		// if (isAuthenticated) {
		// 	history.push('/talkybots');
		// }
		if (!email || !token || !expiresAt) {
			history.push('/login');
		}
	}, []);

	return (
		<div>
			<Grid
				className={classes.container}
				container
				direction="row"
				justifyContent="center"
				alignItems="center"
			>
				<Grid item xs={8}>
					<Paper className={classes.profileContentBox} elevation={0}>
						<GridContainer justifyContent="center">
							<GridItem sm={12} md={6}>
								<form
									style={{ width: '80%' }}
									onSubmit={handleSubmit(onSubmit)}
								>
									<Typography className={classes.formHeader}>
										complete your profile
									</Typography>
									{/** ************ first page*********** */}
									{firstPage && (
										<div>
											{/* full_name */}
											<Controller
												name={completeProfileNames.full_name.toString()}
												control={control}
												render={({ field }) => (
													<FormTextInput
														hookFormField={field}
														requiredSymbol
														error={
															errors?.[
																completeProfileNames
																	.full_name
															]?.message
														}
														type="text"
														label="Full Name:"
														placeHolder="Full Name"
														className={
															classes.inputStyle
														}
													/>
												)}
											/>

											{/* email */}
											<Controller
												name={completeProfileNames.email.toString()}
												control={control}
												render={({ field }) => (
													<FormTextInput
														hookFormField={field}
														requiredSymbol
														error={
															errors?.[
																completeProfileNames
																	.email
															]?.message
														}
														type="text"
														label="Email:"
														placeHolder="Email"
														disabled
														className={`${classes.inputDisableStyle} ${classes.inputStyle}`}
													/>
												)}
											/>
										</div>
									)}

									{/** ************ second page*********** */}
									{!firstPage && (
										<div>
											{/* password */}
											<Controller
												name={completeProfileNames.password.toString()}
												control={control}
												render={({ field }) => (
													<FormTextInput
														hookFormField={field}
														error={
															errors?.[
																completeProfileNames
																	.password
															]?.message
														}
														type={
															showPass
																? 'text'
																: 'password'
														}
														label="password"
														placeHolder="password"
														icon={
															!showPass ? (
																<EyeIconClose
																	onClick={() =>
																		setShowPass(
																			!showPass
																		)
																	}
																	style={{
																		width: '25px',
																		height: '22px',
																		marginRight:
																			'10px',
																	}}
																/>
															) : (
																<EyeIconOpen
																	onClick={() =>
																		setShowPass(
																			!showPass
																		)
																	}
																	style={{
																		width: '25px',
																		height: '22px',
																		marginRight:
																			'10px',
																	}}
																/>
															)
														}
														iconPosition="end"
													/>
												)}
											/>

											{/* password_confirmation */}
											<Controller
												name={
													completeProfileNames.password_confirmation
												}
												control={control}
												render={({ field }) => (
													<FormTextInput
														hookFormField={field}
														error={
															errors?.[
																completeProfileNames
																	.password_confirmation
															]?.message
														}
														type={
															showPass2
																? 'text'
																: 'password'
														}
														label="Confirm Password"
														placeHolder="confirm password"
														icon={
															!showPass2 ? (
																<EyeIconClose
																	onClick={() =>
																		setShowPass2(
																			!showPass2
																		)
																	}
																	style={{
																		width: '25px',
																		height: '22px',
																		marginRight:
																			'10px',
																	}}
																/>
															) : (
																<EyeIconOpen
																	onClick={() =>
																		setShowPass2(
																			!showPass2
																		)
																	}
																	style={{
																		width: '25px',
																		height: '22px',
																		marginRight:
																			'10px',
																	}}
																/>
															)
														}
														iconPosition="end"
													/>
												)}
											/>
										</div>
									)}

									<div className={classes.dotsContainer}>
										<span
											className={
												firstPage
													? classes.activeDot
													: classes.notActiveDot
											}
										>
											{' '}
										</span>

										<span
											className={
												!firstPage
													? classes.activeDot
													: classes.notActiveDot
											}
										>
											{' '}
										</span>
									</div>
									<div className={classes.footerBtn}>
										{!firstPage && (
											<button
												className={classes.backBtn}
												onClick={() =>
													setFirstPage(true)
												}
											>
												<i
													className={`${classes.nextIcon}  fas fa-angle-left`}
												>
													{' '}
												</i>
												Back
											</button>
										)}
										{firstPage && (
											<button
												className={classes.nextBtn}
												onClick={handleNextBtn}
											>
												Next
												<i
													className={`${classes.nextIcon}  fas fa-angle-right`}
												>
													{' '}
												</i>
											</button>
										)}
										{!firstPage && (
											<button
												type="submit"
												className={classes.nextBtn}
												disabled={loading}
											>
												{loading ? (
													<CircularProgress
														color="white"
														size={15}
													/>
												) : (
													'Finish'
												)}
											</button>
										)}
									</div>
									{error ? (
										<div className={classes.errorStyle}>
											{error}
										</div>
									) : null}
								</form>
							</GridItem>

							<GridItem align="center" md={6}>
								<img
									className={classes.profileContentBoxImg}
									src={completeUserProfileImg}
									alt="complete User Profile"
								/>
								<Typography className={classes.footerDesc}>
									join your organization team
								</Typography>
							</GridItem>
						</GridContainer>
					</Paper>
				</Grid>
			</Grid>
		</div>
	);
};

export default Component;

const useStyles = makeStyles(() => ({
	container: {
		backgroundImage: `url(${completeUserProfileBG})`,
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: '100% 100%',
		height: '100vh',
		padding: '1px',
	},
	formHeader: {
		fontFamily: 'Poppins !important',
		fontSize: '1.875rem !important',
		fontWeight: 600,
		color: '#202020',
		textTransform: 'capitalize',
	},
	footerDesc: {
		fontFamily: 'Poppins !important',
		fontSize: '2rem  !important',
		fontWeight: 800,
		color: '#000000',
		textTransform: 'capitalize',
		width: '90%',
	},
	profileContentBox: {
		// margin: '0rem auto 0rem auto',
		// width: '65rem',
		minHeight: '35rem',
		boxShadow: '0px 4px 14px #0000000A',
		borderRadius: '0.625rem',
		padding: '3rem 2.5rem',
	},
	profileContentBoxImg: {
		width: '100%',
		marginBottom: '2rem',
	},
	labelStyle: {
		display: 'block',
		marginTop: '2rem',
		fontFamily: 'Poppins !important',
		fontSize: '1.0625rem !important',
		fontWeight: 500,
		color: '#212121',
		textTransform: 'capitalize',
	},
	inputStyle: {
		fontFamily: 'Poppins !important',
		fontSize: '1.25rem !important',
		fontWeight: 500,
		color: '#000000',
		textTransform: 'capitalize',

		width: '100%',
		height: '3rem',
		margin: '1rem 0px 0.2rem 0px',
		display: 'block',
		border: '1px solid #D9D9D9',
		borderRadius: '0.625rem',
		padding: '0px 1rem',
	},
	inputDisableStyle: {
		background: '#ECEEF0 0% 0% no-repeat padding-box',
	},
	dotsContainer: {
		textAlign: 'center',
		margin: '2rem',
	},
	activeDot: {
		background: '#6418C3 0% 0% no-repeat padding-box',
		borderRadius: '1.75rem',
		display: 'inline-block',
		width: '1.25rem',
		height: '0.625rem',
		margin: '0px 0.3125rem',
	},
	notActiveDot: {
		display: 'inline-block',
		width: '0.625rem',
		height: '0.625rem',
		background: '#C4C4C4 0% 0% no-repeat padding-box',
		borderRadius: '50%',
	},
	nextBtn: {
		fontFamily: 'Poppins !important',
		fontSize: '1.0625rem !important',
		fontWeight: 600,
		textTransform: 'capitalize',
		letterSpacing: '0.87px',
		textAlign: 'center',

		float: 'right',
		// padding: '.5rem 1rem',
		minWidth: '6rem',
		lineHeight: '2.5rem',
		minHeight: '2.5rem',
		borderRadius: '.5rem',
		color: '#FFFFFF',
		background: '#6418C3 0% 0% no-repeat padding-box',
		boxShadow: ' 0px 4px 4px #0000000A',
		cursor: 'pointer',
	},
	backBtn: {
		fontFamily: 'Poppins !important',
		fontSize: '1.0625rem !important',
		fontWeight: 600,
		textTransform: 'capitalize',
		letterSpacing: '0.87px',
		color: '#000000',
		cursor: 'pointer',
	},
	nextIcon: {
		fontSize: '1.4rem !important',
		// fontWeight:500
		transform: 'translateY(13%)',
		// marginRight: '.5rem',
	},
	errorStyle: {
		color: '#D8000C',
		marginTop: '.5rem',
	},
	footerBtn: {
		marginTop: '4rem',
	},
	passwordContainer: {
		position: 'relative',
		'& > svg': {
			position: 'absolute',
			top: '7px',
			right: '10px',
		},
	},
}));
