import { useEffect, useState } from 'react';
import { Skeleton } from '@material-ui/lab'
// local styles
import styles from './index.module.scss'

const Component = ({ cardInfo, isLoading }) => {
  // get current media query
  const [matches, setMatches] = useState(
    window.matchMedia('(max-width: 1600px)').matches
  );

  useEffect(() => {
    window
      .matchMedia('(max-width: 1600px)')
      .addEventListener('change', (e) => setMatches(e.matches));

    return () => window.removeEventListener('change', (e) => setMatches(e.matches));
  }, []);
  return (
    <>
      {
        isLoading ? (
          <Skeleton className={styles.skeleton} variant="rect" animation="wave" height={matches ? 109 : 121} />
        ) : (
          <div className={styles.Container}>
            {cardInfo.icon}
            <span className={styles.cardTitle}>{cardInfo.cardTitle}</span>
            <span className={styles.result}>{cardInfo.result ?? 0}</span>
          </div >
        )
      }
    </>
  )
}
export default Component