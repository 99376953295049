export const ADD_NEW_ACCOUNT = 'ADD_NEW_ACCOUNT';
export const GET_ALL_ACCOUNTS = 'GET_ALL_ACCOUNTS';
export const REMOVE_ACCOUNT = 'REMOVE_ACCOUNT';
export const LIST_ACCOUNT_CHANNELS = 'LIST_ACCOUNT_CHANNELS';
export const GET_ALL_ACCOUNTS_ERROR = 'GET_ALL_ACCOUNTS_ERROR';

export const GET_ALL_WHATSAPP_CHANNELS = 'GET_ALL_WHATSAPP_CHANNELS';
export const CREATE_WHATSAPP_ACCOUNT_SUCCESS =
	'CREATE_WHATSAPP_ACCOUNT_SUCCESS';
export const TOGGLE_WHATSAPP_CHANNEL = 'TOGGLE_WHATSAPP_CHANNEL';
export const REMOVE_WHATSAPP_ACCOUNT = 'REMOVE_WHATSAPP_ACCOUNT';

export const GET_ALL_INSTAGRAM_ACCOUNTS = 'GET_ALL_INSTAGRAM_ACCOUNTS';
export const GET_ALL_INSTAGRAM_ACCOUNTS_ERROR =
	'GET_ALL_INSTAGRAM_ACCOUNTS_ERROR';
export const REMOVE_INSTAGRAM_ACCOUNT = 'REMOVE_INSTAGRAM_ACCOUNT';
