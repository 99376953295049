import Typography from '@material-ui/core/Typography';
import { Container, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import relationshipImg from 'assets/images/landingImgs/relationshipImg.png';
const useStyles = makeStyles((theme) => ({
	relationTitleFrame: {
		// width:"30%",
		margin: '3.125rem auto 0px auto',
	},
	relationSubTitle: {
		...theme.typography.subtitle5,
		color: theme.palette.text.primary,
		// lineHeight:0,
	},
	relationTitle: {
		...theme.typography.subtitle3,
		color: theme.palette.text.primary,
		lineHeight: 1.5,
	},
	relationImg: {
		[theme.breakpoints.up('md')]: { width: '70%' },
		width: '95%',
		margin: '-10% auto 6.25rem auto',
	},
}));
const Relationship = () => {
	const classes = useStyles();
	return (
		<Paper elevation={0}>
			<Paper
				className={classes.relationTitleFrame}
				align="center"
				elevation={0}
			>
				<Typography className={classes.relationSubTitle}>
					Benefits
				</Typography>
				<Typography className={classes.relationTitle}>
					HOW CRM IMPROVES <br /> CUSTOMER RELATIONSHIPS
				</Typography>
			</Paper>
			<Paper className={classes.relationImg} elevation={0}>
				<img width="100%" height="100%" src={relationshipImg} alt="" />
			</Paper>
		</Paper>
	);
};

export default Relationship;
