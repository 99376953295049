import DateRangeIcon from '@material-ui/icons/DateRange';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Dropdown from 'components/dashboard/Shared/dropdown/Dropdown';
import { useRef, useState } from 'react';
// local styles
import styles from './index.module.scss';



const Component = ({
  statusDurationValue,
  setStatusDurationValue,
  statusChannelValue,
  setStatusChannelValue,
  channelData,
  DurationData
}) => {

  const [showDropDuration, setShowDropDuration] = useState(false)
  const durationSelectedValue = DurationData?.find((item) => item.value === statusDurationValue)
  const durationRef = useRef()

  const [showDropChannel, setShowDropChannel] = useState(false)
  const cahnnelSelectedValue = channelData?.find((item) => item.value === statusChannelValue)
  const channelRef = useRef()


  return (
    <div className={styles.container}>
      {channelData && <div onClick={() => { setShowDropChannel(!showDropChannel) }} className={styles.dropDawnContainer}>
        <div
          ref={channelRef}
          style={showDropChannel ? { borderRadius: "20px 20px 0 0" } : { borderRadius: "30px" }}
          className={styles.filters}
        >
          {cahnnelSelectedValue?.icon}
          <span style={{ color: "#686868" }}>
            {cahnnelSelectedValue?.title}
          </span>
          <ExpandMoreIcon />
        </div>
        {showDropChannel && <Dropdown menu={channelData} setShow={setShowDropChannel} setSelected={setStatusChannelValue} statusRef={channelRef} />}
      </div>}

      {DurationData && <div onClick={() => { setShowDropDuration(!showDropDuration) }} className={styles.dropDawnContainer}>
        <div
          ref={durationRef}
          style={showDropDuration ? { borderRadius: "20px 20px 0 0" } : { borderRadius: "30px" }}
          className={styles.filters}
        >
          <DateRangeIcon style={{ color: '#686868', maxWidth: '25px' }} />
          <span style={{ color: "#686868" }}>
            {durationSelectedValue?.title}
          </span>
          <ExpandMoreIcon />
        </div>
        {showDropDuration && <Dropdown menu={DurationData} setShow={setShowDropDuration} setSelected={setStatusDurationValue} statusRef={durationRef} />}
      </div>}
    </div>
  )
}

export default Component