// get message in
export const GET_MESSAGES_IN_SUCCESS = 'GET_MESSAGES_IN_SUCCESS';
export const GET_MESSAGES_IN_ERROR = 'GET_MESSAGES_IN_ERROR';
// get message out
export const GET_MESSAGES_OUT_SUCCESS = 'GET_MESSAGES_OUT_SUCCESS';
export const GET_MESSAGES_OUT_ERROR = 'GET_MESSAGES_OUT_ERROR';
// get customer analytics
export const GET_CUSTOMER_ANALYTICS_SUCCESS = 'GET_CUSTOMER_ANALYTICS_SUCCESS';
export const GET_CUSTOMER_ANALYTICS_ERROR = 'GET_CUSTOMER_ANALYTICS_ERROR';
// get number of new Customers
export const GET_NUMBER_OF_NEW_CUSTOMERS_SUCCESS =
	'GET_NUMBER_OF_NEW_CUSTOMERS_SUCCESS';
export const GET_NUMBER_OF_NEW_CUSTOMERS_ERROR =
	'GET_NUMBER_OF_NEW_CUSTOMERS_ERROR';
// get number of conversation sessions
export const GET_NUMBER_OF_CONVERSATION_SESSIONS_SUCCESS =
	'GET_NUMBER_OF_CONVERSATION_SESSIONS_SUCCESS';
export const GET_NUMBER_OF_CONVERSATION_SESSIONS_ERROR =
	'GET_NUMBER_OF_CONVERSATION_SESSIONS_ERROR';
// get gender
export const GET_GENDER_SUCCESS = 'GET_GENDER_SUCCESS';
export const GET_GENDER_ERROR = 'GET_GENDER_ERROR';
// get closed conversation by who
export const GET_CLOSED_CONVERSATION_SUCCESS =
	'GET_CLOSED_CONVERSATION_SUCCESS';
export const GET_CLOSED_CONVERSATION_ERROR = 'GET_CLOSED_CONVERSATION_ERROR';
// get number of conversation
export const GET_NUMBER_OF_CONVERSATION_SUCCESS =
	'GET_NUMBER_OF_CONVERSATION_SUCCESS';
export const GET_NUMBER_OF_CONVERSATION_ERROR =
	'GET_NUMBER_OF_CONVERSATION_ERROR';
// get most frequent questions
export const GET_MOST_FREQUENT_QUESTIONS_SUCCESS =
	'GET_MOST_FREQUENT_QUESTIONS_SUCCESS';
export const GET_MOST_FREQUENT_QUESTIONS_ERROR =
	'GET_MOST_FREQUENT_QUESTIONS_ERROR';
// get Statement Recognition
export const GET_STATEMENT_RECOGNITION_SUCCESS =
	'GET_STATEMENT_RECOGNITION_SUCCESS';
export const GET_STATEMENT_RECOGNITION_ERROR =
	'GET_STATEMENT_RECOGNITION_ERROR';
// get Completion scenarios
export const GET_COMPLETION_SCENARIOS_SUCCESS =
	'GET_COMPLETION_SCENARIOS_SUCCESS';
export const GET_COMPLETION_SCENARIOS_ERROR = 'GET_COMPLETION_SCENARIOS_ERROR';
// get Most Used Scenarios
export const GET_MOST_USED_SCENARIOS_SUCCESS =
	'GET_MOST_USED_SCENARIOS_SUCCESS';
export const GET_MOST_USED_SCENARIOS_ERROR = 'GET_MOST_USED_SCENARIOS_ERROR';
// get Conversation rating
export const GET_CONVERSATION_RATING_SUCCESS =
	'GET_CONVERSATION_RATING_SUCCESS';
export const GET_CONVERSATION_RATING_ERROR = 'GET_CONVERSATION_RATING_ERROR';
// get customer Status analytics
export const GET_CUSTOMER_STATUS_SUCCESS = 'GET_CUSTOMER_STATUS_SUCCESS';
export const GET_CUSTOMER_STATUS_ERROR = 'GET_CUSTOMER_STATUS_ERROR';
// get assigned & not assigned customers
export const GET_ASSIGNED_CUSTOMERS_SUCCESS = 'GET_ASSIGNED_CUSTOMERS_SUCCESS';
export const GET_ASSIGNED_CUSTOMERS_ERROR = 'GET_ASSIGNED_CUSTOMERS_ERROR';
// get team members messages
export const GET_TEAM_MEMBERS_MESSAGES_SUCCESS =
	'GET_TEAM_MEMBERS_MESSAGES_SUCCESS';
export const GET_TEAM_MEMBERS_MESSAGES_ERROR =
	'GET_TEAM_MEMBERS_MESSAGES_ERROR';
export const RESET_TEAM_MEMBERS_MESSAGES_SUCCESS =
	'RESET_TEAM_MEMBERS_MESSAGES_SUCCESS';
// get takeover and escalation
export const GET_TAKEOVER_AND_ESCALATION_SUCCESS =
	'GET_TAKEOVER_AND_ESCALATION_SUCCESS';
export const GET_TAKEOVER_AND_ESCALATION_ERROR =
	'GET_TAKEOVER_AND_ESCALATION_ERROR';
// get team handover
export const GET_TEAM_HANDOVER_SUCCESS = 'GET_TEAM_HANDOVER_SUCCESS';
export const GET_TEAM_HANDOVER_ERROR = 'GET_TEAM_HANDOVER_ERROR';
// get bot escalation
export const GET_BOT_ESCALATION_SUCCESS = 'GET_BOT_ESCALATION_SUCCESS';
export const GET_BOT_ESCALATION_ERROR = 'GET_BOT_ESCALATION_ERROR';
// get bot interactions
export const GET_BOT_INTERACTIONS_SUCCESS = 'GET_BOT_INTERACTIONS_SUCCESS';
export const GET_BOT_INTERACTIONS_ERROR = 'GET_BOT_INTERACTIONS_ERROR';
// get bot activites
export const GET_BOT_ACTIVITES_SUCCESS = 'GET_BOT_ACTIVITES_SUCCESS';
export const GET_BOT_ACTIVITES_ERROR = 'GET_BOT_ACTIVITES_ERROR';
export const RESET_BOT_ACTIVITES_SUCCESS = 'RESET_BOT_ACTIVITES_SUCCESS';
export const GET_SENTIMENT = 'GET_SENTIMENT';
export const GET_SENTIMENT_SUCCESS = 'GET_SENTIMENT_SUCCESS';
export const GET_SENTIMENT_FAILED = 'GET_SENTIMENT_FAILED';
