import { connect } from "react-redux";
import { getCompletionScenariosAction } from "store/actions/creators/dashboard";


const mapStateToProps = (state) => {
  return {
    talkybotId: state?.activeTalkybot?.id,
    completion_scenarios: state?.dashboard?.completion_scenarios,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCompletionScenarios: ({ talkyBotID, duration_filter, provider_filter, setUiLoadingError }) =>
      dispatch(
        getCompletionScenariosAction({
          talkyBotID,
          duration_filter,
          provider_filter,
          setUiLoadingError,
        })
      ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps);
