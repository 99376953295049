/**
 * used in Conversations (savedReplies reducer) to parse the full_message by taking the message and the mention
 * message: string >> what the user is typing.
 * mention: full object containing id, display, is_custom_field, etc...
 * ex: 'hello Mr. @@{{{ {"id":"74a56413-1c7b-4f3b-a817-8a07a6d27397","display":"email","is_custom_field":false,"is_bot_field":false,"is_normal_field":true,"value":null} }}}@@>>><<< '
 * it should be like that: hello Mr. "email"
 * @param payload {array || object }
 * @returns {array || object }
 */

function parseSavedReply(payload) {
	const data = payload;

	if (Array.isArray(payload)) {
		const messages = data.map((item) => item.full_message);

		for (let i = 0; i < messages.length; i++) {
			const message = messages[i];
			const splittedStr = message.split('>>><<<  ');

			let totalMessage = '';

			for (let j = 0; j < splittedStr.length; j++) {
				const firstPart = splittedStr[j].indexOf('@@{{{');
				const secondPart = splittedStr[j].indexOf('}}}@@');

				if (firstPart > -1 && secondPart > -1) {
					// this slice just to take the object itself (mention object)
					const slicedString = splittedStr[j].slice(
						firstPart + 6,
						secondPart - 1
					);

					// this slice just to take the display value from the object (mention object)
					const splittedSlicedString = slicedString
						?.split(',')[1]
						?.split(':')[1];

					const finalString =
						splittedStr[j].slice(0, firstPart) +
						splittedSlicedString;

					totalMessage += finalString + ' ';
				} else {
					totalMessage += splittedStr[j];
				}

				data[i].full_message = totalMessage;
			}
		}
	} else {
		const message = data?.full_message;
		const splittedStr = message.split('>>><<<  ');

		let totalMessage = '';

		for (let i = 0; i < splittedStr.length; i++) {
			const firstPart = splittedStr[i].indexOf('@@{{{');
			const secondPart = splittedStr[i].indexOf('}}}@@');

			if (firstPart > -1 && secondPart > -1) {
				// this slice just to take the object itself (mention object)
				const slicedString = splittedStr[i].slice(
					firstPart + 6,
					secondPart - 1
				);

				// this slice just to take the display value from the object (mention object)
				const splittedSlicedString = slicedString
					?.split(',')[1]
					?.split(':')[1];

				const finalString =
					splittedStr[i].slice(0, firstPart) + splittedSlicedString;

				totalMessage += finalString + ' ';
			} else {
				totalMessage += splittedStr[i];
			}

			data.full_message = totalMessage;
		}
	}

	return data;
}

export default parseSavedReply;
