import moment from 'moment';
import { mapArrayToObject } from './stateManagement';

/**
 * Check if the target within the to from range ..
 * @param target
 * @param to
 * @param from
 * @returns {boolean}
 */
const dateInRange = ({ target, to, from }) => {
	const targetToCheck = moment(target).format('DD/MM/YYYY');
	const fromDate = moment(from).format('DD/MM/YYYY');
	const toDate = moment(to).format('DD/MM/YYYY');
	return targetToCheck >= fromDate && targetToCheck <= toDate;
};

/**
 *
 * @param filters
 * @param people
 * @returns {*}
 */
const filterPeopleHelper = ({ filters, people }) => {
	if (filters.length === 0) {
		return people;
	}
	let peopleToFilter = Object.values(people);
	let filtered = [];
	filters.forEach((filter) => {
		const { filterQuery } = filter;
		switch (filter.filterType) {
			case 'string':
				switch (filterQuery?.operator?.name) {
					case 'contains':
						peopleToFilter.forEach((person) => {
							if (
								person[filterQuery?.name]
									.toUpperCase()
									.includes(filterQuery?.value.toUpperCase())
							) {
								filtered.push(person);
							}
						});
						break;
					case 'equals':
						peopleToFilter.forEach((person) => {
							if (
								person[filterQuery?.name].toUpperCase() ===
								filterQuery?.value.toUpperCase()
							) {
								filtered.push(person);
							}
						});
						break;
					case 'startsWith':
						peopleToFilter.forEach((person) => {
							if (
								person[filterQuery?.name]
									.toUpperCase()
									.startsWith(
										filterQuery?.value.toUpperCase()
									)
							) {
								filtered.push(person);
							}
						});
						break;
					case 'isEmpty':
						peopleToFilter.forEach((person) => {
							if (person[filterQuery?.name] === '') {
								filtered.push(person);
							}
						});
						break;
					case 'isNotEmpty':
						peopleToFilter.forEach((person) => {
							if (person[filterQuery?.name] !== '') {
								filtered.push(person);
							}
						});
						break;
					default:
						break;
				}
				break;
			case 'options':
				switch (filterQuery?.operator?.name) {
					case 'is':
						peopleToFilter.forEach((person) => {
							if (
								person[filterQuery?.name].toUpperCase() ===
								filterQuery?.value.toUpperCase()
							) {
								filtered.push(person);
							}
						});
						break;
					case 'isUnknown':
						peopleToFilter.forEach((person) => {
							if (person[filterQuery?.name] === '') {
								filtered.push(person);
							}
						});
						break;
				}
				break;
			case 'datetime':
				peopleToFilter.forEach((person) => {
					if (
						dateInRange({
							target: person[filterQuery?.name],
							to: filterQuery?.value.to,
							from: filterQuery?.value.from,
						})
					) {
						filtered.push(person);
					}
				});
				break;
			default:
				break;
		}
	});

	return mapArrayToObject(filtered, true);
};

export default filterPeopleHelper;
