import { connect } from 'react-redux';
import { activateEntityAction, changePriorityAction, clearActiveEntityAction, getPriorityListAction } from 'store/actions/creators/entities';

const mapStateToProps = (state) => {
	return {
		activeTalkybot: state?.activeTalkybot,
		activeEntity: state?.entities?.activeEntity,
		priorityList: state?.entities?.priorityList,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		clearActiveEntity: () => dispatch(clearActiveEntityAction()),
		activateEntity: (entityId, botBuilderId) =>
			dispatch(activateEntityAction(entityId, botBuilderId)),
		getPriorityList: ({entityId, botBuilderAppId, setUiLoadingError}) => dispatch(getPriorityListAction({entityId, botBuilderAppId, setUiLoadingError})),
		changePriority: ({entityId, botBuilderAppId, entityPriorityId, position, enqueueSnackbar, setUiLoadingError }) => dispatch(changePriorityAction({entityId, botBuilderAppId, entityPriorityId, position, enqueueSnackbar, setUiLoadingError })),
	};
};

export default connect(mapStateToProps, mapDispatchToProps);
