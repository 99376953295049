// local assets
import { ReactComponent as NoOfClosedConversation } from 'assets/images/Dashboard/NoOfClosedConversation.svg';
import { ReactComponent as NoOfMessageIn } from 'assets/images/Dashboard/NoOfMessageIn.svg';
import { ReactComponent as NoOfMessageOut } from 'assets/images/Dashboard/NoOfMessageOut.svg';
import { ReactComponent as NoOfSessions } from 'assets/images/Dashboard/NoOfSessions.svg';
import { ReactComponent as NoOfUniqueUser } from 'assets/images/Dashboard/NoOfUniqueUser.svg';
// *////////////////////////////////
import { connect } from 'react-redux';
import {
	getAssignedCustomersAction,
	getConversationRatingAction,
	getCustomerAnalyticsAction,
	getCustomerStatusAction,
	getGenderAction,
	getNumberOfConversationAction,
	getNumberOfConversationSessionsAction,
	getNumberOfNewCustomersAction,
	getSentimentAction,
} from 'store/actions/creators/dashboard';
const mapStateToProps = (state) => {
	return {
		talkybotId: state?.activeTalkybot?.id,
		gender: renderGenderCardInfo(state),
		conversationRating: renderConversationRating(state),
		analyticsCardsInfo: renderAnalyticsCardInfo(state),
		ChartsInfo: renderChartsInfo(state),
		assignedCustomersInfo: renderAssignedCustomersInfo(state),
		sentiment: state?.dashboard?.sentiment,
		// this message for showing or hiding the chart
		// for customer analytics chart, if the filter is overall, hide the chart and show the message
		message: state?.dashboard?.customerAnalytics?.message,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		getCustomerAnalytics: ({
			talkyBotID,
			duration_filter,
			provider_filter,
			setUiLoadingError,
		}) =>
			dispatch(
				getCustomerAnalyticsAction({
					talkyBotID,
					duration_filter,
					provider_filter,
					setUiLoadingError,
				})
			),
		getNumberOfNewCustomers: ({
			talkyBotID,
			duration_filter,
			provider_filter,
			setUiLoadingError,
		}) =>
			dispatch(
				getNumberOfNewCustomersAction({
					talkyBotID,
					duration_filter,
					provider_filter,
					setUiLoadingError,
				})
			),
		getNumberOfConversationSessions: ({
			talkyBotID,
			duration_filter,
			provider_filter,
			setUiLoadingError,
		}) =>
			dispatch(
				getNumberOfConversationSessionsAction({
					talkyBotID,
					duration_filter,
					provider_filter,
					setUiLoadingError,
				})
			),
		getGender: ({
			talkyBotID,
			duration_filter,
			provider_filter,
			setUiLoadingError,
		}) =>
			dispatch(
				getGenderAction({
					talkyBotID,
					duration_filter,
					provider_filter,
					setUiLoadingError,
				})
			),
		getNumberOfConversation: ({
			talkyBotID,
			duration_filter,
			provider_filter,
			setUiLoadingError,
		}) =>
			dispatch(
				getNumberOfConversationAction({
					talkyBotID,
					duration_filter,
					provider_filter,
					setUiLoadingError,
				})
			),
		getConversationRating: ({
			talkyBotID,
			duration_filter,
			provider_filter,
			setUiLoadingError,
		}) =>
			dispatch(
				getConversationRatingAction({
					talkyBotID,
					duration_filter,
					provider_filter,
					setUiLoadingError,
				})
			),
		getCustomerStatus: ({
			talkyBotID,
			duration_filter,
			provider_filter,
			setUiLoadingError,
		}) =>
			dispatch(
				getCustomerStatusAction({
					talkyBotID,
					duration_filter,
					provider_filter,
					setUiLoadingError,
				})
			),
		getAssignedCustomers: ({
			talkyBotID,
			duration_filter,
			provider_filter,
			setUiLoadingError,
		}) =>
			dispatch(
				getAssignedCustomersAction({
					talkyBotID,
					duration_filter,
					provider_filter,
					setUiLoadingError,
				})
			),
			getSentiment: ({
				talkyBotID,
				duration_filter,
				provider_filter,
				setUiLoadingError,
			}) =>
				dispatch(
					getSentimentAction({
						talkyBotID,
						duration_filter,
						provider_filter,
						setUiLoadingError,
					})
				),
	};
};
const renderAnalyticsCardInfo = (state) => {
	return [
		{
			id: 1,
			icon: <NoOfMessageIn />,
			cardTitle: 'No. Of Message In',
			result: state?.dashboard?.messagesIn?.number_of_messages_in,
		},
		{
			id: 2,
			icon: <NoOfMessageOut />,
			cardTitle: 'No. Of Messages Out',
			result: state?.dashboard?.messagesOut?.number_of_messages_out,
		},
		{
			id: 3,
			icon: <NoOfClosedConversation />,
			cardTitle: 'No. Of Closed Conversation',
			result: state?.dashboard?.closed_conversation
				?.all_closed_conversations,
		},
		{
			id: 4,
			icon: <NoOfUniqueUser />,
			cardTitle: 'No. Of New Contacts',
			result: state?.dashboard?.numberOfUnqiueUsers
				?.number_of_new_customers,
		},
		{
			id: 5,
			icon: <NoOfSessions />,
			cardTitle: 'No. Of Conversation Sessions',
			result: state?.dashboard?.numberOfConversationSessions
				?.number_of_conversations_sessions,
		},
	];
};
const renderConversationRating = (state) => {
	return {
		average: state?.dashboard?.conversation_rating?.average_out_of_5,
		data: [
			{
				id: 1,
				title: '5 Star',
				result: state?.dashboard?.conversation_rating
					?.rate_five_percentage,
			},
			{
				id: 2,
				title: '4 Star',
				result: state?.dashboard?.conversation_rating
					?.rate_four_percentage,
			},
			{
				id: 3,
				title: '3 Star',
				result: state?.dashboard?.conversation_rating
					?.rate_three_percentage,
			},
			{
				id: 4,
				title: '2 Star',
				result: state?.dashboard?.conversation_rating
					?.rate_two_percentage,
			},
			{
				id: 5,
				title: '1 Star',
				result: state?.dashboard?.conversation_rating
					?.rate_one_percentage,
			},
		],
	};
};
const renderGenderCardInfo = (state) => {
	return [
		{
			id: 1,
			title: 'Male',
			result: state?.dashboard?.gender?.male,
			background: '#B7DBFF',
		},
		{
			id: 2,
			title: 'Female',
			result: state?.dashboard?.gender?.female,
			background: '#FFCCEC',
		},
		{
			id: 3,
			title: 'Unknown',
			result: state?.dashboard?.gender?.unknown,
			background: '#B7DBFF',
		},
	];
};
const renderChartsInfo = (state) => {
	return [
		{
			id: 1,
			title: 'No. of closed conversations',
			total: state?.dashboard?.closed_conversation
				?.all_closed_conversations,
			data: [
				{
					id: 1,
					name: 'By Bot',
					value: state?.dashboard?.closed_conversation
						?.conversations_closed_by_bot,
				},
				{
					id: 2,
					name: 'By Team Members',
					value: state?.dashboard?.closed_conversation
						?.conversations_closed_by_user,
				},
			],
			message: state?.dashboard?.closed_conversation?.message || '',
			colors: ['#B7DBFF', '#B479FF'],
		},
		{
			id: 2,
			title: 'No. of conversations',
			total: state?.dashboard?.number_of_conversation
				?.all_conversations_num,
			data: [
				{
					id: 1,
					name: 'Open',
					value: state?.dashboard?.number_of_conversation
						?.opened_conversations_num,
				},
				{
					id: 2,
					name: 'Closed',
					value: state?.dashboard?.number_of_conversation
						?.closed_conversations_num,
				},
			],
			message: state?.dashboard?.number_of_conversation?.message || '',
			colors: ['#39D5CF', '#FFBB00'],
		},
		{
			id: 3,
			title: 'Contact analytics',
			total: state?.dashboard?.customerAnalytics?.total_customers,
			data: [
				{
					name: 'New Customers',
					value: state?.dashboard?.customerAnalytics
						?.total_number_of_new_customers,
				},
				{
					name: 'Returning Customers',
					value: state?.dashboard?.customerAnalytics
						?.total_number_of_returning_customers,
				},
			],
			message: state?.dashboard?.customerAnalytics?.message || '',
			colors: ['#CE61FF', '#FFD04F'],
		},
		{
			id: 4,
			title: 'Contact status analytics',
			total: state?.dashboard?.customer_status?.total_customer,
			data: state?.dashboard?.customer_status?.data?.map((item) => ({
				name: item?.status_name,
				value: item?.number_of_customer,
			})),
			message: state?.dashboard?.customer_status?.message || '',
			colors: state?.dashboard?.customer_status?.data?.map((item) =>
				item?.status_color ? item?.status_color : '#00000091'
			),
		},
	];
};
const renderAssignedCustomersInfo = (state) => {
	return [
		{
			id: 1,
			title: 'Assigned',
			result: state?.dashboard?.assigned_customers
				?.number_of_assigned_customers,
			background: '#B7DBFF',
		},
		{
			id: 2,
			title: 'Not Assigned',
			result: state?.dashboard?.assigned_customers
				?.number_of_unassigned_customers,
			background: '#000000',
		},
	];
};
export default connect(mapStateToProps, mapDispatchToProps);
