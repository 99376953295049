/* eslint-disable no-debugger */
import { handleActionError } from 'helpers/reducerError';

import axios from 'helpers/axios';
import { talkybotsPath } from 'constants/apis';
import { mapArrayToObject } from 'helpers/stateManagement';
import {
	CREATE_BOT,
	DELETE_BOT,
	GET_ALL_BOTS,
	GET_BOT_DETAILS,
	ACTIVATE_BOT,
	DEACTIVATE_BOT,
	PUBLISH_BOT,
	EDIT_BOT,
	UNINSTALL_APP,
	INSTALL_APP,
	CREATE_BOT_FAIL,
	GET_ALL_BOTS_FAIL,
	EDIT_BOT_FAIL,
	DELETE_BOT_FAIL,
	GET_ALL_FIELDS_SUCCESS,
	GET_ALL_FIELDS_FAIL,
	GET_SINGLE_BOT,
	GET_SINGLE_BOT_FAIL,
} from 'store/actions/types/talkybots';
import apiRequest, { getErrorMessageAndCode } from 'helpers/apiRequest';

export const getAllBotsAction = (setUiLoadingError) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			const actionData = {
				type: GET_ALL_BOTS,
				payload: {
					talkybots: mapArrayToObject(responseData.data),
				},
			};
			dispatch(actionData);
		};
		const onError = (e) => {
			dispatch({
				type: GET_ALL_BOTS_FAIL,
			});
		};
		apiRequest.get({
			url: talkybotsPath,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};

export const getBotDetailsAction = (botId) => {
	return async (dispatch) => {
		try {
			const {
				status,
				data: { success, data },
			} = await axios.get(`${talkybotsPath}/${botId}`);
			if (status && success) {
				const actionData = {
					type: GET_BOT_DETAILS,
					payload: data,
				};
				dispatch(actionData);
			}
		} catch (e) {
			// handle error
		}
	};
};

export const createBotAction = (botData, setUiLoadingError) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			//! function to transform the talkybot that is coming from the post request to the same structure as the talkybots in the state management
			const transformObjectStructure = (inputObject) => {
				const { attributes, ...rest } = inputObject;
				const transformedObject = { ...inputObject, ...attributes };
			
				if (transformedObject.installed_applications) {
					transformedObject.installed_applications = transformedObject.installed_applications.map(app => {
						const { attributes: appAttributes, ...appRest } = app;
						return { ...appRest, ...appAttributes };
					});
				}
			
				return transformedObject;
			};			
			const actionData = {
				type: CREATE_BOT,
				payload: { talkybot: transformObjectStructure(responseData.data.talkybot.data) },
			};
			dispatch(actionData);
		};
		const onError = (e) => {
			dispatch({
				type: CREATE_BOT_FAIL,
			});
		};
		apiRequest.post({
			url: talkybotsPath,
			bodyData: botData,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};

export const deleteBotAction = (talkybotId, setUiLoadingError) => {
	// eslint-disable-next-line no-debugger
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			const deleteData = {
				type: DELETE_BOT,
				payload: { talkybotId },
			};
			dispatch(deleteData);
		};
		const onError = (e) => {
			dispatch({
				type: DELETE_BOT_FAIL,
			});
		};
		apiRequest.delete({
			url: `${talkybotsPath}/${talkybotId}`,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};


export const getSingleBotAction = (talkybotId, setUiLoadingError) => {
	// eslint-disable-next-line no-debugger
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			const botData= {
				type: GET_SINGLE_BOT,
				payload: { 
					talkybot: responseData.data
				},
			};
			dispatch(botData);
		};
		const onError = (e) => {
			dispatch({
				type: GET_SINGLE_BOT_FAIL,
			});
		};
		apiRequest.get({
			url: `${talkybotsPath}/${talkybotId}`,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};


export const editBotAction = (botId, data, setUiLoadingError) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			const editData = {
				type: EDIT_BOT,
				payload: { talkybot: responseData?.data },
			};
			dispatch(editData);
		};
		const onError = (e) => {
			dispatch({
				type: EDIT_BOT_FAIL,
			});
		};
		apiRequest.patch({
			url: `${talkybotsPath}/${botId}`,
			bodyData: data,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};

export const uninstallApplicationAction = (
	talkybotId,
	application,
	history
) => {
	return async (dispatch) => {
		try {
			const {
				status,
				data: { success },
			} = await axios.post(`${talkybotsPath}/${talkybotId}/uninstall`, {
				application_id: application?.id || application.attributes.id,
			});

			if (status) {
				const actionData = {
					type: UNINSTALL_APP,
					payload: {
						application:
							application.attributes.applicationable_type,
						talkybotId,
					},
				};

				// await dispatch(actionData);

				history.push('/talkybots');
			}
		} catch (e) {
			// handle error
		}
	};
};

export const installApplicationAction = (
	talkybotId,
	application,
	enqueueSnackbar,
	history
) => {
	return async (dispatch) => {
		try {
			const body = {
				application: application.applicationType,
				type_id: application.typeId,
			};

			const {
				status,
				data: { data },
			} = await axios.post(`${talkybotsPath}/${talkybotId}/install`, {
				...body,
			});

			if (status) {
				const actionData = {
					type: INSTALL_APP,
					payload: {
						application: {
							attributes: {
								...data,
							},
						},
						talkybotId,
					},
				};
				enqueueSnackbar(
					`Install conversation application successfully`,
					{
						variant: 'success',
						autoHideDuration: 3000,
					}
				);
				await dispatch(actionData);
				history.push('/talkybots');
			}
		} catch (e) {
			enqueueSnackbar(getErrorMessageAndCode(e)?.message, {
				variant: 'error',
				autoHideDuration: 3000,
			});
		}
	};
};

export const activateTalkybotAction = (bot) => {
	return async (dispatch) => {
		try {
			const actionData = {
				type: ACTIVATE_BOT,
				payload: {
					data: {
						...bot,
					},
				},
			};
			dispatch(actionData);
		} catch (e) {
			// handle error
		}
	};
};

export const deactivateTalkybotAction = () => {
	return {
		type: DEACTIVATE_BOT,
	};
};

export const publishTalkybotOnAction = (provider) => {
	return async (dispatch) => {
		try {
			window.location.assign(
				`http://localhost:3000/api/users/auth/${provider}`
			);

			const actionData = {
				type: PUBLISH_BOT,
			};
			dispatch(actionData);
		} catch (e) {
			// handle error
		}
	};
};

export const getAllFieldsAction = (
	talkybotId,
	setUiLoadingError = () => {}
) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			dispatch({
				type: GET_ALL_FIELDS_SUCCESS,
				payload: responseData.data,
			});
		};
		const onError = (e) => {
			dispatch({
				type: GET_ALL_FIELDS_FAIL,
			});
		};

		apiRequest.get({
			url: `${talkybotsPath}/${talkybotId}/all_fields`,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};
