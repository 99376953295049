/** ================ Path Flow actions Types ============= */
export const GET_ALL_PATHS_FLOWS = '[flows] get all flows';
export const GET_ALL_PATHS_FLOWS_ERROR = '[flows] get all flows error';
export const GET_FLOW_BY_LANGUAGE_AND_TYPE = 'GET_FLOW_BY_LANGUAGE_AND_TYPE';
export const CREATE_PATH_FLOW = 'CREATE_PATH_FLOW';
export const CREATE_PATH_FLOW_ERROR = 'CREATE_PATH_FLOW_ERROR';

export const DELETE_PATH_FLOW = 'DELETE_PATH_FLOW';

export const BULK_DELETE_FLOWS_SUCCESS = 'BULK_DELETE_FLOWS_SUCCESS';
export const BULK_DELETE_FLOWS_FAIL = 'BULK_DELETE_FLOWS_FAIL';

export const EDIT_PATH_FLOW = 'EDIT_PATH_FLOW';
export const TOGGLE_PATH_FLOW = 'TOGGLE_PATH_FLOW';
export const ADD_PATH_TO_FLOW = 'ADD_PATH_TO_FLOW';
