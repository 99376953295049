export const talkybotsPath = '/api/talkybots';
export const authPath = '/api/users';
export const authAdminPanel = '/api/auth';
export const appsPath = '/api/applications';
export const botBuilderPath = '/api/bot_builder_apps';
export const accountsPath = '/api/accounts';
export const instagramAccountsPath = '/api/instagram_accounts';
export const peoplePath = '/api/people';
export const tenantsPath = '/api/tenants';
export const tenantRolesPath = '/api/tenant_roles';
export const notificationsPath = '/api/notifications';
export const completeUserProfile = '/api/users/complete_profile';
export const personCustomFieldsUrl = '/api/person_custom_fields';
export const crmAppUrl = '/api/crm_apps';
export const userStatusPath = '/api/user_status';
export const conversationsByPersonStatus = '/api/conversations/person_status';

// dashboard constants
export const dashboard = '/api/statistics';


export const applicationMembers = (botId, appId, permissionName) =>
	`/api/talkybots/${botId}/users/${appId}?permission_type=${permissionName}`;

export const crmPeopleUrl = (crmAppId) => `/api/${crmAppId}/people`;

export const crmStatus = (crmAppId) => `/api/${crmAppId}/statuses`;

export const currentUsage = (tanentId) => `/api/tenants/${tanentId}/usage`;

export const currentSessions = (tenantId) => `/api/tenants/${tenantId}/session_usage`
export const currentEngagement = (tenantId) => `/api/tenants/${tenantId}/engagment_usage`
export const currentMobileAppUsage = (tenantId) => `/api/tenants/${tenantId}/mobile_app_usage`
export const currentBotUsage = (tenantId) => `/api/tenants/${tenantId}/bot_usage`
