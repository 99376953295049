import React from 'react';

const GenderBar = ({ assigned, male, female, unknown }) => {
  const total = male + female + unknown;
  const malePercentage = (male / total) * 100;
  const femalePercentage = (female / total) * 100;
  const unknownPercentage = (unknown / total) * 100;

  return (
    <div style={{ display: 'flex', flexDirection: 'column-reverse', width: '20px', height: '120px' }}>
      <div
        style={{
          backgroundColor: '#909090 ',
          height: `${unknownPercentage}%`,
          borderRadius: '4px',
					width: '10px',
        }}
      ></div>
      <div
        style={{
          backgroundColor: '#FFCCEC',
          height: `${femalePercentage}%`,
          borderRadius: '4px',
					width: '10px',
          marginBottom: '2px',
        }}
      ></div>
      <div
        style={{
          backgroundColor: '#B7DBFF',
          height: `${malePercentage}%`,
          borderRadius: '4px',
					width: '10px',
          marginBottom: '2px',
        }}
      ></div>

    </div>
  );
};

export default GenderBar;