// passing optional paramaeters:
// if the format = 'h:mm am/pm'  => return the time in 12 hour format
/**
 *
 * @param {date} d
 * @param {optional} format
 * @returns {string} date and time
 */
export default function formatDate(d, format = 'd,MMM,YYYY') {
	// server Date [gotten from the server] is in UTC format
	const serverDate = new Date(d);
	// user date now [gotten from the client] is in UTC format
	const dateNow = new Date();
	// Detect the user timezone offset and get the absolute value of it in minutes
	const userTimeZone = Math.abs(dateNow.getTimezoneOffset() / 60);
	// get the server time zone from the server date
	const serverTimeZone = Math.abs(serverDate.getTimezoneOffset() / 60);
	// get the difference between the user timezone and the server timezone
	const diff = serverTimeZone - userTimeZone;
	// General format for the Date (d,MMM,YYYY)
	let result = serverDate.toLocaleDateString('en-US', {
		year: 'numeric',
		month: 'short',
		day: 'numeric',
	});
	// Replace comma with space and split the string
	const tempDate = result.replace(/,/g, '').split(' ');
	// if the format = 'h:mm am/pm'  => return the time in 12 hour format
	if (format === 'h:mm am/pm') {
		// get am and pm from date
		const am = serverDate.getHours() < 12;
		const newHours = (serverDate.getHours() + diff) % 12 || 12;
		const minutes = serverDate.getMinutes();
		return `${tempDate[1]} 
		${tempDate[0]} ${tempDate[2]} ${newHours}:${
			minutes < 10 ? '0' : ''
		}${minutes} ${am ? 'AM' : 'PM'} `;
	} else {
		// => [Return the date in the General format]
		return `${tempDate[1]} 
		${tempDate[0]} ${tempDate[2]}`;
	}
}
export const dateTimeFormat = (date) => {
	const dateJs = new Date(date);
	return dateJs.toLocaleTimeString([], {
		hour: '2-digit',
		minute: '2-digit',
		hour12: true,
	});
};
