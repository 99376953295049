import {
	SEND_OTP_TO_ADMIN_SUCCESS,
	SEND_OTP_TO_ADMIN_FAILED,
	VALIDATE_OTP_SUCCESS,
	VALIDATE_OTP_FAILED,
	RESEND_OTP_SUCCESS,
	RESEND_OTP_FAILED,
	CREATE_ORGANIZATION_SUCCESS,
	CREATE_ORGANIZATION_FAILED,
	GET_USAGE_OF_SINGLE_TENANT_SUCCESS,
	GET_USAGE_OF_SINGLE_TENANT_FAILED,
	TOGGLE_ACTIVITY_SUCCESS,
	GET_CURRENT_ADMIN_USER_SUCCESS,
	GET_CURRENT_ADMIN_USER_FAILED,
	TOGGLE_ACTIVITY_FAILED,
	TOGGLE_BLOCKED_SUCCESS,
	TOGGLE_BLOCKED_FAILED,
	EDIT_CURRENT_SUBSCRIBTION_SUCCESS,
	EDIT_CURRENT_SUBSCRIBTION_FAILED,
	EDIT_NEXT_SUBSCRIBTION_SUCCESS,
	EDIT_NEXT_SUBSCRIBTION_FAILED,
	GET_ALL_ORGANIZATIONS_SUCCESS,
	GET_ALL_ORGANIZATIONS_FAILED,
	GET_NEXT_SUBSCRIBTION_USAGE_SUCCESS,
	GET_NEXT_SUBSCRIBTION_USAGE_FAILED,
	// log out 
	LOGOUT_ADMIN_SUCCESS,
	LOGOUT_ADMIN_FAILED,
} from '../types/adminPanel';
import { authAdminPanel } from 'constants/apis';
import apiRequest from 'helpers/apiRequest';
import setAuthToken from 'helpers/set-auth-token';
import toTimestamp from 'helpers/to-timestamp';
import { mapArrayToObject } from 'helpers/stateManagement';

/** save token on storage */

const saveDataToStorage = (token, expiresAt) => {
	localStorage.setItem(
		'adminToken',
		JSON.stringify({
			token,
			expiresAt,
		})
	);
};

/** auth admijn token */

export const authenticate = (token) => {
	return async (dispatch, getState) => {
		setAuthToken(token);

		if (token) {
			const jwtPayload = JSON.parse(window.atob(token.split(".")[1]))
			const exp = new Date(jwtPayload.exp * 1000)
			if (exp < Date.now()) {
				console.log("token expired")
			}else {
				saveDataToStorage(token, exp);
			}
		}

		const {adminPanel: { adminUser },} = getState();
		if (!adminUser) {
			await dispatch(getCurrentAdminUserAction());
		}
	};
};
/***************************************************************************************** */
/* ************************************  Start Log out Admin **********************/
export const logoutAdmin = (history) => {
	return async (dispatch) => {
		localStorage.removeItem('adminToken')
		dispatch({
			type: LOGOUT_ADMIN_SUCCESS,
		})
		history.push('/admin')
	}
}
/* ************************************  End Log out Admin **********************/
/* ************************************  Start Check is Admin Or Not **********************/
export const loginAdminAction = (
	adminData,
	setUiLoadingError = () => { },
	enqueueSnackbar
) => {
	return async (dispatch) => {
		const onSuccess = (responseData) => {
			const actionData = {
				type: SEND_OTP_TO_ADMIN_SUCCESS,
				payload: {
					isAdmin: responseData,
				},
			};
			dispatch(actionData);
		};

		const onError = (e) => {
			enqueueSnackbar('wrong credentials', {
				variant: 'error',
				autoHideDuration: 3000,
			});
			dispatch({ type: SEND_OTP_TO_ADMIN_FAILED });
		};

		apiRequest.post({
			url: `${authAdminPanel}/admin`,
			bodyData: adminData,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};
/* ************************************  End Check is Admin Or Not **********************/
/***************************************************************************************** */
/* ************************************  Start Resend OTP ********************************/
/**
 *
 * @param adminData
 * @return {string}
 */
export const resendOtpAction = (
	adminData,
	setUiLoadingError = () => { },
	enqueueSnackbar
) => {
	return async (dispatch) => {
		const onSuccess = (responseData) => {
			enqueueSnackbar('OTP Resend Successfully', {
				variant: 'success',
				autoHideDuration: 3000,
			});
			const actionData = {
				type: RESEND_OTP_SUCCESS,
				payload: {
					validOtp: responseData,
				},
			};
			dispatch(actionData);
		};

		const onError = (e) => {
			enqueueSnackbar('wrong credentials', {
				variant: 'error',
				autoHideDuration: 3000,
			});
			dispatch({ type: RESEND_OTP_FAILED });
		};

		apiRequest.post({
			url: `${authAdminPanel}/resend_otp`,
			bodyData: adminData,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};
/* ************************************  End Resend OTP ********************************/
/***************************************************************************************** */
/* ************************************  Start Verify OTP ********************************/
/**
 *
 * @param otp
 * @return {string}
 */

export const checkOTPAction = (
	otp,
	history,
	setUiLoadingError = () => { },
	enqueueSnackbar
) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			const { token } = responseData.data;
			await dispatch(authenticate(token));
			const actionData = {
				type: VALIDATE_OTP_SUCCESS,
				payload: {
					isAuthenticatedAdmin: true,
				},
			};
			dispatch(actionData);
			history.push('/admin/organizations');
		};
		const onError = (e) => {
			enqueueSnackbar('wrong otp', {
				variant: 'error',
				autoHideDuration: 3000,
			});
			dispatch({ type: VALIDATE_OTP_FAILED });
		};

		apiRequest.post({
			url: `${authAdminPanel}/validate_otp`,
			bodyData: otp,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};
/* ************************************  End Verify OTP ********************************/
/***************************************************************************************** */
/* ************************************  Start Create Organization  ********************************/
export const createOrganizationAction = ({
	organizationData,
	setUiLoadingError = () => { },
	enqueueSnackbar,
	history,
}) => {
	return async (dispatch) => {
		const onSuccess = (responseData) => {
			const actionData = {
				type: CREATE_ORGANIZATION_SUCCESS,
				payload: {
					organization: responseData.data,
				},
			};
			enqueueSnackbar('Organization created successfully', {
				variant: 'success',
				autoHideDuration: 3000,
			});

			dispatch(actionData);
			history.push('/admin/organizations');
		};

		const onError = (e) => {
			enqueueSnackbar('Organization not created', {
				variant: 'error',
				autoHideDuration: 3000,
			});

			dispatch({ type: CREATE_ORGANIZATION_FAILED });
		};

		apiRequest.post({
			url: `${authAdminPanel}/create_tenant`,
			bodyData: organizationData,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};
/* ************************************  End Create Organization  ********************************/
/***************************************************************************************** */
/* *************************  Start Change Activity of single Organization state  *****************/
/**
 * @param tenant id
 * @param source {if the action occured from the single tenant page or the organizations table}
 * @return {boolean}
 */

export const changeActivityOfTenantAction = (
	tenantId,
	source,
	setUiLoadingError,
	enqueueSnackbar
) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			const actionData = {
				type: TOGGLE_ACTIVITY_SUCCESS,
				payload: {
					tenantActivityState: responseData.data,
					source: source,
				},
			};
			enqueueSnackbar('Organization Status Changed successfully', {
				variant: 'success',
				autoHideDuration: 3000,
			});
			dispatch(actionData);
		};
		const onError = (e) => {
			enqueueSnackbar('Something went wrong', {
				variant: 'error',
				autoHideDuration: 3000,
			});
			dispatch({ type: TOGGLE_ACTIVITY_FAILED });
		};
		apiRequest.put({
			url: `${authAdminPanel}/${tenantId}/toggle_active`,
			onSuccess,
			onError,
			setUiLoadingError,
			enqueueSnackbar,
		});
	};
};
/* *************************  End Change Activity of single Organization state  *****************/
/***************************************************************************************** */
/* *************************  Start Change Blocked of single Organization state  *****************/
/**
 * @param tenant id
 * @return {boolean}
 */

export const changeBlockedOfTenantAction = (
	tenantId,
	setUiLoadingError,
	enqueueSnackbar
) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			const actionData = {
				type: TOGGLE_BLOCKED_SUCCESS,
				payload: {
					tenantBlockedState: responseData.data,
				},
			};
			enqueueSnackbar('Organization Status Changed successfully', {
				variant: 'success',
				autoHideDuration: 3000,
			});
			dispatch(actionData);
		};
		const onError = (e) => {
			enqueueSnackbar('Something went wrong', {
				variant: 'error',
				autoHideDuration: 3000,
			});
			dispatch({ type: TOGGLE_BLOCKED_FAILED });
		};
		apiRequest.put({
			url: `${authAdminPanel}/${tenantId}/toggle_blocking`,
			onSuccess,
			onError,
			setUiLoadingError,
			enqueueSnackbar,
		});
	};
};
/* *************************  End Change Blocked of single Organization state  *****************/
/***************************************************************************************** */
/* *************************  Start Get Current Usage of single Organization  *****************/
/**
 * @param tenant id
 * @return {string}
 */
export const getSingleTenantUsageAction = (tenantId, setUiLoadingError) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			const actionData = {
				type: GET_USAGE_OF_SINGLE_TENANT_SUCCESS,
				payload: {
					tenantCurrentUsage: responseData.data,
				},
			};
			dispatch(actionData);
		};
		const onError = (e) => {
			dispatch({ type: GET_USAGE_OF_SINGLE_TENANT_FAILED });
		};

		apiRequest.get({
			url: `${authAdminPanel}/${tenantId}/tenant_usage`,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};
/* *************************  End Get Current Usage of single Organization  *****************/
/***************************************************************************************** */
/* *************************  Start Get Next Usage of single Organization  *****************/
/**
 * @param tenant id
 * @return {string}
 */
export const getNextUsagedAction = (tenantId, setUiLoadingError) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			const actionData = {
				type: GET_NEXT_SUBSCRIBTION_USAGE_SUCCESS,
				payload: {
					tenantNextUsage: responseData.data,
				},
			};
			dispatch(actionData);
		};
		const onError = (e) => {
			dispatch({ type: GET_NEXT_SUBSCRIBTION_USAGE_FAILED });
		};

		apiRequest.get({
			url: `${authAdminPanel}/${tenantId}/next_subscription`,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};
/* *************************  End Get Next Usage of single Organization  *****************/
/***************************************************************************************** */
/* *************************  Start Get All Organizations  *****************/

export const getAllOrganizationsAction = ({ setUiLoadingError }) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			const actionData = {
				type: GET_ALL_ORGANIZATIONS_SUCCESS,
				payload: {
					organizations:
						// maparraytoobject(responseData.data)  // convert array to object
						mapArrayToObject(responseData.data.result, true, true),
					count: responseData.data.count,
				},
			};
			dispatch(actionData);
		};
		const onError = (e) => {
			dispatch({ type: GET_ALL_ORGANIZATIONS_FAILED });
		};

		apiRequest.get({
			url: `${authAdminPanel}/all_tenants`,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};
/* *************************  End Get All Organizations  *****************/
/***************************************************************************************** */
/* *************************  Start Get Current Admin   *****************/

export const getCurrentAdminUserAction = (setUiLoadingError = () => { }) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			const actionData = {
				type: GET_CURRENT_ADMIN_USER_SUCCESS,
				payload: {
					adminUser: responseData.data,
				},
			};
			dispatch(actionData);
		};
		const onError = (e) => {
			dispatch({ type: GET_CURRENT_ADMIN_USER_FAILED });
		};

		apiRequest.get({
			url: `${authAdminPanel}/validate_token`,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};
/* *************************  End Get Current Admin  *****************/
/***************************************************************************************** */

/* *************************  Start Edit Current Subscription   *****************/

/**
 * @param tenant_Id
 * @param Add_Current_Subscribtion_Data
 * @return {string}
 */
export const addCurrentSubscribtionAction = ({
	history,
	tenantId,
	EditCurrentSubscriptionData,
	setUiLoadingError = () => { },
	enqueueSnackbar,
}) => {
	return async (dispatch) => {
		const onSuccess = (responseData) => {
			enqueueSnackbar('Tenant Updated Successfully', {
				variant: 'success',
				autoHideDuration: 3000,
			});
			const actionData = {
				type: EDIT_CURRENT_SUBSCRIBTION_SUCCESS,
				payload: {
					newData: responseData.data,
				},
			};
			dispatch(actionData);
			history.push(`/admin/organizations/details/${tenantId}`);
		};

		const onError = (e) => {
			enqueueSnackbar('something went wrong', {
				variant: 'error',
				autoHideDuration: 3000,
			});
			dispatch({ type: EDIT_CURRENT_SUBSCRIBTION_FAILED });
		};

		apiRequest.put({
			url: `${authAdminPanel}/${tenantId}/edit_current_subscription`,
			bodyData: EditCurrentSubscriptionData,
			onSuccess,
			onError,
			setUiLoadingError
		})
	}
}
/* *************************  End Edit Current Subscription   *****************/
/***************************************************************************************** */

/* *************************  Start Edit Next Subscription   *****************/

/**
 * @param tenant_Id
 * @param Add_Next_Subscribtion_Data
 * @return {string}
 */
/** is Admin or not */
export const editNextSubscribtionAction = ({
	history,
	tenantId,
	EditNextSubscriptionData,
	setUiLoadingError = () => { },
	enqueueSnackbar,
}) => {
	return async (dispatch) => {
		const onSuccess = (responseData) => {
			enqueueSnackbar('Tenant Updated Successfully', {
				variant: 'success',
				autoHideDuration: 3000,
			});
			const actionData = {
				type: EDIT_NEXT_SUBSCRIBTION_SUCCESS,
				payload: {
					newData: responseData.data,
				},
			};
			dispatch(actionData);
			history.push(`/admin/organizations/details/${tenantId}`);
		};

		const onError = (e) => {
			enqueueSnackbar('something went wrong', {
				variant: 'error',
				autoHideDuration: 3000,
			});
			dispatch({ type: EDIT_NEXT_SUBSCRIBTION_FAILED });
		};

		apiRequest.put({
			url: `${authAdminPanel}/${tenantId}/edit_next_subscription`,
			bodyData: EditNextSubscriptionData,
			onSuccess,
			onError,
			setUiLoadingError
		})
	}
}
/* *************************  End Edit Next Subscription   *****************/
/***************************************************************************************** */