import React from 'react';
import styles from './index.module.scss';

function PopupContent({ onClose }) {
  return (
    <div className={styles.popup}>
      <div className={styles.popupContent}>
      <h3>This is a custom popup</h3>
      <p>Popup content goes here.</p>
      </div>
      <div className={styles.popupArrow} onClick={onClose}></div>
    </div>
  );
}

export default PopupContent;