export const GET_INTEGRATIONS_SUCCESS =
	'[integrations] get integrations success';
export const GET_INTEGRATIONS_FAIL = '[integrations] get integrations fail';
export const CREATE_INTEGRATION_SUCCESS =
	'[integrations] create integration success';
export const CREATE_INTEGRATION_FAIL = '[integrations] create integration fail';
export const UPDATE_INTEGRATION_SUCCESS =
	'[integrations] update integration success';
export const UPDATE_INTEGRATION_FAIL = '[integrations] update integration fail';
export const DELETE_INTEGRATION_SUCCESS =
	'[integrations] delete integration success';
export const DELETE_INTEGRATION_FAIL = '[integrations] delete integration fail';
export const GET_SINGLE_INTEGRATION_SUCCESS =
	'[integrations] get single integration success';
export const GET_SINGLE_INTEGRATION_FAIL =
	'[integrations] get single integration fail';
export const RESET_ACTIVE_INTEGRAION =
	'[integrations] reset active integration';
	export const TEST_INTEGRATION_RESPONSE_SUCCESS = '[integrations] test integration response success';
	export const TEST_INTEGRATION_RESPONSE_FAIL = '[integrations] test integration response fail';
	export const CREATE_RESPONSE_CASE_SUCCESS = '[integrations] create response case success';
	export const CREATE_RESPONSE_CASE_FAIL = '[integrations] create response case fail';
