import React from 'react';
import { CardContent, Typography, Grid } from '@material-ui/core';
import renderDate from 'helpers/renderDate';
import useStyles from './styles';

const ChangeStatusBody = ({ userName, personName, content, byBot }) => {
	const classes = useStyles();
	const hourFormate = 'h:mm am/pm';

	return (
		<Grid container className={classes.root}>
			<Grid item xs={9} className={classes.statusWrapper}>
				<CardContent className={classes.content}>
					<Typography variant="body" className={classes.activity}>
						<Typography
							display="inline"
							className={classes.action}
							title={userName || ''}
						>
							{`${userName}  `}
						</Typography>
						Changed Status
						{content.from && content.from.name && (
							<>
								{' '}
								from{' '}
								<Typography
									display="inline"
									// className={classes.action}
									title={content.from.name || ''}
								>
									{content.from.name}{' '}
								</Typography>
							</>
						)}
						{content.to && content.to.name && (
							<>
								{' '}
								to{' '}
								<Typography
									display="inline"
									// className={classes.action}
									title={content.to.name || ''}
								>
									{content.to.name}{' '}
								</Typography>
							</>
						)}
					</Typography>
				</CardContent>
			</Grid>
			<Grid item xs={3} className={classes.dateWrapper}>
				<CardContent className={classes.content}>
					<Typography variant="body2" className={classes.date}>
						{renderDate(content.date, hourFormate)}
					</Typography>
				</CardContent>
			</Grid>
		</Grid>
	);
};

export default ChangeStatusBody;
