import { connect } from 'react-redux';
import {
	getClosedConversationAction,
	getMessagesInAction,
	getMessagesOutAction,
} from 'store/actions/creators/dashboard';

// states below are required for all tabs
const mapStateToProps = (state) => {
	return {
		talkybotId: state?.activeTalkybot?.id,
	};
};

// actions below are required for all tabs
const mapDispatchToProps = (dispatch) => {
	return {
		getMessagesIn: ({
			talkyBotID,
			duration_filter,
			provider_filter,
			setUiLoadingError,
		}) =>
			dispatch(
				getMessagesInAction({
					talkyBotID,
					duration_filter,
					provider_filter,
					setUiLoadingError,
				})
			),
		getMessagesOut: ({
			talkyBotID,
			duration_filter,
			provider_filter,
			setUiLoadingError,
		}) =>
			dispatch(
				getMessagesOutAction({
					talkyBotID,
					duration_filter,
					provider_filter,
					setUiLoadingError,
				})
			),
		getClosedConversation: ({
			talkyBotID,
			duration_filter,
			provider_filter,
			setUiLoadingError,
		}) =>
			dispatch(
				getClosedConversationAction({
					talkyBotID,
					duration_filter,
					provider_filter,
					setUiLoadingError,
				})
			),
	};
};
export default connect(mapStateToProps, mapDispatchToProps);
