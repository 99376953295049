import { connect } from 'react-redux';
import {
	addNewEntityRegexAction,
	addNewRegexValueAction,
	deleteEntityRegexAction,
	deleteRegexValueAction,
	getAllEntityRegexAction,
	getAllRegexValuesAction,
	updateEntityRegexAction,
	updateRegexValueAction
} from 'store/actions/creators/entities';
import {
	clearActiveIntentAction,
} from 'store/actions/creators/faqs';

const mapStateToProps = (state) => {
	return {
		activeTalkybot: state?.activeTalkybot,
		activeEntity: state?.entities?.activeEntity,
		regexes: state?.entities?.regexes,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getAllEntityRegex: ({entityId, botBuilderAppId}) => dispatch(getAllEntityRegexAction({entityId, botBuilderAppId})),
		addNewEntityRegex: ({entityId, language, name, enqueueSnackbar, setUiLoadingError}) => dispatch(addNewEntityRegexAction({entityId, language, name, enqueueSnackbar, setUiLoadingError})),
		updateEntityRegex: ({entityId, regexId, language, name, enqueueSnackbar, setUiLoadingError}) => dispatch(updateEntityRegexAction({entityId, regexId, language, name, enqueueSnackbar, setUiLoadingError})),
		deleteEntityRegex: ({entityId, regexId, enqueueSnackbar,setUiLoadingError}) => dispatch(deleteEntityRegexAction({entityId, regexId, enqueueSnackbar,setUiLoadingError})),
		getAllRegexValues: ({regexId, setUiLoadingError}) => dispatch(getAllRegexValuesAction({regexId, setUiLoadingError})),
		addNewRegexValue: ({regexId, value, enqueueSnackbar, setUiLoadingError}) => dispatch(addNewRegexValueAction({regexId, value, enqueueSnackbar, setUiLoadingError})),
		deleteRegexValue: ({regexId, valueId, enqueueSnackbar, setUiLoadingError}) => dispatch(deleteRegexValueAction({regexId, valueId, enqueueSnackbar, setUiLoadingError})),
		EditRegexValue: ({regexId, valueId, value, enqueueSnackbar, setUiLoadingError}) => dispatch(updateRegexValueAction({regexId, valueId, value, enqueueSnackbar, setUiLoadingError})),
	};
};

export default connect(mapStateToProps, mapDispatchToProps);
