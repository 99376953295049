import { dashboard } from 'constants/apis';
import apiRequest from 'helpers/apiRequest';
import {
	// get message in
	GET_MESSAGES_IN_SUCCESS,
	GET_MESSAGES_IN_ERROR,
	// get message out
	GET_MESSAGES_OUT_SUCCESS,
	GET_MESSAGES_OUT_ERROR,
	// get gender
	GET_GENDER_SUCCESS,
	GET_GENDER_ERROR,
	// get closed conversation by who
	GET_CLOSED_CONVERSATION_SUCCESS,
	GET_CLOSED_CONVERSATION_ERROR,
	// get number of conversation
	GET_NUMBER_OF_CONVERSATION_SUCCESS,
	GET_NUMBER_OF_CONVERSATION_ERROR,
	// get most frequent questions
	GET_MOST_FREQUENT_QUESTIONS_SUCCESS,
	GET_MOST_FREQUENT_QUESTIONS_ERROR,
	// get Statement Recognition
	GET_STATEMENT_RECOGNITION_SUCCESS,
	GET_STATEMENT_RECOGNITION_ERROR,
	// get Completion scenarios
	GET_COMPLETION_SCENARIOS_SUCCESS,
	GET_COMPLETION_SCENARIOS_ERROR,
	// get Most Used Scenarios
	GET_MOST_USED_SCENARIOS_SUCCESS,
	GET_MOST_USED_SCENARIOS_ERROR,
	// get Conversation rating
	GET_CONVERSATION_RATING_SUCCESS,
	GET_CONVERSATION_RATING_ERROR,
	// get customer Status analytics
	GET_CUSTOMER_STATUS_SUCCESS,
	GET_CUSTOMER_STATUS_ERROR,
	// get assigned & not assigned customers
	GET_ASSIGNED_CUSTOMERS_SUCCESS,
	GET_ASSIGNED_CUSTOMERS_ERROR,
	// get number of new Customers
	GET_NUMBER_OF_NEW_CUSTOMERS_SUCCESS,
	GET_NUMBER_OF_NEW_CUSTOMERS_ERROR,
	// get number of conversation sessions
	GET_NUMBER_OF_CONVERSATION_SESSIONS_SUCCESS,
	GET_NUMBER_OF_CONVERSATION_SESSIONS_ERROR,
	// get team members messages
	GET_TEAM_MEMBERS_MESSAGES_SUCCESS,
	GET_TEAM_MEMBERS_MESSAGES_ERROR,
	// get customer analytics
	GET_CUSTOMER_ANALYTICS_SUCCESS,
	GET_CUSTOMER_ANALYTICS_ERROR,
	// get takeover and escalation
	GET_TAKEOVER_AND_ESCALATION_SUCCESS,
	GET_TAKEOVER_AND_ESCALATION_ERROR,
	// get team handover
	GET_TEAM_HANDOVER_SUCCESS,
	GET_TEAM_HANDOVER_ERROR,
	// get bot escalation
	GET_BOT_ESCALATION_SUCCESS,
	GET_BOT_ESCALATION_ERROR,
	// get bot interactions
	GET_BOT_INTERACTIONS_SUCCESS,
	GET_BOT_INTERACTIONS_ERROR,
	// get bot activites
	GET_BOT_ACTIVITES_SUCCESS,
	GET_BOT_ACTIVITES_ERROR,
	GET_SENTIMENT_SUCCESS,
	GET_SENTIMENT_FAILED,
} from 'store/actions/types/dashboard';
/******************** Start Overview Tab Requests  ***************/
// get message in
export const getMessagesInAction = ({
	talkyBotID,
	duration_filter,
	provider_filter,
	setUiLoadingError,
}) => {
	return (dispatch) => {
		const onSuccess = (responseData) => {
			dispatch({
				type: GET_MESSAGES_IN_SUCCESS,
				payload: responseData.data,
			});
		};
		const onError = (error) => {
			dispatch({
				type: GET_MESSAGES_IN_ERROR,
			});
		};
		apiRequest.get({
			url: `${dashboard}/${talkyBotID}/messages_in?date_filter=${duration_filter}&provider_filter=${provider_filter}`,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};
// get message out
export const getMessagesOutAction = ({
	talkyBotID,
	duration_filter,
	provider_filter,
	setUiLoadingError,
}) => {
	return (dispatch) => {
		const onSuccess = (responseData) => {
			dispatch({
				type: GET_MESSAGES_OUT_SUCCESS,
				payload: responseData.data,
			});
		};
		const onError = (error) => {
			dispatch({
				type: GET_MESSAGES_OUT_ERROR,
			});
		};
		apiRequest.get({
			url: `${dashboard}/${talkyBotID}/messages_out?date_filter=${duration_filter}&provider_filter=${provider_filter}`,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};
// get customer analytics
export const getCustomerAnalyticsAction = ({
	talkyBotID,
	duration_filter,
	provider_filter,
	setUiLoadingError,
}) => {
	return (dispatch) => {
		const onSuccess = (responseData) => {
			dispatch({
				type: GET_CUSTOMER_ANALYTICS_SUCCESS,
				payload: responseData.data,
			});
		};
		const onError = (error) => {
			dispatch({
				type: GET_CUSTOMER_ANALYTICS_ERROR,
			});
		};
		apiRequest.get({
			url: `${dashboard}/${talkyBotID}/customer_analytics?date_filter=${duration_filter}&provider_filter=${provider_filter}`,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};
// get customer Status analytics
export const getCustomerStatusAction = ({
	talkyBotID,
	duration_filter,
	provider_filter,
	setUiLoadingError,
}) => {
	return (dispatch) => {
		const onSuccess = (responseData) => {
			dispatch({
				type: GET_CUSTOMER_STATUS_SUCCESS,
				payload: responseData,
			});
		};
		const onError = (error) => {
			dispatch({
				type: GET_CUSTOMER_STATUS_ERROR,
			});
		};
		apiRequest.get({
			url: `${dashboard}/${talkyBotID}/customers_status?date_filter=${duration_filter}&provider_filter=${provider_filter}`,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};
// get number of new Customers
export const getNumberOfNewCustomersAction = ({
	talkyBotID,
	duration_filter,
	provider_filter,
	setUiLoadingError,
}) => {
	return (dispatch) => {
		const onSuccess = (responseData) => {
			dispatch({
				type: GET_NUMBER_OF_NEW_CUSTOMERS_SUCCESS,
				payload: responseData.data,
			});
		};
		const onError = (error) => {
			dispatch({
				type: GET_NUMBER_OF_NEW_CUSTOMERS_ERROR,
			});
		};
		apiRequest.get({
			url: `${dashboard}/${talkyBotID}/number_of_new_customers?date_filter=${duration_filter}&provider_filter=${provider_filter}`,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};
// get number of conversation sessions
export const getNumberOfConversationSessionsAction = ({
	talkyBotID,
	duration_filter,
	provider_filter,
	setUiLoadingError,
}) => {
	return (dispatch) => {
		const onSuccess = (responseData) => {
			dispatch({
				type: GET_NUMBER_OF_CONVERSATION_SESSIONS_SUCCESS,
				payload: responseData.data,
			});
		};
		const onError = (error) => {
			dispatch({
				type: GET_NUMBER_OF_CONVERSATION_SESSIONS_ERROR,
			});
		};
		apiRequest.get({
			url: `${dashboard}/${talkyBotID}/number_of_conversations_sessions?date_filter=${duration_filter}&provider_filter=${provider_filter}`,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};
// get gender
export const getGenderAction = ({
	talkyBotID,
	duration_filter,
	provider_filter,
	setUiLoadingError,
}) => {
	return (dispatch) => {
		const onSuccess = (responseData) => {
			dispatch({
				type: GET_GENDER_SUCCESS,
				payload: responseData.data,
			});
		};
		const onError = (error) => {
			dispatch({
				type: GET_GENDER_ERROR,
			});
		};
		apiRequest.get({
			url: `${dashboard}/${talkyBotID}/customers_gender?date_filter=${duration_filter}&provider_filter=${provider_filter}`,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};
// get closed conversation by who
export const getClosedConversationAction = ({
	talkyBotID,
	duration_filter,
	provider_filter,
	setUiLoadingError,
}) => {
	return (dispatch) => {
		const onSuccess = (responseData) => {
			dispatch({
				type: GET_CLOSED_CONVERSATION_SUCCESS,
				payload: responseData.data,
			});
		};
		const onError = (error) => {
			dispatch({
				type: GET_CLOSED_CONVERSATION_ERROR,
			});
		};
		apiRequest.get({
			url: `${dashboard}/${talkyBotID}/number_of_conversations_closing_actions?date_filter=${duration_filter}&provider_filter=${provider_filter}`,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};
// get Conversation rating
export const getConversationRatingAction = ({
	talkyBotID,
	duration_filter,
	provider_filter,
	setUiLoadingError,
}) => {
	return (dispatch) => {
		const onSuccess = (responseData) => {
			dispatch({
				type: GET_CONVERSATION_RATING_SUCCESS,
				payload: responseData.data,
			});
		};
		const onError = (error) => {
			dispatch({
				type: GET_CONVERSATION_RATING_ERROR,
			});
		};
		apiRequest.get({
			url: `${dashboard}/${talkyBotID}/conversations_feedback?date_filter=${duration_filter}&provider_filter=${provider_filter}`,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};
// get number of conversation
export const getNumberOfConversationAction = ({
	talkyBotID,
	duration_filter,
	provider_filter,
	setUiLoadingError,
}) => {
	return (dispatch) => {
		const onSuccess = (responseData) => {
			dispatch({
				type: GET_NUMBER_OF_CONVERSATION_SUCCESS,
				payload: responseData.data,
			});
		};
		const onError = (error) => {
			dispatch({
				type: GET_NUMBER_OF_CONVERSATION_ERROR,
			});
		};
		apiRequest.get({
			url: `${dashboard}/${talkyBotID}/number_of_conversations?date_filter=${duration_filter}&provider_filter=${provider_filter}`,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};
// get numbers of assigned customers
export const getAssignedCustomersAction = ({
	talkyBotID,
	duration_filter,
	provider_filter,
	setUiLoadingError,
}) => {
	return (dispatch) => {
		const onSuccess = (responseData) => {
			dispatch({
				type: GET_ASSIGNED_CUSTOMERS_SUCCESS,
				payload: responseData.data,
			});
		};
		const onError = (error) => {
			dispatch({
				type: GET_ASSIGNED_CUSTOMERS_ERROR,
			});
		};
		apiRequest.get({
			url: `${dashboard}/${talkyBotID}/assigned_clients_summary?date_filter=${duration_filter}&provider_filter=${provider_filter}`,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};

//get sentiment percentage

export const getSentimentAction = ({
	talkyBotID,
	duration_filter,
	provider_filter,
	setUiLoadingError,
}) => {
	return (dispatch) => {
		const onSuccess = (responseData) => {
			dispatch({
				type: GET_SENTIMENT_SUCCESS,
				payload: responseData.data,
			});
		};
		const onError = (error) => {
			dispatch({
				type: GET_SENTIMENT_FAILED,
			});
		};
		apiRequest.get({
			url: `${dashboard}/${talkyBotID}/session_sentiments?date_filter=${duration_filter}&provider_filter=${provider_filter}`,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};

/******************** End Overview Tab Requests  ***************/
/******************** Start Chatbot Tab Requests  ***************/
// get most frequent questions
export const getBotInteractionsAction = ({
	talkyBotID,
	duration_filter,
	provider_filter,
	setUiLoadingError,
}) => {
	return (dispatch) => {
		const onSuccess = (responseData) => {
			dispatch({
				type: GET_BOT_INTERACTIONS_SUCCESS,
				payload: responseData.data,
			});
		};
		const onError = (error) => {
			dispatch({
				type: GET_BOT_INTERACTIONS_ERROR,
			});
		};
		apiRequest.get({
			url: `${dashboard}/${talkyBotID}/bot_interactions?date_filter=${duration_filter}&provider_filter=${provider_filter}`,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};
// get most frequent questions
export const getMostFrequentQuestionsAction = ({
	talkyBotID,
	duration_filter,
	provider_filter,
	setUiLoadingError,
}) => {
	return (dispatch) => {
		const onSuccess = (responseData) => {
			dispatch({
				type: GET_MOST_FREQUENT_QUESTIONS_SUCCESS,
				payload: responseData.data,
			});
		};
		const onError = (error) => {
			dispatch({
				type: GET_MOST_FREQUENT_QUESTIONS_ERROR,
			});
		};
		apiRequest.get({
			url: `${dashboard}/${talkyBotID}/most_frequent_questions?date_filter=${duration_filter}&provider_filter=${provider_filter}`,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};
// get Statement Recognition
export const getStatementRecognationAction = ({
	talkyBotID,
	duration_filter,
	provider_filter,
	setUiLoadingError,
}) => {
	return (dispatch) => {
		const onSuccess = (responseData) => {
			dispatch({
				type: GET_STATEMENT_RECOGNITION_SUCCESS,
				payload: responseData.data,
			});
		};
		const onError = (error) => {
			dispatch({
				type: GET_STATEMENT_RECOGNITION_ERROR,
			});
		};
		apiRequest.get({
			url: `${dashboard}/${talkyBotID}/statement_recognation?date_filter=${duration_filter}&provider_filter=${provider_filter}`,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};
// get Completion scenarios
export const getCompletionScenariosAction = ({
	talkyBotID,
	duration_filter,
	provider_filter,
	setUiLoadingError,
}) => {
	return (dispatch) => {
		const onSuccess = (responseData) => {
			dispatch({
				type: GET_COMPLETION_SCENARIOS_SUCCESS,
				payload: responseData.data,
			});
		};
		const onError = (error) => {
			dispatch({
				type: GET_COMPLETION_SCENARIOS_ERROR,
			});
		};
		apiRequest.get({
			url: `${dashboard}/${talkyBotID}/scenarios_completion?date_filter=${duration_filter}&provider_filter=${provider_filter}`,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};
// get Most Used Scenarios
export const getMostUsedScenariosAction = ({
	talkyBotID,
	duration_filter,
	provider_filter,
	setUiLoadingError,
}) => {
	return (dispatch) => {
		const onSuccess = (responseData) => {
			dispatch({
				type: GET_MOST_USED_SCENARIOS_SUCCESS,
				payload: responseData.data,
			});
		};
		const onError = (error) => {
			dispatch({
				type: GET_MOST_USED_SCENARIOS_ERROR,
			});
		};
		apiRequest.get({
			url: `${dashboard}/${talkyBotID}/most_used_scenarios?date_filter=${duration_filter}&provider_filter=${provider_filter}`,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};
// get bot escalation
export const getBotEscalationAction = ({
	talkyBotID,
	duration_filter,
	provider_filter,
	setUiLoadingError,
}) => {
	return (dispatch) => {
		const onSuccess = (responseData) => {
			dispatch({
				type: GET_BOT_ESCALATION_SUCCESS,
				payload: responseData.data,
			});
		};
		const onError = (error) => {
			dispatch({
				type: GET_BOT_ESCALATION_ERROR,
			});
		};
		apiRequest.get({
			url: `${dashboard}/${talkyBotID}/bot_escalation?date_filter=${duration_filter}&provider_filter=${provider_filter}`,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};
/******************** End Chatbot Tab Requests  ***************/
/******************** Start Team mamber Tab Requests  ***************/
// get team members messages
export const getTeamMembersMessagesAction = ({
	talkyBotID,
	page,
	duration_filter,
	provider_filter,
	setUiLoadingError,
}) => {
	return (dispatch) => {
		const onSuccess = (responseData) => {
			dispatch({
				type: GET_TEAM_MEMBERS_MESSAGES_SUCCESS,
				payload: responseData,
			});
		};
		const onError = (error) => {
			dispatch({
				type: GET_TEAM_MEMBERS_MESSAGES_ERROR,
			});
		};
		apiRequest.get({
			url: `${dashboard}/${talkyBotID}/team_members_messages?date_filter=${duration_filter}&provider_filter=${provider_filter}&page=${page}`,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};
// get takeover and escalation
export const getTakeoverAndEscalationAction = ({
	talkyBotID,
	duration_filter,
	provider_filter,
	setUiLoadingError,
}) => {
	return (dispatch) => {
		const onSuccess = (responseData) => {
			dispatch({
				type: GET_TAKEOVER_AND_ESCALATION_SUCCESS,
				payload: responseData.data,
			});
		};
		const onError = (error) => {
			dispatch({
				type: GET_TAKEOVER_AND_ESCALATION_ERROR,
			});
		};
		apiRequest.get({
			url: `${dashboard}/${talkyBotID}/takeover_and_escalation?date_filter=${duration_filter}&provider_filter=${provider_filter}`,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};
// get team handover
export const getTeamHandoverAction = ({
	talkyBotID,
	duration_filter,
	provider_filter,
	setUiLoadingError,
}) => {
	return (dispatch) => {
		const onSuccess = (responseData) => {
			dispatch({
				type: GET_TEAM_HANDOVER_SUCCESS,
				payload: responseData.data,
			});
		};
		const onError = (error) => {
			dispatch({
				type: GET_TEAM_HANDOVER_ERROR,
			});
		};
		apiRequest.get({
			url: `${dashboard}/${talkyBotID}/internal_team_handover?date_filter=${duration_filter}&provider_filter=${provider_filter}`,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};
// get bot activites
export const getBotActivitesAction = ({
	talkyBotID,
	page,
	duration_filter,
	provider_filter,
	setUiLoadingError,
}) => {
	return (dispatch) => {
		const onSuccess = (responseData) => {
			dispatch({
				type: GET_BOT_ACTIVITES_SUCCESS,
				payload: responseData,
			});
		};
		const onError = (error) => {
			dispatch({
				type: GET_BOT_ACTIVITES_ERROR,
			});
		};
		apiRequest.get({
			url: `${dashboard}/${talkyBotID}/team_member_activities?page=${page}&per_page=10&date_filter=${duration_filter}&provider_filter=${provider_filter}`,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};
/******************** End Team mamber Tab Requests  ***************/
