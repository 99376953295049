import Typography from '@material-ui/core/Typography';
import { Container, Grid, Paper, Collapse, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
	FAQSectionFrame: {
		marginTop: '6rem',
		[theme.breakpoints.down('sm')]: {
			padding: '5.625rem 0px 6.25rem 0px',
		},

		padding: '5.625rem 5rem 12.5rem 5rem',
		textAlign: 'center',
		backgroundColor: theme.palette.background.primaryOpacity,
	},
	FAQSHeader: {
		...theme.typography.title1,
		color: theme.palette.text.primary,
	},
	FAQSTitleFrame: {
		background: ' #FFFFFF 0% 0% no-repeat padding-box',
		boxShadow: ' 0px 1px 6px #0000004D',
		padding: '1.25rem',
		margin: '1.5625rem 0px',
	},
	FAQSTitle: {},
	FAQSDesc: {
		...theme.typography.body4,
		color: theme.palette.text.dark,
		width: '70%',
	},
	containerStyle: {
		[theme.breakpoints.up('md')]: {
			paddingRight: theme.spacing(15),
			paddingLeft: theme.spacing(15),
		},
		paddingRight: theme.spacing(3),
	},
}));

const FAQS = [
	{
		header: 'What is a Monthly Active User?',
		desc: ' We classify each MAU as a single end-user who has had one or more interactions with the bot or agent on any of the communication channels during a single billing cycle.',
	},
	{
		header: 'How does the free trial work?',
		desc: ' When you sign up and create a Talkybot account, you will Start 14 Days free trail for access Talkybot features.',
	},
	{
		header: 'Can request a demo?',
		desc: ' Schedule a demo now. Contact Us Now and we’ll get back to you with more details.',
	},
	{
		header: 'How to handle multiple clients for Agency?',
		desc: ' Talkybot is designed for Agencies Hierarchy, so you can manage all of your clients in one account and control the access of each user based on their role in Talkybot user management system.',
	},
	{
		header: 'What is Talkybot user management system?',
		desc: ' Talkybot system provides 4 Kinds of user management. 1st user is “Products Owner” which has the only access to billing and able to invite other users .2nd “Super Admin” which has access to all account bots and can invite admin and normal users. 3rd “Admin” get accessed to specific bot or all bots by superior users. The last “Normal user” is limited and gets permission by superior users on specific apps inside a specific bot.',
	},
	{
		header: 'How can I cancel my subscription? And what are the policies?',
		desc: ' You can cancel your plan at any time from your bot settings, you’ll enjoy your current plan until the end of the billing cycle then you’ll no longer be billed.',
	},
	{
		header: 'What if I need help building my bot?',
		desc: ' Sure, Talkybot team will take over the conversation designing process and bot implementation.',
	},
	{
		header: 'What types of payment do you accept?',
		desc: ' We accept all types of credit and debit cards.',
	},
	{
		header: 'Do you have custom plans for agencies and enterprises?',
		desc: ' Yes we do, just contact us and we will take care of the rest.',
	},
	{
		header: 'What are Fb, IG and web?',
		desc: ' They are referring to the channels that bot can handle in each plan such as Facebook, Instagram and web widget chatbot. ',
	},
];

const PricingFAQS = () => {
	const classes = useStyles();
	const [open, setOpen] = useState(new Array(FAQS.length).fill(false));
	const handleClick = (index) => {
		setOpen(
			open.map((item, i) => {
				if (i == index) {
					item = !item;
				}
				return item;
			})
		);
	};
	const handleViewMore = () => {};
	return (
		<Paper className={classes.FAQSectionFrame} elevation={0}>
			<Container className={classes.containerStyle} maxWidth={false}>
				<Typography className={classes.FAQSHeader}>FAQS</Typography>
				{FAQS.map((question, i) => (
					<Paper
						align="left"
						onClick={() => handleClick(i)}
						className={classes.FAQSTitleFrame}
					>
						<Grid
							container
							justifyContent="space-between"
							alignItems="center"
							className={classes.calcBoxCaptionNumFrame}
						>
							<Typography
								align="left"
								className={classes.FAQSTitle}
							>
								{question.header}
							</Typography>
							<Typography
								align="right"
								className={classes.calcBoxCaptionNumLabel}
							>
								{open[i] ? <ExpandLess /> : <ExpandMore />}
								{/* Max */}
							</Typography>
						</Grid>
						<Collapse in={open[i]} timeout="auto" unmountOnExit>
							<Typography className={classes.FAQSDesc}>
								{question.desc}
							</Typography>
						</Collapse>
					</Paper>
				))}
				<Button onClick={handleViewMore}>View More</Button>
			</Container>
		</Paper>
	);
};

export default PricingFAQS;
