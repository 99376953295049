export const LIST_ALL_ACCOUNTS = 'LIST_ALL_ACCOUNTS';
export const LIST_ALL_PAGES_FOR_ACCOUNT = 'LIST_ALL_PAGES_FOR_ACCOUNT';
export const CONNECT_TO_PAGE = 'CONNECT_TO_PAGE';
export const DISCONNECT_TO_PAGE = 'DISCONNECT_TO_PAGE';
export const CLEAN_PAGES = 'CLEAN_PAGES';
export const REMOVE_PAGE = 'REMOVE_PAGE';
export const DISCONNECT_FROM_PAGE = 'DISCONNECT_FROM_PAGE';
export const DISCONNECT_PAGE_CHANNEL = 'DISCONNECT_PAGE_CHANNEL';
export const DISCONNECT_INSTAGRAM_PAGE_CHANNEL = 'DISCONNECT_INSTAGRAM_PAGE_CHANNEL';
export const DISCONNECT_WHATSAPP_CHANNEL = 'DISCONNECT_WHATSAPP_CHANNEL';
export const REMOVE_FACEBOOK_CHANNEL = 'REMOVE_FACEBOOK_CHANNEL';
export const REMOVE_INSTAGRAM_CHANNEL = 'REMOVE_INSTAGRAM_CHANNEL';
export const DISCONNECT_AND_CONNECT_PAGE_CHANNEL =
	'DISCONNECT_AND_CONNECT_PAGE_CHANNEL';
	export const DISCONNECT_AND_CONNECT_INSTAGRAM_PAGE_CHANNEL =
	'DISCONNECT_AND_CONNECT_INSTAGRAM_PAGE_CHANNEL';
