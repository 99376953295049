import { yupResolver } from '@hookform/resolvers/yup';
import { Accordion, AccordionDetails, AccordionSummary, Button, TextField, Typography, makeStyles, } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete-list-icon.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit-list-icon.svg';
import plusIcon from 'assets/icons/plusIcon.svg';
import ModalContainer from 'components/common/ModalContainer/Modal';
import useSetUiLoadingError from 'hooks/useSetUiLoadingError';
import { useSnackbar } from 'notistack';
import React, { useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import DeleteList from './CRUD/DeleteList';
import EditList from './CRUD/EditList';
import ValueRow from './ValueRow';
import languageName from 'helpers/LnaguageName';
import FormSelect from 'controllers/FormSelect';
import mapSynonyms from 'helpers/mapSynonyms';

const ListCard = ({
  list,
  expanded,
  handleChange,
  index,
  activeTalkybot,
  updateEntityList,
  entityId,
  deleteEntityList,
  addNewListValue,
  deleteListValue,
  EditListValue,
  handleRefreshPriority,
  synonyms,
}) => {

  const classes = useStyles();
  const [openEditListModal, setOpenEditListModal] = React.useState(false);
  const [openDeleteListModal, setOpenDeleteListModal] = useState(false)
  const [listToDelete, setListToDelete] = useState(null)
  const [listToEdit, setListToEdit] = useState(null)
  const [listValue, setListValue] = useState('')

  const inputRef = useRef(null);

  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, successData, backendError, setUiLoadingError] =
    useSetUiLoadingError();

  const renderEditList = () => (
    <ModalContainer
      title="Edit List"
      onClose={() => setOpenEditListModal(false)}
      open={openEditListModal}
      component={
        <EditList
          activeTalkybot={activeTalkybot}
          handleCloseModal={() => setOpenEditListModal(false)}
          updateEntityList={updateEntityList}
          entityId={entityId}
          listToEdit={listToEdit}
          handleRefreshPriority={handleRefreshPriority}
        />
      }
    />
  );


  const renderConfirmDeleteList = () => (
    <ModalContainer
      title="Edit List"
      onClose={() => setOpenDeleteListModal(false)}
      open={openDeleteListModal}
      component={
        <DeleteList
          activeTalkybot={activeTalkybot}
          handleCloseModal={() => setOpenDeleteListModal(false)}
          deleteEntityList={deleteEntityList}
          entityId={entityId}
          listToDelete={listToDelete}
          setListToDelete={setListToDelete}
          handleRefreshPriority={handleRefreshPriority}
        />
      }
    />
  );
  const validationSchema = yup.object().shape({
    value: yup
      .string().required('You should write a value').test({
        name: 'notOnlySpaces',
        message: 'Value cannot be only spaces',
        test: (note) => {
          // Check if the value is not only spaces
          return !(/^\s+$/.test(note));
        },
      }).min(3).max(30)
    ,
    synonym: yup
			.string().required('You should select a synonym')
			,
  });

  const {
    handleSubmit,
    formState: { errors },
    setError,
    control,
    watch,
    reset,
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    defaultValues: {
      synonym: 0,
    },
  });



  const handleSubmitCreateValue = (payload) => {
    if (
      Object.keys(errors).length === 0) {
      addNewListValue({
        listId: list.id,
        value: payload?.value?.replace(/\s+/g, ' ').trim(),
        synonymId: parseInt(payload?.synonym),
        enqueueSnackbar,
        setUiLoadingError,
      })
      setValue('value', '')
      setValue('synonym', 0)
    }
  };

  const handleInputBlur = () => {
    // Unfocus the input when blurred
    inputRef.current.blur();
  };



  return (
    <>
      <Accordion
        key={index}
        expanded={expanded.includes(list.attributes.lang)}
        onChange={handleChange(list.attributes.lang)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-${index}-content`}
          id={`panel-${index}-header`}
          className={classes.collapseHeader}
        >
          <div className={classes.listNameContainer}>
            <Typography className={classes.listName}>{languageName(list.attributes.lang)}</Typography>
            <Typography className={classes.listThreshold}>Threshold: {list.attributes.threshold}%</Typography>

          </div>
          <div className={classes.actions} onClick={(e) => {

          }}>
            <div className={classes.divider}></div>
            <EditIcon onClick={(e) => {
              e.stopPropagation()
              setOpenEditListModal(true)
              setListToEdit(list)
            }} />
            <div className={classes.divider}></div>
            <DeleteIcon onClick={(e) => {
              e.stopPropagation()
              setOpenDeleteListModal(true)
              setListToDelete(list)
            }}
            />
            <div className={classes.divider}></div>
          </div>
          <Typography className={classes.valuesCount}>Values: {list?.attributes?.values?.data?.length}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.detailsContainer}>
          <div className={classes.inputLabelsContainer}>
            <span className={classes.valueText}>Value</span>
            <span>Relate To Synonym</span>
          </div>
          <div className={classes.inputsWrapper}>
            <form onSubmit={handleSubmit(handleSubmitCreateValue)} className={classes.InputsContainer}>


              <Controller
                name='value'
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    ref={inputRef}
                    variant="outlined"
                    size='small'
                    placeholder='Write Value'
                    className={classes.valueInput}
                    error={errors?.value?.message}
                    onBlur={handleInputBlur}
                    helperText={errors?.value?.message}
                  />
                )}
              />
              <div
                className={classes.synonymInput}
              >

              <Controller
							name="synonym"
							control={control}
							render={({ field }) => (
								<FormSelect
									{...field}
									error={errors?.synonym?.message}
									select
									smallHeight
									placeHolder="Select A Synonym"
									// array of categories
									options={mapSynonyms(synonyms)}
									style={{
										padding: '7px',
										// border: '1px solid #6418C3',
									}}
									/>
									)}
								/>
                    </div>
              <Button
                className={classes.addButton}
                type="submit"
              // onClick={handleAddNewValue}
              >
                <img
                  src={plusIcon}
                  alt="add topic flow"
                  className={classes.plusIcon}
                />
                Add
              </Button>
            </form>
            {list?.attributes?.values?.data?.map((value, i) => {
              return (
                <ValueRow
                  key={value.id}
                  value={value}
                  deleteListValue={deleteListValue}
                  listId={list?.id}
                  EditListValue={EditListValue}
                  i={i}
                  synonyms={synonyms}
                />
              )
            })}
          </div>
        </AccordionDetails>
      </Accordion>
      {renderEditList()}
      {renderConfirmDeleteList()}
    </>
  )
}

export default ListCard

const useStyles = makeStyles((theme) => ({

  actions: {
    display: 'flex',
    alignItems: 'center',
    flexBasis: '14%',
    '@media (max-width: 1363px)': {
      flexBasis: '20%',
    },
    flexShrink: '0',
    justifyContent: 'center',
    gap: '20px',
  },
  listsContainer: {
    width: '84.6%',
    '@media (max-width: 1363px)': {
      width: '81%',
      height: '35vh',
    },
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: '0px 5px',
    height: '500px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    '& .MuiAccordionSummary-root.Mui-expanded ': {
      borderTopRightRadius: '12px',
      borderTopLeftRadius: '12px',
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      height: '53px',
      minHeight: '0',
      border: 'none',
      boxShadow: 'none',
      borderTopRightRadius: '12px',
      borderTopLeftRadius: '12px',
    },
    '& .MuiAccordion-root.Mui-expanded': {
      border: '1px solid #4E4E4E',
      boxShadow: 'none',
    },
    '& .MuiAccordion-rounded': {
      borderRadius: '12px',
    },
    '& .MuiPaper-elevation1': {
      boxShadow: 'none',
    },
    '& ::-webkit-scrollbar': {
      width: '0'
    },
    '& .MuiAccordion-root:before': {
      height: '0px',
    }
  },
  collapseHeader: {
    display: 'flex',
    padding: '0 10px',
    backgroundColor: '#F0F0F0',
    height: '53px',
    minHeight: '53px',
    border: 'none',
    borderTopRightRadius: '12px',
    borderTopLeftRadius: '12px',

  },
  listNameContainer: {
    display: 'flex',
    alignItems: 'center',
    flexBasis: '22%',
    flexShrink: '0',
    justifyContent: 'space-between',
    '@media (max-width: 1363px) and (max-height: 700px)': {
      flexBasis: '25%',
    },
  },
  listName: {
    fontSize: '16px',
    '@media (max-width: 1363px)': {
      fontSize: '14px',
    },
    fontWeight: '500',
  },
  listThreshold: {
    fontSize: '16px',
    color: '#909090',
    '@media (max-width: 1363px)': {
      fontSize: '14px',
    },
  },
  valuesCount: {
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: '500',
    '@media (max-width: 1363px)': {
      fontSize: '14px',
    },

  },
  divider: {
    width: '0px',
    height: '53px',
    backgroundColor: '#E3E3E3',
    border: '1px solid #E3E3E3',
    opacity: '1',
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginBottom: '30px'
  },
  inputLabelsContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '10px',
    padding: '0 10px'
  },
  valueText: {
    flexBasis: '52.5%',
    flexShrink: '0',
    '@media (max-width: 1363px)': {
      flexBasis: '52.3%',
    },
  },
  InputsContainer: {
    padding: '10px',
    display: 'flex',
    flexDirection: 'row',
    gap: '15px',
    height: '40px',
    alignItems: 'center',
    marginTop: '10px',
  },
  addButton: {
    backgroundColor: 'rgba(100, 24, 195, 0.08)',
    backgroundColorOpacity: '0.08',
    borderRadius: '7px',
    color: '#6418C3',
    height: '38px',
    width: '100%',
    fontSize: '16px',
    '@media (max-width: 1363px)': {
      fontSize: '14px',
    },

  },
  inputsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px'
  },
  valueActionsContainer: {
    display: 'flex',
    flexDirection: 'rown',
    gap: '10px',
  },
  editValue: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '45px',
    height: '40px',
    backgroundColor: 'rgba(144, 144, 144, 0.08)',
    borderRadius: '7px'
  },
  deleteValue: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '45px',
    height: '40px',
    backgroundColor: 'rgba(209, 26, 68, 0.08)',
    borderRadius: '7px'
  },
  valueInput: {
    flexBasis: '50.65%',
    flexShrink: '0',
    borderRadius: '5px',
    '@media (max-width: 1363px)': {
      flexBasis: '47%',
    },
  },
  synonymInput: {
    flexBasis: '33%',
    // border: '1px solid red',
    overflow: 'hidden',
    flexShrink: '0',
    '@media (max-width: 1363px)': {
      flexBasis: '31%',
    },
    display: 'flex',
    // alignItems: 'center',
    height: '43px',
    justifyContent: 'center',
    '& .MuiTextField-root': {
      height: '43px',
      borderRadius: '5px',
      border: '1px solid #C8C8C8',
      backgroundColor: 'white',
      color: 'red',
      fontSize: '18px',
      '@media (max-width: 1363px)': {
        height: '38px',
        marginTop: '3px'
      },
    },
    '& .MuiSelect-select': {
      color: 'black',
      fontWeight: '300',
    },
    '& .MuiSvgIcon-root' : {
      paddingBottom: '3px'
    }
  },
  plusIcon: {
    width: '14px',
    marginRight: '0.6rem',
    '@media (max-width: 1363px)': {
      width: '14px',
    },
  },
}));