// bot gats reducer

/* eslint-disable prefer-template */

import { FILTER_CRM_PEOPLE } from 'store/actions/types/crm-filters';
import filterPeopleHelper from 'helpers/filter-people-helper';
import {
	CREATE_BOT_BUTTON,
	DELETE_BOT_BUTTON,
	GET_ALL_BUTTONS,
	UPDATE_BOT_BUTTON,
} from 'store/actions/types/botGates';

const INIT_STATE = {
	facebookButtons: {},
};

export default (state = INIT_STATE, { type, payload }) => {
	switch (type) {
		case GET_ALL_BUTTONS: {
			return {
				...state,
				facebookButtons: { ...payload.facebookButtons },
			};
		}
		case CREATE_BOT_BUTTON: {
			return {
				...state,
				facebookButtons: {
					...state.facebookButtons,
					[payload.id]: payload,
				},
			};
		}
		case UPDATE_BOT_BUTTON: {
			// update facebookButtons by id
			return {
				...state,
				facebookButtons: {
					...state.facebookButtons,
					[payload.id]: payload,
				},
			};
		}
		case DELETE_BOT_BUTTON: {
			//
			const { [payload.id]: deleted, ...rest } = state.facebookButtons;
			return {
				...state,
				facebookButtons: { ...rest },
			};
		}
		default:
			return state;
	}
};
