const mapCategories = (arr) => {
	return [
		{
			label: 'Select A category',
			value: 0,
			style: { background: 'white' },
		},
		...arr.map((item) => {
			return {
				label: item?.name,
				value: item?.id,
			};
		}),
	];
};
export default mapCategories;
