// mui import
import { Box } from '@material-ui/core';
import { Tab, Tabs } from '@mui/material';
// local Components
import Filter from '../Shared/Filter';
import ChatBotTab from './ChatbotTab';
import OverViewTab from './OverviewTab';
import TeamMembersTab from './TeamMembersTab';
// local styles
import styles from './index.module.scss';

const Component = ({
	setCurrentView,
	currentView,
	statusDurationValue,
	setStatusDurationValue,
	statusChannelValue,
	setStatusChannelValue,
	channelData,
	DurationData,
	tabValue,
	setTabValue,
}) => {
	/** ********************** Start Tabs Setting ************* */

	const handleTabsChange = (event, newValue) => {
		setTabValue(newValue);
		localStorage.setItem('dashboardTabIndex', parseInt(newValue))
	};

	function TabPanel(props) {
		const { children, value, index, ...other } = props;
		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`simple-tabpanel-${index}`}
				aria-labelledby={`simple-tab-${index}`}
				{...other}
			>
				{value === index && (
					<Box sx={{ p: 3 }}>
						<div>{children}</div>
					</Box>
				)}
			</div>
		);
	}

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	}

	/** ********************** End Tabs Setting ************* */

	const tabs = ['Overview', 'Chatbot', 'Team Members'];

	return (
		<div className={styles.Container}>
			<div className={styles.header}>
				<div className={styles.tabsContainer}>
					<Tabs
						value={tabValue}
						onChange={handleTabsChange}
						aria-label="basic tabs example"
						classes={{ indicator: styles.tabIndicator }}
						textColor="inherit"
					>
						{tabs.map((tab, index) => (
							<Tab
								sx={{
									fontWeight:
										tabValue === index ? 'bold' : '300',
									color:
										tabValue === index
											? '#6418C3'
											: '#424242',
								}}
								className={styles.tabLabel}
								label={tab}
								{...a11yProps(index)}
								key={index}
							/>
						))}
					</Tabs>
				</div>
				<Filter
					statusDurationValue={statusDurationValue}
					setStatusDurationValue={setStatusDurationValue}
					statusChannelValue={statusChannelValue}
					setStatusChannelValue={setStatusChannelValue}
					channelData={channelData}
					DurationData={DurationData}
				/>
			</div>
			<div className={styles.body}>
				<TabPanel value={tabValue} index={0}>
					<OverViewTab
						statusDurationValue={statusDurationValue}
						statusChannelValue={statusChannelValue}
					/>
				</TabPanel>

				<TabPanel value={tabValue} index={1}>
					<ChatBotTab
						setCurrentView={setCurrentView}
						currentView={currentView}
						statusDurationValue={statusDurationValue}
						statusChannelValue={statusChannelValue}
					/>
				</TabPanel>

				<TabPanel value={tabValue} index={2}>
					<TeamMembersTab
						statusDurationValue={statusDurationValue}
						statusChannelValue={statusChannelValue}
					/>
				</TabPanel>
			</div>
		</div>
	);
};

export default Component;
