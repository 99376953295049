import { Container, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import UnionBenefits from 'assets/images/landingImgs/UnionBenefits.png';
import testimonialAvetar from 'assets/images/landingImgs/testimonialAvetar.png';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import TestimonialCard from './testimonialCard';

const useStyles = makeStyles((theme) => ({
	sectionBackGround: {
		backgroundColor: theme.palette.background.primaryOpacity,
		padding: '6rem 0px',
		position: 'relative',
	},
	unionTopImg: {
		width: '7.5rem',
		height: '7.0625rem',
		// transform: "translateY(-40%)",
		animation: '$slideDown 10s linear  infinite alternate-reverse',
		position: 'absolute',
	},
	'@keyframes slideDown': {
		from: { top: '0px' },
		to: { top: '-100px' },
	},
	SectionSmTitle: {
		...theme.typography.subtitle5,
		color: theme.palette.text.primary,
	},
	SectionLgTitle: {
		...theme.typography.subtitle3,
		color: theme.palette.text.primary,
		marginBottom: '6.25rem',
	},
	carasoulFrame: {
		overflow: 'visible !important',
	},
}));
const postBody =
	'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam\
                  nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat\
                          volutpat. Ut wisi enim ad minim veniam,';
const testimonialInfo = {
	avetarImg: testimonialAvetar,
	postBody,
	name: 'Shrouk ahmed',
	jop: 'Ui & Ux Designer',
};
const Testimonial = () => {
	const classes = useStyles();
	const options = {
		responsive: {
			0: {
				items: 1,
			},
			600: {
				items: 2,
			},
			1000: {
				items: 3,
			},
		},
	};
	return (
		<Paper elevation={0} className={classes.sectionBackGround}>
			<Container>
				<img
					className={classes.unionTopImg}
					src={UnionBenefits}
					alt=""
				/>
				<Typography className={classes.SectionSmTitle} align="center">
					Testimonial
				</Typography>
				<Typography className={classes.SectionLgTitle} align="center">
					Our Customers Say
				</Typography>
				<OwlCarousel
					className={classes.carasoulFrame + ' owl-theme'}
					loop
					margin={60}
					items={3}
					loop={true}
					center={true}
					autoplay={true}
					autoplayTimeout={3000}
					autoplayHoverPause={true}
					responsive={{ ...options.responsive }}
				>
					<TestimonialCard {...testimonialInfo} />
					<TestimonialCard {...testimonialInfo} />
					<TestimonialCard {...testimonialInfo} />
					<TestimonialCard {...testimonialInfo} />
				</OwlCarousel>
			</Container>
		</Paper>
	);
};

export default Testimonial;
