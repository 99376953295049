/* eslint-disable no-console */
/* eslint-disable no-debugger */
/* eslint-disable import/first */
/* eslint-disable prefer-arrow-callback */

import {
	GET_CURRENT_FLOW_STATE_SUCCESS,
	GET_CURRENT_FLOW_STATE_FAILED,
	GET_CURRENT_FLOW_STATE_REQUEST,
	SAVE_NEW_FLOW_STATE_REQUEST,
	SAVE_NEW_FLOW_STATE_FAILED,
	SAVE_NEW_FLOW_STATE_SUCCESS,
	UPDATE_CURRENT_FLOW_STATE_FAILED,
	UPDATE_CURRENT_FLOW_STATE_SUCCESS,
	CLEAR_CURRENT_FLOW_STATE,
} from 'store/actions/types/flowpathsdata';
import { ADD_NODE, DELETE_NODE, EDIT_NODE } from 'store/actions/types/nodes';

import { pending, rejected, resolved } from 'constants/status';
import { GUIDE_MESSAGE } from 'constants/nodesTypes';
import { generateGuideMessageScheme } from 'helpers/nodeSchemeHelpers';
import { GUIDE_MESSAGE_TEXT } from 'constants/nodes';

const initialState = {
	data: [],
	savedBackend: true,
};
// generate unique id
const uniqueId = () => {
	const dateString = Date.now().toString(36);
	const randomness = Math.random().toString(36).substr(2);
	return dateString + randomness;
};
export default (state = initialState, { type, payload, error }) => {
	switch (type) {
		case GET_CURRENT_FLOW_STATE_REQUEST: {
			return {
				...state,
				meta: {
					status: pending,
					action: GET_CURRENT_FLOW_STATE_REQUEST,
				},
			};
		}
		case GET_CURRENT_FLOW_STATE_SUCCESS: {
			const { currentFlowState } = payload;
			return {
				...state,
				savedBackend: true,
				data: [...currentFlowState],
				meta: {
					status: resolved,
					action: GET_CURRENT_FLOW_STATE_SUCCESS,
				},
			};
		}
		case GET_CURRENT_FLOW_STATE_FAILED: {
			return {
				...state,
				meta: {
					status: rejected,
					action: GET_CURRENT_FLOW_STATE_SUCCESS,
				},
				error,
			};
		}
		case CLEAR_CURRENT_FLOW_STATE: {
			return {
				...state,
				data: [],
			};
		}
		case UPDATE_CURRENT_FLOW_STATE_SUCCESS: {
			const { newFlowState } = payload;
			return {
				...state,
				meta: {
					status: resolved,
					action: UPDATE_CURRENT_FLOW_STATE_SUCCESS,
				},
				savedBackend: false,
				data: newFlowState,
			};
		}
		case UPDATE_CURRENT_FLOW_STATE_FAILED: {
			return {
				...state,
				meta: {
					status: rejected,
					action: UPDATE_CURRENT_FLOW_STATE_FAILED,
				},
				error,
			};
		}
		case ADD_NODE: {
			const { generatedNode } = payload;
			console.log('generatedNode....Reducer', generatedNode);

			// remove guide message if index more than 1
			const newData =
				state?.data?.length === 1 &&
				state?.data[0]?.node_type === GUIDE_MESSAGE
					? [{ ...generatedNode, id: uniqueId() }]
					: [...state.data, { ...generatedNode, id: uniqueId() }];
			return {
				...state,
				savedBackend: false,
				data: newData,
			};
		}
		case EDIT_NODE: {
			const { updatedGeneratedNode } = payload;
			const newData = state?.data.map((component) => {
				if (component?.id === updatedGeneratedNode?.id)
					return updatedGeneratedNode;
				return component;
			});
			return {
				...state,
				savedBackend: false,
				data: newData,
			};
		}
		case DELETE_NODE: {
			const { nodeId } = payload;

			const newData = state?.data.filter(
				(component) => component?.id !== nodeId
			);
			if (newData?.length === 0) {
				const generatedNode =
					generateGuideMessageScheme(GUIDE_MESSAGE_TEXT);
				newData.push({ ...generatedNode, id: uniqueId() });
			}
			return {
				...state,
				savedBackend: false,
				data: newData,
			};
		}
		case SAVE_NEW_FLOW_STATE_REQUEST: {
			return {
				...state,
				action: SAVE_NEW_FLOW_STATE_REQUEST,
				meta: {
					status: pending,
					action: SAVE_NEW_FLOW_STATE_REQUEST,
				},
			};
		}
		case SAVE_NEW_FLOW_STATE_SUCCESS: {
			return {
				...state,
				action: SAVE_NEW_FLOW_STATE_SUCCESS,
				meta: {
					status: resolved,
					action: SAVE_NEW_FLOW_STATE_REQUEST,
				},
				savedBackend: true,
			};
		}
		case SAVE_NEW_FLOW_STATE_FAILED: {
			return {
				...state,
				action: SAVE_NEW_FLOW_STATE_SUCCESS,
				meta: {
					status: rejected,
					action: SAVE_NEW_FLOW_STATE_REQUEST,
				},
				error,
				savedBackend: true,
			};
		}

		default:
			return state;
	}
};
