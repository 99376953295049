import { Avatar, Menu, MenuItem } from '@material-ui/core';
import Logo from 'assets/images/logo_with_text.svg';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logoutAdmin } from 'store/actions/creators/adminPanel';
import styles from './index.module.scss';

const APHeader = () => {
	const adminUser = useSelector((state) => state?.adminPanel?.adminUser);
	const history = useHistory();
	const dispatch = useDispatch();
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLogout = () => {
		setAnchorEl(null);
		dispatch(logoutAdmin(history))
	};

	return (
		<div className={styles.container}>
			<div className={styles.wrapper}>
				<div className={styles.logoContainer}>
					<img
						className={styles.logo}
						src={Logo}
						alt="Talkybot logo"
					/>
					<h2>TalkyBot</h2>
				</div>

				<div>
					<Avatar
						style={{ cursor: 'pointer' }}
						id="basic-button"
						aria-controls={open ? 'basic-menu' : undefined}
						aria-haspopup="true"
						aria-expanded={open ? 'true' : undefined}
						onClick={handleClick}
						src={adminUser?.attributes?.attachment_url}
					></Avatar>

					<Menu
						id="basic-menu"
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
						MenuListProps={{
							'aria-labelledby': 'basic-button',
						}}
						style={{ top: '25px' }}
					>
						<MenuItem onClick={handleLogout}>Logout</MenuItem>
					</Menu>
				</div>
			</div>
		</div>
	);
};

export default APHeader;
