import { connect } from 'react-redux';
import {
	getBotEscalationAction,
	getBotInteractionsAction,
	getCompletionScenariosAction,
	getMostFrequentQuestionsAction,
	getMostUsedScenariosAction,
	getStatementRecognationAction,
} from 'store/actions/creators/dashboard';
// local assets
import { ReactComponent as Esclations } from 'assets/images/Dashboard/Esclations.svg';
import { ReactComponent as Interaction } from 'assets/images/Dashboard/Interaction.svg';
import { ReactComponent as NoOfMessageIn } from 'assets/images/Dashboard/NoOfMessageIn.svg';
import { ReactComponent as NoOfMessageOut2 } from 'assets/images/Dashboard/NoOfMessageOut2.svg';

const mapStateToProps = (state) => {
	return {
		talkybotId: state?.activeTalkybot?.id,
		most_frequent_questions: state?.dashboard?.most_frequent_questions,
		most_used_scenarios: state?.dashboard?.most_used_scenarios,
		StatementRecognationState: StatementRecognation(state),
		ChatbotCardInfo: ChatbotCardInfo(state),
		completion_scenarios: state?.dashboard?.completion_scenarios,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getBotInteractions: ({
			talkyBotID,
			duration_filter,
			provider_filter,
			setUiLoadingError,
		}) =>
			dispatch(
				getBotInteractionsAction({
					talkyBotID,
					duration_filter,
					provider_filter,
					setUiLoadingError,
				})
			),
		getMostFrequentQuestions: ({
			talkyBotID,
			duration_filter,
			provider_filter,
			setUiLoadingError,
		}) =>
			dispatch(
				getMostFrequentQuestionsAction({
					talkyBotID,
					duration_filter,
					provider_filter,
					setUiLoadingError,
				})
			),
			getBotEscalation: ({
			talkyBotID,
			duration_filter,
			provider_filter,
			setUiLoadingError,
		}) =>
			dispatch(
				getBotEscalationAction({
					talkyBotID,
					duration_filter,
					provider_filter,
					setUiLoadingError,
				})
			),
		getMostUsedScenarios: ({
			talkyBotID,
			duration_filter,
			provider_filter,
			setUiLoadingError,
		}) =>
			dispatch(
				getMostUsedScenariosAction({
					talkyBotID,
					duration_filter,
					provider_filter,
					setUiLoadingError,
				})
			),
		getStatementRecognation: ({
			talkyBotID,
			duration_filter,
			provider_filter,
			setUiLoadingError,
		}) =>
			dispatch(
				getStatementRecognationAction({
					talkyBotID,
					duration_filter,
					provider_filter,
					setUiLoadingError,
				})
			),
		getCompletionScenarios: ({
			talkyBotID,
			duration_filter,
			provider_filter,
			setUiLoadingError,
		}) =>
			dispatch(
				getCompletionScenariosAction({
					talkyBotID,
					duration_filter,
					provider_filter,
					setUiLoadingError,
				})
			),
	};
};

const ChatbotCardInfo = (state) => {
	return [
		{
			id: 1,
			icon: <Esclations />,
			cardTitle: 'No. Of Escalations',
			result: state?.dashboard?.bot_escalation?.bot_escalation,
		},
		{
			id: 2,
			icon: <NoOfMessageIn />,
			cardTitle: 'No. Of Conversations Closed By Bot',
			result: state?.dashboard?.closed_conversation
				?.conversations_closed_by_bot,
		},
		{
			id: 3,
			icon: <Interaction />,
			cardTitle: 'No. Of Bot Interactions',
			result: state?.dashboard?.bot_interactions?.total_bot_interactions,
		},
		{
			id: 4,
			icon: <NoOfMessageOut2 />,
			cardTitle: 'No. Of Messages Out',
			result: state?.dashboard?.messagesOut
				?.number_of_messages_out_by_bot,
		},
	];
};

const StatementRecognation = (state) => {
	return {
		id: 1,
		title: 'Statement Recognition',
		total: state?.dashboard?.statement_recognition?.total,
		data: [
			{
				name: 'Understood',
				value: state?.dashboard?.statement_recognition
					?.recognized_statments,
			},
			{
				name: 'Language Not Supported',
				value: state?.dashboard?.statement_recognition
					?.language_not_supported,
			},
			{
				name: 'Misunderstood',
				value: state?.dashboard?.statement_recognition
					?.unrecognized_statments,
			},
		],
		arabic: state?.dashboard?.statement_recognition?.unrecognized_arabic_statements,
		english: state?.dashboard?.statement_recognition?.unrecognized_english_statements,
		colors: ['#B7DBFF', '#B479FF', '#FFC4B9'],
	};
};

export default connect(mapStateToProps, mapDispatchToProps);
