import {
	GET_ALL_PATHS_FLOWS,
	GET_FLOW_BY_LANGUAGE_AND_TYPE,
	CREATE_PATH_FLOW,
	DELETE_PATH_FLOW,
	TOGGLE_PATH_FLOW,
	EDIT_PATH_FLOW,
	BULK_DELETE_FLOWS_SUCCESS,
} from 'store/actions/types/pathsflows';

const INIT_STATE = {
	flows: [],
	flowsCount: 0,
	singleFlow: {},
	totalCount: 0,
};

export default (state = INIT_STATE, { type, payload }) => {
	switch (type) {
		case GET_FLOW_BY_LANGUAGE_AND_TYPE: {
			const {flow} = payload;
			return {
				...state,
				singleFlow : flow
			}
		}
		case GET_ALL_PATHS_FLOWS: {
			const { flows, count, totalCount } = payload;
			return {
				...state,
				flows: { ...flows },
				flowsCount: count,
				totalCount: totalCount,
			};
		}

		case CREATE_PATH_FLOW: {
			const { flow } = payload;
			return {
				...state,
				flows: {
					...state.flows,
					[flow.id]: flow,
				},
				flowsCount: state.flowsCount + 1,
				totalCount: state.totalCount + 1,
			};
		}
		case EDIT_PATH_FLOW: {
			const { flow } = payload;
			return {
				...state,
				flows: {
					...state.flows,
					[flow.id]: flow,
				},
			};
		}

		case DELETE_PATH_FLOW: {
			const { pathFlowId } = payload;
			// [TODO:] i will search about this later how copilot can handle this for deletion
			const { [pathFlowId]: deletedFlow, ...flows } = state.flows;
			return {
				...state,
				flows,
				flowsCount: state.flowsCount - 1,
				totalCount: state.totalCount - 1,
			};
		}
		case BULK_DELETE_FLOWS_SUCCESS: {
			const { pathsFlowIds } = payload;
			const newFlows = pathsFlowIds.reduce((flow, key) => {
				const { [key]: _, ...flows } = flow;
				return flows;
			}, state.flows);
			return {
				...state,
				flows: newFlows,
				flowsCount: state.flowsCount - pathsFlowIds.length,
			};
		}

		case TOGGLE_PATH_FLOW: {
			const { pathFlowId } = payload;
			const newState = { ...state };
			const previousFlowStatue = newState[pathFlowId].status;
			newState[pathFlowId].status = !previousFlowStatue;
			return newState;
		}

		default:
			return state;
	}
};
