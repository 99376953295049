import {
	Box,
	CircularProgress,
	makeStyles,
	Typography,
	Tooltip,
} from '@material-ui/core';
import React from 'react';

function CircularProgressWithLabel({
	minExpectedValue,
	maxExpectedValue,
	valueOfProgress,
	size,
	thickness,
	mainLayoutStyle,
	usagePercentage,
	color = 'primary',
}) {
	const classes = useStyles();
	// Function to normalise the values (minExpectedValue / maxExpectedValue could be integrated)
	const normalise = (value) =>
		((value - minExpectedValue) * 100) /
		(maxExpectedValue - minExpectedValue);

	return (
		<Box position="relative" display="flex">
			{mainLayoutStyle ? (
				<Tooltip
					title={`Usage ${usagePercentage}%`}
					placement="right"
					arrow
					classes={{
						tooltip: classes.tooltip,
						arrow: classes.tooltipArrow,
					}}
				>
					<CircularProgress
						variant="determinate"
						value={normalise(valueOfProgress)}
						size={size}
						thickness={thickness}
						style={{
							color: color,
							marginTop: mainLayoutStyle ? '2rem' : '',
							marginLeft: mainLayoutStyle ? '-0.3rem' : '',
						}}
					/>
				</Tooltip>
			) : (
				<CircularProgress
					variant="determinate"
					value={normalise(valueOfProgress)}
					size={size}
					thickness={thickness}
					style={{
						color: color,
						marginTop: mainLayoutStyle ? '2rem' : '',
						marginLeft: mainLayoutStyle ? '-0.3rem' : '',
					}}
				/>
			)}
			{/* <CircularProgress
				variant="determinate"
				value={normalise(valueOfProgress)}
				size={size}
				thickness={thickness}
				style={{
					color: color,
					marginTop: mainLayoutStyle ? '2rem' : '',
					marginLeft: mainLayoutStyle ? '-0.3rem' : '',
				}}
			/> */}
			{!mainLayoutStyle && (
				<Box className={classes.progressLabelWrapper}>
					<Typography
						className={classes.progressLabel}
						variant="caption"
						component="div"
					>
						{valueOfProgress}
					</Typography>
				</Box>
			)}
		</Box>
	);
}

export default CircularProgressWithLabel;

const useStyles = makeStyles((theme) => ({
	progressLabelWrapper: {
		position: 'absolute',
		top: '15%',
		left: '15%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		border: '1px solid rgb(151, 151, 151,0.4)',
		borderRadius: '50%',
		width: '70%',
		height: '70%',
		textAlign: 'center',
		'& .MuiCircularProgress-colorPrimary' : {
			width: '7rem'
		}
	},
	progressLabel: {
		fontSize: '.9rem',
		color: '#330404',
	},
	tooltip: {
		...theme.typography.subtitle6,
		padding: '.5rem 1rem',
		backgroundColor: 'grey',
	},
	tooltipArrow: {
		color: 'grey',
	},
}));
