import {
	Button,
	CircularProgress,
	Typography,
	makeStyles,
	useTheme
} from '@material-ui/core';
import { ReactComponent as Warning } from 'assets/images/Warning-delete.svg';
import ErrorView from 'components/common/waitingReqView/ErrorView';
import useSetUiLoadingError from 'hooks/useSetUiLoadingError';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


export default function DeleteEntityComponent({
	deleteEntity,
	entityToDelete,
	setEntityToDelete,
	activeTalkybot,
	handleCloseModal,
}) {
	const [open, setOpen] = React.useState(true);
	return (
		DeleteEntityContent({
			deleteEntity,
			entityToDelete,
			setEntityToDelete,
			activeTalkybot,
			handleCloseModal
		})
	);
}

const DeleteEntityContent = ({
	deleteEntity,
	entityToDelete,
	setEntityToDelete,
	activeTalkybot,
	handleCloseModal,
}) => {
	const classes = useStyles();
	const theme = useTheme();
	const { enqueueSnackbar } = useSnackbar();

	const history = useHistory()

	// 🔁 Loading and 🔴error handling
	const [isLoading, successData, backendError, setUiLoadingError] =
		useSetUiLoadingError();

	useEffect(() => {
		if (successData) {
			setEntityToDelete(null);
			handleCloseModal()
		}
	}, [successData]);

	return (
		<>
			<div className={classes.deleteImageTextContainer}>

				<Warning />
				<Typography className={classes.deleteBotDescription}>
					Are You Sure You Want To Delete {entityToDelete?.attributes?.name}
					&nbsp;Entity?
				</Typography>
			</div>
			{backendError?.message && (
				<ErrorView error={backendError?.message} />
			)}
			<div className={classes.buttonContainer}>
				<Button
					onClick={handleCloseModal}
					variant="contained"
					color={theme.palette.secondary.light}
					autoFocus
					className={classes.noBtn}
				>
					No
				</Button>
				<Button
					autoFocus
					disabled={isLoading}
					variant="contained"
					color="primary"
					className={classes.yesBtn}
					onClick={() => {
						const entityId = entityToDelete?.id;
						const botBuilderAppId = activeTalkybot?.bot_builder_id;
						deleteEntity({
							entityId,
							botBuilderAppId,
							history,
							setUiLoadingError,
							enqueueSnackbar
						});
					}}
				>
					{isLoading ? (
						<CircularProgress color="white" size={15} />
					) : (
						'Yes'
					)}
				</Button>
			</div>
		</>
	);
};
const useStyles = makeStyles((theme) => ({
	buttonsWrapper: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		marginTop: '2rem',
	},
	buttonContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: theme.spacing(20),
		marginBottom: theme.spacing(15),
	},
	yesBtn: {
		borderRadius: '1rem',
		width: theme.spacing(130),
	},
	noBtn: {
		marginRight: '1rem',
		borderRadius: '1rem',
		width: theme.spacing(140),
	},
	deleteBotDescription: {
		...theme.typography.body2,
		marginTop: theme.spacing(15),
		marginBottom: theme.spacing(15),
		color: theme.palette.common.dark,
	},
	deleteImageTextContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		width: '100%',
		alignItems: 'center'
	},
	deleteLabel: {
		color: '#FF6470'
	}
}));
