import { useState, useEffect } from 'react';

const useTitle = (defaultValue) => {
	const [state, setState] = useState(() => {
		return typeof defaultValue === 'function'
			? defaultValue()
			: defaultValue;
	});

	useEffect(() => {
		document.title = `Talkybot - ${state}`;
	}, [state]);

	return [state, setState];
};

export default useTitle;
