import { Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Offline, Online, Detector } from 'react-detect-offline';
import noInternetConnectionImg from 'assets/images/noInternetConnection.png';
import GridContainer from 'components/common/Grid/GridContainer';

const NoInternetConnection = () => {
	// const { previousPath = '' } = useParams();
	return (
		// <Detector
		// 	render={({ online }) => (
		// 		<>
		// 			{online ? (
		// 				window.location.replace(`/${previousPath}`)
		// 			) : (
		<GridContainer
			justifyContent="center"
			alignItems="center"
			direction="column"
			style={{ height: '100vh', padding: '6rem' }}
		>
			<img
				height="90%"
				src={noInternetConnectionImg}
				alt="no Internet Connection page"
			/>
			<Typography
				style={{
					textAlign: 'center',
					font: 'normal normal bold 1.5rem/38px Poppins',
					color: ' #232324',
					textTransform: 'capitalize',
					transform: 'translateY(-10rem)',
				}}
			>
				Missing Connection
			</Typography>
		</GridContainer>
		// 	)}
		// </>
		// 	)}
		// />
	);
};

export default NoInternetConnection;
