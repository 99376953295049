import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as BulkDeleteIcon } from 'assets/icons/bulkdelete.svg';
import plusIcon from 'assets/icons/plusIcon.svg';
import GridContainer from 'components/common/Grid/GridContainer';
import GridItem from 'components/common/Grid/GridItem';
import ModalContainer from 'components/common/ModalContainer/Modal';
import FormSelect from 'controllers/FormSelect';
import FormTextInput from 'controllers/FormTextInput';
import mapCategories from 'helpers/MapCategories';
import useSetUiLoadingError from 'hooks/useSetUiLoadingError';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

export default function AddEntity({
	addEntity,
	activeTalkybot,
	categories,
	entities,
	defaultCategory,
	showBulkDeleteButton,
	setShowBulkDeleteButton,
}) {
	const [open, setOpen] = React.useState(false);
	const classes = useStyles();

	return (
		<>
			{showBulkDeleteButton && (
				<BulkDeleteIcon
					style={{ background: '#ffffff', marginRight: '2rem' }}
				/>
			)}
			<Button
				variant="contained"
				color="primary"
				className={classes.addEntity}
				onClick={() => setOpen(true)}
			>
				<img
					src={plusIcon}
					alt="add topic flow"
					style={{ width: '0.8rem', marginRight: '1rem' }}
				/>
				Add Entity
			</Button>
			{/* <Typography className={classes.addTopicFlowBtn}>
				Add Topic Flow
			</Typography> */}
			<ModalContainer
				title="Add Entity"
				onClose={() => setOpen(false)}
				open={open}
				component={
					<AddIntentContent
						addEntity={addEntity}
						activeTalkybot={activeTalkybot}
						handleCloseModal={() => setOpen(false)}
						categories={categories}
						entities={entities}
						defaultCategory={defaultCategory}
					/>
				}
			/>
		</>
	);
}

const AddIntentContent = ({
	addEntity,
	activeTalkybot,
	handleCloseModal,
	categories,
	entities,
	defaultCategory,
}) => {
	const classes = useStyles();
	const history = useHistory();

	const { enqueueSnackbar } = useSnackbar();

	// const isEntityWithSameName = (name, allEntities) => {
	// 	return allEntities.some((entity) => entity.name === name);
	// };

	const validationSchema = yup.object().shape({
		name: yup.string().required(),
		entity_category_id: yup
			.number()
			.required('intent category is required'),
	});

	const {
		handleSubmit,
		formState: { errors },
		setError,
		control,
		watch,
		reset,
		getValues,
	} = useForm({
		resolver: yupResolver(validationSchema),
		mode: 'all',
		defaultValues: {
			name: '',
			entity_category_id: defaultCategory === null ? 0 : defaultCategory,
		},
	});

	// 🔁 Loading and error handling
	const [isLoading, successData, backendError, setUiLoadingError] =
		useSetUiLoadingError();


	const handleSubmitCreateIntent = async (payload) => {
		// if (isEntityWithSameName(payload.name, entities)) {
		// 	setError('name', {
		// 		type: 'manual',
		// 		message: 'There is intent with the same name',
		// 	});
		// }

		// if the category is not selected
		if (payload.entity_category_id === 0) {
			setError('entity_category_id', {
				type: 'manual',
				message: 'You should select category',
			});
		}


		if (
			Object.keys(errors).length === 0 &&
			payload.entity_category_id !== 0) {
			const entity = {
				name: payload.name,
				categoryId: payload.entity_category_id,
			};
			const botBuilderAppId = activeTalkybot?.bot_builder_id;

			addEntity({
				entity,
				botBuilderAppId,
				enqueueSnackbar,
				setUiLoadingError,
			});
		}
	};

	// dismiss the model when the backend is done
	useEffect(() => {
		if (successData) {
			handleCloseModal();
		}
	}, [successData]);

	return (
		<div className={classes.root}>
			<form onSubmit={handleSubmit(handleSubmitCreateIntent)}>
				<GridContainer
					spacing={10}
					justifyContent="space-evenly"
					className={classes.row}
				>
					<GridItem md={12} xs={12}>
						<Controller
							name="name"
							control={control}
							defaultValue=""
							render={({ field }) => (
								<FormTextInput
									{...field}
									error={errors?.name?.message}
									type="text"
									placeHolder="Entity Name"
								/>
							)}
						/>
					</GridItem>
					{/* TODO: to be added when backend finish it  [category,lang] */}
					<GridItem md={12} xs={12}>
						<Controller
							name="entity_category_id"
							control={control}
							defaultValue="1"
							render={({ field }) => (
								<FormSelect
									{...field}
									error={errors?.entity_category_id?.message}
									select
									// array of categories
									options={mapCategories(categories)}
									defaultValue="0"
									style={{
										padding: '7px',
										border: '1px solid #6418C3',
									}}
								/>
							)}
						/>
					</GridItem>
				</GridContainer>

				<GridContainer
					justifyContent="flex-end"
					className={classes.row}
					style={{ marginTop: '40px', marginBottom: '20px' }}
				>
					<Button
						onClick={() => handleCloseModal()}
						style={{ marginRight: '20px', padding: '10px' }}
					>
						Cancel
					</Button>
					<Button variant="contained" color="primary" type="submit">
						{isLoading ? (
							<CircularProgress color="white" size={15} />
						) : (
							'Add'
						)}
					</Button>
				</GridContainer>
			</form>
		</div>
	);
};
const useStyles = makeStyles((theme) => ({
	addEntity: {
		borderRadius: '1rem',
		backgroundColor: 'transparent',
		color: '#6418C3',
		boxShadow: 'none',
		fontSize: '20px',
		margin: '10px 0px',
		'&:hover': {
			backgroundColor: 'transparent',
			boxShadow: 'none',
		},
	},
}));
