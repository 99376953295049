import { connect } from 'react-redux';
import { authenticate } from 'store/actions/creators/auth';
import completeUserProfileAction from 'store/actions/creators/completeUserProfile';
import * as yup from 'yup';

const completeProfileNames = {
	full_name: 'full_name',
	email: 'email',
	password: 'password',
	password_confirmation: 'password_confirmation',
};

const passwordsValidationSchema = yup.object().shape({
	[completeProfileNames.full_name]: yup.string().required('Name is required'),
	[completeProfileNames.email]: yup
		.string()
		.email('Invalid email')
		.required('Email is required'),
	[completeProfileNames.password]: yup
		.string()
		.required('Password is required')
		.min(8, 'Password must be at least 8 characters'),
	[completeProfileNames.password_confirmation]: yup
		.string()
		.required('Confirm password is required')
		.oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const mapStateToProps = (state) => {
	return {
		isAuthenticated: state?.auth?.isAuthenticated,
		userData: state.completeUserProfile.userData,
		loading: state.completeUserProfile.pending,
		error: state.completeUserProfile.error,
		passwordsValidationSchema: passwordsValidationSchema,
		completeProfileNames: completeProfileNames,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		sentCompleteUserProfile: (userData) =>
			dispatch(completeUserProfileAction(userData)),
		auth: (token) => dispatch(authenticate(token)),
		// logOut:(withoutRouting)=>dispatch(logUserOutAction(withoutRouting))
	};
};

export default connect(mapStateToProps, mapDispatchToProps);
