import { Container, Grid, Paper, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import kemetLogoCarousel from 'assets/images/landingImgs/kemetLogoCarousel.png';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const useStyles = makeStyles((theme) => ({
	sectionBackGround: {
		padding: '6.25rem 0px 15.5rem  0px',
	},
	carasoulFrame: {
		overflow: 'visible !important',
	},
	kemetLogoCarousel: {},
}));

const FooterCarousel = () => {
	const classes = useStyles();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));

	const options = {
		responsive: {
			0: {
				items: 1,
			},
			600: {
				items: 2,
			},
			1000: {
				items: 3,
			},
		},
	};
	return (
		<Paper elevation={0} className={classes.sectionBackGround}>
			<OwlCarousel
				className={classes.carasoulFrame + ' owl-theme'}
				margin={50}
				items={matches ? 3 : 6}
				loop={true}
				center={true}
				autoplay={true}
				autoplayTimeout={3000}
				autoplayHoverPause={true}
				// responsive={{ ...options.responsive }}
			>
				{/* <Paper className={classes.kemetLogoCarousel} elevation={0}>
					<img src={kemetLogoCarousel} alt="" />
				</Paper> */}
				<Paper className={classes.kemetLogoCarousel} elevation={0}>
					<img src={kemetLogoCarousel} alt="" />
				</Paper>
				<Paper className={classes.kemetLogoCarousel} elevation={0}>
					<img src={kemetLogoCarousel} alt="" />
				</Paper>
				<Paper className={classes.kemetLogoCarousel} elevation={0}>
					<img src={kemetLogoCarousel} alt="" />
				</Paper>
			</OwlCarousel>
		</Paper>
	);
};

export default FooterCarousel;
