import React from 'react';
import {
	ADD_NOTE,
	ASSIGN_TEAM_MEMBER,
	CHANGE_STATUS,
	CREATE_PERSON,
	INCOMING_CALL,
	INCOMING_MESSAGE,
	MISSED_CALL,
	OUTGOING_CALL,
	OUTGOING_MESSAGE,
	UPDATE_PERSON,
	EDIT_NOTE,
	DELETE_NOTE,
} from 'constants/activityTypes';
import ChangeStatusBody from './ChangeStatusBody';
import CreatePersonBody from './CreatePersonBody';
import AssignTeamMemberBody from './AssignTeamMemberBody';
import AddNoteBody from './AddNoteBody';
import DeleteNoteBody from './DeleteNoteBody';
import EditNoteBody from './EditNoteBody';
import UpdatePersonBody from './UpdatePersonBody';
import SmsOrCallBody from './SmsOrCallBody';

const CardBody = ({
	userName,
	personName,
	byPerson,
	byBot,
	content,
	action = 'make an action',
	type,
}) => {
	const renderActivityBody = () => {
		let body;
		switch (type) {
			case CREATE_PERSON:
				body = (
					<CreatePersonBody
						userName={userName}
						personName={personName}
						byPerson={byPerson}
						content={content}
						action={action}
					/>
				);
				break;

			case CHANGE_STATUS:
				body = (
					<ChangeStatusBody
						userName={userName}
						personName={personName}
						byPerson={byPerson}
						byBot={byBot}
						content={content}
					/>
				);
				break;

			case ASSIGN_TEAM_MEMBER:
				body = (
					<AssignTeamMemberBody
						userName={userName}
						personName={personName}
						byPerson={byPerson}
						content={content}
						byBot={byBot}
					/>
				);
				break;

			case ADD_NOTE:
				body = (
					<AddNoteBody
						userName={userName}
						personName={personName}
						byPerson={byPerson}
						content={content}
					/>
				);
				break;
			case EDIT_NOTE:
				body = (
					<EditNoteBody
						userName={userName}
						personName={personName}
						byPerson={byPerson}
						content={content}
					/>
				);
				break;
			case DELETE_NOTE:
				body = (
					<DeleteNoteBody
						userName={userName}
						personName={personName}
						byPerson={byPerson}
						content={content}
					/>
				);
				break;
			case UPDATE_PERSON:
				body = (
					<UpdatePersonBody
						userName={userName}
						personName={personName}
						byPerson={byPerson}
						content={content}
					/>
				);
				break;

			case INCOMING_CALL:
			case OUTGOING_CALL:
			case MISSED_CALL:
			case INCOMING_MESSAGE:
			case OUTGOING_MESSAGE:
				body = (
					<SmsOrCallBody
						userName={userName}
						personName={personName}
						byPerson={byPerson}
						content={content}
						action={action}
					/>
				);
				break;

			default:
				break;
		}
		return body;
	};
	return <>{renderActivityBody()}</>;
};

export default CardBody;
