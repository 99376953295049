import { connect } from 'react-redux';

import { logUserOutAction } from 'store/actions/creators/auth';

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		logUserOut: async () => dispatch(logUserOutAction),
	};
};

export default connect(mapStateToProps, mapDispatchToProps);
