export const handleActionError = (
	incomingError,
	defaultMessage = 'Sorry ,please try again'
) => {
	// The request was made and the server responded with a status code
	if (incomingError?.response?.status === '')
		if (incomingError?.response?.data) {
			if (incomingError?.response?.data) {
				if (incomingError?.response?.data?.error)
					return incomingError.response.data.error;
			}
			return incomingError.response.data;
		}
	// error with status code
	if (incomingError?.response?.data?.status)
		return incomingError?.response?.data?.status;

	if (incomingError?.response?.data?.error) {
		return incomingError?.response?.data?.error;
	}
	// Something happened in setting up the request that triggered an Error
	if (incomingError?.message) {
		return incomingError.message;
	}
	return defaultMessage;
};
