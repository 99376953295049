import React, { useEffect } from 'react';
import { Paper } from '@material-ui/core';
import Services from './services';
import AboutHeader from './aboutHeader';

const About = () => {
	useEffect(() => {
		// 👇️ scroll to top on page load
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}, []);

	return (
		<Paper elevation={0}>
			<AboutHeader />
			<Services />
		</Paper>
	);
};

export default About;
