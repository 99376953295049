import massenger from 'assets/icons/installedMassenger.svg';

const Apps = {
	BotBuilder: {
		name: 'BotBuilder',
		applicationType: 'BotBuilderApp',
		description: 'manage client status',
		typeId: 1,
	},
	CRM: {
		name: 'CRM',
		applicationType: 'CrmApp',
		description: 'manage client status',
		url: 'settings/crm',
		typeId: 2,
	},
	Conversation: {
		name: 'Conversations',
		description: 'manage conversation settings and taking over',
		url: 'settings/conversations',
		applicationType: 'ConversationsApp',
		typeId: 3,
		isReady: true,
		smallIcon: massenger,
	},
};

export default Apps;
