import Typography from '@material-ui/core/Typography';
import { Box, Container, Grid, Card, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LiveSupportImg from 'assets/images/landingImgs/LiveSupportImg.png';
import UnionBenefits from 'assets/images/landingImgs/UnionBenefits.png';

import supportWave from 'assets/images/landingImgs/supportWave.png';

const useStyles = makeStyles((theme) => ({
	content: {
		padding: '3.75rem 0px',
		position: 'relative',
	},

	LiveSupportTitleFrame: {
		// width: "50%",
		margin: '3.75rem auto',
	},
	LiveSupportSubTitle: {
		...theme.typography.subtitle5,
		color: theme.palette.text.primary,
		// lineHeight:0,
	},
	LiveSupportTitle: {
		...theme.typography.subtitle3,
		color: theme.palette.text.primary,
		lineHeight: 1.5,
	},

	LiveSupportImg: {
		width: '65%',
		hight: '65%',
	},
	contentHeader: {
		...theme.typography.subtitle3,
		color: theme.palette.text.primary,
		margin: '0.9375rem 0px',
	},
	contentDesc: {
		...theme.typography.body3,
		color: theme.palette.text.primary,
	},
	unionTopImg: {
		width: '7.5rem',
		height: '7.0625rem',
		backgroundColor: 'transparent',
		// transform: "translateY(-40%)",
		position: 'absolute',
		left: '2rem',
		animation: '$slideDown 10s linear  infinite alternate-reverse',
	},
	'@keyframes slideDown': {
		from: { top: '0px' },
		to: { top: '-6.25rem' },
	},
	iconStyle: {
		width: '12.5rem',
		height: '12.5rem',
		border: '2px solid',
		borderColor: theme.palette.primary,
		borderRadius: '50%',
		textAlign: 'center',
		backgroundColor: theme.palette.common.white,
	},
	iconHeader: {
		...theme.typography.subtitle5,
		lineHeight: 1,
		marginBottom: '0.625rem',
	},
	headerColorPrim: {
		color: theme.palette.text.primaryShad5,
	},
	headerColorPrimLight: {
		color: theme.palette.text.primaryShad6,
	},
	headerColorRed: {
		color: theme.palette.text.redShad4,
	},
	iconDesc: {
		...theme.typography.body4,
		color: theme.palette.text.dark,
	},
	supportWaveImg: {
		width: '67%',
		margin: 'auto',
		padding: '5.5% 3%',

		// position: "relative",
		// // overflow: "hidden",
		// transform:"rotate(90deg)",

		[theme.breakpoints.up('md')]: {
			backgroundImage: `url(${supportWave})`,
			backgroundSize: '100% 100%',
			backgroundPosition: 'center center',
			backgroundRepeat: 'no-repeat',
		},
	},
	supportWaveContent: {
		// padding: '50px 10px',
		// position: 'absolute',
		// top: '0',
		// padding: '30px',
		// overflow: 'hidden',
		// transform: 'rotate(270deg)',
	},
	containerStyle: {
		[theme.breakpoints.up('md')]: {
			paddingRight: theme.spacing(15),
			paddingLeft: theme.spacing(15),
		},
		paddingRight: theme.spacing(4),
	},
}));
const LiveSupport = () => {
	const classes = useStyles();
	return (
		<Paper className={classes.content} elevation={0}>
			<img className={classes.unionTopImg} src={UnionBenefits} alt="" />
			<Container className={classes.containerStyle} maxWidth={false}>
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					spacing={2}
				>
					{/* Desc */}
					<Grid item md={6}>
						<Typography className={classes.contentHeader}>
							3 -Live Support
						</Typography>
						<Typography className={classes.contentDesc}>
							It is hard to guarantee live chat support to your
							visitors with a support executive round the clock.
							But here, a live chatbot beats untimely human
							interaction by offering support 24 hours a day for
							365 days a year prioritizing customer service over
							everything. An added advantage is It’s human-like
							behaviour and instant responses that connect with
							the human.
						</Typography>
					</Grid>
					{/* section img */}
					<Grid item md={6} align="end">
						<img
							className={classes.LiveSupportImg}
							src={LiveSupportImg}
							alt=""
						/>
					</Grid>
				</Grid>
				{/********  Bot builder********** */}
				<Paper
					className={classes.LiveSupportTitleFrame}
					align="center"
					elevation={0}
				>
					<Typography className={classes.LiveSupportSubTitle}>
						Benefits
					</Typography>
					<Typography className={classes.LiveSupportTitle}>
						Talkybot is the smartest <br /> Conversational support
						platform
					</Typography>
				</Paper>
				{/***************  button Icons ************/}
				<Paper
					className={classes.supportWaveImg}
					align="center"
					elevation={0}
				>
					{/* <img width="100%" height="100%" src={supportWave} alt="" /> */}
					<Grid
						className={classes.supportWaveContent}
						container
						direction="row"
						justifyContent="space-between"
						alignItems="flex-start"
						spacing={10}
						align="center"
					>
						{/* Easy to monitor */}
						<Grid item md={4}>
							<Typography
								className={
									classes.iconHeader +
									' ' +
									classes.headerColorPrim
								}
							>
								Easy to monitor
							</Typography>
							<Typography className={classes.iconDesc}>
								it’s easy to monitor sessions and transcripts,
								conduct quality control audits and both support
								and supervise agents.
							</Typography>
						</Grid>

						{/* Low Cost */}
						<Grid item md={4}>
							<Typography
								className={
									classes.iconHeader +
									' ' +
									classes.headerColorPrimLight
								}
							>
								Low Cost
							</Typography>
							<Typography className={classes.iconDesc}>
								a live chat session is a full 20-30% cheaper
								than a phone call.
							</Typography>
						</Grid>
						{/* Enriches your CRM */}
						<Grid item md={4}>
							<Typography
								className={
									classes.iconHeader +
									' ' +
									classes.headerColorRed
								}
							>
								Enriches your CRM
							</Typography>
							<Typography className={classes.iconDesc}>
								Your chat software will automatically stream
								data to your CRM. you can keep your CRM informed
								and updated.
							</Typography>
						</Grid>
					</Grid>
				</Paper>
			</Container>
		</Paper>
	);
};

export default LiveSupport;
