/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import Loading from 'components/common/waitingReqView/loading';
import { Grid, makeStyles, Paper, Typography, Button, Card, CardMedia, Divider, CardHeader } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Error from 'components/common/waitingReqView/error';

import GridContainer from 'components/common/Grid/GridContainer';
import MeterInfo from './MeterInfo/Component';
import styles from './index.module.scss';
import MeterCard from './MeterInfo/MeterCard/component';
import BotsTable from './MeterInfo/BotsTable';
import CircularProgressWithLabel from 'components/common/CircularProgressWithLabel/CircularProgressWithLabel';
import { ReactComponent as Facebook } from 'assets/icons/Usage/Facebook.svg';
import { ReactComponent as Whatsapp } from 'assets/icons/Usage/Whatsapp.svg';
import { ReactComponent as Instagram } from 'assets/images/Dashboard/instagram.svg';
import SessionsCard from './MeterInfo/SessionsCard/SessionsCard';
import MobileAppCard from './MeterInfo/MobileAppCard/MobileAppCard';
import EngagementsCard from './MeterInfo/EngagementsCard/EngagementsCard';
import { Skeleton } from '@material-ui/lab';
// import PlanDetails from './PlanDetails';

const Usage = ({
	getCurrentUsage,
	meterInfo,
	planDetails,
	error,
	loading,
	getAllBots,
	talkybots,
	tenantId,
	userID,
	getCurrentSessions,
	sessions,
	getCurrentEngagement,
	engagements,
	getCurrentMobileApp,
	mobileApp,
	getCurrentBotUsage,
	botUsage,
}) => {

	const history = useHistory();
	const classes = useStyles();
	const filteredMeterInfo = meterInfo?.filter(bot => bot.nameOfMeter !== 'Re-Engagements' && bot.nameOfMeter !== 'Sessions')

	useEffect(() => {
		getCurrentUsage(tenantId);
		getCurrentSessions(tenantId)
		getCurrentEngagement(tenantId)
		getCurrentMobileApp(tenantId)
		getCurrentBotUsage(tenantId)
		// All bots ware called when being first in my bots page and saved by persistent in redux
		// getAllBots(setUiLoadingError);
	}, []);
	const [matches, setMatches] = useState(
		window.matchMedia('(max-width: 1600px)').matches
	);

	console.log('getCurrentBotUsageAction', botUsage?.data)
	console.log('getCurrentBotUsageAction', talkybots)

	function formatNumberWithCommas(number) {
		// Convert the number to a string
		let numberString = number.toString();
	
		// Use a regular expression to add commas after every three digits
		numberString = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	
		return numberString;
	}

	const getImageUrl = (nameOfMeter) => {
		switch (nameOfMeter) {
			case 'Bots':
				return usageBots;
			case 'Custom Variables':
				return usageCustomVariables;
			case 'Team Members':
				return usageTeamMembers;
			case 'Re-Engagements':
				return usageEngagements;
			default:
				return '';
		}
	};


	return (
		<div className={styles.container}>
			<div className={styles.planContainer}>
				<h3>Organization Usage</h3>
				<div className={styles.planWrapper}>
					
				<div className={styles.planContentContainer}>
					<div className={styles.planTypeContainer}>
					<span className={styles.currentPlan}>Current Plan : </span>
					<span className={styles.plan}>{planDetails?.title}</span>
					</div>
					<span className={styles.expiry}>{`Expired On ${planDetails?.next_date_for_paying?.split(' ')[0]}`}</span>
				</div>
					<button className={styles.button} onClick={() => {history.push(`/users/${userID}/subscription?`);}}> Upgrade </button>
				</div>
			</div>

			<div className={styles.contetntContainer}>
				<div className={styles.cardsContainer}>
				{filteredMeterInfo ? (
					filteredMeterInfo?.map((bot, i) => (
						<MeterCard
						totalNumOfMeter={bot.totalNumOfMeter}
						currentNumOfMeter={bot.currentNumOfMeter}
						nameOfMeter={bot.nameOfMeter}
					/>
			))
				) : (
					Array.from({ length: 3 }, (_, index) => (
						<Skeleton style={{ borderRadius: '12px', width: '100%' }} variant="rect" animation="wave" height={272} />
					))
				)}

				{sessions ? (
					<SessionsCard sessions={sessions} formatNumberWithCommas={formatNumberWithCommas} />
				) : (
					<Skeleton style={{ borderRadius: '12px', width: '100%' }} variant="rect" animation="wave" height={512} />
				)}

				{engagements ? (
					<EngagementsCard engagements={engagements} formatNumberWithCommas={formatNumberWithCommas} />
				) : (
					<Skeleton style={{ borderRadius: '12px', width: '100%' }} variant="rect" animation="wave" height={512} />
				)}

				{mobileApp ? (
					mobileApp?.mobile_app && <MobileAppCard sessions={sessions} />
				) : (
					<Skeleton style={{ borderRadius: '12px', width: '100%' }} variant="rect" animation="wave" height={512} />
				)}

				
				
				 

				</div>
				<div className={styles.botsContainer}>
					{botUsage?.data ? (
						<BotsTable talkybots={botUsage?.data} formatNumberWithCommas={formatNumberWithCommas} />
					) : (
						<Skeleton style={{ borderRadius: '12px', width: '100%' }} variant="rect" animation="wave" height={810} />
					)}
				
				</div>
			</div>
		</div>
	);
};

export default Usage;

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: 'transparent',
	},
	workspace: {
		backgroundColor: 'transparent',
	},
	workSpaceHeader: {
		...theme.typography.subtitle2,
		color: '#000000',
		padding: '3.625rem 0rem',
	},
	upgradeSection: {
		marginTop: '2.6rem',
		border: '1px solid #6418C3',
		height: '4rem',
		paddingTop: '0.7rem',
		paddingLeft: '1rem',
		paddingRight: '1rem',
		paddingBottom: '0.5rem',
		display: 'flex',
		direction: 'row',
		backgroundColor: 'transparent',
		position: 'relative',
		width: '22rem',
	},
	plan: {
		color: theme.palette.primary.main,
		...theme.typography.body2,
		fontWeight: 600,
		marginLeft: '0.25rem',
		textTransform: 'capitalize',
	},
	expired: {
		color: 'red',
		fontSize: '0.8rem',
		textAlign: 'left',
	},
	upgradeButton: {
		color: theme.palette.common.white,
		...theme.typography.subtitle2,
		fontWeight: 500,
		backgroundColor: '#6318c3',
		'&:hover': {
			backgroundColor: '#6318c3',
		},
		padding: '.4rem 1.2rem',
	},
	cardStyle: {
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		boxShadow: ' 0px 9px 28px #CCCCCC2E',
		borderRadius: '0.5rem',
		height: '32rem',
		border: '1px solid #E4E4E2 '
	},
	cardTitle: {
		fontSize: '1.1rem',
		fontFamily: 'Poppins',
		fontWeight: '600',
		color: ' #222222',
		textTransform: 'capitalize',
		marginBottom: '.8rem',
	},
	cardSubHeader: {
		fontSize: '1rem',
		fontFamily: 'Poppins',
		fontWeight: 'normal',
		color: ' #222222',
		textTransform: 'capitalize',
		opacity: 0.6,
		textAlign: 'center',
	},
	divider: {
		margin: '0 auto',
		width: '90%',
		marginTop: '-0.9rem',
	},
	titleStyle: {
		paddingLeft: '3rem',
		paddingTop: '1.2rem',
		paddingBottom: '0.66rem',
		color: '#6418c3',
		fontSize: '1.2rem',
		fontFamily: 'Poppins',
		fontWeight: '600',
	},
	cardHeader: {
		display: 'flex',
		flexDirection: 'column',
		marginTop: '2rem'
	},
	bottomDivider: {
		margin: '1rem auto',
		width: '90%',
	}
}));
