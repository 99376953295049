import { Fragment, useEffect, useRef, useState } from 'react';
// local component
import ActivityCard from 'components/common/ActivityCard';
import AnalyticsCard from 'components/dashboard/Shared/AnalyticsCard';
// local assets
import avatarLogin from 'assets/images/adminPanel/avatarLogin.svg';
import { ReactComponent as NoOfUniqueUser } from 'assets/images/Dashboard/NoOfUniqueUser.svg';
// local styles
import styles from './index.module.scss';
import useSetUiLoadingError from 'hooks/useSetUiLoadingError';
import { Skeleton } from '@material-ui/lab';
import ActivitesLoader from './loader';
import { RESET_BOT_ACTIVITES_SUCCESS, RESET_TEAM_MEMBERS_MESSAGES_SUCCESS } from 'store/actions/types/dashboard';
import { useDispatch } from 'react-redux';
const Component = ({
  incidents,
  statusDurationValue,
  statusChannelValue,
  talkybotId,
  // action from redux
  getTeamMembersMessages,
  getTakeoverAndEscalation,
  getTeamHandover,
  getBotActivites,
  // state from reducer
  TeamMemberCardInfo,
  team_members_messages,
  //TODO: update state
  bot_activites,
}) => {
  const dispatch = useDispatch()
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [messagesCurrentPage, setMessagesCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingMessages, setLoadingMessages] = useState(false)
  const activitesListRef = useRef();
  const messagesListRef = useRef();

  // get current media query
  const [matches, setMatches] = useState(
    window.matchMedia('(max-width: 1600px)').matches
  );
  const [newContentLoaded, setNewContentLoaded] = useState(false);

  const [isLoadingBotActivites, successDataBotActivites, backendErrorBotActivites, setUiLoadingErrorBotActivites] =
    useSetUiLoadingError();
  const [isLoadingTeamMembersMessages, successDataTeamMembersMessages, backendErrorTeamMembersMessages, setUiLoadingErrorTeamMembersMessages] =
    useSetUiLoadingError();
  const [isLoadingGetTakeoverAndEscalation, successDataGetTakeoverAndEscalation, backendErrorGetTakeoverAndEscalation, setUiLoadingErrorGetTakeoverAndEscalation] =
    useSetUiLoadingError();
  const [isLoadingTeamHandover, successDataTeamHandover, backendErrorTeamHandover, setUiLoadingErrorTeamHandover] =
    useSetUiLoadingError();
  useEffect(() => {
    window
      .matchMedia('(max-width: 1600px)')
      .addEventListener('change', (e) => setMatches(e.matches));
    return () => {
      dispatch({ type: RESET_BOT_ACTIVITES_SUCCESS })
      dispatch({ type: RESET_TEAM_MEMBERS_MESSAGES_SUCCESS })
    }
  }, []);
  useEffect(() => {
    getTakeoverAndEscalation({
      talkyBotID: talkybotId,
      duration_filter: statusDurationValue,
      provider_filter: statusChannelValue,
      setUiLoadingError: setUiLoadingErrorGetTakeoverAndEscalation
    })
    getTeamHandover({
      talkyBotID: talkybotId,
      duration_filter: statusDurationValue,
      provider_filter: statusChannelValue,
      setUiLoadingError: setUiLoadingErrorTeamHandover
    })
  }, [statusDurationValue, statusChannelValue])
  // start Pagination fun
  useEffect(() => {
    getTeamMembersMessages({
      talkyBotID: talkybotId,
      page: messagesCurrentPage,
      duration_filter: statusDurationValue,
      provider_filter: statusChannelValue,
      setUiLoadingError: setUiLoadingErrorTeamMembersMessages
    })
    setLoadingMessages(false)
  }, [statusDurationValue, statusChannelValue, messagesCurrentPage])

  useEffect(() => {
    getBotActivites({
      talkyBotID: talkybotId,
      page: currentPage,
      duration_filter: statusDurationValue,
      provider_filter: statusChannelValue,
      setUiLoadingError: setUiLoadingErrorBotActivites
    })
    setLoading(false)
  }, [statusDurationValue, statusChannelValue, currentPage])

  const onScrollActivites = () => {
    if (activitesListRef.current && bot_activites?.meta?.next_page !== null) {
      const { scrollTop, scrollHeight, clientHeight } = activitesListRef.current;
      if (Math.floor(scrollHeight - scrollTop) === clientHeight && bot_activites?.meta?.current_page < bot_activites?.meta?.total_pages) {
        setLoading(true);
        setNewContentLoaded(true)
        setCurrentPage((prev) => prev + 1);
      }
    }
  };
  const onScrollMessages = () => {
    if (messagesListRef.current && team_members_messages?.meta?.next_page !== null) {
      const { scrollTop, scrollHeight, clientHeight } = messagesListRef.current;
      if (Math.floor(scrollHeight - scrollTop) === clientHeight && team_members_messages?.meta?.current_page < team_members_messages?.meta?.total_pages) {
        setLoadingMessages(true);
        setMessagesCurrentPage((prev) => prev + 1);
      }
    }
  };
  // end  Pagination fun
  return (
    <div className={styles.Container}>
      {
        TeamMemberCardInfo.map((card) => (
          <Fragment key={card.id}>
            <AnalyticsCard
              cardInfo={card}
              isLoading={isLoadingGetTakeoverAndEscalation}
            />
          </Fragment>
        ))
      }
      {/* bot activites */}
      {
        isLoadingBotActivites && !newContentLoaded ? (
          <Skeleton className={styles.BotActivitesSkeleton} variant="rect" animation="wave" height={matches ? 430 : 690} />
        ) : (
          <div className={styles.ActivitesContainer}>
            <h3>Team Member Activities</h3>
            {
              bot_activites?.data?.length > 0 ? (
                <div className={styles.listactivities} onScroll={() => onScrollActivites()} ref={activitesListRef}>
                  {bot_activites?.data?.map((incident, index) => (
                    <ActivityCard
                      userAvatar={incident?.attributes?.user?.avatar}
                      key={incident.id}
                      id={incident.id}
                      userName={incident.attributes.user_name}
                      personName={incident.attributes.person_name}
                      action={incident.attributes.action}
                      content={incident.attributes.content}
                      byPerson={incident.attributes.by_person}
                      byBot={incident.attributes.by_bot}
                      type={incident.attributes.activity_type.name}
                    />
                  ))}
                  {loading && <ActivitesLoader />}
                </div>
              ) : (
                <div className={styles.noDataContainer}>
                  <NoOfUniqueUser />
                </div>
              )
            }
          </div>
        )
      }

      {/* team member messages */}
      {
        isLoadingTeamMembersMessages ? (
          <Skeleton className={styles.TeamMemberMessagesSkeleton} variant="rect" animation="wave" height={matches ? 571 : 819} />
        ) : (
          <div className={styles.TeamMemberMessageContainer}>
            <div className={styles.wrapper}>
              <h3>Team Member Messages</h3>
              <span className={styles.totalMsgs}>{team_members_messages?.total}</span>
            </div>
            {
              team_members_messages?.data?.length > 0 ? (
                <ul ref={messagesListRef} onScroll={() => onScrollMessages()}>
                  {
                    team_members_messages?.data?.map((member, i) => (
                      <li key={member?.id}>
                        <div className={styles.basicInfo}>
                          <img src={member?.member_avatar || avatarLogin} alt="person" />
                          <span className={styles.name}>{member?.member_name}</span>
                        </div>
                        <span className={styles.numberOfMsg}>
                          {member?.number_of_messages} Msgs
                        </span>
                      </li>
                    ))
                  }
                  {loadingMessages && <ActivitesLoader />}
                </ul>
              ) : (
                <div className={styles.noDataContainer}>
                  <NoOfUniqueUser />
                </div>
              )
            }
          </div>
        )
      }
    </div>
  )
}
export default Component