import styles from './styles.module.css';
import {
	Container,
	Avatar,
	Grid,
	Collapse,
	Hidden,
	useMediaQuery,
	Box,
	Menu,
	MenuItem,
	Typography,
} from '@material-ui/core';
import { NavLink, Link, useRouteMatch, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import logo from 'assets/images/landingImgs/newLogo.svg';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme) => ({
	root: {},
	navStyle: {
		position: 'absolute',
		top: 0,
		width: '100%',
		zIndex: 54554,
		padding: '2.5rem 0px ',
	},
	navContent: {
		[theme.breakpoints.down('sm')]: {
			backgroundColor: '#ffffff',
			paddingBottom: '1.875rem',
			borderRadius: '0.625rem',
			paddingTop: '0.9375rem',
		},
	},
	navItem: {
		textDecoration: 'none',

		fontSize: '1.25rem',
		lineHeight: '1.5rem',
		fontWeight: 600,

		letterSpacing: '0.9px',
		color: '#43348B',
		textTransform: 'uppercase',
	},
	navLogo: {
		width: '11.1875rem',
		height: '4.1875rem',
		// marginBottom: '1rem',
	},
	tryNowBtn: {
		backgroundColor: theme.palette.background.primary,
		color: theme.palette.text.white,
		padding: '0.625rem',
		boxShadow: '0px 3px 6px #00000070',
		borderRadius: '4px',
	},
	avatar: {
		backgroundColor: theme.palette.background.primary,
		cursor: 'pointer',
	},
	containerStyle: {
		[theme.breakpoints.up('md')]: {
			paddingRight: theme.spacing(15),
			paddingLeft: theme.spacing(15),
		},
		paddingRight: theme.spacing(4),
	},
}));

/**
 *
 * @returns
 */

const Component = ({ auth, logUserOut }) => {
	// alert(auth);
	const theme = useTheme();
	const classes = useStyles();
	const matches = useMediaQuery(theme.breakpoints.up('md'));
	const { path, url } = useRouteMatch();

	useEffect(() => {
		if (matches) {
			setOpenMenu(true);
		} else {
			setOpenMenu(false);
		}
	}, [matches]);

	// avatar
	const [avatarChar, setAvatarChar] = useState('');
	useEffect(() => {
		let arr = [];
		arr = auth?.user?.user_profile?.name?.split(' ');
		const chars = arr
			? arr.length > 1
				? arr[0][0] + arr[1][0]
				: arr.length == 1
					? arr[0][0]
					: ''
			: '';
		setAvatarChar(chars);
	}, [auth]);

	const [openMenu, setOpenMenu] = useState(false);
	const handleClickIconMenu = () => {
		setOpenMenu(!openMenu);
	};
	const activeStyle = {
		// backgroundColor: "transparent",
		// color: "#0000FF",
		// padding: "0.9375rem",
		// borderRadius: "5px",
		// border: "1px solid #0000FF",
	};
	const activeStyleBtn = {
		// backgroundColor: "#0000FF",
		// color: "#FFFFFF",
		// padding: "0.9375rem",
		// borderRadius: "5px",
	};
	const dropdownStyle = {
		color: '#0000FF',
	};
	const [anchorEl, setAnchorEl] = useState(null);

	const handleCloseProfile = () => {
		setAnchorEl(null);
	};
	const handleClickProfile = (event) => {
		setAnchorEl(event.currentTarget);
	};
	// log out
	const handleLogOut = () => {
		setAnchorEl(null);
		logUserOut();
	};
	return (
		<nav className={classes.navStyle}>
			<Container className={classes.containerStyle} maxWidth={false}>
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignItems="center"
				>
					<Grid item md={3}>
						<NavLink activeStyle={activeStyleBtn} exact to={url}>
							<img
								className={classes.navLogo}
								src={logo}
								alt=""
							/>
						</NavLink>
					</Grid>
					<Hidden mdUp>
						<Grid item>
							<MenuIcon onClick={handleClickIconMenu} />
							<Collapse in={openMenu}></Collapse>
						</Grid>
					</Hidden>

					{openMenu && (
						<Grid
							item
							container
							justifyContent="space-between"
							alignItems="center"
							direction="row"
							md={8}
							spacing={1}
							// wrap="nowrap"
							className={classes.navContent}
						>
							<Grid item xs={12} md={1}>
								<NavLink
									activeStyle={activeStyle}
									className={classes.navItem}
									aria-current="page"
									exact
									// onClick={()=>setOpenMenu(false)}
									to={`${url}`}
								>
									Home
								</NavLink>
							</Grid>
							<Grid item xs={12} md={1}>
								<NavLink
									activeStyle={activeStyle}
									className={classes.navItem}
									to={`${url}/about`}
								// onClick={()=>setOpenMenu(false)}
								>
									ABOUT
								</NavLink>
							</Grid>
							<Grid item xs={12} md={1}>
								<NavLink
									activeStyle={activeStyle}
									className={classes.navItem}
									to={`${url}/features`}
								// onClick={()=>setOpenMenu(false)}
								>
									Features
								</NavLink>
							</Grid>
							{/* <Grid item xs={12} md={1}>
								<NavLink
									activeStyle={activeStyle}
									className={classes.navItem}
									aria-current="page"
									to={`${url}/pricing`}
									// onClick={()=>setOpenMenu(false)}
								>
									Pricing
								</NavLink>
							</Grid> */}
							<Grid item xs={12} md={1}>
								<NavLink
									activeStyle={activeStyle}
									className={classes.navItem}
									aria-current="page"
									to={`${url}/contact`}
								// onClick={()=>setOpenMenu(false)}
								>
									contact
								</NavLink>
							</Grid>

							{auth.isAuthenticated === false && (
								<>
									<Grid item align="right" xs={12} md={1}>
										<NavLink
											activeStyle={activeStyle}
											className={classes.navItem}
											aria-current="page"
											to="/login"
										>
											Login
										</NavLink>
									</Grid>

									<Grid align="left" item xs={12} md={2}>
										<NavLink
											activeStyle={activeStyle}
											className={
												classes.navItem +
												' ' +
												classes.tryNowBtn
											}
											aria-current="page"
											to="/home/contact"
										>
											try now
										</NavLink>
									</Grid>
								</>
							)}

							{auth.isAuthenticated === true && (
								<Grid item align="right" xs={2}>
									{auth?.user?.attachment_url ? (
										<Avatar
											onClick={handleClickProfile}
											alt="user profile"
											src={auth?.user?.attachment_url}
											className={classes.avatar}
										// className={classes.large}
										/>
									) : (
										<Avatar
											className={classes.avatar}
											onClick={handleClickProfile}
										>
											{avatarChar}
										</Avatar>
									)}

									{/* 
									<Grid
										style={{ cursor: 'pointer' }}
										onClick={handleClickProfile}
										container
										justifyContent="space-between"
										alignItems="center"
									>
										 <Grid item sm={5} md={6}>
											<Typography>
												Hi ,<span>ahmed</span>
											</Typography>
										</Grid> 
										{/* <Grid item align="right" sm={5} md={6}>
											<Avatar>AM</Avatar>
										</Grid>
									</Grid> */}

									<Menu
										variant="menu"
										style={{
											zIndex: 59885632,
											display: 'inline-flex',
										}}
										id="simple-menu"
										anchorEl={anchorEl}
										keepMounted
										open={Boolean(anchorEl)}
										onClose={handleCloseProfile}
										anchorOrigin={{
											vertical: 'top',
											horizontal: 'right',
										}}
										transformOrigin={{
											vertical: 'top',
											horizontal: 'right',
										}}
									>
										<Link
											to={`/users/${auth.user.id}`}
											onClick={handleCloseProfile}
										>
											<MenuItem>Profile</MenuItem>
										</Link>
										<Link
											to="/talkybots"
											onClick={handleCloseProfile}
										>
											<MenuItem>TalkyBots</MenuItem>
										</Link>

										<Link onClick={handleLogOut}>
											<MenuItem>Logout</MenuItem>
										</Link>
									</Menu>
								</Grid>
							)}
						</Grid>
					)}
				</Grid>
			</Container>
		</nav>
	);
};

export default Component;
