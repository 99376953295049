import React from 'react';
import {
	Card,
	CardHeader,
	CardMedia,
	makeStyles,
	Divider,
	Typography,
} from '@material-ui/core';
import styles from './index.module.scss';
import { ReactComponent as Facebook } from 'assets/icons/Usage/Facebook.svg';
import { ReactComponent as Whatsapp } from 'assets/icons/Usage/Whatsapp.svg';
import { ReactComponent as Instagram } from 'assets/images/Dashboard/instagram.svg';

import CircularProgressWithLabel from 'components/common/CircularProgressWithLabel/CircularProgressWithLabel';
import usageEngagements from 'assets/images/usageEngagements.svg';


function EngagementsCard({ engagements, formatNumberWithCommas }) {
	const classes = useStyles();

	const progressColor = Math.round(((engagements?.total_number_of_engagments - engagements?.remaining_engagments) / engagements?.total_number_of_engagments) * 100) > 75 ? 'red' : 'green';
	return (
		<Card elevation={0} className={classes.cardStyle}>
				<Typography className={classes.titleStyle}>
					MAU
				</Typography>
				<CardMedia
        image={usageEngagements}
					style={{
						width: '1rem',
						height: '1rem',
						position: 'relative',
						top: '-2.2rem',
						left: '1rem',
						marginRight: '1rem',
					}}
				/>

				<Divider className={classes.divider} />
				<CardHeader
					classes={{
						title: classes.cardTitle,
						subheader: classes.cardSubHeader,
					}}
					className={classes.cardHeader}
					avatar={
						<CircularProgressWithLabel
							minExpectedValue={0}
							maxExpectedValue={engagements?.total_number_of_engagments}
							valueOfProgress={engagements?.total_number_of_engagments - engagements?.remaining_engagments}
							size="6.5rem"
							thickness={4}
							color={progressColor}
						/>
					}
					title={`${formatNumberWithCommas(engagements?.total_number_of_engagments - engagements?.remaining_engagments)} of ${formatNumberWithCommas(engagements?.total_number_of_engagments)} Engagements`}
					subheader={`${formatNumberWithCommas(engagements?.remaining_engagments)} Engagements left`}
				/>
				<Divider className={classes.bottomDivider} />
				<div className={styles.channelsContainer}>
					<div className={styles.singleChannelContainer}>
					<div className={styles.iconNameContainer}>
					<Facebook className={styles.facebookIcon} />
					<span>Facebook</span>
					</div>
					<span>{formatNumberWithCommas(engagements?.facebook)}</span>
					</div>
					<div className={styles.singleChannelContainer}>
					<div className={styles.iconNameContainer}>
					<Instagram className={styles.channelIcon} />
					<span>Instagram</span>
					</div>
					<span>{formatNumberWithCommas(engagements?.instagram)}</span>
					</div>
					<div className={styles.singleChannelContainer}>
					<div className={styles.iconNameContainer}>
					<Whatsapp className={styles.channelIcon} />
					<span>Whatsapp</span>
					</div>
					<span>{formatNumberWithCommas(engagements?.whatsapp)}</span>
					</div>
				</div>
			</Card>
	);
}

export default EngagementsCard;

const useStyles = makeStyles(() => ({
	cardStyle: {
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		boxShadow: ' 0px 9px 28px #CCCCCC2E',
		borderRadius: '0.5rem',
		height: '32rem',
		border: '1px solid #E4E4E2 '
	},
	cardTitle: {
		fontSize: '1.1rem',
		fontFamily: 'Poppins',
		fontWeight: '600',
		color: ' #222222',
		textTransform: 'capitalize',
		marginBottom: '.8rem',
	},
	cardSubHeader: {
		fontSize: '1rem',
		fontFamily: 'Poppins',
		fontWeight: 'normal',
		color: ' #222222',
		textTransform: 'capitalize',
		opacity: 0.6,
		textAlign: 'center',
	},
	divider: {
		margin: '0 auto',
		width: '90%',
		marginTop: '-0.9rem',
	},
	titleStyle: {
		paddingLeft: '3rem',
		paddingTop: '1.2rem',
		paddingBottom: '0.66rem',
		color: '#6418c3',
		fontSize: '1.2rem',
		fontFamily: 'Poppins',
		fontWeight: '600',
	},
	cardHeader: {
		display: 'flex',
		flexDirection: 'column',
		marginTop: '2rem'
	},
  bottomDivider: {
		margin: '1rem auto',
		width: '90%',
	}
}));
