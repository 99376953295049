import React, { useState } from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { Controller } from 'react-hook-form';

import { Checkbox, TextField } from '@material-ui/core';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { log } from 'debug';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ControlledAutocompleteInput = ({
	error = false,
	hookFormField,
	options = [],
	getOptionLabel,
	renderOption,
	onChange: ignored,
	placeholder,
	limitTags,
	multiple,
	// style for texfield in autocomplete
	style,
	autocompleteStyle,
	...props
}) => {
	const { onChange, ...restFormField } = hookFormField;

	return (
		<Autocomplete
			multiple={multiple}
			limitTags={limitTags}
			{...restFormField}
			{...props}
			classes={autocompleteStyle}
			onChange={(e, data) => hookFormField.onChange(data)}
			options={options}
			getOptionLabel={getOptionLabel}
			disableClearable
			// filterSelectedOptions
			renderOption={renderOption}
			renderInput={(params) => (
				<TextField
					{...params}
					placeholder={placeholder}
					variant="outlined"
					className={style}
					error={!!error}
				/>
			)}
		/>
	);
};
export default ControlledAutocompleteInput;
