import React, { useRef, useState } from 'react';
import {
	ProSidebar,
	Menu,
	MenuItem,
	SubMenu,
	SidebarHeader,
	SidebarContent,
} from 'react-pro-sidebar';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import Apps from 'constants/applications';
import { ReactComponent as ToLeftArrowIcon } from 'assets/icons/down-arrow.svg';
import { ReactComponent as ToRightArrowIcon } from 'assets/icons/to_right_arrow.svg';
import { ReactComponent as DashboardIcon } from 'assets/icons/dashboard_1.svg';
import { ReactComponent as ContactsIcon } from 'assets/icons/contact-book.svg';
import { ReactComponent as BotsBuilderIcon } from 'assets/icons/robot.svg';
import { ReactComponent as ChatIcon } from 'assets/icons/chat_2.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/setting.svg';
import { ReactComponent as StoreIcon } from 'assets/icons/appstore.svg';
import { ReactComponent as BotGatesIcon } from 'assets/icons/BotGatesIcon.svg';
import Bot from 'assets/icons/myBotAvatar.svg';
import 'react-pro-sidebar/dist/css/styles.css';
import {
	CAN_CREATE_BOT,
	CAN_VIEW_BOT_BUILDER,
	CAN_VIEW_CRM,
	CAN_VIEW_DASHBOARD,
	CAN_VIEW_SETTINGS,
	CAN_VIEW_STORE,
} from 'constants/permissions';
import UserHasPermission from 'hoc/UserHasPermission';
import { Avatar, Box, Tooltip, Typography, Divider } from '@material-ui/core';
import Styles from './Component.module.css';

const Component = ({ collapseMenu, handleCollapse, width, style }) => {
	const collapseIconStyle = {
		lineHeight: '2rem',
	};

	const classes = useStyles();
	const location = useLocation();
	const history = useHistory();
	const collapseButton = useRef();

	const activeTalkybot = useSelector((state) => state.activeTalkybot);
	const installedApplications =
		activeTalkybot?.attributes?.installed_applications;

	const dashboardPath = `/talkybots/${activeTalkybot?.id}/dashboard`;
	const CRMPath = `/talkybots/${activeTalkybot?.id}/contacts`;
	const BOTGATESPath = `/talkybots/${activeTalkybot?.id}/bot-gates`;
	const FAQSPath = `/talkybots/${activeTalkybot?.id}/bot-builder/faqs`;
	const BotBuilder = `/talkybots/${activeTalkybot?.id}/bot-builder`;
	const storePath = `/talkybots/${activeTalkybot?.id}/store`;
	const settingsPath = `/talkybots/${activeTalkybot?.id}/settings`;
	const StopWordsPath = `/talkybots/${activeTalkybot?.id}/bot-builder/stop-words`;

	const ErrorFallback = ({ error }) => {
		return (
			<div role="alert">
				<p>Something went wrong:</p>
				<pre>{error.message}</pre>
			</div>
		);
	};

	const handleClickOnBotBuilderIcon = () => {
		if (!collapseMenu) {
			return;
		}
		collapseButton.current.click();
	};
	return (
		<ErrorBoundary FallbackComponent={ErrorFallback}>
			<div
				style={{
					position: 'fixed',
					transition: 'linear all .5s',
					width,
					...style,
				}}
			>
				<div className={Styles['notch-container']}>
					<div
						ref={collapseButton}
						className={Styles.collapseButtonContainerStyle}
						style={{
							position: 'absolute',
							right: '-.8rem',
						}}
						onClick={handleCollapse}
					>
						<span style={collapseIconStyle}>
							{!collapseMenu ? (
								<ToLeftArrowIcon fill="black" />
							) : (
								<ToRightArrowIcon fill="black" />
							)}
						</span>
					</div>
					<ProSidebar collapsed={collapseMenu}>
						<SidebarHeader className={classes.sidebarHeader}>
							<Avatar
								className={classes.botAvatar}
								src={
									activeTalkybot?.image
										? activeTalkybot?.image
										: Bot
								}
							/>

							{!collapseMenu && (
								<Typography className={classes.headerBotName}>
									{activeTalkybot?.name}
								</Typography>
							)}
						</SidebarHeader>
						<Divider width="100%" />

						<SidebarContent>
							<Menu
								style={{
									marginTop: '15px',
								}}
							>
								<UserHasPermission action={CAN_VIEW_DASHBOARD}>
									<Tooltip
										disableHoverListener={!collapseMenu}
										classes={{
											tooltip:
												location.pathname ===
													dashboardPath
													? classes.tooltip
													: classes.tooltipNotActive,
											arrow:
												location.pathname ===
													dashboardPath
													? classes.tooltipArrow
													: classes.tooltipArrowNotActive,
										}}
										title="Dashboard"
										placement="right"
										arrow
									>
										<MenuItem
											icon={
												<DashboardIcon
													fill={
														location.pathname ===
															dashboardPath
															? '#6418C3'
															: '#626262'
													}
													className="sideIcon"
													width="1.5rem"
													heigh="1.5rem"
												/>
											}
											className={classes.itemStyle}
										// style={{ color: 'red' }}
										>
											<Link
												style={{
													color:
														location.pathname ===
															dashboardPath
															? '#6418C3'
															: '#626262',
												}}
												to={dashboardPath}
											>
												{!collapseMenu && 'Dashboard'}
											</Link>
										</MenuItem>
									</Tooltip>
								</UserHasPermission>

								<UserHasPermission
									action={CAN_VIEW_CRM}
									app={Apps.CRM.applicationType}
								>
									<Tooltip
										disableHoverListener={!collapseMenu}
										classes={{
											tooltip: location.pathname.includes(
												'/contacts'
											)
												? classes.tooltip
												: classes.tooltipNotActive,
											arrow: location.pathname.includes(
												'/contacts'
											)
												? classes.tooltipArrow
												: classes.tooltipArrowNotActive,
										}}
										title="Contacts"
										placement="right"
										arrow
									>
										<MenuItem
											icon={
												<ContactsIcon
													fill={
														location.pathname.includes(
															'/contacts'
														)
															? '#6418C3'
															: '#626262'
													}
													className="sideIcon"
													width="1.5rem"
													heigh="1.5rem"
												/>
											}
											className={classes.itemStyle}
										>
											<Link
												to={CRMPath}
												style={{
													color: location.pathname.includes(
														'/contacts'
													)
														? '#6418C3'
														: '#626262',
												}}
											>
												{!collapseMenu && 'Contacts'}
											</Link>
										</MenuItem>
									</Tooltip>
								</UserHasPermission>

								<UserHasPermission
									action={CAN_VIEW_BOT_BUILDER}
									app={Apps.BotBuilder.applicationType}
								>
									<Tooltip
										disableHoverListener={!collapseMenu}
										classes={{
											tooltip: location.pathname.includes(
												'/bot-builder'
											)
												? classes.tooltip
												: classes.tooltipNotActive,
											arrow: location.pathname.includes(
												'/bot-builder'
											)
												? classes.tooltipArrow
												: classes.tooltipArrowNotActive,
										}}
										title="Bot Builder"
										placement="right"
										arrow
									>
										<MenuItem
											icon={
												<BotsBuilderIcon
													fill={
														location.pathname.includes(
															'/bot-builder'
														)
															? '#6418C3'
															: '#626262'
													}
													className="sideIcon"
													width="1.5rem"
													heigh="1.5rem"
												/>
											}
											className={classes.itemStyle}
										>
											<Link
												to={BotBuilder}
												style={{
													color: location.pathname.includes(
														'/bot-builder'
													)
														? '#6418C3'
														: '#626262',
												}}
											>
												{!collapseMenu && 'Bot Builder'}
											</Link>
										</MenuItem>
									</Tooltip>
								</UserHasPermission>
								{/* <UserHasPermission
									action={CAN_VIEW_CRM}
									app={Apps.CRM.applicationType}
								> */}
								<Tooltip
									disableHoverListener={!collapseMenu}
									classes={{
										tooltip:
											location.pathname === BOTGATESPath
												? classes.tooltip
												: classes.tooltipNotActive,
										arrow:
											location.pathname === BOTGATESPath
												? classes.tooltipArrow
												: classes.tooltipArrowNotActive,
									}}
									title="Bot Gates"
									placement="right"
									arrow
								>
									<MenuItem
										icon={
											<BotGatesIcon
												fill={
													location.pathname ===
														BOTGATESPath
														? '#6418C3'
														: '#626262'
												}
												className="sideIcon"
												width="1.5rem"
												heigh="1.5rem"
											/>
										}
										className={classes.itemStyle}
									>
										<Link
											to={BOTGATESPath}
											style={{
												color:
													location.pathname ===
														BOTGATESPath
														? '#6418C3'
														: '#626262',
											}}
										>
											{!collapseMenu && 'Bot Gates'}
										</Link>
									</MenuItem>
								</Tooltip>
								{/* </UserHasPermission> */}

								{installedApplications
									?.filter(
										(app) =>
											app?.attributes
												?.applicationable_type !==
											'BotBuilderApp' &&
											app?.attributes
												?.applicationable_type !==
											'CrmApp' &&
											app?.attributes?.visible
									)
									?.map((app) => {
										return (
											<UserHasPermission
												action={`CAN_VIEW_${app?.attributes?.name?.toUpperCase()}`}
												app={
													app.attributes
														?.applicationable_type
												}
											>
												<Tooltip
													disableHoverListener={
														!collapseMenu
													}
													classes={{
														tooltip:
															location.pathname ===
																`/talkybots/${activeTalkybot?.id
																}/${app?.attributes?.name?.toLowerCase()}s`
																? classes.tooltip
																: classes.tooltipNotActive,
														arrow:
															location.pathname ===
																`/talkybots/${activeTalkybot?.id
																}/${app?.attributes?.name?.toLowerCase()}s`
																? classes.tooltipArrow
																: classes.tooltipArrowNotActive,
													}}
													title={
														app?.attributes?.name
													}
													placement="right"
													arrow
												>
													<MenuItem
														icon={
															<ChatIcon
																fill={
																	location.pathname ===
																		`/talkybots/${activeTalkybot?.id
																		}/${app?.attributes?.name?.toLowerCase()}s`
																		? '#6418C3'
																		: '#626262'
																}
																className="sideIcon"
																width="1.5rem"
																heigh="1.5rem"
															/>
														}
														className={
															classes.itemStyle
														}
													>
														<Link
															to={`/talkybots/${activeTalkybot?.id
																}/${app?.attributes?.name?.toLowerCase()}s`}
															style={{
																color:
																	location.pathname ===
																		`/talkybots/${activeTalkybot?.id
																		}/${app?.attributes?.name?.toLowerCase()}s`
																		? '#6418C3'
																		: '#626262',
															}}
														>
															{!collapseMenu &&
																app?.attributes
																	?.name}
														</Link>
													</MenuItem>
												</Tooltip>
											</UserHasPermission>
										);
									})}
								{/* ********** Note:: if u need to show store un comment below ***************** */}
								{/* <UserHasPermission action={CAN_VIEW_STORE}>
									<Tooltip
										disableHoverListener={!collapseMenu}
										classes={{
											tooltip: location.pathname.includes(
												'/store'
											)
												? classes.tooltip
												: classes.tooltipNotActive,
											arrow: location.pathname.includes(
												'/store'
											)
												? classes.tooltipArrow
												: classes.tooltipArrowNotActive,
										}}
										title="Store"
										placement="right"
										arrow
									>
										<MenuItem
											icon={
												<StoreIcon
													fill={
														location.pathname.includes(
															'/store'
														)
															? '#6418C3'
															: '#626262'
													}
													className="sideIcon"
													width="1.5rem"
													heigh="1.5rem"
												/>
											}
											className={classes.itemStyle}
										>
											<Link
												to={storePath}
												style={{
													color: location.pathname.includes(
														'/store'
													)
														? '#6418C3'
														: '#626262',
												}}
											>
												{!collapseMenu && 'Store'}
											</Link>
										</MenuItem>
									</Tooltip>
								</UserHasPermission> */}

								<UserHasPermission action={CAN_VIEW_SETTINGS}>
									<Tooltip
										disableHoverListener={!collapseMenu}
										classes={{
											tooltip: location.pathname.includes(
												'/settings'
											)
												? classes.tooltip
												: classes.tooltipNotActive,
											arrow: location.pathname.includes(
												'/settings'
											)
												? classes.tooltipArrow
												: classes.tooltipArrowNotActive,
										}}
										title="Settings"
										placement="right"
										arrow
									>
										<MenuItem
											icon={
												<SettingsIcon
													fill={
														location.pathname.includes(
															'/settings'
														)
															? '#6418C3'
															: '#626262'
													}
													className="sideIcon"
													width="1.5rem"
													heigh="1.5rem"
												/>
											}
											className={classes.itemStyle}
										>
											<Link
												to={settingsPath}
												style={{
													color: location.pathname.includes(
														'/settings'
													)
														? '#6418C3'
														: '#626262',
												}}
											>
												{!collapseMenu && 'Settings'}
											</Link>
										</MenuItem>
									</Tooltip>
								</UserHasPermission>
							</Menu>
						</SidebarContent>
					</ProSidebar>
				</div>
			</div>
		</ErrorBoundary>
	);
};

export default Component;

const useStyles = makeStyles((theme) => ({
	itemStyle: {
		fontSize: '1.25rem ',
		// padding: window.innerWidth > 1500 ? '0.3125rem' : '0rem',
		padding: '0px 0.3125rem',
		fontFamily: 'Poppins',
		'& .pro-inner-item': {
			padding: '.8rem .5rem !important',
			margin: 'auto',
		},
	},
	sideIcon: {
		// fontSize: '1rem',
	},
	sidebarHeader: {
		borderBottom: 'none !important',
		display: 'flex',
		// justifyContent: 'center',
		alignItems: 'center',
		marginTop: '3rem',
		paddingBottom: '1rem',
		gap: '.5rem',
		marginLeft: '.8rem',
	},
	headerBotName: {
		...theme.typography.subtitle2_1,
		color: theme.palette.common.black,
		overflowWrap: 'anywhere',
	},
	botAvatar: {
		width: theme.spacing(40),
		height: theme.spacing(40),
	},
	tooltip: {
		...theme.typography.subtitle6,
		padding: '.5rem 1rem',
		backgroundColor: theme.palette.primary.light,
		marginLeft: '0rem',
	},
	tooltipArrow: {
		// fontSize: '1rem',
		color: theme.palette.primary.light,
	},
	tooltipNotActive: {
		...theme.typography.subtitle6,
		padding: '.5rem 1rem',
		// backgroundColor: theme.palette.primary.light,
		marginLeft: '0rem',
	},
	tooltipArrowNotActive: {
		// fontSize: '1rem',
		// color: theme.palette.primary.light,
	},
}));
