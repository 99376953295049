import React, { useEffect } from 'react';
import NoData from 'assets/images/no-data.svg';
import GridContainer from 'components/common/Grid/GridContainer';
import { Typography, useTheme, makeStyles, Button } from '@material-ui/core';

import classNames from 'classnames';

const NoDataComponent = ({
	title = 'No Data !',
	documentTitle,
	image,
	width,
	height,
	comingSoon,
	size = 'sm',
	isSynonym,
	setOpenAddSynonymModal,
	plusIcon,
}) => {
	const theme = useTheme();

	const classes = useStyles();

	useEffect(() => {
		if (documentTitle) document.title = `Talkybot - ${documentTitle}`;
	}, []);

	const renderImage = () => {
		if (image) {
			return (
				<figure width={width} height={height}>
					<img
						// width="100%"
						width={width}
						// height="100%"
						height={height}
						src={image}
						alt="no data found"
					/>
				</figure>
			);
			// return image;
		}
		return (
			<img src={NoData} alt="no data" className={classes.noDataImage} />
		);
	};
	const rendercomingSoon = () => {
		if (comingSoon) {
			return (
				// <img
				// 	src={ComingSoonImage}
				// 	alt="no data"
				// 	className={classes.comingSoonImage}
				// />
				<div className={classes.comingSoonImage}>Comming Soon</div>
			);
		}
		return null;
	};
	const rootStyle = classNames([classes.root], {
		[classes.rootSizeSm]: size === 'sm',
		[classes.rootSizeLg]: size === 'lg',
		[classes.rootSizeMd]: size === 'md',
		[classes.rootSizeXs]: size === 'xs',
	});
	return (
		<GridContainer
			className={rootStyle}
			justifyContent="center"
			alignItems="center"
		>
			{rendercomingSoon()}
			{renderImage()}
			<Typography className={classes.title}>{title}</Typography>
			{isSynonym ? (
				<Button
				variant="contained"
				color="primary"
				className={classes.addSynonym}
				onClick={() => setOpenAddSynonymModal(true)}
			>
				<img
					src={plusIcon}
					alt="add topic flow"
					style={{ width: '0.8rem', marginRight: '0.3rem' }}
				/>
				Add Synonym
			</Button>
 			) : null}
		</GridContainer>
	);
};

export default NoDataComponent;

const useStyles = makeStyles((theme) => ({
	root: {
		flexDirection: 'column',
		background: 'white',
		// background: 'green',
		// height: '50vh',
		// height: '100vh',
		// height: calc(`100vh - ${theme.spacing(2)}px`),
		// height: 'calc(100vh - 360px)',
		// height: 'calc(100vh - 10rem)',
		width: '100%',
		position: 'relative',
		overflow: 'hidden',
		textAlign: 'center',
		marginTop: '25px'
	},
	rootSizeLg: {
		height: 'calc(100vh - 10rem)',
	},
	rootSizeSm: {
		// height: 'calc(100vh - 16rem)',
		height: 'calc(100vh - 25rem)',
	},
	rootSizeMd: {
		height: 'calc(100vh - 17rem)',
	},
	title: {
		...theme.typography.subtitle3,
		marginTop: theme.spacing(16),
	},
	noDataImage: {
		// 🛠 fixing the image 📸 sizes in small devices using ... material-ui breakpoints 👌
		marginTop: theme.spacing(20),
		[theme.breakpoints.down('md')]: {
			width: theme.spacing(250),
		},
		width: theme.spacing(255),
	},
	comingSoonImage: {
		position: 'absolute',
		right: '0',
		top: '0',
		zIndex: '100',
		color: 'white',
		fontSize: theme.spacing(18),
		textAlign: 'center',
		transform: 'rotate( 45deg) translate(27.5%, -40%)',
		minWidth: theme.spacing('290'),
		fontWeight: 'bold',
		fontStyle: 'italic',
		boxShadow: '0px 2px 2px 1px #1209096e',
		textShadow: '2px 2px 4px #5400007d',
		background:
			'radial-gradient(circle, rgb(255, 10, 0) 0%, rgb(200, 0, 0) 90%)',
	},
	rootSizeXs: {
		height: 'calc(100vh - 16rem)',
	},
	addSynonym: {
    borderRadius: '10px',
    padding: '11px 27px',
    '&:hover': {
      backgroundColor: '#B77DFF',
    },
		marginTop: '20px',
		fontSize: '16px'
  },
}));
