import {
	CLEAR_CURRENT_MEMBER,
	EDIT_MEMBER_PERMISSIONS_SUCCESS,
	EDIT_MEMBER_STATUS_SUCCESS,
	GET_INVITATION_STEPS_SUCCESS,
	GET_MEMBERS_SUCCESS,
	GET_MEMBER_SUCCESS,
} from 'store/actions/types/members';

export default (
	state = {
		members: null,
		currentMember: null,
		invitationSteps: [],
	},
	{ type, payload }
) => {
	switch (type) {
		// case INVITE_MEMBER_SUCCESS: {
		// 	return {
		// 		...state,
		// 		loading: false,
		// 		// members: null,
		// 		error: false,
		// 		success: true,
		// 	};
		// }

		case EDIT_MEMBER_STATUS_SUCCESS: {
			const { status } = payload;
			return {
				...state,
				currentMember: {
					...state.currentMember,
					status,
				},
			};
		}
		case EDIT_MEMBER_PERMISSIONS_SUCCESS: {
			return {
				...state,
				// members: null,
				currentMember: null,
			};
		}
		case GET_MEMBERS_SUCCESS: {
			const { members } = payload;
			return {
				...state,
				members,
			};
		}
		case GET_MEMBER_SUCCESS: {
			const { member } = payload;
			return {
				...state,
				currentMember: member,
			};
		}
		case CLEAR_CURRENT_MEMBER: {
			return {
				...state,
				currentMember: null,
				invitationSteps: [],
			};
		}

		case GET_INVITATION_STEPS_SUCCESS: {
			const { invitationSteps } = payload;
			return {
				...state,
				invitationSteps,
			};
		}
		default:
			return state;
	}
};
