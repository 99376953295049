import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	Typography,
	TableHead,
	TableRow,
	Paper,
	Container,
	Box,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
const useStyles = makeStyles((theme) => ({
	headerFrame: {
		textAlign: 'center',
		color: theme.palette.text.primary,
		margin: ' 0px auto',
		// maxWidth: "40%",
	},
	headerText: {
		...theme.typography.subtitle3,
		marginBottom: '0.625rem',
	},
	headerDesc: {
		...theme.typography.body4,
	},
	//   table
	tableContainer: {
		// minWidth: 650,
		margin: '6.25rem 0px  6.25rem 0px',
		border: '2px solid',
		borderRadius: '2.1875rem 0.5rem 3.875rem 0.5rem',
		// overflowY:"visible",
		overflow: 'auto',
		[theme.breakpoints.up('md')]: {
			overflow: 'visible ',
		},
	},
	headerBoxFirst: {
		[theme.breakpoints.up('md')]: {
			marginTop: '-17%',
		},
		marginTop: '-1.5rem',
		width: '12.375rem',
		padding: '1.5625rem .5rem',
		// margin:"auto",
		marginBottom: '0.9375rem',
		border: ' 2px solid',
		borderRadius: ' 0px 1.625rem',
		// transform:"translateY(-60%)",
		backgroundColor: theme.palette.background.white,
		textAlign: 'center',
	},
	headerBoxOthers: {
		[theme.breakpoints.up('md')]: {
			marginTop: '-17%',
		},
		marginTop: '-1.5rem',
		marginTop: '-1.5rem',
		width: '12.375rem',
		padding: '1.5625rem .5rem',
		margin: 'auto',
		marginBottom: '0.9375rem',
		border: ' 2px solid',
		borderRadius: ' 0px 1.625rem',
		// transform:"translateY(-60%)",

		backgroundColor: theme.palette.background.primary,
		color: theme.palette.text.white,
		textAlign: 'center',
	},
	tableHeader: {},
	headerCell: {
		border: 0,
		position: 'relative',
	},
	bodyCell: {
		paddingLeft: '3.125rem',
	},
	checkIcon: {
		...theme.typography.subtitle4,
		fontWeight: 'bold',
		color: theme.palette.icon.red,
	},
	clearIcon: {
		...theme.typography.subtitle4,
		color: theme.palette.icon.lightRed,
	},
	containerStyle: {
		[theme.breakpoints.up('md')]: {
			paddingRight: theme.spacing(15),
			paddingLeft: theme.spacing(15),
		},
		paddingRight: theme.spacing(3),
	},
}));

function createData(Features, Starter, Professional, Enterprise) {
	return { Features, Starter, Professional, Enterprise };
}
const rows = [
	createData('Messenger', true, false, true),
	createData('Ice cream sandwich', false, true, false),
	createData('Eclair', false, false, true),
	createData('Cupcake', true, true, true),
	createData('Gingerbread', false, true, false),
	createData('Gingerbread', false, true, false),
	createData('Gingerbread', false, true, false),
	createData('Gingerbread', false, true, false),
	createData('Gingerbread', false, true, false),
	createData('Gingerbread', false, true, false),
	createData('Gingerbread', false, true, false),
	createData('Gingerbread', false, true, false),
	createData('Gingerbread', false, true, false),
];

const PricingTable = () => {
	const classes = useStyles();
	return (
		<Paper elevation={0}>
			<Container className={classes.containerStyle} maxWidth={false}>
				{/* header */}
				<Paper className={classes.headerFrame} elevation={0}>
					<Typography className={classes.headerText}>
						Compare and choose
					</Typography>
					<Typography className={classes.headerDesc}>
						Lorem ipsum dolor sit amet, consectetuer adipiscing
						elit, <br /> sed diam nonummy nibh euismod tincidunt ut
						laoreet dolore
					</Typography>
				</Paper>
				{/* table */}
				<TableContainer className={classes.tableContainer}>
					<Table
						className={classes.tableContent}
						size="small"
						aria-label="a price table"
					>
						<TableHead className={classes.tableHeader}>
							<TableRow>
								<TableCell
									className={
										classes.headerCell +
										' ' +
										classes.bodyCell
									}
								>
									<Box className={classes.headerBoxFirst}>
										Features & benifts
									</Box>
								</TableCell>
								<TableCell
									className={classes.headerCell}
									align="right"
								>
									<Box className={classes.headerBoxOthers}>
										Starter
									</Box>
								</TableCell>
								<TableCell
									className={classes.headerCell}
									align="right"
								>
									<Box className={classes.headerBoxOthers}>
										Professional
									</Box>
								</TableCell>
								<TableCell
									className={classes.headerCell}
									align="right"
								>
									<Box className={classes.headerBoxOthers}>
										Enterprise
									</Box>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{rows.map((row) => (
								<TableRow key={row.name}>
									<TableCell
										className={classes.bodyCell}
										component="th"
										scope="row"
									>
										{row.Features}
									</TableCell>
									<TableCell align="center">
										{row.Starter && (
											<CheckIcon
												className={classes.checkIcon}
											/>
										)}
										{!row.Starter && (
											<ClearIcon
												className={classes.clearIcon}
											/>
										)}
									</TableCell>
									<TableCell align="center">
										{row.Professional && (
											<CheckIcon
												className={classes.checkIcon}
											/>
										)}
										{!row.Professional && (
											<ClearIcon
												className={classes.clearIcon}
											/>
										)}
									</TableCell>
									<TableCell align="center">
										{row.Enterprise && (
											<CheckIcon
												className={classes.checkIcon}
											/>
										)}
										{!row.Enterprise && (
											<ClearIcon
												className={classes.clearIcon}
											/>
										)}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Container>
		</Paper>
	);
};

export default PricingTable;
