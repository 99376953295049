import { Avatar, Badge, makeStyles } from '@material-ui/core';
import React from 'react';
import {
	IncomingMessageIcon,
	INCOMING_MESSAGE,
	INCOMING_CALL,
	IncomingCallIcon,
	MISSED_CALL,
	OUTGOING_MESSAGE,
	MissedCallIcon,
	OUTGOING_CALL,
	OutgoingCallIcon,
	OutgoingMessageIconn,
	ASSIGN_TEAM_MEMBER,
	AssignTeamMemberUrl,
	ADD_NOTE,
	EDIT_NOTE,
	AddNoteUrl,
	CHANGE_STATUS,
	ChangeStatusUrl,
	CREATE_PERSON,
	CreatePersonUrl,
	DELETE_NOTE,
	UpdatePersonUrl,
	DeleteNoteUrl,
	UPDATE_PERSON,
} from 'constants/activityTypes';

// import botAvatar from 'assets/icons/botAvatar.png';
import botAvatar from 'assets/images/logo_with_text.svg';

const useStyles = makeStyles((theme) => ({
	badgeContent: {
		fontSize: '0.8rem',
		opacity: 2,
		width: theme.spacing(25),
		height: theme.spacing(25),
	},
	userProfile: {
		width: theme.spacing(45),
		height: theme.spacing(45),
	},
}));

const renderTypeImage = (activityType) => {
	let imageSrc = '';
	switch (activityType) {
		case INCOMING_MESSAGE:
			imageSrc = IncomingMessageIcon;
			break;

		case OUTGOING_MESSAGE:
			imageSrc = OutgoingMessageIconn;

			break;

		case INCOMING_CALL:
			imageSrc = IncomingCallIcon;
			break;

		case OUTGOING_CALL:
			imageSrc = OutgoingCallIcon;
			break;

		case MISSED_CALL:
			imageSrc = MissedCallIcon;
			break;

		case ASSIGN_TEAM_MEMBER:
			imageSrc = AssignTeamMemberUrl;
			break;

		case ADD_NOTE:
			imageSrc = AddNoteUrl;
			break;
		case UPDATE_PERSON:
			imageSrc = UpdatePersonUrl;
			break;
		case EDIT_NOTE:
			imageSrc = AddNoteUrl;
			break;
		case DELETE_NOTE:
			imageSrc = DeleteNoteUrl;
			break;

		case CHANGE_STATUS:
			imageSrc = ChangeStatusUrl;
			break;

		case CREATE_PERSON:
			imageSrc = CreatePersonUrl;
			break;

		default:
			imageSrc = MissedCallIcon;
			break;
	}

	return imageSrc;
};

const ActivityTypeImage = ({ alt = '', type, userAvatar, byPerson, byBot }) => {
	const classes = useStyles();
	return (
		<>
			<Badge
				className={classes.badgeIconStyle}
				overlap="circular"
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				badgeContent={
					<Avatar
						className={classes.badgeContent}
						src={renderTypeImage(type)}
					/>
				}
			>
				{byBot ? (
					<Avatar className={classes.userProfile} src={botAvatar} />
				) : (
					<Avatar className={classes.userProfile} src={userAvatar} />
				)}
			</Badge>
		</>
	);
};

export default ActivityTypeImage;
