import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import {
	InputAdornment,
	InputLabel,
	MenuItem,
	TextField,
} from '@material-ui/core';

/**
 *
 * @param {*boolean : change the border color} error
 * @param {*string : plaseHolder of input} placeHolder
 * @param {*string: ex : text,number,password,email...} type
 * @param {*fun: on input change} onChange
 * @param {*value of input} value
 * @param {*defaultValue of input} defaultValue
 * @returns input field with type (type)
 */
const TextInput = ({
	hookFormField = {}, // react hook form fields (ref - onchange- onblur - value- name)
	error = false,
	placeHolder = 'Enter Your Text',
	type = 'text',
	iconPosition = '',
	icon,
	onChange = () => {},
	value,
	defaultValue,
	disabled,
	style,
	innerInputStyle,
	select = false,
	noBorder = false,
	options = [], // if input select - must be arr of obj - and every obj shape{label , value}
	withContainer = true,
	requiredSymbol = false,
	...props
}) => {
	const optionsWithPlaceHolder = [
		{ label: placeHolder, value: '' },
		...options,
	];
	const { ref, ...restFormField } = hookFormField;
	const classes = useStyles();
	const inputStyle = classNames([classes.inputContainer, style], {
		[classes.mainBorderColor]: !error,
		[classes.errorBorderColor]: error,
		[classes.noBorderColor]: noBorder,
		[classes.costumStyle]: style,
		[classes.inputContainer]: !style,
		[classes.disabledField]: disabled,
	});
	const innerInputClassStyle = classNames([
		innerInputStyle,
		[classes.textStyle],
	]);
	const ICON =
		iconPosition === 'start'
			? {
					startAdornment: (
						<InputAdornment position="start">{icon}</InputAdornment>
					),
			  }
			: iconPosition === 'end'
			? {
					endAdornment: (
						<InputAdornment position="end">{icon}</InputAdornment>
					),
			  }
			: '';
	return (
		<TextField
			className={inputStyle}
			onChange={onChange}
			placeholder={placeHolder}
			type={type}
			defaultValue={defaultValue}
			disabled={disabled}
			value={value}
			error={
				// convert text to boolean
				!!error
			}
			select={select}
			InputProps={{
				...ICON,
				disableUnderline: true,
				className: innerInputClassStyle,
			}}
			// react hook form props
			inputRef={ref}
			{...restFormField}
			{...props}
		>
			{/* if input select render options */}

			{optionsWithPlaceHolder?.map((option) => (
				<MenuItem
					disabled={option?.value === ''}
					key={option.value}
					value={option.value}
				>
					{option.label}
				</MenuItem>
			))}
		</TextField>
	);
};

export default TextInput;

const useStyles = makeStyles((theme) => ({
	inputContainer: {
		width: '100%',
		height: '41px',
		backgroundColor: theme.palette.grey[50],
		borderRadius: theme.borderRadius.borderRadius8px,
		border: '0.5px solid #ffffff',
		color: theme.palette.common.black,
	},
	textStyle: {
		...theme.typography.textInput,
		fontSize: '1.12rem',
		borderRadius: '0.5rem',
		width: '100%',
		height: '100%',
		overflow: 'hidden',

		'& input': {
			borderRadius: '0.5rem',
			padding: theme.spacing(15),
		},
		// '& .MuiInputAdornment-positionEnd': { marginRight: theme.spacing(8) },
	},
	mainBorderColor: {
		borderColor: theme.palette.primary.main,
		width: '100%',
	},
	errorBorderColor: {
		borderColor: theme.palette.error.main,
		// marginBottom: theme.spacing(25),
	},
	noBorderColor: {
		border: 'none',
	},
	disabledField: {
		border: `none`,
		backgroundColor: '#ddd',
		color: theme.palette.common.black,
	},
	costumStyle: {
		height: 'auto',
	},
}));
