import * as botFieldsActions from 'store/actions/types/bot-fields';

export default (
	state = {
		fields: [],
		count: 0,
	},
	{ type, payload }
) => {
	switch (type) {
		case botFieldsActions.GET_ALL_BOT_FIELDS_SUCCESS: {
			return {
				...state,
				fields: payload.fields,
				count: payload.count,
			};
		}
		// case botFieldsActions.GET_SINGLE_BOT_FIELD_SUCCESS: {
		// 	return {
		// 		...state,
		// 	};
		// }
		case botFieldsActions.CREATE_BOT_FIELD_SUCCESS: {
			return {
				...state,
				fields: [...state.fields, payload.field],
				count: state.count + 1,
			};
		}
		case botFieldsActions.EDIT_BOT_FIELD_SUCCESS: {
			return {
				...state,
				fields: state.fields.map((field) => {
					if (field.id.toString() === payload?.field?.id.toString())
						return payload.field;
					return field;
				}),
			};
		}
		case botFieldsActions.DELETE_BOT_FIELD_SUCCESS: {
			return {
				...state,
				fields: state.fields.filter(
					({ id }) => id.toString() !== payload?.field?.id?.toString()
				),
				count: state.count - 1,
			};
		}

		default:
			return state;
	}
};
