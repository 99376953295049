import { useEffect } from 'react';
import { Paper } from '@material-ui/core';
import FeaturesHeader from './featuresHeader';
import BotBuilder from './botBuilder';
import Relationship from './relationship';
import LiveSupport from './liveSupport';
import BotAnalytics from './botAnalytics';
import UserManagement from './userManagement';
const Features = () => {
	useEffect(() => {
		// 👇️ scroll to top on page load
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}, []);

	return (
		<Paper elevation={0}>
			<FeaturesHeader />
			<Relationship />
			<BotBuilder />
			<LiveSupport />
			<BotAnalytics />
			<UserManagement />
		</Paper>
	);
};

export default Features;
