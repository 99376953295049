import {
	Avatar,
	Container,
	Divider,
	makeStyles,
	Tooltip,
	Typography,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import { ReactComponent as RobotIcon } from 'assets/icons/botIcon.svg';
import { ReactComponent as LogOutIcon } from 'assets/icons/logoutIcon.svg';
import NotificationIcon from 'assets/icons/notificationIcon.svg';
import NotificationIconActive from 'assets/icons/notificationIconActive.svg';
import NotificationIconGray from 'assets/icons/notificationIconGray.svg';
import { ReactComponent as UsersIcon } from 'assets/icons/team-member-icon11.svg';
import TalkybotLogo from 'assets/images/Mask Group 1@2x.png';
import GridContainer from 'components/common/Grid/GridContainer';
import SideNav from 'components/common/SideNav/Component';
import 'components/common/SideNav/Component.scss';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import CustomAvatar from 'components/common/CustomAvatar/Component';
import GridItem from 'components/common/Grid/GridItem';
import {
	deleteUserPermissionTokenAction,
	getCurrentUser,
	logUserOutAction,
} from 'store/actions/creators/auth';

import { CAN_VIEW_USAGE } from 'constants/permissions';

import { DEVICE_NOTIFICATION_PERMISSION } from 'constants/tokensTypes';
import UserHasPermission from 'hoc/UserHasPermission';

import PiechartActive from 'assets/icons/pie-chartActive.svg';
import PiechartNotActive from 'assets/icons/pie-chartNotActive.svg';
import { getContainerSize } from 'theme';
import { onMessageListener } from 'config/firebase';
import { unRegisterServiceWorker } from 'serviceWorker';

const MainLayout = ({ children, withTalkybot, isCollapsed }) => {
	const teamMemberPath = '/team-members';
	const talkybotsPath = '/talkybots';
	const landingPagePath = '/home';
	const notificationsPath = '/notifications';
	const usagePath = '/usage';

	// selector to get user notification status
	const userNotificationStatus = useSelector(
		(state) => state?.auth?.user?.notification_status
	);
	const theme = useTheme();
	const dispatch = useDispatch();
	// [TODO:] iscollape menu : make it dynamic from route file
	const [collapseMenu, setCollapseMenu] = useState(isCollapsed);
	const handleCollapse = () => {
		setCollapseMenu((prev) => !prev);
	};
	const matchesSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const location = useLocation();

	/************************/
	/* 	   UseEffects		   */
	/**********************/
	useEffect(() => {
		if (matchesSmScreen) {
			setCollapseMenu(matchesSmScreen);
		}
	}, [matchesSmScreen]);

	const classes = useStyles();
	// const { talkybotId } = useParams();
	const user = useSelector((state) => state?.auth?.user);
	// const activeTalkybot = useSelector((state) => state.activeTalkybot);
	const userData = user?.user_profile;

	const logoutWithDestroyFirebaseToken = () => {
		const currentToken = localStorage.getItem('firebase_token');
		const tokenConfig = {
			token_type: DEVICE_NOTIFICATION_PERMISSION,
			token_value: currentToken,
		};
		dispatch(deleteUserPermissionTokenAction(tokenConfig));
		dispatch(logUserOutAction);
		unRegisterServiceWorker()
	};
	// using it in usage icon chart
	// const MAU = meterInfo?.find(
	// 	(meter) => meter.nameOfMeter === 'Re-Engagements'
	// );

	// const normalise = (value) => (value * 100) / MAU?.totalNumOfMeter;

	// const usagePercentage = normalise(MAU?.currentNumOfMeter);

	/* ************ handle notification mark ****************** */
	const [notification, setNotification] = useState({ title: '', body: '' });

	onMessageListener()
		.then((payload) => {
			setNotification({
				title: payload?.notification?.title,
				body: payload?.notification?.body,
			});
		})
		.catch((err) => console.log('failed: ', err));

	useEffect(() => {
		if (notification?.title) {
			dispatch(getCurrentUser());
		}
	}, [notification]);
	/* ************ handle notification mark ****************** */

	return (
		<GridContainer className={classes.root}>
			<GridItem className={classes.fixedColumn}>
				<GridItem
					style={{
						textAlign: 'center',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Link to={landingPagePath}>
						<img
							className={classes.fixedLogo}
							src={TalkybotLogo}
							alt="Talkybot Logo"
						/>
					</Link>
					<Link to={talkybotsPath}>
						<Tooltip
							classes={{
								tooltip: location.pathname.includes(
									'/talkybots'
								)
									? classes.tooltip
									: classes.tooltipNotActive,
								arrow: location.pathname.includes('/talkybots')
									? classes.tooltipArrow
									: classes.tooltipArrowNotActive,
							}}
							title="My Bots"
							placement="right"
							arrow
						>
							<RobotIcon
								fill={
									location.pathname.includes('/talkybots')
										? '#6418C3'
										: '#909090'
								}
								width={theme.spacing(30)}
								height={theme.spacing(27)}
								style={{
									marginTop: '2rem',
								}}
							/>
						</Tooltip>
					</Link>

					<Link to={teamMemberPath}>
						<Tooltip
							classes={{
								tooltip: location.pathname.includes(
									'/team-members'
								)
									? classes.tooltip
									: classes.tooltipNotActive,
								arrow: location.pathname.includes(
									'/team-members'
								)
									? classes.tooltipArrow
									: classes.tooltipArrowNotActive,
							}}
							title="Team Members"
							placement="right"
							arrow
						>
							<UsersIcon
								fill={
									location.pathname.includes('/team-members')
										? '#6418C3'
										: '#909090'
								}
								width={theme.spacing(30)}
								height={theme.spacing(27)}
								style={{ marginTop: '2rem' }}
							/>
						</Tooltip>
					</Link>
					<UserHasPermission action={CAN_VIEW_USAGE}>
						<Link className={classes.usageIcon} to={usagePath}>
							<Tooltip
								classes={{
									tooltip:
										location.pathname === usagePath
											? classes.tooltip
											: classes.tooltipNotActive,
									arrow:
										location.pathname === usagePath
											? classes.tooltipArrow
											: classes.tooltipArrowNotActive,
								}}
								title="Usage"
								placement="right"
								arrow
							>
								{/* uncomment if you need icon chart not static */}
								{/* {usagePercentage > 5 ? (
									<CircularProgressWithLabel
										minExpectedValue={0}
										maxExpectedValue={MAU?.totalNumOfMeter}
										valueOfProgress={MAU?.currentNumOfMeter}
										size="1.8rem"
										thickness={4}
										color={
											usagePercentage > 75
												? 'red'
												: 'green'
										}
										mainLayoutStyle
										usagePercentage={usagePercentage}
									/>
								) : (
									<CircularProgressWithLabel
										minExpectedValue={0}
										maxExpectedValue={0}
										valueOfProgress={100}
										size="1.8rem"
										thickness={4}
										color="grey"
										mainLayoutStyle
										usagePercentage={usagePercentage}
									/>
								)} */}

								<img
									width="100%"
									height="100%"
									style={{ marginTop: '2rem' }}
									src={
										location.pathname === usagePath
											? PiechartActive
											: PiechartNotActive
									}
									alt="usage"
								/>
							</Tooltip>
						</Link>
					</UserHasPermission>
				</GridItem>
				<GridItem
					style={{
						// display: 'flex',
						textAlign: 'center',
						// flexDirection: 'column',
						// alignItems: 'center',
					}}
				>
					{/* <SearchIcon fill="#909090" />
					<EarthIcon fill="#909090" style={{ marginTop: '1.5rem' }} />
					<CalendarIcon
						fill="#909090"
						style={{ marginTop: '1.5rem' }}
					/> */}
					{/* Notification */}
					<Link
						to={notificationsPath}
						style={{ position: 'relative' }}
					// onClick={clearNotification}
					>
						<Tooltip
							title="Notification"
							placement="right"
							arrow
							classes={{
								tooltip:
									location.pathname === notificationsPath
										? classes.tooltip
										: classes.tooltipNotActive,
								arrow:
									location.pathname === notificationsPath
										? classes.tooltipArrow
										: classes.tooltipArrowNotActive,
							}}
						>
							<img
								width={theme.spacing(450)}
								src={
									// (userNotificationStatus && location.pathname === notificationsPath) ? (
									// 	NotificationIcon
									// ) : (userNotificationStatus && location.pathname !== notificationsPath) ? (
									// 	NotificationIconActive
									// 	) : (!userNotificationStatus && location.pathname !== notificationsPath) ? (
									// 	NotificationIconGray
									// ) : null
									userNotificationStatus
										? location.pathname ===
											notificationsPath
											? NotificationIcon
											: NotificationIconGray
										: NotificationIconActive
								}
								alt="notification"
							/>
						</Tooltip>
					</Link>
					{/* my profile */}
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							// marginTop: '3rem',
							textAlign: 'center',
						}}
					>
						<Divider width="120%" />
						<Link to={`/users/${userData?.id}`}>
							<div style={{ marginTop: '1rem' }}>
								{user?.attachment_url ? (
									<Avatar
										alt="user profile"
										src={user?.attachment_url}
									// className={classes.large}
									/>
								) : (
									<CustomAvatar name={userData?.name} />
								)}
							</div>
						</Link>
						<Typography className={classes.profileLabel}>
							My Profile
						</Typography>
						<Divider width="120%" />
						{/* <InformationIcon
							fill="#909090"
							style={{ marginTop: '1.5rem' }}
						/> */}

						<GridItem className={classes.logoutContainer}>
							<LogOutIcon
								fill="#909090"
								width={theme.spacing(20)}
								height={theme.spacing(23)}
								title="logout"
								onClick={logoutWithDestroyFirebaseToken}
							/>
							<Typography className={classes.logoutText}>
								Logout
							</Typography>
						</GridItem>
					</div>
				</GridItem>
			</GridItem>
			{withTalkybot && (
				<GridItem>
					<SideNav
						handleCollapse={handleCollapse}
						collapseMenu={collapseMenu}
						style={{
							left: '5.875rem',
							width: collapseMenu ? '4rem' : '17.375rem',
							zIndex: 1,
						}}
					/>
				</GridItem>
			)}
			<div
				item
				style={{
					marginLeft: withTalkybot
						? !collapseMenu
							? '24.2rem'
							: '11rem'
						: '7rem',
					width: '100%',
					overflowX: 'hidden',
					height: '100vh',
				}}
			>
				<Container
					style={{
						paddingRight: '1rem',
						paddingLeft: '1rem',
					}}
					maxWidth={getContainerSize()}
				>
					{children}
				</Container>
			</div>
		</GridContainer>
	);
};

export default MainLayout;

const useStyles = makeStyles((theme) => ({
	fixedColumn: {
		width: theme.spacing(90),
		height: '100%',
		// backgroundColor: '#F4F7F9',
		boxShadow: '0px 3px 6px #7E7E7E29',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'center',
		zIndex: 1,
		position: 'fixed',
	},
	toggleColumn: {},
	fixedLogo: {
		height: theme.spacing(78),
		width: theme.spacing(63),
		marginTop: '1.125rem',
	},
	root: {
		// display: 'flex',
		// height: '100vh',
	},

	notificationIcon: {
		background: '#8F2483',
		position: 'absolute',
		height: '1rem',
		width: '1rem',
		top: '0rem',
		right: '.1rem',
		borderRadius: '50%',
	},
	usageIcon: {
		width: theme.spacing(32),
		height: theme.spacing(29),
	},
	logoutContainer: {
		marginTop: theme.spacing(35),
		marginBottom: theme.spacing(35),
	},
	logoutText: {
		...theme.typography.buttonLink,
		color: theme.palette.secondary.main,
	},
	tooltip: {
		...theme.typography.subtitle6,
		padding: '.5rem 1rem',
		backgroundColor: theme.palette.primary.light,
	},
	tooltipArrow: {
		// fontSize: '1rem',
		color: theme.palette.primary.light,
	},
	tooltipNotActive: {
		...theme.typography.subtitle6,
		padding: '.5rem 1rem',
		// backgroundColor: theme.palette.primary.light,
	},
	tooltipArrowNotActive: {
		// fontSize: '1rem',
		// color: theme.palette.primary.light,
	},
	profileLabel: {
		...theme.typography.subtitle6,
		marginTop: theme.spacing(10),
		marginBottom: theme.spacing(16),
	},
}));