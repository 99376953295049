import Typography from '@material-ui/core/Typography';
import { Container, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import pricingHeaderFactors from 'assets/images/landingImgs/pricingHeaderFactors.png';
import Pricing from '../shared/pricing/pricing';

const useStyles = makeStyles((theme) => ({
	headerSection: {
		marginTop: '9.375rem',
		padding: '4.375rem',
		textAlign: 'center',
		backgroundColor: theme.palette.background.primaryOpacity,
		backgroundImage: `url(${pricingHeaderFactors})`,
		backgroundSize: '80% 70%',
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
	},
	PricingTitle: {
		...theme.typography.title,
		color: theme.palette.text.primary,
	},
	PricingSubTitle: {
		...theme.typography.subtitle7,
		color: theme.palette.text.primary,
	},
}));
const PricingHeader = () => {
	const classes = useStyles();
	return (
		<Paper elevation={0}>
			<Paper className={classes.headerSection} elevation={0}>
				<Typography className={classes.PricingTitle}>
					Pricing
				</Typography>
				<Typography className={classes.PricingSubTitle}>
					home / Pricing
				</Typography>
			</Paper>
			<Pricing />
		</Paper>
	);
};

export default PricingHeader;
