export const getConnectedChannels = (talkyBotId, accounts = []) => {
	const valid = [];

	accounts?.forEach(({ channels, id }) => {
		channels?.forEach((channel) => {
			if (
				channel.attributes.is_connected.is_connected_before &&
				channel.attributes.is_connected.the_same_tenant &&
				channel.attributes.talkybot_connection &&
				channel.attributes.talkybot_connection.talkybot_id.toString() ===
					talkyBotId.toString()
			) {
				valid.push({
					account_id: id,
					channel_connected: true,
					channel_id: channel.id,
				});
			}
		});
	});

	return valid;
};

export const getConnectedChannelsToAnotherOrganization = (accounts = []) => {
	const valid = [];

	accounts?.forEach(({ channels, id }) => {
		channels?.forEach((channel) => {
			if (channel.attributes.is_connected.the_same_tenant === false) {
				valid.push({
					account_id: id,
					connected_to_another_organization: true,
					channel_id: channel.id,
				});
			}
		});
	});

	return valid;
};

export const getOldConnectedChannelInfo = (
	accountId,
	connectedChannels = []
) => {
	const info = connectedChannels.filter((channel) => {
		return channel.account_id === accountId;
	});

	return info[0];
};

export const getOldConnectedChannelToAnotherOrganizationInfo = (
	accountId,
	connectedChannelsToAnotherOrganization = []
) => {
	const info = connectedChannelsToAnotherOrganization.filter((channel) => {
		return channel.account_id === accountId;
	});

	return info[0];
};

export const getConnectedAccountsObj = (channels) => {
	const obj = {};
	channels.forEach(({ id, connected }) => {
		if (!obj[id]) obj[id] = 1;
		else if (obj[id]) obj[id] += 1;
	});
	return obj;
};
