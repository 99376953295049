import React from 'react';
import { CircularProgress } from '@material-ui/core';

const LoadingSubSection = () => {
	return (
		<div
			style={{
				textAlign: 'center',
				marginTop: '3rem',
				// lineHeight: '50vh',
			}}
		>
			<CircularProgress />
		</div>
	);
};

export default LoadingSubSection;
