export const ADD_NEW_SYNONYM = 'ADD_NEW_SYNONYM';
export const UPDATE_SYNONYM = 'UPDATE_SYNONYM';
export const UPDATE_SYNONYM_FAIL = 'UPDATE_SYNONYM_FAIL';
export const DELETE_SYNONYM = 'DELETE_SYNONYM';
export const DELETE_SYNONYM_FAIL = 'DELETE_SYNONYM_FAIL';
export const GET_ALL_SYNONYMS = 'GET_ALL_SYNONYMS';
export const GET_SYNONYM = 'GET_ALL_SYNONYMS';
export const SYNONYM_ERROR = 'SYNONYM_ERROR';
export const ACTIVATE_SYNONYM = 'ACTIVATE_SYNONYM';
export const CLEAR_ACTIVATE_SYNONYM = 'CLEAR_ACTIVATE_SYNONYM';
export const CLEAR_ACTIVE_SYNONYM = 'CLEAR_ACTIVE_SYNONYM';
export const GET_ALL_SYNONYM_VALUES = 'GET_ALL_SYNONYM_VALUES';
export const ADD_NEW_SYNONYM_VALUE = 'ADD_NEW_SYNONYM_VALUE';
export const UPDATE_SYNONYM_VALUE = 'UPDATE_SYNONYM_VALUE';
export const UPDATE_SYNONYM_VALUE_FAIL = 'UPDATE_SYNONYM_VALUE_FAIL';
export const DELETE_SYNONYM_VALUE = 'DELETE_SYNONYM_VALUE';
export const DELETE_SYNONYM_VALUE_FAIL = 'DELETE_SYNONYM_VALUE_FAIL';