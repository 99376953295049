import React from 'react';
import TextInput from 'components/common/Inputs/textInput/TextInput';
import { makeStyles, Typography } from '@material-ui/core';
import SelectInput from 'components/common/Inputs/Select/SelectInput';

const useStyles = makeStyles((theme) => ({
	labelStyle: {
		...theme.typography.subtitle4,
		color: theme.palette.primary.main,
		marginBottom: theme.spacing(8),
		marginTop: theme.spacing(18),
	},
	errorStyle: {
		...theme.typography.subtitle4,
		color: theme.palette.error.main,
		marginTop: theme.spacing(8),
	},
}));
function FormTextInput({
	onChange,
	label,
	error,
	type,
	disabled = false,
	smallHeight,
	...props
}) {
	const classes = useStyles();

	return (
		<>
			<Typography className={classes.labelStyle}>{label}</Typography>
			<SelectInput
				disabled={disabled}
				onChange={onChange}
				isError={error}
				type={type}
				smallHeight={smallHeight}
				{...props}
			/>
			{error && (
				<Typography className={classes.errorStyle}>{error}</Typography>
			)}
		</>
	);
}

export default FormTextInput;
