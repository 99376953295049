import React, { useEffect } from 'react';
import NotFoundImage from 'assets/images/404.png';
import GridContainer from 'components/common/Grid/GridContainer';
import { useHistory } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';


const NoAccessComponent = () => {
	const history = useHistory();

	useEffect(() => {
		document.title = 'Page not found - Talkybot';
	}, []);

	const styles = {
		btnGoBCK: {
			backgroundColor: '#7c2bdf',
			color: 'white',
			padding: '10px 15px',
			borderRadius: '30px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			cursor: 'pointer',
			transition: '0.3s',
			marginTop: '30px',
			marginLeft: '30px',
    }
	}

	return (
		<div>
			<button onClick={() => history.goBack()} style={styles.btnGoBCK}>
				<ArrowBackIosIcon />
				<span>Go Back</span>
			</button>
			<GridContainer
				justifyContent="center"
				alignItems="center"
				style={{
					height: '100vh',
					padding: '2rem',
					flexDirection: 'column',
				}}
			>
				<img
					height="100%"
					src={NotFoundImage}
					alt="404 notfound page"
				/>
			</GridContainer>
		</div>
	);
};

export default NoAccessComponent;
