import React from 'react';
import { Box, Container, Grid } from '@material-ui/core';
// import unionServices from "assets/images/UnionServices.png";

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import unionUnionNum from 'assets/images/landingImgs/UnionNum.png';
import easyImg from 'assets/images/landingImgs/easyImg.svg';
// import enterpriseReadyImg from 'assets/images/landingImgs/enterpriseReadyImg.svg';
// import securityImg from 'assets/images/landingImgs/securityImg.svg';

const useStyles = makeStyles((theme) => ({
	sectionStyle: {
		textAlign: 'center',
		margin: '6.25px 0px 7.5rem 0px',
		paddingBottom: '6.25rem',
	},
	unionServices: {
		textAlign: 'end',
		transform: 'translateY(50%)',
	},
	headerSm: {
		...theme.typography.subtitle5,
		lineHeight: '0px',
		letterSpacing: '1.1px',
		color: '#43348B',
		textTransform: 'capitalize',
	},
	headerLg: {
		...theme.typography.subtitle3,
		color: theme.palette.text.primary,
		margin: '1rem 0px',
	},
	contentStyle: {
		marginTop: '6.25rem',
	},
	UnionNumImg: {
		backgroundImage: `url(${unionUnionNum})`,
		backgroundSize: '100% 100%',
		backgroundRepeat: 'no-repeat',
		display: 'inline-block',
		backgroundPosition: 'center center',
		marginBottom: '1.25rem',
	},
	UnionNum: {
		...theme.typography.title1,
		color: theme.palette.text.primary,
		padding: '1.875rem 1.25rem',
	},
	UnionHeader: {
		...theme.typography.subtitle4,
		color: theme.palette.text.primary,
	},
	UnionDesc: {
		...theme.typography.body4,
		color: theme.palette.text.dark,
	},
	containerStyle: {
		[theme.breakpoints.up('md')]: {
			paddingRight: theme.spacing(15),
			paddingLeft: theme.spacing(15),
		},
	},
}));
const Services = () => {
	const classes = useStyles();
	return (
		<div className={classes.sectionStyle}>
			<Container className={classes.containerStyle} maxWidth={false}>
				<Typography className={classes.headerSm}>Services</Typography>
				<Typography className={classes.headerLg}>
					What we offer to you ?
				</Typography>
				{/*  */}
				<div className={classes.contentStyle}>
					<Grid container alignItems="flex-start" justifyContent="" spacing={1}>
						{/*  */}
						<Grid item xs={12} md={4}>
							<Box className={classes.UnionNumImg}>
								<Typography className={classes.UnionNum}>
									67 %
								</Typography>
							</Box>
							<Typography className={classes.UnionHeader}>
								Easy to use
							</Typography>
							<Typography className={classes.UnionDesc}>
								Without the need of in-depth technical knowledge.
							</Typography>
						</Grid>
						{/*  */}
						<Grid item xs={12} md={4}>
							<Box className={classes.UnionNumImg}>
								<Typography className={classes.UnionNum}>
									30 %
								</Typography>
							</Box>
							<Typography className={classes.UnionHeader}>
								Enterprise Ready
							</Typography>
							<Typography className={classes.UnionDesc}>
								Create a chatbot without any required coding skills.
							</Typography>
						</Grid>
						{/*  */}
						<Grid item xs={12} md={4}>
							<Box className={classes.UnionNumImg}>
								<Typography className={classes.UnionNum}>
									30 %
								</Typography>
							</Box>
							<Typography className={classes.UnionHeader}>
								Security at its finest
							</Typography>
							<Typography className={classes.UnionDesc}>
								We keep your conversational data confidential.
							</Typography>
						</Grid>
					</Grid>
				</div>
			</Container>
		</div>
	);
};

export default Services;
