import {
  GET_ALL_SCENARIOS,
  CREATE_NEW_SCENARIO,
  UPDATE_SCENARIO,
  DELETE_SCENARIO
} from 'store/actions/types/scenarios'

const INIT_STATE = {
  scenarios: [],
  scenariosCount: 0
}

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case GET_ALL_SCENARIOS: {
      const { scenarios, count } = payload
      return {
        ...state,
        scenarios: { ...scenarios },
        scenariosCount: count
      }
    }

    case CREATE_NEW_SCENARIO: {
      const { scenario } = payload
      return {
        ...state,
        scenarios: {
          [" " + scenario.id]: scenario,
          ...state.scenarios,
        }
      }
    }

    case DELETE_SCENARIO: {
      const { scenarioId } = payload;
      const { [" " + scenarioId]: deletedScenario, ...scenarios } = state.scenarios;

      return {
        ...state,
        scenarios,
      };
    }

    case UPDATE_SCENARIO: {
      // get payload data
      const { scenario } = payload;
      // get current state
      const { scenarios } = state;
      // update scenario
      scenarios[" " + scenario.id] = scenario;
      // return new state
      return {
        ...state,
        scenarios,
      };


    }
    default:
      return state

  }
}