export default class IntentModel {
	constructor({
		id,
		name,
		status,
		updatedAt,
		faqs,
		replies,
		modifiedBy,
		category,
		lang,
	}) {
		this.id = id;
		this.name = name;
		this.status = status;
		this.updatedAt = updatedAt;
		this.faqs = faqs;
		this.replies = replies;
		this.modifiedBy = modifiedBy;
		this.category = category;
		this.lang = lang;
	}
}
