import { useEffect } from 'react';
import HomeHeader from './homeHeader';
import Benefits from './benefits';
import BenefitesCreateBot from './benefitesCreateBot';
import TalkyBotTools from './talkyBotTools';
import Testimonial from './testimonial/testimonial';
import Pricing from '../shared/pricing/pricing';
import FooterCarousel from './footerCarousel';
import { Paper } from '@material-ui/core';
const Home = () => {
	useEffect(() => {
		// 👇️ scroll to top on page load
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}, []);

	return (
		<Paper elevation={0}>
			<HomeHeader />
			<Benefits />
			<BenefitesCreateBot />
			<TalkyBotTools />
			{/* <Pricing /> */}
			{/* <Testimonial /> */}
			{/* <FooterCarousel /> */}
		</Paper>
	);
};

export default Home;
