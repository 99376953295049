import { initializeApp } from 'firebase/app';
import {
	getMessaging,
	getToken,
	isSupported,
	onMessage,
} from 'firebase/messaging';
import { DEVICE_NOTIFICATION_PERMISSION } from 'constants/tokensTypes';
import {
	deleteUserPermissionTokenAction,
	saveUserPermissionTokenAction,
} from 'store/actions/creators/auth';
import AudioSound from 'assets/audio/notification.mp3';
import { useDispatch } from 'react-redux';
// import { useSnackbar } from 'notistack';

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FCM_APP_API_KEY,
	authDomain: process.env.REACT_APP_FCM_APP_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FCM_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FCM_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FCM_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FCM_APP_APP_ID,
};

const app = initializeApp(firebaseConfig);
// const { enqueueSnackbar } = useSnackbar();

// check if the browser supports the messaging service
const messaging = (async () => {
	try {
		const isSupportedBrowser = await isSupported();
		if (isSupportedBrowser) {
			return getMessaging(app);
		}
		return null;
	} catch (err) {
		return null;
	}
})();

export const onMessageListener = async () =>
	new Promise((resolve) =>
		(async () => {
			const messagingResolve = await messaging;
			onMessage(messagingResolve, (payload) => {
				resolve(payload);
			});
		})()
	);

export const requestForToken = async (dispatch) => {
	try {
		const messagingResolve = await messaging;
		const currentToken = await getToken(messagingResolve, {
		vapidKey: process.env.REACT_APP_FCM_APP_SERVER_KEY,
	});
	if (currentToken) {
		localStorage.setItem('firebase_token', currentToken);
		const tokenConfig = {
			token_type: DEVICE_NOTIFICATION_PERMISSION,
			token_value: currentToken,
			device: 'web'
		};
		dispatch(saveUserPermissionTokenAction(tokenConfig));
	}
	} catch (err) {
		console.log('An error occurred while retrieving token. ', err);
	}
};
