import { Grid, Card, CardMedia, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import dashedImg from 'assets/images/landingImgs/dashedImg.png';

const useStyles = makeStyles((theme) => ({
	DashedCard: {
		backgroundColor: 'transparent',
		backgroundImage: `url(${dashedImg})`,
		backgroundSize: '100% 100%',
		backgroundRepeat: 'no-repeat',
		padding: '1px 1px',
	},
	innerCard: {
		margin: '12px',
		padding: '0.9375rem 0.9375rem 60px 0.9375rem',
		boxShadow: '0px 3px 6px #00000029',
		borderRadius: '2.1875rem 0px',
		textAlign: 'center',
	},
	cardImage: {
		width: '9.375rem',
		height: '9.375rem',
		borderRadius: '50%',
		margin: '1.25rem auto',
	},
	cardBody: {
		...theme.typography.body4,
		color: theme.palette.text.dark,
		marginBottom: '1.25rem',
	},
	testimonialName: {
		...theme.typography.body3,
		color: theme.palette.text.primary,
	},
	testimonialJop: {
		...theme.typography.body3,
		color: theme.palette.text.dark,
	},
}));
const TestimonialCard = ({ avetarImg, postBody, name, jop }) => {
	const classes = useStyles();
	return (
		<Card elevation={0} className={classes.DashedCard}>
			<Card className={classes.innerCard}>
				<CardMedia className={classes.cardImage} image={avetarImg} />
				<Typography className={classes.cardBody}>{postBody}</Typography>
				<Grid container justifyContent="space-between">
					<Grid item>
						<Typography className={classes.testimonialName}>
							{name}
						</Typography>
					</Grid>
					<Grid item>
						<Typography className={classes.testimonialJop}>
							{jop}
						</Typography>
					</Grid>
				</Grid>
			</Card>
		</Card>
	);
};

export default TestimonialCard;
