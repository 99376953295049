import { useState, useEffect } from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ChatbotLineCharts from '../Shared/chatbotCharts/lineChart/ChatbotLineCharts';
import useSetUiLoadingError from 'hooks/useSetUiLoadingError';
import { ReactComponent as NoOfUniqueUser } from 'assets/images/Dashboard/NoOfUniqueUser.svg';

import Filter from '../Shared/Filter';
import { Skeleton } from '@material-ui/lab';
// local styles
import styles from './index.module.scss';

const Component = ({
  setCurrentView,
  currentView,
  statusDurationValue,
  setStatusDurationValue,
  statusChannelValue,
  setStatusChannelValue,
  channelData,
  DurationData,
  // action from redux
  getCompletionScenarios,
  // redux state
  completion_scenarios,
  ///
  talkybotId,
  setTabValue,
}) => {

  // get current media query
  const [matches, setMatches] = useState(
    window.matchMedia('(max-width: 1600px)').matches
  );
  useEffect(() => {
    window
      .matchMedia('(max-width: 1600px)')
      .addEventListener('change', (e) => setMatches(e.matches));
  }, []);
  const [isLoadingCompletionScenarios, successDataCompletionScenarios, backendErrorCompletionScenarios, setUiLoadingErrorCompletionScenarios] =
    useSetUiLoadingError();

  useEffect(() => {
    getCompletionScenarios({
      talkyBotID: talkybotId,
      duration_filter: statusDurationValue,
      provider_filter: statusChannelValue,
      setUiLoadingError: setUiLoadingErrorCompletionScenarios
    })
  }, [statusDurationValue, statusChannelValue])

  return (
    <div className={styles.Container}>
      <div className={styles.header}>
        <div className={styles.leftPart}>
          <button
            className={styles.backButton}
            onClick={() => {
              setCurrentView('main');
              localStorage.setItem('dashboardView', 'main')
            }}
          >
            <ArrowBackIcon />
          </button>
          <span className={styles.headtext}>
            Scenarios
          </span>
        </div>
        <Filter
          statusDurationValue={statusDurationValue}
          setStatusDurationValue={setStatusDurationValue}
          statusChannelValue={statusChannelValue}
          setStatusChannelValue={setStatusChannelValue}
          channelData={channelData}
          DurationData={DurationData}
        />
      </div>
      <div className={styles.body}>
        {/* Completion scenarios */}
        {
          isLoadingCompletionScenarios ? (
            <Skeleton className={styles.CompletionScenariosSkeleton} variant="rect" animation="wave" height={matches ? 244 : 253} />
          ) : (
            <div className={styles.completionScenarios}>
              <ChatbotLineCharts
                setCurrentView={setCurrentView}
                currentView={currentView}
                InfoData={completion_scenarios}
                statusDurationValue={statusDurationValue}
              />
            </div>

          )
        }
        {/* scenario table */}
        {
          isLoadingCompletionScenarios ? (
            <Skeleton className={styles.CompletionScenariosSkeleton} variant="rect" animation="wave" height={matches ? 244 : 253} />
          ) : (
            <table className={styles.projectTable}>
              <thead>
                <tr>
                  <th>Scenario Name</th>
                  <th>Times Started</th>
                  <th>Incomplete </th>
                  <th>Completion</th>
                </tr>
              </thead>
              <>
                {
                  completion_scenarios?.all_bot_scenarios_completion ? (
                    <tbody>
                      {completion_scenarios?.all_bot_scenarios_completion?.map((row, index) => (
                        <tr key={index}>
                          <td>{row?.attributes?.scenario_name}</td>
                          <td>{row?.attributes?.times_started}</td>
                          <td>{row?.attributes?.times_not_completed > 0 ? row?.attributes?.times_not_completed : 0}</td>
                          <td>{row?.attributes?.rate_of_completion ?? 0} %</td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <div className={styles.noData}>
                      <NoOfUniqueUser />
                    </div>
                  )
                }
              </>
            </table>
          )
        }
      </div>
    </div>
  )
}

export default Component;
