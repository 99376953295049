/* eslint-disable no-restricted-globals */
/* eslint-disable consistent-return */
import React from 'react';
import { useSelector } from 'react-redux';
import MainLayout from 'layouts/MainLayout';

const UserAuth = (Component, publicRouter, isCollapsed) => {
	const auth = useSelector((state) => state.auth);
	const activeTalkybot = useSelector((state) => state.activeTalkybot);
	const userAuthState = auth?.isAuthenticated;
	const withTalkybot = !!activeTalkybot?.id;
	// eslint-disable-next-line no-debugger
	// debugger;
	const handleAuth = () => {
		if (!userAuthState) {
			if (publicRouter) {
				return <Component />;
			}

			// return history.push('/login');
		} /* else {
			? Here will appear user role and router name mix to show or not showing
			return <Component />;
		} */
		return (
			<MainLayout isCollapsed={isCollapsed} withTalkybot={withTalkybot}>
				<Component />
			</MainLayout>
		);
	};
	return () => {
		return handleAuth();
	};
};

export default UserAuth;
