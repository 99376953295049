import {
	LOG_USER_IN_SUCCESS,
	REGISTER_USER_SUCCESS,
	LOG_USER_OUT,
	GET_CURRENT_USER,
	EDIT_CURRENT_USER_PROFILE_SUCCESS,
	EDIT_PASSWORD_SUCCESS,
	CLEAR_IS_AUTHENTICATED,
	UPLOAD_AVATAR_FAIL,
	UPLOAD_AVATAR_SUCCESS,
	SAVE_USER_PERMISSION_TOKEN_SUCCESS,
	SAVE_USER_PERMISSION_TOKEN_FAILED,
	NOTIFICATION_STATUS_CHANGED,
	NOTIFICATION_STATUS_CHANGE_SUCCESS,
	LOGED_IN_USER_NOTIFICATION_STATUS_CHANGE,
	SEND_CONFIRM_USER_SUCCESS,
	SEND_CONFIRM_USER_FAIL,
} from 'store/actions/types/auth';
import notifications from './notifications';

const initialAuthState = {
	isAuthenticated: false,
	user: null,
	triggerReadNotification: false,
	is_confirmed: false,
	// firebaseToken: false,
};
export default (state = initialAuthState, { type, payload }) => {
	switch (type) {
		case UPLOAD_AVATAR_SUCCESS:
			return {
				...state,
				user: {
					...state.user,
					attachment_url: payload.attachment_url,
				},
			};

		case UPLOAD_AVATAR_FAIL:
			return {
				...state,
				user: {
					...state.user,
					error: 'Upload avatar failed',
				},
			};

		case LOG_USER_IN_SUCCESS: {
			return {
				isAuthenticated: true,
				user: payload,
			};
		}
		case REGISTER_USER_SUCCESS: {
			return {
				...state,
			};
		}

		case LOG_USER_OUT: {
			localStorage.removeItem('firebase_token')
			return initialAuthState;
		}
		case CLEAR_IS_AUTHENTICATED: {
			localStorage.removeItem('firebase_token')
			return {
				...state,
				isAuthenticated: false,
				user: null,
			};
		}

		case GET_CURRENT_USER: {
			return {
				isAuthenticated: true,
				user: payload,
			};
		}
		case EDIT_CURRENT_USER_PROFILE_SUCCESS: {
			return {
				...state,
				user: payload,
				isAuthenticated: true,
			};
		}
		case EDIT_PASSWORD_SUCCESS: {
			return {
				...state,
				user: payload,
				isAuthenticated: true,
			};
		}
		// change the state of the notification
		case NOTIFICATION_STATUS_CHANGE_SUCCESS: {
			const { seen } = payload;

			return {
				...state,
				user: {
					...state.user,
					notification_status: true,
				},
				triggerReadNotification: true,
			};
		}
		case LOGED_IN_USER_NOTIFICATION_STATUS_CHANGE: {
			return {
				...state,
				user: {
					...state.user,
					notification_status: false,
				},
				triggerReadNotification: false,
			};
		}
		// case AUTHENTICATE: {
		// 	return {
		// 		...state,
		// 		isAuthenticated: true,
		// 		user: payload,
		// 	};
		// 	}
		case SEND_CONFIRM_USER_SUCCESS: {
			// get is_confirmed from the payload
			const { is_confirmed } = payload;
			// reset the user and isAuthenticated to false
			// remove tokenData from the local storage
			localStorage.removeItem('tokenData')
			return {
				...state,
				isAuthenticated: false,
				user: null,
				is_confirmed,
			};
		}
		case SEND_CONFIRM_USER_FAIL: {
			// reset the user and isAuthenticated to false
			localStorage.removeItem('tokenData');
			return {
				...state,
				isAuthenticated: false,
				user: null,
				is_confirmed: false,
			};
		}
		default:
			return state;
	}
};
