import { Container, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SinglePricingFrame from './singlePricingFrame';
import homeUnionPriceImg from 'assets/images/landingImgs/homeUnionPrice.png';
const useStyles = makeStyles((theme) => ({
	sectionFrame: {
		position: 'relative',
		overflow: 'hidden',
		padding: '5rem 0px 6.25rem 0px',
		marginBottom: theme.spacing(80),
	},
	homeUnionPriceImg: {
		position: 'absolute',
		top: '50%',
		right: '-40px',
		[theme.breakpoints.down('xs')]: {
			width: '20%',
		},
		width: '11%',
	},
	headerText: {
		...theme.typography.subtitle3,
		color: theme.palette.text.primary,
		textAlign: 'center',
		paddingBottom: '3.125rem',
	},
	containerStyle: {
		[theme.breakpoints.up('md')]: {
			paddingRight: theme.spacing(15),
			paddingLeft: theme.spacing(15),
		},
		paddingRight: theme.spacing(4),
	},
}));

const listObjStarter = [
	{ status: true, text: '5 Bots' },
	{ status: true, text: '3000 MAU' },
	{ status: true, text: '5 Users' },
	{ status: true, text: 'FB' },
	{ status: true, text: '5 Custom Field' },
];
const listObjPro = [
	{ status: true, text: '10 Bots' },
	{ status: true, text: '8000 MAU' },
	{ status: true, text: '10 Users' },
	{ status: true, text: 'FB | IG' },
	{ status: true, text: '10 Custom Field' },
];
const listObjPremium = [
	{ status: true, text: '20 Bots' },
	{ status: true, text: '20,000 MAU' },
	{ status: true, text: '20 Users' },
	{ status: true, text: 'FB | IG | Web ' },
	{ status: true, text: '20 Custom Field' },
];
const Pricing = () => {
	const classes = useStyles();
	return (
		<Paper className={classes.sectionFrame} elevation={0}>
			<img
				src={homeUnionPriceImg}
				className={classes.homeUnionPriceImg}
				alt=""
			/>
			<Container className={classes.containerStyle} maxWidth={false}>
				<Typography className={classes.headerText}>
					Choose Pricing Plan
				</Typography>
				<Grid
					container
					direction="row"
					justifyContent="space-evenly"
					alignItems="center"
					spacing={2}
				>
					<Grid item xs={7} sm={4} md={3}>
						<SinglePricingFrame
							listObj={listObjStarter}
							header="Starter"
							price="$18"
						/>
					</Grid>
					<Grid item xs={7} sm={4} md={3}>
						<SinglePricingFrame
							listObj={listObjPro}
							header="Professional"
							price="$39"
						/>
					</Grid>
					<Grid item xs={7} sm={4} md={3}>
						<SinglePricingFrame
							listObj={listObjPremium}
							header="Premium"
							price="$89"
						/>
					</Grid>
				</Grid>
			</Container>
		</Paper>
	);
};

export default Pricing;
