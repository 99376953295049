export default class AccountModel {
	constructor({ id, name, avatar, provider, channels, tenant }) {
		this.id = id;
		this.name = name;
		this.avatar = avatar;
		this.provider = provider;
		this.channels = channels;
		this.tenant = tenant;
	}
}
