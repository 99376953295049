export const GET_PEOPLE = '[CRM] get people';
export const GET_PEOPLE_FAIL = '[CRM] get people fail';
export const BULK_DELETE_PEOPLE = '[CRM] bulk delete people';
export const BULK_DELETE_PEOPLE_FAIL = '[CRM] bulk delete people fail';
export const ADD_PERSON = 'ADD_PERSON';
export const ADD_PERSON_FAIL = '[CRM] add person fail';
export const GET_PERSON = 'GET_PERSON';
export const EDIT_PERSON = '[CRM][PersonProfile] edit person';
export const EDIT_PERSON_FAIL = '[CRM][PersonProfile] edit person fail';
export const DELETE_PERSON = '[CRM][PersonProfile] delete person';
export const DELETE_PERSON_FAIL = '[CRM][PersonProfile] delete person fail';
export const ACTIVATE_PERSON = 'ACTIVATE_PERSON';
export const GET_CUSTOM_FIELDS = 'GET_CUSTOM_FIELDS';
export const ADD_CUSTOM_FIELD = 'ADD_CUSTOM_FIELD';
export const ADD_CUSTOM_FIELD_FAIL = 'ADD_CUSTOM_FIELD_FAIL';
export const GET_PERSON_INCIDENTS = '[CRM] get person incidents';

export const DELETE_PERSON_NOTE_SUCCESS =
	'[CRM][personProfile]DELETE_PERSON_NOTE_SUCCESS';
export const DELETE_PERSON_NOTE_FAIL =
	'[CRM][personProfile]DELETE_PERSON_NOTE_FAIL';
export const EDIT_CUSTOM_FIELD = 'EDIT_CUSTOM_FIELD';
export const EDIT_CUSTOM_FIELD_FAIL =
	'[CRM][CUSTOMField] edit custom field fail';
export const DELETE_CUSTOM_FIELD_SUCCESS =
	'[customField] delete customField success';
export const DELETE_CUSTOM_FIELD_FAIL = '[customField] delete customField fail';
export const ADD_NOTE_TO_PERSON = '[CRM][PersonProfile] add note to person';
export const ADD_NOTE_TO_PERSON_FAIL =
	'[CRM][PersonProfile] add note to person fail';
export const UPDATE_PERSON_NOTE_SUCCESS =
	'[CRM][personProfile]update person note success';
export const UPDATE_PERSON_NOTE_FAIL =
	'[CRM][personProfile]update person note fail';
export const GET_PERSON_NOTES = 'GET_PERSON_NOTES';

export const GET_VIEWER_MEMBERS_REQUEST = 'GET_VIEWER_MEMBERS_REQUEST';
export const GET_VIEWER_MEMBERS_SUCCESS = 'GET_VIEWER_MEMBERS_SUCCESS';
export const GET_VIEWER_MEMBERS_FAIL = 'GET_VIEWER_MEMBERS_FAIL';

export const GET_EDITOR_MEMBERS_REQUEST = 'GET_EDITOR_MEMBERS_REQUEST';
export const GET_EDITOR_MEMBERS_SUCCESS = 'GET_EDITOR_MEMBERS_SUCCESS';
export const GET_EDITOR_MEMBERS_FAIL = 'GET_EDITOR_MEMBERS_FAIL';

export const GET_PERSON_REQUEST = 'GET_PERSON_REQUEST';
export const GET_PERSON_SUCCESS = 'GET_PERSON_SUCCESS';
export const GET_PERSON_FAIL = 'GET_PERSON_FAIL';

export const UPDATE_PERSON_CUSTOM_FIELDS_REQUEST =
	'UPDATE_PERSON_CUSTOM_FIELDS_REQUEST';

export const UPDATE_PERSON_CUSTOM_FIELDS_SUCCESS =
	'UPDATE_PERSON_CUSTOM_FIELDS_SUCCESS';

export const UPDATE_PERSON_CUSTOM_FIELDS_FAIL =
	'UPDATE_PERSON_CUSTOM_FIELDS_FAIL';

export const UPDATE_OR_CREATE_PERSON_CUSTOM_FIELDS_REQUEST =
	'UPDATE_OR_CREATE_PERSON_CUSTOM_FIELDS_REQUEST';
export const UPDATE_OR_CREATE_PERSON_CUSTOM_FIELDS_SUCCESS =
	'UPDATE_OR_CREATE_PERSON_CUSTOM_FIELDS_SUCCESS';

export const UPDATE_OR_CREATE_PERSON_CUSTOM_FIELDS_FAILED =
	'UPDATE_OR_CREATE_PERSON_CUSTOM_FIELDS_FAILED';

export const GET_CRM_STATUS_SUCCESS = 'GET_CRM_STATUS_SUCCESS';

export const GET_CRM_STATUS_REQUEST = 'GET_CRM_STATUS_REQUEST';

export const GET_CRM_STATUS_FAIL = 'GET_CRM_STATUS_FAIL';

export const ADD_CRM_STATUS_REQUEST = 'ADD_CRM_STATUS_REQUEST';
export const ADD_CRM_STATUS_SUCCESS = 'ADD_CRM_STATUS_SUCCESS';
export const ADD_CRM_STATUS_FAIL = 'ADD_CRM_STATUS_FAIL';

export const REMOVE_CRM_STATUS_REQUEST = 'REMOVE_CRM_STATUS_REQUEST';
export const REMOVE_CRM_STATUS_SUCCESS = 'REMOVE_CRM_STATUS_SUCCESS';
export const REMOVE_CRM_STATUS_FAIL = 'REMOVE_CRM_STATUS_FAIL';

export const UPDATE_CRM_STATUS_REQUEST = 'UPDATE_CRM_STATUS_REQUEST';
export const UPDATE_CRM_STATUS_SUCCESS = 'UPDATE_CRM_STATUS_SUCCESS';
export const UPDATE_CRM_STATUS_FAIL = 'UPDATE_CRM_STATUS_FAIL';

export const UPDATE_CRM_PEOPLE_ASSIGN_REQUEST =
	'UPDATE_CRM_PEOPLE_ASSIGN_REQUEST';
export const UPDATE_CRM_PEOPLE_ASSIGN_SUCCESS =
	'UPDATE_CRM_PEOPLE_ASSIGN_SUCCESS';
export const UPDATE_CRM_PEOPLE_ASSIGN_FAIL = 'UPDATE_CRM_PEOPLE_ASSIGN_FAIL';

export const GET_ALL_CRM_APP_FIELDS_SUCCESS = 'GET_ALL_CRM_APP_FIELDS_SUCCESS';
export const GET_ALL_CRM_APP_FIELDS_FAIL = 'GET_ALL_CRM_APP_FIELDS_FAIL';

export const GET_BOT_SCENARIOS_SUCCESS = 'GET_BOT_SCENARIOS_SUCCESS';
export const GET_BOT_SCENARIOS_FAILED = 'GET_BOT_SCENARIOS_FAILED';

export const GET_PERSON_SCENARIOS = 'GET_PERSON_SCENARIOS';
export const GET_PERSON_SCENARIOS_FAILED = 'GET_PERSON_SCENARIOS_FAILED';

export const GET_SCENARIO_VARIATION = 'GET_SCENARIO_VARIATION';
export const GET_SCENARIO_VARIATION_FAILED = 'GET_SCENARIO_VARIATION_FAILED';

//Channels data

export const GET_CRM_CHANNEL_REQUEST = 'GET_CRM_CHANNEL_REQUEST';
export const GET_CRM_CHANNEL_SUCCESS = 'GET_CRM_CHANNEL_SUCCESS';
export const GET_CRM_CHANNEL_FAIL = 'GET_CRM_CHANNEL_FAIL';
