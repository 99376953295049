// Tenant Permissions
export const CAN_INVITE_NORMAL_USER = 'CAN_INVITE_NORMAL_USER';
export const CAN_INVITE_ADMIN = 'CAN_INVITE_ADMIN';
export const CAN_CREATE_BOT = 'CAN_CREATE_BOT';
export const CAN_INVITE_SUPER_ADMIN = 'CAN_INVITE_SUPER_ADMIN';
export const CAN_CHANGE_BILLING_ACCOUNT = 'CAN_CHANGE_BILLING_ACCOUNT';
export const CAN_CHANGE_PRODUCT_OWNER = 'CAN_CHANGE_PRODUCT_OWNER';
// Bot  Permissions
export const CAN_VIEW_BOT = 'CAN_VIEW_BOT';
export const CAN_PUBLISH_BOT = 'CAN_PUBLISH_BOT';
export const CAN_VIEW_SETTINGS = 'CAN_VIEW_SETTINGS';
export const CAN_VIEW_STORE = 'CAN_VIEW_STORE';
export const CAN_VIEW_DASHBOARD = 'CAN_VIEW_DASHBOARD';
export const CAN_EDIT_BOT_CONFIGURATION = 'CAN_EDIT_BOT_CONFIGURATION';

// Applications Permissions
export const CAN_VIEW_CONVERSATION = 'CAN_VIEW_CONVERSATION';
export const CAN_VIEW_BOT_BUILDER = 'CAN_VIEW_BOT_BUILDER';
export const CAN_EDIT_BOT_BUILDER = 'CAN_EDIT_BOT_BUILDER';
export const CAN_EDIT_CONVERSATION = 'CAN_EDIT_CONVERSATION';
export const CAN_VIEW_CRM = 'CAN_VIEW_CRM';
export const CAN_EDIT_CRM = 'CAN_EDIT_CRM';
export const CAN_VIEW_BILLING = 'CAN_VIEW_BILLING';
export const CAN_VIEW_SUBSCRIPTION = 'CAN_VIEW_SUBSCRIPTION';

// Global Permissions
export const CAN_VIEW_TALKYBOTS = 'CAN_VIEW_TALKYBOTS';
export const CAN_VIEW_NOTIFICATIONS = 'CAN_VIEW_NOTIFICATIONS';
export const CAN_VIEW_TENANT_MEMBERS = 'CAN_VIEW_TENANT_MEMBERS';
export const CAN_VIEW_USAGE = 'CAN_VIEW_USAGE';

export const CAN_INVITE_IN_GENERAL = 'CAN_INVITE_IN_GENERAL';
export const CAN_EDIT_PERMISSION = 'CAN_EDIT_PERMISSION';
export const CAN_EDIT_PERSON_PROFILE = 'CAN_EDIT_PERSON_PROFILE';
export const CAN_VIEW_PROFILE = 'CAN_VIEW_PROFILE';
