import classNames from 'classnames';
import APHeader from 'components/AdminPanel/AdminPanelOrganization/MainHeader/Components';
import React from 'react';
// locale stayle
import styles from './index.module.scss';

const AdminMainLayout = ({ children, width = 'container' }) => {
	//  dynamically style for wrapper component based on width prop
	const wrapperStyle = classNames([styles.pageWrapper], {
		[styles.fluidPageWrapper]: width === 'fluid',
	});

	return (
		<>
			<APHeader />
			<div className={styles.pageContainer}>
				<div className={wrapperStyle}>{children}</div>
			</div>
		</>
	);
};

export default AdminMainLayout;
