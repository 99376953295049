import React from 'react';

import { Grid } from '@material-ui/core';
import SynonymsDetailsComponent from 'components/BotBuilder/Synonyms/SynonymDetails';

const SynonymDetails = () => {
	return (
		<div style={{ marginTop: '1rem' }}>
			<Grid container>
				<Grid item xs={12}>
					<SynonymsDetailsComponent />
				</Grid>
			</Grid>
		</div>
	);
};

export default SynonymDetails;
