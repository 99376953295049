/* eslint-disable import/no-duplicates */
import IncomingMessageIconUrl from 'assets/images/activities/incoming_message.svg';
import OutgoingMessageIconUrl from 'assets/images/activities/outgoing_message.svg';

import IncomingCallIconUrl from 'assets/images/activities/incoming_call.svg';
import OutgoingCallIconUrl from 'assets/images/activities/outgoing_call.svg';
import MissedCallIconUrl from 'assets/images/activities/missed_call.svg';

import AssignTeamMember from 'assets/images/activities/assign_team_member.svg';

import ChangeStatus from 'assets/images/activities/changeStatus.svg';

import AddNote from 'assets/images/activities/add_note.svg';
import DeleteNote from 'assets/images/activities/add_note.svg';

import CreatePerson from 'assets/images/activities/create_person.svg';
import UpdatePerson from 'assets/images/activities/updated.svg';

export const IncomingMessageIcon = IncomingMessageIconUrl;
export const OutgoingMessageIconn = OutgoingMessageIconUrl;
export const IncomingCallIcon = IncomingCallIconUrl;
export const OutgoingCallIcon = OutgoingCallIconUrl;
export const MissedCallIcon = MissedCallIconUrl;
export const AssignTeamMemberUrl = AssignTeamMember;
export const ChangeStatusUrl = ChangeStatus;
export const AddNoteUrl = AddNote;
export const DeleteNoteUrl = DeleteNote;
export const CreatePersonUrl = CreatePerson;
export const UpdatePersonUrl = UpdatePerson;

// MESSAGES
export const INCOMING_MESSAGE = 'incoming message';
export const OUTGOING_MESSAGE = 'outgoing message';

// CALL

export const INCOMING_CALL = 'incoming call';
export const OUTGOING_CALL = 'outgoing call';
export const MISSED_CALL = 'missed call';

// ASSIGN
export const ASSIGN_TEAM_MEMBER = 'assign team member';

// STATUS
export const CHANGE_STATUS = 'change status';

// NOTE
export const ADD_NOTE = 'add note';
export const EDIT_NOTE = 'edit note';
export const DELETE_NOTE = 'delete note';

// COLLABORATION
export const CREATE_PERSON = 'create person';
export const UPDATE_PERSON = 'update person';
