import axios from 'axios';
const generateMacAddress = () => {
	const macAddress = "XX:XX:XX:XX:XX:XX".replace(/X/g, function () {
		return "0123456789ABCDEF".charAt(Math.floor(Math.random() * 16))
	});

	// if local storage didn't have mac address, set it
	if (!localStorage.getItem('macAddress')) {
		localStorage.setItem('macAddress', macAddress);
	}
	return localStorage.getItem('macAddress');
	
}
const talkybotAxios = axios.create({
	baseURL:
		process.env.NODE_ENV !== 'development'
			? process.env.REACT_APP_SERVER_PRODUCTION_URL
			: process.env.REACT_APP_SERVER_DEVELOPMENT_URL,
	// to be able to send cookies to the server (for authentication) and set cookies from the server
	headers: {
		'mac_address': generateMacAddress(),
	}
});

export default talkybotAxios;
