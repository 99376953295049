import Typography from '@material-ui/core/Typography';
import { Container, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UserManagementImg from 'assets/images/landingImgs/UserManagementImg.jpg';

const useStyles = makeStyles((theme) => ({
    content: {
        padding: '3.75rem 0px',
        paddingBottom: '250px',
    },
    UserManagementImg: {
        width: '20.5625rem',
        height: '20.4375rem',
    },
    contentHeader: {
        ...theme.typography.subtitle3,
        color: theme.palette.text.primary,
        margin: '0.9375rem 0px',
    },
    contentDesc: {
        ...theme.typography.body3,
        color: theme.palette.text.primary,
    },
    containerStyle: {
        [theme.breakpoints.up('md')]: {
            paddingRight: theme.spacing(15),
            paddingLeft: theme.spacing(15),
        },
        paddingRight: theme.spacing(4),
    },
}));
const UserManagement = () => {
    const classes = useStyles();
    return (
        <Paper elevation={0}>
            <Paper className={classes.content} elevation={0}>
                <Container className={classes.containerStyle} maxWidth={false}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                    >
                        <Grid item md={6}>
                            <Typography className={classes.contentHeader}>
                                5- User Management
                            </Typography>
                            <Typography className={classes.contentDesc}>
                                Create builder and administration roles by selecting from a
                                granular list of entitlements and assigning them to your
                                account users.
                            </Typography>
                        </Grid>
                        <Grid item md={6} align="end">
                            <img
                                className={classes.UserManagementImg}
                                src={UserManagementImg}
                                alt=""
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Paper>
        </Paper>
    );
};

export default UserManagement;
