import { useLocation } from 'react-router-dom';

/**
 * Custom hook to catch query parameters
 * @returns {URLSearchParams}
 */
const useQuery = () => {
	return new URLSearchParams(useLocation().search);
};

export default useQuery;
