import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';

// @material-ui/core components
import Grid from '@material-ui/core/Grid';

const GridItem = (props) => {
	const { className, children, style, ...rest } = props;
	return (
		<Grid item {...rest}>
			<div style={{ ...style }} className={className}>
				{children}
			</div>
		</Grid>
	);
};

GridItem.propTypes = {
	children: PropTypes.node,
	lg: PropTypes.oneOf([
		false,
		'auto',
		true,
		1,
		2,
		3,
		4,
		5,
		6,
		7,
		8,
		9,
		10,
		11,
		12,
	]),
	xl: PropTypes.oneOf([
		false,
		'auto',
		true,
		1,
		2,
		3,
		4,
		5,
		6,
		7,
		8,
		9,
		10,
		11,
		12,
	]),
	xs: PropTypes.oneOf([
		false,
		'auto',
		true,
		1,
		2,
		3,
		4,
		5,
		6,
		7,
		8,
		9,
		10,
		11,
		12,
	]),
	md: PropTypes.oneOf([
		false,
		'auto',
		true,
		1,
		2,
		3,
		4,
		5,
		6,
		7,
		8,
		9,
		10,
		11,
		12,
	]),
	sm: PropTypes.oneOf([
		false,
		'auto',
		true,
		1,
		2,
		3,
		4,
		5,
		6,
		7,
		8,
		9,
		10,
		11,
		12,
	]),
	wrap: PropTypes.oneOf(['nowrap', 'wrap', 'wrap-reverse']),
	classes: PropTypes.object,
};

export default GridItem;
