import { useEffect, useState } from 'react';
// mui
import { Cell, Legend, Pie, PieChart } from 'recharts';
// local assets
import { ReactComponent as NoOfUniqueUser } from 'assets/images/Dashboard/NoOfUniqueUser.svg';
// local styles
import styles from './index.module.scss';

function ChatbotPieCharts({ ChartsInfo, isLoading }) {
	// get current media query
	const [matches, setMatches] = useState(
		window.matchMedia('(max-width: 1600px)').matches
	);
	useEffect(() => {
		window
			.matchMedia('(max-width: 1600px)')
			.addEventListener('change', (e) => setMatches(e.matches));
	}, []);

	const renderLegendVertically = (props) => {
		const { payload } = props;

		return (
			<ul className={styles.verticalListContainer}>
				<>
					{payload.map((entry, index) => (
						<li key={index} className={styles.item}>
							<span
								className={styles.colorBox}
								style={{
									backgroundColor: `${entry.color}`,
								}}
							></span>

							<div className={styles.itemInfo}>
								<span className={styles.title}>
									{entry.value}
								</span>
								<span className={styles.result}>
									{parseInt(entry.payload.value)}
								</span>
							</div>
						</li>
					))}

					<li className={styles.item}>
						<div className={styles.itemWrapper}>
							<span
								className={styles.colorBox}
								style={{
									backgroundColor: '#FFC4B9',
								}}
							></span>
							<span className={styles.language}>Arabic</span>
							<span className={styles.counter}>{ChartsInfo?.arabic}</span>
						</div>

						<div className={styles.itemWrapper}>
							<span
								className={styles.colorBox}
								style={{
									backgroundColor: '#FFC4B9',
								}}
							></span>
							<span className={styles.language}>English</span>
							<span className={styles.counter}>{ChartsInfo?.english}</span>
						</div>
					</li>
				</>
			</ul>
		);
	};

	return (
		<>
			<div className={styles.container}>
				<h3>{ChartsInfo.title}</h3>
				{
					(ChartsInfo.total !== 0 && !isLoading) ? (
						<PieChart
							width={matches ? 490 : 650}
							height={matches ? 200 : 210}
							className={styles.wrapper}
						>
							<Legend
								layout="vetical"
								verticalAlign="middle"
								align="right"
								width={matches ? "300px" : "400px"}
								content={renderLegendVertically}
							/>
							<text x={matches ? 85 : 105} y={matches ? 95 : 100} textAnchor="middle">
								Total
							</text>
							<text x={matches ? 85 : 105} y={matches ? 115 : 125} textAnchor="middle" fontWeight="bold">
								{ChartsInfo.total}
							</text>
							<Pie
								data={ChartsInfo.data}
								cx={matches ? 80 : 100}
								innerRadius={50}
								outerRadius={70}
								legendType="square"
								fill="#8884d8"
								key={Math.random()}
							>
								{ChartsInfo.data.map((entry, index) => (
									<Cell
										key={`cell-${index}`}
										fill={ChartsInfo.colors[index]}
									/>
								))}
							</Pie>
						</PieChart>
					) : (
						<NoOfUniqueUser />
					)
				}
			</div>
		</>
	);
}

export default ChatbotPieCharts;
