import { connect } from 'react-redux';
import { addNewSynonymValueAction, deleteSynonymValueAction, getAllSynonymValuesAction, updateSynonymValueAction } from 'store/actions/creators/synonyms';

const mapStateToProps = (state) => {
	return {
		activeTalkybot: state?.activeTalkybot,
		activeEntity: state?.entities?.activeEntity,
		lists: state?.entities?.lists,
		values: state?.synonyms?.values,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getAllSynonymValues: ({synonymId, botBuilderAppId}) => dispatch(getAllSynonymValuesAction({synonymId, botBuilderAppId})),
		addNewSynonymValue: ({synonymId, botBuilderAppId, value, enqueueSnackbar, setUiLoadingError}) => dispatch(addNewSynonymValueAction({synonymId, botBuilderAppId, value, enqueueSnackbar, setUiLoadingError})),
		editSynonymValue: ({synonymId, botBuilderAppId, valueId, value, enqueueSnackbar, setUiLoadingError}) => dispatch(updateSynonymValueAction({synonymId, botBuilderAppId, valueId, value, enqueueSnackbar, setUiLoadingError})),
		deleteSynonymValue: ({synonymId, botBuilderAppId, valueId, enqueueSnackbar, setUiLoadingError}) => dispatch(deleteSynonymValueAction({synonymId, botBuilderAppId, valueId, enqueueSnackbar, setUiLoadingError})),
	};
};

export default connect(mapStateToProps, mapDispatchToProps);
