import React, { useRef, useState } from 'react';
// eslint-disable-next-line import/no-unresolved
import { Mention, MentionsInput } from 'react-mentions';
import Picker from 'emoji-picker-react';
import { Box, Grid, makeStyles, Menu } from '@material-ui/core';
import addMentionIcon from 'assets/icons/add.svg';
import defaultStyle from './defaultStyle';
import defaultMentionStyle from './defaultMentionStyle';

// that match last occurrence of( >>><<<)
// NOT USE UNLESS YOU KNOW WHAT IT DOES
const REGEX = /(\B>>><<<\B)(?!.*[\r\n]*.*\1)/;

const MARKUP = '@@{{{ __display__ }}}@@>>><<<';
const TRIGGER = '@';

// NOTE: react-mentions limitation:
// this package is need mentions as array of objects with id and display properties
// and use markup (only accept id-display) to destruct the mentions from the input text
// so that we make display as:json.stringify() to enable us using many attributes not just display text

/**
 *
 * @param {array[{id,display}]} mentionsList suggestion mentions - array of objects with id and display properties
 * @param {string} placeholder  Placeholder text
 * @param {boolean} singleLine  Make text input single line
 * @param {Object} singlelineStyle to override single line style
 * @param {string} onChange  Callback function when input value is changed (when not using react hook form)
 * @param {string} className  Class name for input
 * @param {Object} hookFormField  React hook form field (ref , name ,onChange,onblur,...)
 * @param {boolean} withEmoji  Show emoji picker
 * @param {boolean} withMention  Show add mention icon
 *
 * @returns {React.ReactElement}  Text input with mentions and emoji picker
 */
const DynamicInput = ({
	error = false,
	mentionsList = [],
	placeholder,
	singleLine,
	singleLineStyle,
	onChange,
	className,
	hookFormField = {},
	withEmoji,
	withMention,
	...props
}) => {
	const mentionListWithoutSpace = mentionsList.map((mention) => ({
		...mention,
		display: JSON.stringify({
			...JSON.parse(mention.display),
			display: JSON.parse(mention.display).display.replaceAll(' ', '_'),
			value: JSON.parse(mention.display)?.value !== null ? JSON.parse(mention.display).value.replaceAll(' ', '+') : null
		}),
	}));

	const { ref, ...restFormField } = hookFormField;
	const classes = useStyles();
	// emoji
	const [emojiPicker, setEmojiPicker] = useState(false);
	const handleEmojiPickerToggle = (e) => {
		// setEmojiPicker((prev) => !prev);
		setEmojiPicker(e.currentTarget);
	};
	const handleClosePicker = () => {
		setEmojiPicker(false);
	};
	const handleAddMentions = (e) => {
		restFormField?.setFocus(restFormField?.name);
		if (restFormField?.setValue && restFormField?.name)
			restFormField.setValue(
				restFormField.name,
				`${restFormField.value} @`
			);
	};
	const onEmojiClick = (event, emojiObject) => {
		if (
			restFormField?.setValue &&
			emojiObject?.emoji &&
			restFormField?.value &&
			restFormField?.name
		)
			restFormField.setValue(
				restFormField.name,
				restFormField.value + emojiObject.emoji
			);
		// handleClosePicker();
	};

	/**
	 * on change we set value of mentions attribute and then we set value of input by calling onChange function with event parameter
	 */
	const serializeOnChange = (event, newVal, newPlainTextVal, mentions) => {
		// for react hook form
		if (restFormField?.name) {
			// call onchange of react hook form to set value of input
			restFormField.onChange(event);
			// we comment this value until backend need it
			// 	// set value of mentions attribute
			// if (restFormField?.InputNamesSchema.mentions) {
			// 	restFormField.setValue(
			// 		restFormField?.InputNamesSchema.mentions,
			// 		mentions
			// 	);
			return;
		}

		// if not using react hook form call props's onchange
		onChange(event, mentions);
	};
	const renderSuggestion = (suggestion) => {
		if (suggestion.is_custom_field)
			return (
				<div>
					{suggestion.display}{' '}
					<span className={classes.GroupName}> Custom field</span>
				</div>
			);
		if (suggestion.is_bot_field)
			return (
				<div>
					{suggestion.display}{' '}
					<span className={classes.GroupName}> Bot field</span>
				</div>
			);

		return <option>{suggestion.display}</option>;
	};
	return (
		<Box style={{ position: 'relative ' }}>
			<MentionsInput
				inputRef={ref}
				{...restFormField} // should be at top to enable local (onChange) overwriting react hook's onchange
				onChange={serializeOnChange} // will overWrite react hook form's onchange
				// className={className}
				style={defaultStyle}
				singleLine={singleLine}
				// allowSuggestionsAboveCursor
				placeholder={placeholder}
				className={singleLine && singleLineStyle}
				{...props}
			>
				<Mention
					markup={MARKUP}
					style={defaultMentionStyle}
					trigger={TRIGGER}
					data={mentionListWithoutSpace}
					appendSpaceOnAdd
					// how the mention will be displayed on text input
					displayTransform={(id, display) =>
						JSON.parse(display).display
					}
					// how the mentions list will be displayed
					renderSuggestion={(
						suggestion,
						search,
						highlightedDisplay
					) => renderSuggestion(JSON.parse(suggestion.display))}
				/>
			</MentionsInput>

			<div className={classes.positioningDiv}>
				<Grid container flex alignItems="center">
					{withMention && (
						<img
							onClick={handleAddMentions}
							className={classes.addMentionIcon}
							src={addMentionIcon}
							alt="Mention some one"
						/>
					)}
					{withEmoji && (
						<>
							<i
								aria-controls="pickerId"
								aria-haspopup="true"
								onClick={handleEmojiPickerToggle}
								className={`far fa-smile ${classes.emoji}`}
							>
								{' '}
							</i>
							<Menu
								variant="menu"
								id="pickerId"
								anchorEl={emojiPicker}
								keepMounted
								open={Boolean(emojiPicker)}
								onClose={handleClosePicker}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								// transformOrigin={{
								// 	vertical: 'top',
								// 	horizontal: 'right',
								// }}
							>
								{emojiPicker && (
									<Picker
										onEmojiClick={onEmojiClick}
										preload
									/>
								)}
							</Menu>
						</>
					)}
				</Grid>
			</div>
		</Box>
	);
};

export default DynamicInput;
const useStyles = makeStyles((theme) => ({
	positioningDiv: {
		position: 'absolute',
		bottom: 10,
		right: 10,
	},
	emoji: {
		fontSize: '1.4rem',
		marginLeft: '.5rem',
		// position: 'relative',
		cursor: 'pointer',
	},
	addMentionIcon: {
		width: theme.spacing(22),
		cursor: 'pointer',
	},
	GroupName: {
		...theme.typography.caption,
		fontStyle: 'italic',
		color: theme.palette.primary.main,
	},
}));
