import React from 'react';
import { CardContent, Typography, Grid } from '@material-ui/core';
import renderDate from 'helpers/renderDate';
import useStyles from './styles';

const EditNoteBody = ({ userName, personName, content }) => {
	const classes = useStyles();
	const hourFormate = 'h:mm am/pm';

	return (
		<Grid container className={classes.root}>
			<Grid item xs={9} className={classes.statusWrapper}>
				<CardContent className={classes.content}>
					<Typography variant="body" className={classes.activity}>
						<Typography
							display="inline"
							className={classes.action}
							title={userName}
						>
							{`${userName}  `}
						</Typography>
						Deleted Note{' '}
						{/* <Typography display="inline" className={classes.action}>
							{personName && personName}{' '}
						</Typography> */}
						{content?.to && content?.to?.attributes?.user_profile && (
							<>
								Of{' '}
								<Typography
									display="inline"
									// className={classes.action}
								>
									{content?.to?.attributes?.user_profile.name}
								</Typography>
							</>
						)}
					</Typography>
				</CardContent>

				{content?.note?.body && (
					<CardContent className={classes.content}>
						<div
							className={classes.messageContainer}
							title={content?.note?.body}
						>
							{content?.note?.body}
						</div>
					</CardContent>
				)}
			</Grid>
			<Grid item xs={3} className={classes.dateWrapper}>
				<CardContent className={classes.content}>
					<Typography variant="body2" className={classes.date}>
						{/* {content.date} */}
						{renderDate(content.date, hourFormate)}
					</Typography>
				</CardContent>
			</Grid>
		</Grid>
	);
};

export default EditNoteBody;
