import {
	ADD_TRIGGER_ERROR_FUN,
	CLEAR_TOPIC_FLOWS_ERRORS,
	DELETE_TOPIC_FLOWS_ERROR,
	FIRE_TOPIC_FLOWS_ERROR,
} from 'store/actions/types/topicFlowsErrors';

const initialState = {
	tobicFlowsErrorsInfo: [],
	allNodesErrorsCount: 0,
	triggerFun: () => {},
};

export default (state = initialState, { type, payload }) => {
	const foundedNode = state.tobicFlowsErrorsInfo?.find(
		(item) => item?.nodeId === payload?.nodeId
	);
	const setNodeError = () => {
		return foundedNode
			? // node is exist
			  state.tobicFlowsErrorsInfo.map((node) => {
					if (node.nodeId === foundedNode.nodeId) {
						return {
							...node,
							nodeErrorState: true,
						};
					}
					return node;
			  })
			: // not exist
			  [
					...state.tobicFlowsErrorsInfo,
					{ nodeId: payload.nodeId, nodeErrorState: true },
			  ];
	};
	const clearNodeErorrState = () => {
		return foundedNode
			? // node is exist
			  state.tobicFlowsErrorsInfo.filter(
					(node) => node.nodeId !== foundedNode.nodeId
			  )
			: // not exist
			  [...state.tobicFlowsErrorsInfo];
	};

	switch (type) {
		case FIRE_TOPIC_FLOWS_ERROR: {
			return {
				...state,
				tobicFlowsErrorsInfo: setNodeError(),
				allNodesErrorsCount:
					foundedNode && foundedNode.nodeErrorState
						? state.allNodesErrorsCount
						: state.allNodesErrorsCount + 1,
			};
		}
		case DELETE_TOPIC_FLOWS_ERROR: {
			return {
				...state,
				tobicFlowsErrorsInfo: clearNodeErorrState(),
				allNodesErrorsCount:
					foundedNode && foundedNode.nodeErrorState
						? state.allNodesErrorsCount - 1
						: state.allNodesErrorsCount,
			};
		}
		case ADD_TRIGGER_ERROR_FUN: {
			return {
				...state,
				triggerFun: payload?.triggerFun,
			};
		}
		case CLEAR_TOPIC_FLOWS_ERRORS: {
			return {
				...initialState,
			};
		}
		default:
			return state;
	}
};

// using counter

/**
 * import {
	CLEAR_NODE_ERRORS_COUNTER_FROM_TOPIC_FLOWS_ERRORS,
	DELETE_TOPIC_FLOWS_ERROR,
	FIRE_TOPIC_FLOWS_ERROR,
} from 'store/actions/types/topicFlowsErrors';

const initialState = {
	tobicFlowsErrorsInfo: [],
	allNodesErrorsCount: 0,
};

export default (state = initialState, { type, payload }) => {
	const foundedNode = state.tobicFlowsErrorsInfo?.find(
		({ nodeId }) => nodeId === payload?.nodeId
	);
	const DEFAULT_INCREMENT_VALUE = 1;
	const DEFAULT_DECREMENT_VALUE = 1;

	const incrementNodeErrorCounterBy = (
		incrementValue = DEFAULT_INCREMENT_VALUE
	) => {
		return foundedNode
			? // node is exist
			  state.tobicFlowsErrorsInfo.map((node) => {
					if (node.nodeId === foundedNode.nodeId) {
						return {
							...node,
							nodeErrorsCount:
								node.nodeErrorsCount + incrementValue,
						};
					}
					return node;
			  })
			: // not exist
			  [
					...state.tobicFlowsErrorsInfo,
					{ nodeId: payload.nodeId, nodeErrorsCount: incrementValue },
			  ];
	};
	const decrementNodeErorrCounterBy = (
		decrementValue = DEFAULT_DECREMENT_VALUE
	) => {
		return foundedNode
			? // node is exist
			  state.tobicFlowsErrorsInfo.map((node) => {
					if (
						node.nodeId === foundedNode.nodeId &&
						node.nodeErrorsCount > 0
					) {
						return {
							...node,
							nodeErrorsCount:
								node.nodeErrorsCount - decrementValue,
						};
					}
					return node;
			  })
			: // not exist
			  [...state.tobicFlowsErrorsInfo];
	};
	switch (type) {
		case FIRE_TOPIC_FLOWS_ERROR: {
			return {
				...state,
				tobicFlowsErrorsInfo: incrementNodeErrorCounterBy(
					DEFAULT_INCREMENT_VALUE
				),
				allNodesErrorsCount:
					state.allNodesErrorsCount + DEFAULT_INCREMENT_VALUE,
			};
		}
		case DELETE_TOPIC_FLOWS_ERROR: {
			return {
				...state,
				tobicFlowsErrorsInfo: decrementNodeErorrCounterBy(
					DEFAULT_DECREMENT_VALUE
				),

				allNodesErrorsCount:
					foundedNode && state.allNodesErrorsCount > 0
						? state.allNodesErrorsCount - DEFAULT_DECREMENT_VALUE
						: state.allNodesErrorsCount,
			};
		}
		case CLEAR_NODE_ERRORS_COUNTER_FROM_TOPIC_FLOWS_ERRORS: {
			const numOfErrorsInTheNode = foundedNode
				? foundedNode.nodeErrorsCount
				: 0;
			return {
				...state,
				tobicFlowsErrorsInfo:
					decrementNodeErorrCounterBy(numOfErrorsInTheNode),
				allNodesErrorsCount:
					foundedNode && state.allNodesErrorsCount > 0
						? state.allNodesErrorsCount - numOfErrorsInTheNode > 0
							? state.allNodesErrorsCount - numOfErrorsInTheNode
							: 0
						: state.allNodesErrorsCount,
			};
		}
		default:
			return state;
	}
};

 */
