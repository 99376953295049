import {
	LIST_ALL_ACCOUNTS,
	LIST_ALL_PAGES_FOR_ACCOUNT,
	CONNECT_TO_PAGE,
	DISCONNECT_TO_PAGE,
	CLEAN_PAGES,
	REMOVE_PAGE,
	DISCONNECT_FROM_PAGE,
	DISCONNECT_PAGE_CHANNEL,
	REMOVE_FACEBOOK_CHANNEL,
	REMOVE_INSTAGRAM_CHANNEL,
	DISCONNECT_AND_CONNECT_PAGE_CHANNEL,
	DISCONNECT_AND_CONNECT_INSTAGRAM_PAGE_CHANNEL,
} from 'store/actions/types/tenants';

const initialState = {
	accounts: [],
	pages: [],
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case LIST_ALL_ACCOUNTS: {
			const { accounts } = payload;
			return {
				...state,
				accounts: accounts.message ? [] : [...accounts],
			};
		}
		case LIST_ALL_PAGES_FOR_ACCOUNT: {
			const { pages } = payload;
			return {
				...state,
				pages,
			};
		}
		case CONNECT_TO_PAGE: {
			const { pageId, accountId } = payload;
			const pages = [...state.pages];
			const accounts = [...state.accounts];
			const accountIndex = accounts.findIndex(
				(account) => account.id === accountId
			);
			if (accountIndex !== -1) {
				const account = accounts[accountIndex];
				const channelIndex = account.channels.findIndex(
					(channel) => channel.id === pageId
				);
				if (channelIndex !== -1) {
					account.channels[
						channelIndex
					].attributes.is_connected.is_connected_before = true;
				}
				accounts[accountIndex] = account;
			}

			// const i = pages.findIndex((page) => page.id === pageId);
			// if (i && pages[i]) {
			// 	pages[i].isConnected = true;
			// }

			return {
				...state,
				accounts,
			};
		}

		case DISCONNECT_TO_PAGE: {
			const { pageId } = payload;
			const pages = [...state.pages];
			const i = pages.findIndex((page) => page.id === pageId);
			if (i && pages[i]) {
				pages[i].isConnected = false;
			}
			return {
				...state,
				pages: [],
			};
		}
		case DISCONNECT_FROM_PAGE: {
			const { pageId } = payload;
			const pages = [...state.pages];
			const i = pages.findIndex((page) => page.id === pageId);
			if (i && pages[i]) {
				pages[i].isConnected = false;
			}
			return {
				...state,
				pages: [],
			};
		}

		case REMOVE_PAGE: {
			return {
				...state,
				// pages,
			};
		}

		case CLEAN_PAGES: {
			return {
				accounts: null,
				pages: [],
			};
		}
		// case DISCONNECT_PAGE_CHANNEL: {
		// 	// Recieve unique id [accountId,channelId,talkbotId]
		// 	// Recieve the changed data  [isconnected_before,theSametenant]
		// 	const { accountId, channelId, talkybotId, accounts } = payload;

		// 	const newAccounts = accounts;
		// 	// loop in omash to get specific account to fuck it
		// 	const accountIndex = newAccounts.findIndex(
		// 		(account) => account.id === accountId
		// 	);
		// 	const targetAccount = accounts[accountIndex];
		// 	const targetAccountChannels = targetAccount.channels;
		// 	const channelIndex = targetAccountChannels.findIndex(
		// 		(channel) => channel.id === channelId
		// 	);
		// 	const targetChannel = targetAccountChannels[channelIndex];
		// 	const targetChannelConnectionState =
		// 		!targetChannel.attributes.is_connected.is_connected_before;

		// 	targetChannel.targetChannel.attributes.is_connected.is_connected_before =
		// 		targetChannelConnectionState;

		// 	targetAccount.channels[channelIndex] = targetChannel;
		// 	newAccounts[accountIndex] = targetAccount;

		// 	return {
		// 		...state,
		// 		...newAccounts,
		// 	};
		// }
		case REMOVE_FACEBOOK_CHANNEL: {
			return {
				...state,
				accounts: payload,
			};
		}
		case REMOVE_INSTAGRAM_CHANNEL: {
			return {
				...state,
				accounts: payload,
			};
		}
		case DISCONNECT_AND_CONNECT_PAGE_CHANNEL:
			return {
				...state,
			};
		case DISCONNECT_AND_CONNECT_INSTAGRAM_PAGE_CHANNEL:
			return {
				...state,
			};
		default:
			return state;
	}
};
