import {
	makeStyles,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	CardMedia,
} from '@material-ui/core';

import React from 'react';
import Bot from 'assets/icons/myBotAvatar.svg';
import styles from './index.module.scss';
import ClickableText from './ClickableText';

const BotsTable = ({ talkybots, formatNumberWithCommas }) => {
	const classes = useStyles();

	return (
		<div className={styles.mainContainer}>
			<h3 className={styles.title}>Bot Usage</h3>
			<div className={styles.botsContainer}>
				{talkybots?.map((bot, index) => (
					<div className={styles.singleBotContainer}>
						<div className={styles.imageNameContainer}>
							<img src={bot?.attributes?.image ? bot?.attributes?.image : Bot} alt='img' className={styles.botImage} />
							<span className={styles.botName}>{bot?.attributes?.name}</span>
						</div>
						<div className={styles.divider} />
						<div className={styles.botInfoContainer}>
							<div className={styles.customFields}>
								<span className={styles.botInfoText}>Custom Fields</span>
								<span className={styles.botInfoValue}>{bot?.attributes?.custom_fields_number}</span>
							</div>
							<div className={styles.customFields}>
								<span className={styles.botInfoText}>Team Members</span>
								<span className={styles.botInfoValue}>{bot?.attributes?.team_member_number}</span>
							</div>
							<div className={styles.customFields}>
								<span className={styles.botInfoText}>MAS</span>
								<span className={styles.botInfoValue}>
									<ClickableText numbers={bot?.attributes?.sessions_number} formatNumberWithCommas={formatNumberWithCommas} />
								</span>
							</div>
							<div className={styles.customFields}>
								<span className={styles.botInfoText}>MAU</span>
								<span className={styles.botInfoValue}>
									<ClickableText numbers={bot?.attributes?.engagements_number} formatNumberWithCommas={formatNumberWithCommas} />
								</span>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default BotsTable;

const useStyles = makeStyles((theme) => ({
	root: {
		borderRadius: '1rem',
		background: 'white',
		boxShadow: 'none',

		marginRight: '1rem',
		height: '50vh',
		overflow: 'scroll',
		'&::-webkit-scrollbar': {
			width: theme.spacing(6),
		},
		'&::-webkit-scrollbar-track': {
			boxShadow: '0px 3px 40px #00000029',
			webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
		},
		'&::-webkit-scrollbar-thumb': {
			borderRadius: '3rem',
			backgroundColor: theme.palette.primary.main,
		},
	},

	tableHeader: {
		...theme.typography.subtitle2_2,
		// padding: '1.5rem',
		backgroundColor: theme.palette.common.white,
	},

	customTableRow: {
		'&:hover': {
			boxShadow: '0px 1px 28px #0000001f',
		},
	},
	tableRow: {
		...theme.typography.body1,
		fontWeight: 500,
		// padding: '1.5rem',
	},

	label: {
		fontFamily: 'Poppins',
		width: '100%',
		fontSize: '1.1875rem ',
	},
	list: {
		fontFamily: 'Poppins',
	},
	statue: {
		borderRadius: '10px',
		textTransform: 'capitalize',
		padding: '2px 1.25rem',
	},
	noneStatue: {
		background: '#DEDADA',
	},
	leadStatue: {
		background: '#B7DBFF',
	},
	contactStatue: {
		background: '#B7FFE1',
	},
	client: {
		display: 'flex',
		alignItems: 'center',
	},
	image: {
		width: '2.5rem',
		borderRadius: '50%',
		height: '2.5rem',
		objectFit: 'cover',
	},
	iconButton: {
		display: 'flex',
		alignItems: 'center',
		fontSize: '0.9375rem',
		cursor: 'pointer',
		marginRight: '1rem',
		'&:hover': {
			color: '#6418c3',
		},
	},

	fieldValue: {
		fontSize: '1.25rem',
		fontFamily: 'Poppins',
		color: 'black',
	},
	formKeyContainer: {
		width: '25%',
	},
	inputField: {
		fontSize: '1.125rem',
		fontFamily: 'Poppins',
		color: 'black',
	},
}));
