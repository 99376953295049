import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from 'components/common/Grid/GridContainer';
import GridItem from 'components/common/Grid/GridItem';
import FormSelect from 'controllers/FormSelect';
import FormTextInput from 'controllers/FormTextInput';
import mapCategories from 'helpers/MapCategories';
import mapLanguages from 'helpers/mapLanguages';
import useSetUiLoadingError from 'hooks/useSetUiLoadingError';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { languagesArray } from 'constants/supportedLanguages'

const AddRegex = ({
	handleCloseModal,
	addNewEntityRegex,
	entityId,
	handleRefreshPriority,
}) => {
	const classes = useStyles();
	const history = useHistory();

	const { enqueueSnackbar } = useSnackbar();


	// const isEntityWithSameName = (name, allEntities) => {
	// 	return allEntities.some((entity) => entity.name === name);
	// };

	const validationSchema = yup.object().shape({
		name: yup.string().required('Threshold is required').test({
			name: 'notOnlySpaces',
			message: 'Value cannot be only spaces',
			test: (note) => {
				// Check if the value is not only spaces
				return !(/^\s+$/.test(note));
			},
		}).min(3).max(30).nullable(),
		language: yup
			.string().required('You should select a language')
		,
	});

	const {
		handleSubmit,
		formState: { errors },
		setError,
		control,
		watch,
		reset,
		getValues,
	} = useForm({
		resolver: yupResolver(validationSchema),
		mode: 'all',
		defaultValues: {
			language: 0,
		},
	});

	// 🔁 Loading and error handling
	const [isLoading, successData, backendError, setUiLoadingError] =
		useSetUiLoadingError();


	const handleSubmitCreateIntent = async (payload) => {
		if (payload.language === '0') {
			setError('language', {
				type: 'manual',
				message: 'You should select Language',
			});
			return
		}

		if (
			Object.keys(errors).length === 0 &&
			payload.language !== 0) {

			addNewEntityRegex({
				entityId,
				language: payload.language,
				name: payload.name,
				enqueueSnackbar,
				setUiLoadingError,
			});
		}
	};

	// dismiss the model when the backend is done
	useEffect(() => {
		if (successData) {
			handleCloseModal();
			handleRefreshPriority()
		}
	}, [successData]);

	return (
		<div>
			<form onSubmit={handleSubmit(handleSubmitCreateIntent)}>
				<GridContainer
					spacing={10}
					justifyContent="space-evenly"
					className={classes.row}
				>

					<GridItem md={12} xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
						<span className={classes.thresholdText}>Name</span>
						<Controller
							name="name"
							control={control}
							defaultValue=""
							render={({ field }) => (
								<FormTextInput
									{...field}
									error={errors?.name?.message}
									type="text"
									placeHolder="Name"
									style={{ margin: '20px 0px' }}
								/>
							)}
						/>
					</GridItem>
					<GridItem md={12} xs={12}>
						<span className={classes.languageText}>Select Language</span>
						<Controller
							name="language"
							control={control}
							render={({ field }) => (
								<FormSelect
									{...field}
									error={errors?.language?.message}
									select
									smallHeight
									placeHolder="Select A language"
									// array of categories
									options={mapLanguages(languagesArray)}
									style={{
										padding: '7px',
										border: '1px solid #6418C3',
									}}
								/>
							)}
						/>
					</GridItem>
				</GridContainer>

				<GridContainer
					justifyContent="flex-end"
					className={classes.row}
					style={{ marginTop: '40px', marginBottom: '20px' }}
				>
					<Button
						onClick={() => handleCloseModal()}
						style={{ marginRight: '20px', padding: '10px' }}
					>
						Cancel
					</Button>
					<Button variant="contained" color="primary" type="submit">
						{isLoading ? (
							<CircularProgress color="white" size={15} />
						) : (
							'Add'
						)}
					</Button>
				</GridContainer>
			</form>
		</div>
	);
};


export default AddRegex

const useStyles = makeStyles((theme) => ({
	addEntity: {
		borderRadius: '1rem',
		padding: '0.5rem 2rem',
		'&:hover': {
			backgroundColor: '#B77DFF',
		},
	},
	languageText: {
		fontSize: '14px',
		fontWeight: '600',
	},
	thresholdText: {
		fontSize: '14px',
		fontWeight: '600',
		margin: '16px 0px'
	},
}));
