/** ================= Saved Replies actions Types ================== */

export const GET_ALL_SAVED_REPLIES = 'GET_ALL_SAVED_REPLIES';
export const GET_ALL_SAVED_REPLIES_ERROR = 'GET_ALL_SAVED_REPLIES_ERROR';
export const RESOLVE_SINGLE_SAVED_REPLY = 'RESOLVE_SINGLE_SAVED_REPLY';
export const RESOLVE_SINGLE_SAVED_REPLY_ERROR =
	'RESOLVE_SINGLE_SAVED_REPLY_ERROR';
export const GET_SINGLE_SAVED_REPLY = 'GET_SINGLE_SAVED_REPLY';
export const GET_SINGLE_SAVED_REPLY_ERROR = 'GET_SINGLE_SAVED_REPLY_ERROR';
export const CREATE_NEW_SAVED_REPLIES = 'CREATE_NEW_SAVED_REPLIES';
export const CREATE_NEW_SAVED_REPLIES_ERROR = 'CREATE_NEW_SAVED_REPLIES_ERROR';
export const UPDATE_SAVED_REPLIES = 'UPDATE_SAVED_REPLIES';
export const UPDATE_SAVED_REPLIES_ERROR = 'UPDATE_SAVED_REPLIES_ERROR';
export const DELETE_SAVED_REPLIES = 'DELETE_SAVED_REPLIES';
export const DELETE_SAVED_REPLIES_ERROR = 'DELETE_SAVED_REPLIES_ERROR';
