import { Paper } from '@material-ui/core';
import ContactHeader from './contactHeader';
const Contact = () => {
	return (
		<Paper elevation={0}>
			<ContactHeader />
		</Paper>
	);
};

export default Contact;
