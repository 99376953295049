import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core';
// import Header from './Header';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import useSetUiLoadingError from 'hooks/useSetUiLoadingError';
import { useSnackbar } from 'notistack';
import SynonymHeader from './Header';
import SynonymBody from './Body';

export default function Component({
  activeSynonym,
  clearActiveSynonym,
  activateSynonym,
  activeTalkybot,
  getPriorityList,
  priorityList,
  changePriority,
}) {
  const classes = useStyles();
  const [tabsValue, setTabsValue] = useState(0);
  const [refreshPriority, setRefreshPriority] = useState(0)
  const [openConfigPriorityMenu, setopenConfigPriorityMenu] = useState(false)

  const { synonymId, talkybotId } = useParams();
  const botBuilderAppId = activeTalkybot?.bot_builder_id

  const [isLoading, successData, backendError, setUiLoadingError] =
    useSetUiLoadingError();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    activateSynonym(synonymId, talkybotId);
    // return () => {
    //   clearActiveSynonym()
    // }
  }, [synonymId, talkybotId]);



  return (
    <Paper style={{ padding: '20px', height: '98vh' }}>
      <Grid container>
        <SynonymHeader
          activeSynonym={activeSynonym}
          activateSynonym={activateSynonym}
        />
      </Grid>
      <Grid container>
        <Grid
          item
          xs
          style={{
            fontFamily: 'poppins',
            fontWeight: '400',
          }}
        >
          <Divider />
        </Grid>
      </Grid>
      <div className={classes.listsContainer}>
        {Object.keys(activeSynonym).length > 0 && (
          <SynonymBody
            synonym={activeSynonym}
            activateSynonym={activateSynonym}
            synonymId={synonymId}
          />
        )}
      </div>
    </Paper>
  );
}
const useStyles = makeStyles(() => ({
  headingStyle: {
    fontWeight: 500,
    fontSize: '15px',
    color: '#000000',
    fontFamily: 'Poppins',
    marginTop: '30px',
    textTransform: 'none',
  },
  paragraphStyle: {
    fontSize: '13px',
    color: '#626262',
    fontFamily: 'Poppins',
    marginTop: '10px',
    marginBottom: '20px',
    userSelect: 'none',
  },
  listsContainer: {
    width: '100%',
    '@media (max-width: 1363px) or (max-height: 760)': {
      width: '100%',
      height: '77vh',
    },
    overflowX: 'hidden',
    overflowY: 'auto',
    scrollbarWidth: "none", // Hide the scrollbar for firefox
    '&::-webkit-scrollbar': {
      display: 'none', // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
    },
    '&-ms-overflow-style:': {
      display: 'none', // Hide the scrollbar for IE
    },
    padding: '0px 0px',
    height: '83vh',

    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    '& .MuiAccordionSummary-root.Mui-expanded ': {
      borderTopRightRadius: '12px',
      borderTopLeftRadius: '12px',
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      height: '53px',
      minHeight: '0',
      border: 'none',
      boxShadow: 'none',
      borderTopRightRadius: '12px',
      borderTopLeftRadius: '12px',
    },
    '& .MuiAccordion-root.Mui-expanded': {
      border: '1px solid #4E4E4E',
      boxShadow: 'none',
    },
    '& .MuiAccordion-rounded': {
      borderRadius: '12px',
    },
    '& .MuiPaper-elevation1': {
      boxShadow: 'none',
    },
    '& ::-webkit-scrollbar': {
      width: '0'
    },
    '& .MuiAccordion-root:before': {
      height: '0px',
    }
  },
}));
