import {
	// get message in and out
	GET_MESSAGES_IN_SUCCESS,
	// get message in and out
	GET_MESSAGES_OUT_SUCCESS,
	// get gender
	GET_GENDER_SUCCESS,
	// get closed conversation by who
	GET_CLOSED_CONVERSATION_SUCCESS,
	// get number of conversation
	GET_NUMBER_OF_CONVERSATION_SUCCESS,
	// get most frequent questions
	GET_MOST_FREQUENT_QUESTIONS_SUCCESS,
	// get Statement Recognition
	GET_STATEMENT_RECOGNITION_SUCCESS,
	// get Completion scenarios
	GET_COMPLETION_SCENARIOS_SUCCESS,
	// get Most Used Scenarios
	GET_MOST_USED_SCENARIOS_SUCCESS,
	// get Conversation rating
	GET_CONVERSATION_RATING_SUCCESS,
	// get customer Status analytics
	GET_CUSTOMER_STATUS_SUCCESS,
	// get assigned & not assigned customers
	GET_ASSIGNED_CUSTOMERS_SUCCESS,
	// get number of new Customers
	GET_NUMBER_OF_NEW_CUSTOMERS_SUCCESS,
	// get number of conversation sessions
	GET_NUMBER_OF_CONVERSATION_SESSIONS_SUCCESS,
	// get team members messages
	GET_TEAM_MEMBERS_MESSAGES_SUCCESS,
	RESET_TEAM_MEMBERS_MESSAGES_SUCCESS,
	// get customer analytics
	GET_CUSTOMER_ANALYTICS_SUCCESS,
	// get takeover and escalation
	GET_TAKEOVER_AND_ESCALATION_SUCCESS,
	// get team handover
	GET_TEAM_HANDOVER_SUCCESS,
	// get bot escalation
	GET_BOT_ESCALATION_SUCCESS,
	GET_BOT_INTERACTIONS_SUCCESS,
	// get bot activites
	GET_BOT_ACTIVITES_SUCCESS,
	RESET_BOT_ACTIVITES_SUCCESS,
	GET_SENTIMENT_SUCCESS,
} from 'store/actions/types/dashboard';
const initialAuthState = {
	messagesIn: [],
	messagesOut: [],
	customerAnalytics: {},
	gender: [],
	numberOfUnqiueUsers: {},
	numberOfConversationSessions: {},
	closed_conversation: [],
	number_of_conversation: [],
	most_frequent_questions: [],
	most_used_scenarios: [],
	statement_recognition: [],
	completion_scenarios: [],
	conversation_rating: [],
	customer_status: {},
	assigned_customers: {},
	takeover_and_escalation: [],
	team_handover: [],
	bot_escalation: [],
	bot_interactions: {},
	team_members_messages: {
		data: [],
		meta: [],
		total: null,
	},
	bot_activites: {
		data: [],
		meta: [],
	},
	sentiment: {},
};
export default (state = initialAuthState, { type, payload }) => {
	switch (type) {
		/******************** Start Overview Tab state  ***************/
		// get message in
		case GET_MESSAGES_IN_SUCCESS:
			return {
				...state,
				messagesIn: payload,
			};
		// get message out
		case GET_MESSAGES_OUT_SUCCESS:
			return {
				...state,
				messagesOut: payload,
			};
		// get customer analytics
		case GET_CUSTOMER_ANALYTICS_SUCCESS:
			return {
				...state,
				customerAnalytics: payload,
			};
		// get customer Status analytics
		case GET_CUSTOMER_STATUS_SUCCESS:
			return {
				...state,
				customer_status: payload,
			};
		// get number of new customers
		case GET_NUMBER_OF_NEW_CUSTOMERS_SUCCESS:
			return {
				...state,
				numberOfUnqiueUsers: payload,
			};
		// get number of conversation sessions
		case GET_NUMBER_OF_CONVERSATION_SESSIONS_SUCCESS:
			return {
				...state,
				numberOfConversationSessions: payload,
			};
		// get Conversation rating
		case GET_CONVERSATION_RATING_SUCCESS:
			return {
				...state,
				conversation_rating: payload,
			};
		// get gender
		case GET_GENDER_SUCCESS:
			return {
				...state,
				gender: payload,
			};
		// get closed conversation by who
		case GET_CLOSED_CONVERSATION_SUCCESS:
			return {
				...state,
				closed_conversation: payload,
			};
		// get number of conversation
		case GET_NUMBER_OF_CONVERSATION_SUCCESS:
			return {
				...state,
				number_of_conversation: payload,
			};
		// get numbers of assigned customers
		case GET_ASSIGNED_CUSTOMERS_SUCCESS:
			return {
				...state,
				assigned_customers: payload,
			};
			//get sentiment percentages
			case GET_SENTIMENT_SUCCESS:
			return {
				...state,
				sentiment: payload,
			};
		/******************** End Overview Tab state  ***************/
		/******************** Start Chatbot Tab state  ***************/
		// get most frequent questions
		case GET_BOT_INTERACTIONS_SUCCESS:
			return {
				...state,
				bot_interactions: payload,
			};
		// get most frequent questions
		case GET_MOST_FREQUENT_QUESTIONS_SUCCESS:
			return {
				...state,
				most_frequent_questions: payload,
			};
		// get Most Used Scenarios
		case GET_MOST_USED_SCENARIOS_SUCCESS:
			return {
				...state,
				most_used_scenarios: Object.keys(payload).map((key) => ({
					title: key,
					value: payload[key],
				})),
			};
		// get Statement Recognition
		case GET_STATEMENT_RECOGNITION_SUCCESS:
			return {
				...state,
				statement_recognition: {
					...payload,
					total:
						payload?.unrecognized_statments +
						payload?.recognized_statments +
						payload?.language_not_supported,
				},
			};
		// get Completion scenarios
		case GET_COMPLETION_SCENARIOS_SUCCESS:
			return {
				...state,
				completion_scenarios: payload,
			};
		// get bot escalation
		case GET_BOT_ESCALATION_SUCCESS:
			return {
				...state,
				bot_escalation: payload,
			};
		/******************** End Chatbot Tab state  ***************/
		/******************** Start Team mamber Tab state  ***************/
		// get team members messages
		case GET_TEAM_MEMBERS_MESSAGES_SUCCESS:
			return {
				...state,
				team_members_messages: {
					data: [
						...state.team_members_messages.data,
						...payload.data.member_messages,
					],
					meta: payload.data.meta,
					total: payload.data.total_messages,
				},
			};
		// reset bot messages
		case RESET_TEAM_MEMBERS_MESSAGES_SUCCESS:
			return {
				...state,
				team_members_messages: {
					data: [],
					meta: [],
					total: null,
				},
			};
		// get takeover and escalation
		case GET_TAKEOVER_AND_ESCALATION_SUCCESS:
			return {
				...state,
				takeover_and_escalation: payload,
			};
		// get team handover
		case GET_TEAM_HANDOVER_SUCCESS:
			return {
				...state,
				team_handover: payload,
			};
		// get bot activites
		case GET_BOT_ACTIVITES_SUCCESS:
			return {
				...state,
				bot_activites: {
					data: [...state.bot_activites.data, ...payload.data],
					meta: payload.meta,
				},
			};
		// reset bot activites
		case RESET_BOT_ACTIVITES_SUCCESS:
			return {
				...state,
				bot_activites: {
					data: [],
					meta: [],
				},
			};
		/******************** End Team mamber Tab state  ***************/
		default:
			return state;
	}
};
