import React from 'react';
import TextInput from 'components/common/Inputs/textInput/TextInput';
import { makeStyles, Typography } from '@material-ui/core';
import DynamicInput from 'components/common/Inputs/textInput/DynamicInput/Component';
import ControlledAutocompleteInput from 'components/common/Inputs/textInput/ControlledAutocompleteInput';

function FormTextInput({ label, error, requiredSymbol = false, ...props }) {
	const classes = useStyles();
	return (
		<>
			{/* label */}
			{label && (
				<Typography className={classes.labelStyle}>
					{label}
					{requiredSymbol && (
						<span className={classes.requiredSymbolStyle}>*</span>
					)}
				</Typography>
			)}
			{/* input field  (dynamic -> third party textfield with option to make mention) */}
			{props?.type === 'DynamicInput' ? (
				<DynamicInput {...props} error={error} />
			) : props.type === 'autoComplete' ? (
				<ControlledAutocompleteInput {...props} error={error} />
			) : (
				<TextInput
					{...props}
					error={error}
					requiredSymbol={requiredSymbol}
				/>
			)}
			{/* show if error */}
			{error && (
				<Typography className={classes.errorStyle}>{error}</Typography>
			)}
		</>
	);
}

export default FormTextInput;

const useStyles = makeStyles((theme) => ({
	labelStyle: {
		...theme.typography.subtitle4,
		color: theme.palette.primary.main,
		marginBottom: theme.spacing(8),
		marginTop: theme.spacing(20),
	},
	errorStyle: {
		...theme.typography.subtitle4,
		textTransform: 'none',

		color: theme.palette.error.main,
		marginTop: theme.spacing(8),
	},
	requiredSymbolStyle: {
		color: theme.palette.error.main,
		marginLeft: theme.spacing(1),
		fontSize: '1rem',
	},
}));
