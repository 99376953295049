import { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import UserAuth from './hoc/UserAuth';

import NotFoundPage from './components/404/Component';
import PrivateRoute from './components/common/PrivateRoute';
import LandingPage_Page from './pages/LandingPage_Page';

/** start admin panel routes */
const APLoginPage = lazy(() => import('./pages/APLoginPage'));
const APOrganizationPage = lazy(() => import('./pages/APOrganizationPage'));
const OrganizationDetailsPage = lazy(() =>
	import('./pages/OrganizationDetailsPage')
);
const AddOrganization = lazy(() => import('./pages/AddOrganization'));
/** end admin panel routes */
const MainLayout = lazy(() => import('./layouts/MainLayout'));
const Login = lazy(() => import('./pages/Login'));
const ForgetPasswordPage = lazy(() => import('./pages/ForgetPasswordPage'));
const ResetPasswordPage = lazy(() => import('./pages/ResetPasswordPage'));
const TenantNameReminderPage = lazy(() =>
	import('./pages/TenantNameReminderPage')
);
const Register = lazy(() => import('./pages/Register'));
const FlowBuilder = lazy(() => import('./pages/FlowBuilder'));
const BotBuilderTopicFlows = lazy(() => import('./pages/BotBuilder'));
const Talkybots = lazy(() => import('./pages/Talkybots'));
const ConfirmationSignupPage = lazy(() => import('./pages/ConfirmationSignup'));
const ConfirmationResetPasswordPage = lazy(() =>
	import('./pages/ConfirmationResetPassword')
);
const Crm = lazy(() => import('./pages/Crm'));
const Store = lazy(() => import('./pages/Store'));
const PreviewApp = lazy(() => import('./pages/PreviewApp'));
const PersonProfilePage = lazy(() => import('./pages/PersonProfilePage'));
const Conversations = lazy(() => import('./pages/Conversations'));
const MembersPage = lazy(() => import('./pages/Members'));
const InviteMemberPage = lazy(() => import('./pages/InviteMember'));
const FAQs = lazy(() => import('./pages/FAQs'));
const CreateJsonApi = lazy(() => import('./pages/CreateJsonApi'));
const FacebookRedirectionHandlerPage = lazy(() =>
	import('./pages/FacebookRedirectionHandlePage')
);
const InstagramRedirectionHandlerPage = lazy(() =>
	import('./pages/InstagramRedirectionHandlePage')
);
const WhatsappRedirectionHandlePage = lazy(() =>
	import('./pages/WhatsappRedirectionHandlePage')
);

const CreateFaqs = lazy(() => import('./pages/CreateFaqs'));

const NotificationsPage = lazy(() => import('./pages/NotificationsPage'));
const BotGatesPage = lazy(() => import('./pages/BotGates'));

const BotBuilderStopWords = lazy(() => import('./pages/BotBuilderStopWords'));

// settings page
const SettingsPage = lazy(() => import('./pages/Settings'));
const CrmSettingsPage = lazy(() => import('./pages/CrmSettings'));
const ConversationsSettingsPage = lazy(() =>
	import('./pages/ConversationsSettings')
);

// dashboard
import { CircularProgress } from '@material-ui/core';
import AdminMainLayout from 'layouts/AdminMainLayout/Component';
import CompleteUserProfilePage from './pages/CompleteUserProfile';
import Dash from './pages/dashboard';
import UsagePage from './pages/UsagePage';
import CreateEntity from 'pages/CreateEntity';
import SynonymDetails from 'pages/SynonymDetails';
const UserProfilePage = lazy(() => import('./pages/UserProfilePage'));

const Routes = () => {
	return (
		<Suspense
			fallback={
				<div className="LOADING_SCREEN_GLOBAL">
					<CircularProgress size={50} />
				</div>
			}
		>
			<Switch>
				{/*----------------------------------- start Puplic routes ------------ */}
				<Route exact path="/admin" component={APLoginPage} />
				<Route path="/home" component={LandingPage_Page} />

				<Route
					exact
					path="/"
					component={() => <Redirect push to="/home" />}
				/>
				<Route
					exact
					path="/completeUserProfile"
					component={() => <CompleteUserProfilePage />}
				/>
				{/* confirmation when redirect from confirmation email  */}
				<Route exact path="/login" component={Login} />
				<Route
					exact
					path="/login_confirmation/:confirmation_token?"
					component={Login}
				/>
				<Route
					exact
					path="/facebook-redirection-handler"
					component={({ ...props }) => (
						<FacebookRedirectionHandlerPage {...props} />
					)}
				/>
				<Route
					exact
					path="/instagram-redirection-handler"
					component={({ ...props }) => (
						<InstagramRedirectionHandlerPage {...props} />
					)}
				/>
				<Route
					exact
					path="/whatsapp-redirection-handler"
					component={({ ...props }) => (
						<WhatsappRedirectionHandlePage {...props} />
					)}
				/>
				<Route
					exact
					path="/forget-password"
					component={ForgetPasswordPage}
				/>
				<Route
					exact
					path="/reset-password"
					component={ResetPasswordPage}
				/>
				<Route
					exact
					path="/organization-name-reminder"
					component={TenantNameReminderPage}
				/>
				<Route exact path="/register" component={Register} />
				<Route
					exact
					path="/noInternetConnection/:previousPath?"
					render={() => (
						<MainLayout withTalkybot={false}>
							<NoInternetConnection />
						</MainLayout>
					)}
				/>
				<Route
					exact
					path="/confirmation-signup/:userID"
					component={(props) => <ConfirmationSignupPage {...props} />}
				/>
				{/*----------------------------------- End Puplic routes ------------ */}
				{/*----------------------------------- Start Private routes ------------ */}

				<PrivateRoute
					exact
					path="/admin/organizations/addOrganization"
					component={() => (
						<AdminMainLayout>
							<AddOrganization />
						</AdminMainLayout>
					)}
				/>
				<PrivateRoute
					exact
					path="/admin/organizations"
					component={() => (
						<AdminMainLayout>
							<APOrganizationPage />
						</AdminMainLayout>
					)}
				/>
				<PrivateRoute
					exact
					path="/admin/organizations/details/:OrganizationId"
					component={() => (
						<AdminMainLayout width="fluid">
							<OrganizationDetailsPage />
						</AdminMainLayout>
					)}
				/>
				<PrivateRoute
					exact
					path="/admin/organizations/details/:OrganizationId/editCurrentSubscription"
					component={() => (
						<AdminMainLayout width="fluid">
							<OrganizationDetailsPage />
						</AdminMainLayout>
					)}
				/>
				<PrivateRoute
					exact
					path="/admin/organizations/details/:OrganizationId/editNextSubscription"
					component={() => (
						<AdminMainLayout width="fluid">
							<OrganizationDetailsPage />
						</AdminMainLayout>
					)}
				/>

				{/*------------------------------------- End Admin Panel---------------------- */}

				<PrivateRoute
					exact
					path="/talkybots"
					component={() => (
						<MainLayout>
							<Talkybots />
						</MainLayout>
					)}
				/>
				<PrivateRoute
					exact
					path="/notifications"
					component={() => (
						<MainLayout>
							<NotificationsPage />
						</MainLayout>
					)}
				/>
				<PrivateRoute
					exact
					path="/usage"
					component={() => (
						<MainLayout>
							<UsagePage />
						</MainLayout>
					)}
				/>

				<PrivateRoute
					exact
					path="/talkybots/:talkybotId/dashboard"
					component={(props) => (
						<MainLayout withTalkybot>
							<Dash {...props} />
						</MainLayout>
					)}
				/>

				<PrivateRoute
					exact
					path="/talkybots/:talkybotId/conversations/:personId?"
					component={UserAuth(Conversations, false, true)}
				/>

				<PrivateRoute
					exact
					path="/talkybots/:talkybotId/contacts/:person_id"
					component={(props) => (
						<MainLayout withTalkybot>
							<PersonProfilePage {...props} />
						</MainLayout>
					)}
				/>

				<PrivateRoute
					exact
					path="/talkybots/:talkybotId/contacts"
					component={() => (
						<MainLayout withTalkybot>
							<Crm />
						</MainLayout>
					)}
				/>
				{/* ********** Note:: if u need to rout to store un comment below ***************** */}
				{/* <Route
					exact
					path="/talkybots/:talkybotId/store"
					component={UserAuth(Store, false)}
				/> */}
				{/* <Route
					exact
					path="/talkybots/:talkybotId/store/:appName/preview"
					component={UserAuth(PreviewApp, false)}
				/> */}

				<PrivateRoute
					exact
					path="/talkybots/:talkybotId/bot-builder"
					component={() => (
						<MainLayout withTalkybot>
							<BotBuilderTopicFlows />
						</MainLayout>
					)}
				/>

				{/* <Route
					exact
					path="/talkybots/:talkybotId/bot-builder/stop-words"
					component={() => (
						<MainLayout withTalkybot>
							<BotBuilderStopWords />
						</MainLayout>
					)}
				/> */}
				<PrivateRoute
					exact
					path="/talkybots/:talkybotId/bot-builder/topic-flows/:flowId"
					component={() => (
						<MainLayout withTalkybot>
							<FlowBuilder />
						</MainLayout>
					)}
				/>

				<PrivateRoute
					exact
					path="/talkybots/:talkybotId/bot-builder/faqs"
					component={() => (
						<MainLayout withTalkybot>
							<FAQs />
						</MainLayout>
					)}
				/>
				{/* create json api */}
				<PrivateRoute
					exact
					path="/talkybots/:talkybotId/bot-builder/integrations/:integrationId"
					component={() => (
						<MainLayout withTalkybot>
							<CreateJsonApi />
						</MainLayout>
					)}
				/>

				<PrivateRoute
					exact
					path="/team-members"
					component={(props) => (
						<MainLayout withTalkybot={false}>
							<MembersPage {...props} />
						</MainLayout>
					)}
				/>

				<PrivateRoute
					exact
					path="/team-members/invite"
					component={(props) => (
						<MainLayout withTalkybot={false}>
							<InviteMemberPage {...props} />
						</MainLayout>
					)}
				/>

				<PrivateRoute
					exact
					path="/team-members/:id"
					component={(props) => (
						<MainLayout withTalkybot={false}>
							<InviteMemberPage {...props} />
						</MainLayout>
					)}
				/>

				<PrivateRoute
					exact
					path="/users/:id/:tab?"
					component={(props) => (
						<MainLayout>
							<UserProfilePage {...props} />
						</MainLayout>
					)}
				/>

				<PrivateRoute
					exact
					path="/talkybots/:talkybotId/bot-builder/:intentId/faqs/create"
					component={(props) => (
						<MainLayout withTalkybot>
							<CreateFaqs />
						</MainLayout>
					)}
				/>
				<PrivateRoute
					exact
					path="/talkybots/:talkybotId/bot-builder/entities/:entityId"
					component={(props) => (
						<MainLayout withTalkybot>
							<CreateEntity />
						</MainLayout>
					)}
				/>
				<PrivateRoute
					exact
					path="/talkybots/:talkybotId/bot-builder/synonyms/:synonymId"
					component={(props) => (
						<MainLayout withTalkybot>
							<SynonymDetails />
						</MainLayout>
					)}
				/>

				{/* settings routes  */}

				<PrivateRoute
					exact
					path="/talkybots/:talkybotId/settings"
					component={(props) => (
						<MainLayout withTalkybot>
							<SettingsPage />
						</MainLayout>
					)}
				/>

				<PrivateRoute
					exact
					path="/talkybots/:talkybotId/settings/crm"
					component={(props) => (
						<MainLayout withTalkybot>
							<CrmSettingsPage />
						</MainLayout>
					)}
				/>
				<PrivateRoute
					exact
					path="/talkybots/:talkybotId/settings/conversations"
					component={(props) => (
						<MainLayout withTalkybot>
							<ConversationsSettingsPage />
						</MainLayout>
					)}
				/>
				{/* bot gates */}
				<PrivateRoute
					path="/talkybots/:talkybotId/bot-gates"
					component={(props) => (
						<MainLayout withTalkybot>
							<BotGatesPage {...props} />
						</MainLayout>
					)}
				/>
				{/*----------------------------------- End Private routes ------------ */}
				{/*----------------------------------- Not Found Page routes ------------ */}

				<Route path="*" component={() => <NotFoundPage />} />
			</Switch>
		</Suspense>
	);
};

export default Routes;
