export const GET_ALL_ENTITIES_CATEGORIES = 'GET_ALL_ENTITIES_CATEGORIES';
export const GET_ALL_CATEGORY_INTENTS = 'GET_ALL_CATEGORY_INTENTS';
export const GET_ALL_CATEGORY_INTENT_REQUEST =
	'GET_ALL_CATEGORY_INTENT_REQUEST';

export const ADD_NEW_ENTITY_CATEGORY = 'ADD_NEW_ENTITY_CATEGORY';

export const UPDATE_ENTITY_CATEGORY = 'UPDATE_ENTITY_CATEGORY';
export const DELETE_ENTITY_CATEGORY =
	'DELETE_ENTITY_CATEGORY';
export const DELETE_ENTITY_CATEGORY_FAIL =
	'DELETE_ENTITY_CATEGORY_FAIL';
export const ADD_NEW_ENTITY_CATEGORY_FAIL =
	'ADD_NEW_ENTITY_CATEGORY_FAIL';
export const SET_DEFAULT_ENTITY_CATEGORY = 'SET_DEFAULT_ENTITY_CATEGORY';
export const SET_DEFAULT_LANGUAGE = '[intents] set active language';
export const ENTITY_ERROR = 'ENTITY_ERROR';
export const GET_ALL_CATEGORY_ENTITIES = 'GET_ALL_CATEGORY_ENTITIES'
export const ADD_NEW_ENTITY = 'ADD_NEW_ENTITY'
export const DELETE_ENTITY = 'DELETE_ENTITY';
export const DELETE_ENTITY_FAIL = 'DELETE_ENTITY_FAIL';
export const UPDATE_ENTITY = 'UPDATE_ENTITY';
export const UPDATE_ENTITY_FAIL = 'UPDATE_ENTITY_FAIL';
export const ACTIVATE_ENTITY = 'ACTIVATE_ENTITY';
export const CLEAR_ACTIVE_ENTITY = 'CLEAR_ACTIVE_ENTITY';
export const GET_ALL_ENTITY_LISTS = 'GET_ALL_ENTITY_LISTS';
export const ADD_NEW_ENTITIY_LIST = 'ADD_NEW_ENTITIY_LIST';
export const UPDATE_ENTITY_LIST = 'UPDATE_ENTITY_LIST';
export const UPDATE_ENTITY_LIST_FAIL = 'UPDATE_ENTITY_LIST_FAIL';
export const DELETE_ENTITY_LIST = 'DELETE_ENTITY_LIST';
export const DELETE_ENTITY_LIST_FAIL = 'DELETE_ENTITY_LIST_FAIL';
export const GET_ALL_LIST_VALUES = 'GET_ALL_LIST_VALUES';
export const ADD_NEW_LIST_VALUE = 'ADD_NEW_LIST_VALUE';
export const UPDATE_LIST_VALUE = 'UPDATE_LIST_VALUE';
export const DELETE_LIST_VALUE = 'DELETE_LIST_VALUE';
export const GET_ALL_ENTITY_REGEX = 'GET_ALL_ENTITY_REGEX';
export const ADD_NEW_ENTITIY_REGEX = 'ADD_NEW_ENTITIY_REGEX';
export const UPDATE_ENTITY_REGEX = 'UPDATE_ENTITY_REGEX';
export const UPDATE_ENTITY_REGEX_FAIL = 'UPDATE_ENTITY_REGEX_FAIL';
export const DELETE_ENTITY_REGEX = 'DELETE_ENTITY_REGEX';
export const DELETE_ENTITY_REGEX_FAIL = 'DELETE_ENTITY_REGEX_FAIL';
export const GET_ALL_REGEX_VALUES = 'GET_ALL_REGEX_VALUES';
export const ADD_NEW_REGEX_VALUE = 'ADD_NEW_REGEX_VALUE';
export const UPDATE_REGEX_VALUE = 'UPDATE_REGEX_VALUE';
export const DELETE_REGEX_VALUE = 'DELETE_REGEX_VALUE';
export const GET_PRIORITY_LIST = 'GET_PRIORITY_LIST';
export const CHANGE_PRIORITY_POSITION = 'CHANGE_PRIORITY_POSITION';
export const GET_PREDEFINED_LIST = 'GET_PREDEFINED_LIST';
export const ADD_PREDEFINED = 'ADD_PREDEFINED';



