import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CircularProgress, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from 'components/common/Grid/GridContainer';
import GridItem from 'components/common/Grid/GridItem';
import TextInput from 'components/common/Inputs/textInput/TextInput';
import { languagesArray } from 'constants/supportedLanguages';
import FormSelect from 'controllers/FormSelect';
import FormTextInput from 'controllers/FormTextInput';
import mapCategories from 'helpers/MapCategories';
import mapLanguages from 'helpers/mapLanguages';
import mapPredefined from 'helpers/mapPredefined';
import useSetUiLoadingError from 'hooks/useSetUiLoadingError';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

const AddPredefined = ({
  activeTalkybot,
  handleCloseModal,
  activeEntity,
  predefinedList,
  addPredefined,
  // setUiLoadingError,
  // isLoading,
  // successData,
  // backendError,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [selectedType, setSelectedType] = useState('');

  const { enqueueSnackbar } = useSnackbar();

  const [
		isLoading,
		successData,
		backendError,
		setUiLoadingError,
		setBackendError,
	] = useSetUiLoadingError();

  // const isEntityWithSameName = (name, allEntities) => {
  // 	return allEntities.some((entity) => entity.name === name);
  // };

  const validationSchema = yup.object().shape({
    type: yup
      .string().required('You should select a language')
    ,
  });


  const {
    handleSubmit,
    formState: { errors },
    setError,
    control,
    watch,
    reset,
    getValues,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    defaultValues: {
      type: 0,
    },
  });

  useEffect(() => {
    predefinedList.map((item) => {
      if (watch('type' !== 0) && item.id === watch('type')) {
        setSelectedType(item.item_type)
      }
    })
  }, [watch('type')])

  // 🔁 Loading and error handling


  const handleSubmitAddPredefined = async (payload) => {
    if (payload.type === '0') {
      setError('language', {
        type: 'manual',
        message: 'You should select Language',
      });
      return
    }

    if (
      Object.keys(errors).length === 0 &&
      payload.type !== 0) {

       addPredefined({
        botBuilderAppId: activeTalkybot?.attributes?.bot_builder_id,
        entityId: activeEntity?.id,
        predefinedId: payload.type,
        enqueueSnackbar,
        setUiLoadingError,
      })
    }
  };

  // dismiss the model when the backend is done
  useEffect(() => {
    if (successData) {
      handleCloseModal();
    }
  }, [successData]);

  return (
    <div>
      <form onSubmit={handleSubmit(handleSubmitAddPredefined)}>
        <GridContainer
          spacing={10}
          justifyContent="space-evenly"
          className={classes.row}
        >

          <GridItem md={12} xs={12}>
            <span className={classes.languageText}>Select Type</span>
            <div className={classes.inputsContainer}>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <FormSelect
                    {...field}
                    error={errors?.language?.message}
                    select
                    smallHeight
                    placeHolder="Select A language"
                    // array of categories
                    options={mapPredefined(predefinedList)}
                    style={{
                      padding: '7px',
                      border: '1px solid #6418C3',
                    }}
                  />
                )}
              />
              <FormTextInput
                disabled
                className={classes.TypeTextInput}
                placeholder='Type: '
                value={selectedType?.length !== 0 ? `Type: ${selectedType}` : ''}
              />
            </div>
          </GridItem>
        </GridContainer>

        <GridContainer
          justifyContent="flex-end"
          className={classes.row}
          style={{ marginTop: '40px', marginBottom: '20px' }}
        >
          <Button
            onClick={() => handleCloseModal()}
            style={{ marginRight: '20px', padding: '10px' }}
          >
            Cancel
          </Button>
          <Button variant="contained" color="primary" type="submit">
            {isLoading ? (
              <CircularProgress color="white" size={15} />
            ) : (
              'Add'
            )}
          </Button>
        </GridContainer>
      </form>
    </div>
  );
};


export default AddPredefined

const useStyles = makeStyles((theme) => ({
  addEntity: {
    borderRadius: '1rem',
    padding: '0.5rem 2rem',
    '&:hover': {
      backgroundColor: '#B77DFF',
    },
  },
  languageText: {
    fontSize: '14px',
    fontWeight: '600',
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '10px',
  },
  TypeTextInput: {
    width: '190px',
    height: '50px',
    border: '1px solid #6418C3',
    borderRadius: '8px',
    fontSize: '14px',
    marginLeft: '5px',
    '& .MuiInputBase-input ': {
      fontSize: '14px'
    },
  },
}));
