import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import reduxThunk from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';

// import {
// 	createStateSyncMiddleware,
// 	initMessageListener,
// 	initStateWithPrevTab,
// } from 'redux-state-sync';

import reducers from './reducers';
// import handleRequestMiddleware from './middleWare/handleRequestMiddleware';

// createStateSync enable use to make state synchronous among multiple taps
const reduxMiddlewares = [
	reduxThunk,
	// createStateSyncMiddleware({
	// 	blacklist: ['persist/PERSIST', 'persist/REHYDRATE'],
	// }),
];

const persistConfig = {
	key: 'root',
	storage,
	// whitelist: ['activeTalkybot', 'talkybots', 'auth', 'tabs', 'adminPanel'],
	whitelist: ['activeTalkybot', 'talkybots', 'tabs'],
};

if (process.env.DEBUG === '*' || process.env.NODE_ENV !== 'production') {
	const reduxLogger = createLogger({
		duration: true,
		timestamp: true,
		level: 'log',
		logErrors: true,
	});
	reduxMiddlewares.push(reduxLogger);
	// reduxMiddlewares.push(handleRequestMiddleware);
}

const persistedReducer = persistReducer(persistConfig, reducers);

export default () => {
	const store = createStore(
		persistedReducer,
		composeWithDevTools(applyMiddleware(...reduxMiddlewares))
	);
	const persistor = persistStore(store);
	// listen to actions from another tap
	// initMessageListener(store);
	return { store, persistor };
};
