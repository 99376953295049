import {
	LIST_STOP_WORDS,
	ADD_STOP_WORD,
	DELETE_STOP_WORD,
} from 'store/actions/types/stopWords';

const initialState = [];

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case LIST_STOP_WORDS: {
			return payload.stopWords;
		}
		case DELETE_STOP_WORD: {
			return state.filter(
				(stopWord) => stopWord.id !== payload.stopWordId
			);
		}
		case ADD_STOP_WORD: {
			return [...state, payload.stopWord];
		}

		default:
			return state;
	}
};
