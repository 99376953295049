import { Button, Divider, Typography, makeStyles } from '@material-ui/core';
import ExitModalIcon from 'assets/icons/Exit-Modal-Icon.svg';
import DragIcon from 'assets/icons/dragIcon.svg';
import languageName from 'helpers/LnaguageName';
import useSetUiLoadingError from 'hooks/useSetUiLoadingError';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const ConfigurePriority = ({
  getPriorityList,
  botBuilderAppId,
  entityId,
  priorityList,
  changePriority,
  refreshPriority,
  successDataGetList,
  openConfigPriorityMenu,
  setopenConfigPriorityMenu,
}) => {
  const classes = useStyles();
  

  const [isLoading, successData, backendError, setUiLoadingError] =
    useSetUiLoadingError();
  // const [isLoadingGetList, successDataGetList, backendErrorGetList, setUiLoadingErrorGetList] =
  //   useSetUiLoadingError();

  // const [items, setItems] = useState(priorityList);
  const [position, setPosition] = useState(null)
  const [itemPriorityId, setItemPriorityId] = useState(null)
  const [items, setItems] = useState(priorityList);

  const containerRef = useRef(null);

  const handleCloseMenu = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setopenConfigPriorityMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleCloseMenu);
    return () => {
      document.removeEventListener('mousedown', handleCloseMenu);
    };
  }, []);

  const { enqueueSnackbar } = useSnackbar();

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const movedItemId = priorityList[result.source.index]?.id;
    const newPosition = result.destination.index;
    setPosition(newPosition + 1)
    setItemPriorityId(movedItemId)

    const reorderedItems = Array.from(items);
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);
    setItems(reorderedItems);

    changePriority({
      entityId,
      botBuilderAppId,
      entityPriorityId: movedItemId,
      position: newPosition + 1,
      enqueueSnackbar,
      setUiLoadingError,
    })
  };
  // useEffect(() => {
  //   getPriorityList({ entityId, botBuilderAppId, setUiLoadingError: setUiLoadingErrorGetList })
  //   setItems(priorityList)
  // }, [openConfigPriorityMenu, refreshPriority])
  
  
  useEffect(() => {
    if (successDataGetList) {
      setItems(priorityList)
    }
  }, [successDataGetList])

  useEffect(() => {
    if (successData) {
      enqueueSnackbar(`Priority Changed successfully`, {
        variant: 'success',
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
      });
    }
  }, [successData])


  return (
    <>
      {!openConfigPriorityMenu ? (
        <Button
          variant="contained"
          color="primary"
          className={classes.configurePriorityButton}
          onClick={() => setopenConfigPriorityMenu(true)}
        >
          Configure Priority
        </Button>
      ) : (
        <div className={classes.configPriorityList} ref={containerRef}>
          <img
            src={ExitModalIcon}
            alt="exit modal"
            className={classes.ExitModalIcon}
            onClick={() => setopenConfigPriorityMenu(false)}
          />
          <Typography className={classes.nodeListHeader}>
            Configure Priority
          </Typography>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '15px 0', padding: '15px' }}>
            <span style={{ fontSize: '14px', textAlign: 'center' }}>Configure entity priority by sorting items with drag and drop.</span>
          </div>
          <Divider className={classes.divider} />

          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {priorityList && priorityList?.length > 0 && priorityList.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id} index={index}>
                      {(provided) => (
                        <div
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '10px',
                            gap: '2px',
                            maxHeight: '80px',

                          }}>

                          <div

                            style={{
                              ...provided.draggableProps.style,
                              display: 'flex',
                              justifyContent: 'start',
                              alignItems: 'center',
                              gap: '15px'
                            }}
                          >
                            <img src={DragIcon} alt="drag icon" />
                            <div style={{
                              display: 'flex',
                              flexDirection: 'column'
                            }}>

                              <Typography style={{
                                fontSize: '16px',
                                fontWeight: '600',
                                color: '#000000'
                              }}>{item?.attributes?.type === 'EntityRegex' ? item?.attributes?.name : languageName(item?.attributes?.name)}</Typography>
                              <span style={{
                                fontSize: '16px',
                                fontWeight: '300',
                                color: '#959595',
                              }}>{item?.attributes?.type === 'EntityList' ? 'List' : 'Regex'}</span>
                            </div>
                          </div>
                          <Divider className={classes.divider} />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}
    </>
  )
}

export default ConfigurePriority

const useStyles = makeStyles((theme) => ({
  configurePriorityButton: {
    border: `1px solid #6418C3`,
    color: '#6418C3',
    backgroundColor: 'white',
    height: '56px',
    borderRadius: '8px',
    fontWeight: '600',
    fontSize: '14px',
    boxShadow: 'none',
    flexBasis: '13.5%',
    '&:hover': {
      backgroundColor: '#F0F0F0',
    },
    '@media (max-width: 1363px)': {
      fontSize: '12px',
    },
  },
  configPriorityList: {
    background: '#ffffff',
    flexWrap: 'nowrap',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '0.3em',
      height: '0.2rem',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'blue',
    },
    position: 'fixed',
    zIndex: 999999,
    right: 0,
    top: 0,
    width: '458px',
    height: '100vh',
    paddingBottom: '3vh',
    borderRadius: '20px',
    boxShadow: '0px 3px 40px #00000029',
    padding: `${theme.spacing(45)} ${theme.spacing(13)} 0 ${theme.spacing(
      13
    )}`,
  },
  ExitModalIcon: {
    position: 'absolute',
    right: theme.spacing(35),
    cursor: 'pointer',
  },
  divider: {
    margin: '15px 10px'
  },
  nodeListHeader: {
    ...theme.typography.h7,
    color: theme.palette.primary.main,
    textAlign: 'center',
  },
}));