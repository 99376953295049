import { ErrorBoundary } from 'react-error-boundary';
import { Redirect, Route } from 'react-router-dom';

import NotFoundComponent from 'components/404/Component';
import Apps from 'constants/applications';
import {
	CAN_CREATE_BOT, CAN_EDIT_PERMISSION, CAN_EDIT_PERSON_PROFILE, CAN_INVITE_IN_GENERAL, CAN_PUBLISH_BOT,
	CAN_VIEW_BOT_BUILDER,
	CAN_VIEW_CONVERSATION,
	CAN_VIEW_CRM, CAN_VIEW_DASHBOARD, CAN_VIEW_NOTIFICATIONS,
	CAN_VIEW_SETTINGS,
	CAN_VIEW_STORE,
	CAN_VIEW_PROFILE,
	CAN_VIEW_TALKYBOTS,
	CAN_VIEW_TENANT_MEMBERS, CAN_VIEW_USAGE
} from 'constants/permissions';
import UserHasPermission from 'hoc/UserHasPermission';
import MainLayout from 'layouts/MainLayout';
import Loading from '../waitingReqView/loading';

/**
 *
 * @param {object} error 		- error object
 * @returns {JSX} 				  - error component
 */
function ErrorFallback({ error }) {
	return (
		<div role="alert">
			<p>Something went wrong:</p>
			<pre>{error.message}</pre>
		</div>
	);
}

/**
 * Component to check if user has permission to access the route or not and redirect to the route if user has permission
 * @param {JSX.Element} component be rendered if user has permission to access the route
 * @param {JSX.Element} props props to be passed to the component
 * @returns
 */
const PrivateRoute = ({ component: Component, ...rest }) => {

	/**
	 * get the action to be performed on the route based on the route path and get the application name from the route path and check if user has permission to access the route or not
	 * @param {string} route - route to be checked
	 * @returns {object} - object containing the action to be performed and the application name if the route is an application route [CRM, Conversations, Talkybots, Store, Bot Builder]]
	 */
	// eslint-disable-next-line consistent-return
	const getActionAndAppForRoute = (route) => {
		const editRegex = /talkybots\/[0-9]*/g;
		const createBotRegex = /talkybots\/create/g;
		if (!route) throw new Error('no route has found !');

		if (route.includes('conversations')) {
			return {
				action: CAN_VIEW_CONVERSATION,
				app: Apps.Conversation.applicationType,
			};
		}
		if (route === '/talkybots/:talkybotId/dashboard')
			return { action: CAN_VIEW_DASHBOARD };
		if (route === '/talkybots/:talkybotId/contacts/:person_id') {
			return {
				action: CAN_EDIT_PERSON_PROFILE,
				app: Apps.CRM.applicationType,
			};
		}
		if (route.includes('crm'))
			return { action: CAN_VIEW_CRM, app: Apps.CRM.applicationType };
		if (route.includes('bot-builder'))
			return {
				action: CAN_VIEW_BOT_BUILDER,
				app: Apps.BotBuilder.applicationType,
			};

		if (route.includes('talkybots')) return { action: CAN_VIEW_TALKYBOTS };
		// if (teamMemberRegex.test(route)) return { action: CAN_EDIT_PERMISSION };
		if (route.includes('team-members'))
			return { action: CAN_VIEW_TENANT_MEMBERS };
		if (route === '/team-members/:id') {
			return {
				action: CAN_EDIT_PERMISSION,
			};
		}
		if (route === '/team-members/invite') {
			return {
				action: CAN_INVITE_IN_GENERAL,
			};
		}

		if (route.includes('notifications'))
			return { action: CAN_VIEW_NOTIFICATIONS };

		if (route.includes('usage')) return { action: CAN_VIEW_USAGE };

		if (route.includes('store')) return { action: CAN_VIEW_STORE };
		if (route.includes('users')) return { action: CAN_VIEW_PROFILE };
		if (route.includes('settings')) return { action: CAN_VIEW_SETTINGS };
		if (route.includes('connect')) return { action: CAN_PUBLISH_BOT };
		if (createBotRegex.test(route)) return { action: CAN_CREATE_BOT };
		if (editRegex.test(route)) return { action: CAN_CREATE_BOT };
		return { action: '' };
	};

	const userHasPermissionProps = getActionAndAppForRoute(rest.path);

	const adminToken = JSON.parse(localStorage.getItem('adminToken'));
	const tokenData = JSON.parse(localStorage.getItem('tokenData'));
	return (
		<Route
			{...rest}
			render={(props) => {
				return (
					(adminToken && rest.path.includes('admin')) ? (
						<Component {...props} />
					) : (!adminToken && rest.path.includes('admin')) ? (
						<Redirect
							to={{
								pathname: '/admin',
								state: { from: props?.location },
							}}
						/>
					) : (tokenData && !rest.path.includes('admin')) ? (
						<UserHasPermission
							action={userHasPermissionProps?.action}
							app={userHasPermissionProps?.app}
							FallbackComponent={() => (
								<Loading />
							)}
						>
							<ErrorBoundary FallbackComponent={ErrorFallback}>
								<Component {...props} />
							</ErrorBoundary>
						</UserHasPermission>
					) : (!tokenData && !rest.path.includes('admin')) ? (
						<Redirect
							to={{
								pathname: '/login',
								state: { from: props?.location },
							}}
						/>
					) : null
				)
			}}
		/>

	);
};

export default PrivateRoute;
