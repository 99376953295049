/* eslint-disable no-case-declarations */
/* eslint-disable no-unreachable */
/* eslint-disable prefer-const */

import {
	ACTIVATE_ENTITY,
	ADD_NEW_ENTITIY_LIST,
	ADD_NEW_ENTITIY_REGEX,
	ADD_NEW_ENTITY,
	ADD_NEW_ENTITY_CATEGORY,
	ADD_NEW_LIST_VALUE,
	ADD_NEW_REGEX_VALUE,
	ADD_PREDEFINED,
	CHANGE_PRIORITY_POSITION,
	CLEAR_ACTIVE_ENTITY,
	DELETE_ENTITY,
	DELETE_ENTITY_CATEGORY,
	DELETE_ENTITY_FAIL,
	DELETE_ENTITY_LIST,
	DELETE_ENTITY_LIST_FAIL,
	DELETE_ENTITY_REGEX,
	DELETE_ENTITY_REGEX_FAIL,
	DELETE_LIST_VALUE,
	DELETE_REGEX_VALUE,
	ENTITY_ERROR,
	GET_ALL_CATEGORY_ENTITIES,
	GET_ALL_ENTITIES_CATEGORIES,
	GET_ALL_ENTITY_LISTS,
	GET_ALL_ENTITY_REGEX,
	GET_ALL_LIST_VALUES,
	GET_ALL_REGEX_VALUES,
	GET_PREDEFINED_LIST,
	GET_PRIORITY_LIST,
	SET_DEFAULT_ENTITY_CATEGORY,
	UPDATE_ENTITY,
	UPDATE_ENTITY_CATEGORY,
	UPDATE_ENTITY_FAIL,
	UPDATE_ENTITY_LIST,
	UPDATE_ENTITY_LIST_FAIL,
	UPDATE_ENTITY_REGEX,
	UPDATE_ENTITY_REGEX_FAIL,
	UPDATE_LIST_VALUE,
	UPDATE_REGEX_VALUE
} from 'store/actions/types/entities';

import apiRequest, { getErrorMessageAndCode } from 'helpers/apiRequest';
import axios from 'helpers/axios';
import { SET_BOT_BUILDER_TAB } from '../types/tabs';

//! ********************************* Categories start *****************************************

// ------------------------ get all categories ---------------------------------
export const getAllCategoriesAction = (botBuilderAppId, setUiLoadingError) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			const categories = [];
			responseData.data.forEach((item) => {
				const category = {
					id: item.id,
					name: item.attributes.name,
				};
				categories.push(category);
			});
			dispatch({
				type: GET_ALL_ENTITIES_CATEGORIES,
				payload: {
					categories,
				},
			});
		};
		const onError = (e) => {
			dispatch({
				type: ENTITY_ERROR,
			});
		};
		apiRequest.get({
			url: `/api/${botBuilderAppId}/entity_categories/`,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};

// ------------------------ update category ---------------------------------

export const updateCategoryAction = ({
	categoryId,
	botBuilderAppId,
	payload,
	setUiLoadingError,
	enqueueSnackbar,
}) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			enqueueSnackbar(`Edited Category successfully`, {
				variant: 'success',
				autoHideDuration: 3000,
			});
			const updatedCategory = {
				id: responseData.data.id,
				name: responseData.data.attributes.name,
			};
			dispatch({
				type: UPDATE_ENTITY_CATEGORY,
				payload: {
					updatedCategory,
				},
			});
		};
		const onError = (e) => {
			enqueueSnackbar(getErrorMessageAndCode(e)?.message, {
				variant: 'error',
				autoHideDuration: 3000,
			});
			dispatch({
				type: ENTITY_ERROR,
			});
		};
		apiRequest.patch({
			url: `/api/${botBuilderAppId}/entity_categories/${categoryId}`,
			bodyData: payload,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};


// ------------------------ delete category ---------------------------------

export const DeleteEntityCategoryAction = (
	categorytId,
	botBuilderAppId,
	deleteAssociatedEntities,
	setUiLoadingError,
	enqueueSnackbar
) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			enqueueSnackbar(`delete  Category successfully`, {
				variant: 'success',
				autoHideDuration: 3000,
			});
			const deletedCategory = {
				id: responseData.data.id,
			};
			dispatch({
				type: DELETE_ENTITY_CATEGORY,
				payload: { deletedCategory },
			});
		};
		const onError = (e) => {
			enqueueSnackbar(getErrorMessageAndCode(e)?.message, {
				variant: 'error',
				autoHideDuration: 3000,
			});
			dispatch({
				type: ENTITY_ERROR,
			});
		};
		apiRequest.delete({
			url: `/api/${botBuilderAppId}/entity_categories/${categorytId}`,
			bodyData: { delete_associated_entities: deleteAssociatedEntities },
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};

// ------------------------ create category ---------------------------------


export const addNewCategoryAction = ({
	formData,
	botBuilderId,
	enqueueSnackbar,
	setUiLoadingError,
}) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			enqueueSnackbar(`Created Category successfully`, {
				variant: 'success',
				autoHideDuration: 3000,
			});
			dispatch({
				type: ADD_NEW_ENTITY_CATEGORY,
				payload: {
					category: {
						id: responseData?.data?.id,
						name: responseData?.data?.attributes?.name,
					},
				},
			});
		};
		const onError = (e) => {
			enqueueSnackbar(getErrorMessageAndCode(e)?.message, {
				variant: 'error',
				autoHideDuration: 3000,
			});
			// dispatch({
			// 	type: FAQS_ERROR,
			// });
		};
		apiRequest.post({
			url: `/api/${botBuilderId}/entity_categories/`,
			bodyData: formData,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};



// ------------------------ set category chosen ---------------------------------

export const setDefaultCategoryAction = (categoryId) => {
	return (dispatch) => {
		dispatch({ type: SET_DEFAULT_ENTITY_CATEGORY, payload: { categoryId } });
	};
};


//! ********************************* Categories end *****************************************

//! ********************************* Entities start *****************************************

// ------------------------ get all entities in a category ---------------------------------
export const getAllCategoryEntitiesAction = ({
	botBuilderAppId,
	categoryId,
	keyword,
	page,
	perPage,
	scrolling,
	setUiLoadingError,
}) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			dispatch({
				type: GET_ALL_CATEGORY_ENTITIES,
				payload: {
					entities: responseData?.data,
					meta: responseData?.meta,
					isScrolling: scrolling
				},
			});
		};
		const onError = (e) => {
			dispatch({ type: ENTITY_ERROR, payload: { e } });
			throw new Error(error.message.toString());
		};

		// TODO: pending on the backend to filter only with lang
		if (categoryId) {
			apiRequest.get({
				url: `/api/${botBuilderAppId}/entities?entity_category_id=${categoryId}&page=${page}&per_page=${perPage}&name=${keyword}`,
				onSuccess,
				onError,
			});
		} else if (!page && !perPage) {
			apiRequest.get({
				url: `/api/${botBuilderAppId}/entities`,
				onSuccess,
				onError,
				setUiLoadingError,
			});
		} else {
			apiRequest.get({
				url: `/api/${botBuilderAppId}/entities?page=${page}&per_page=${perPage}&name=${keyword}`,
				onSuccess,
				onError,
				setUiLoadingError,
			});

		}

	};
};

// ------------------------ Add entity ---------------------------------

export const addNewEntityAction = ({
	entity,
	selectedCategory,
	botBuilderAppId,
	enqueueSnackbar,
	setUiLoadingError,
}) => {
	return async (dispatch) => {
		const requestPayload = {
			name: entity.name,
			entity_category_id: entity.categoryId,
		};

		const onSuccess = async (responseData) => {
			enqueueSnackbar(`Created Entity successfully`, {
				variant: 'success',
				autoHideDuration: 3000,
				anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
			});


			dispatch({
				type: ADD_NEW_ENTITY,
				payload: {
					entity: responseData?.data,
					selectedCategory: selectedCategory,
				},
			});
		};
		const onError = (e) => {
			enqueueSnackbar(getErrorMessageAndCode(e)?.message, {
				variant: 'error',
				autoHideDuration: 3000,
				anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
			});
			dispatch({ type: ENTITY_ERROR, payload: { e } });
		};

		apiRequest.post({
			url: `/api/${botBuilderAppId}/entities`,
			bodyData: requestPayload,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};

// ------------------------ Delete entity ---------------------------------

export const deleteEntityAction = ({
	entityId,
	botBuilderAppId,
	history,
	setUiLoadingError,
	enqueueSnackbar,
}) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			enqueueSnackbar(`Entity deleted successfully`, {
				variant: 'success',
				autoHideDuration: 3000,
			});

			dispatch({ type: DELETE_ENTITY, payload: { entityId: responseData?.data?.id } })
			dispatch({ type: SET_BOT_BUILDER_TAB, payload: 6 });

			history.push(`/talkybots/${botBuilderAppId}/bot-builder`);
		};
		const onError = (e) => {
			enqueueSnackbar(getErrorMessageAndCode(e)?.message, {
				variant: 'error',
				autoHideDuration: 3000,
			});
			dispatch({
				type: DELETE_ENTITY_FAIL,
			});
		};
		apiRequest.delete({
			url: `/api/${botBuilderAppId}/entities/${entityId}`,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};

// ------------------------ Update entity ---------------------------------

export const updateEntityAction = ({
	entityId,
	botBuilderAppId,
	payload,
	selectedCategory,
	enqueueSnackbar,
	setUiLoadingError,
}) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			dispatch({ type: UPDATE_ENTITY, payload: { updatedEntity: responseData?.data, selectedCategory: selectedCategory } });
			enqueueSnackbar(`Entity updated successfully`, {
				variant: 'success',
				autoHideDuration: 3000,
			});
		};
		const onError = (e) => {
			enqueueSnackbar(getErrorMessageAndCode(e)?.message, {
				variant: 'error',
				autoHideDuration: 3000,
			});
			dispatch({
				type: UPDATE_ENTITY_FAIL,
			});
		};
		apiRequest.patch({
			url: `/api/${botBuilderAppId}/entities/${entityId}`,
			bodyData: payload,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};

// ------------------------ Activate entity ---------------------------------
export const activateEntityAction = (entityId, botBuilderId) => {
	return async (dispatch) => {
		try {
			const response = await axios.get(
				`/api/${botBuilderId}/entities/${entityId}`
			);



			dispatch({
				type: ACTIVATE_ENTITY,
				payload: { activatedEntity: response?.data?.data },
			});
		} catch (error) {
			throw new Error(error.message.toString());
		}
	};
};

// ------------------------ clear active entity ---------------------------------
export const clearActiveEntityAction = () => {
	return (dispatch) => {
		dispatch({
			type: CLEAR_ACTIVE_ENTITY
		})
	}
}

//! ********************************* Entities End *****************************************


//! ********************************* Entity List Start *****************************************

// ---------------------------------- get all lists --------------------------

export const getAllEntityListsAction = ({
	entityId,
	botBuilderAppId
}) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			dispatch({
				type: GET_ALL_ENTITY_LISTS,
				payload: {
					lists: responseData?.data,
				},
			});
		};
		const onError = (e) => {
			dispatch({ type: ENTITY_ERROR, payload: { e } });
			throw new Error(e.message.toString());
		};

		// TODO: pending on the backend to filter only with lang
		apiRequest.get({
			url: `/api/${entityId}/entity_lists?bot_builder_app_id=${botBuilderAppId}`,
			onSuccess,
			onError,
		});
	};
};

// ------------------------ Create list ---------------------------------

export const addNewEntityListAction = ({
	entityId,
	language,
	threshold,
	enqueueSnackbar,
	setUiLoadingError,
}) => {
	return async (dispatch) => {
		const requestPayload = {
			lang: language,
			threshold: parseInt(threshold)
		};

		const onSuccess = async (responseData) => {
			enqueueSnackbar(` List created successfully`, {
				variant: 'success',
				autoHideDuration: 3000,
			});


			dispatch({
				type: ADD_NEW_ENTITIY_LIST,
				payload: {
					list: responseData?.data,
				},
			});
		};
		const onError = (e) => {
			enqueueSnackbar(getErrorMessageAndCode(e)?.message, {
				variant: 'error',
				autoHideDuration: 3000,
			});
			dispatch({ type: ENTITY_ERROR, payload: { e } });
		};

		apiRequest.post({
			url: `/api/${entityId}/entity_lists`,
			bodyData: requestPayload,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};

// ------------------------ Update entity list ---------------------------------

export const updateEntityListAction = ({
	entityId,
	listId,
	language,
	threshold,
	enqueueSnackbar,
	setUiLoadingError,
}) => {
	const requestPayload = {
		lang: language,
		threshold: parseInt(threshold)
	};
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			dispatch({ type: UPDATE_ENTITY_LIST, payload: { updatedList: responseData?.data } });
			enqueueSnackbar(`List updated successfully`, {
				variant: 'success',
				autoHideDuration: 3000,
			});
		};
		const onError = (e) => {
			enqueueSnackbar(getErrorMessageAndCode(e)?.message, {
				variant: 'error',
				autoHideDuration: 3000,
			});
			dispatch({
				type: UPDATE_ENTITY_LIST_FAIL,
			});
		};
		apiRequest.patch({
			url: `/api/${entityId}/entity_lists/${listId}`,
			bodyData: requestPayload,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};

// ------------------------ Delete list ---------------------------------

export const deleteEntityListAction = ({
	entityId,
	listId,
	setUiLoadingError,
	enqueueSnackbar,
}) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			enqueueSnackbar(`List deleted successfully`, {
				variant: 'success',
				autoHideDuration: 3000,
			});

			dispatch({ type: DELETE_ENTITY_LIST, payload: { listId: responseData?.data?.id } })

		};
		const onError = (e) => {
			enqueueSnackbar(getErrorMessageAndCode(e)?.message, {
				variant: 'error',
				autoHideDuration: 3000,
			});
			dispatch({
				type: DELETE_ENTITY_LIST_FAIL,
			});
		};
		apiRequest.delete({
			url: `/api/${entityId}/entity_lists/${listId}`,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};

// ------------------------ get list values ---------------------------------

export const getAllListValuesAction = ({
	listId,
	botBuilderAppId
}) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			dispatch({
				type: GET_ALL_LIST_VALUES,
				payload: {
					values: responseData?.data,
					listId,
				},
			});
		};
		const onError = (e) => {
			dispatch({ type: ENTITY_ERROR, payload: { e } });
			throw new Error(e.message.toString());
		};

		// TODO: pending on the backend to filter only with lang
		apiRequest.get({
			url: `/api/${listId}/entity_list_values`,
			onSuccess,
			onError,
		});
	};
};

// ------------------------ Create list value ---------------------------------

export const addNewListValueAction = ({
	listId,
	value,
	synonymId,
	enqueueSnackbar,
	setUiLoadingError,
}) => {
	return async (dispatch) => {
		const requestPayload = {
			value,
			synonym_id: synonymId !== 0 ? synonymId : null
		};

		const onSuccess = async (responseData) => {
			enqueueSnackbar(` Value created successfully`, {
				variant: 'success',
				autoHideDuration: 3000,
			});


			dispatch({
				type: ADD_NEW_LIST_VALUE,
				payload: {
					value: responseData?.data,
					listId,
				},
			});
		};
		const onError = (e) => {
			enqueueSnackbar(getErrorMessageAndCode(e)?.message, {
				variant: 'error',
				autoHideDuration: 3000,
			});
			dispatch({ type: ENTITY_ERROR, payload: { e } });
		};

		apiRequest.post({
			url: `/api/${listId}/entity_list_values`,
			bodyData: requestPayload,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};

// ------------------------ Update list value ---------------------------------

export const updateListValueAction = ({
	listId,
	valueId,
	value,
	synonymId,
	enqueueSnackbar,
	setUiLoadingError,
}) => {
	const requestPayload = {
		value,
		synonym_id: synonymId !== 0 ? synonymId : null
	};
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			dispatch({ type: UPDATE_LIST_VALUE, payload: { listId, valueId, updatedValue: responseData?.data } });
			enqueueSnackbar(`Value updated successfully`, {
				variant: 'success',
				autoHideDuration: 3000,
			});
		};
		const onError = (e) => {
			enqueueSnackbar(getErrorMessageAndCode(e)?.message, {
				variant: 'error',
				autoHideDuration: 3000,
			});
		};
		apiRequest.patch({
			url: `/api/${listId}/entity_list_values/${valueId}`,
			bodyData: requestPayload,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};

// ------------------------ Delete list value ---------------------------------

export const deleteListValueAction = ({
	listId,
	valueId,
	setUiLoadingError,
	enqueueSnackbar,
}) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			enqueueSnackbar(`Value deleted successfully`, {
				variant: 'success',
				autoHideDuration: 3000,
			});

			dispatch({ type: DELETE_LIST_VALUE, payload: { listId, valueId } })

		};
		const onError = (e) => {
			enqueueSnackbar(getErrorMessageAndCode(e)?.message, {
				variant: 'error',
				autoHideDuration: 3000,
			});
		};
		apiRequest.delete({
			url: `/api/${listId}/entity_list_values/${valueId}`,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};


//! ********************************* Entity List End *******************************************


//! ********************************* Entity REGEX start *****************************************


export const getAllEntityRegexAction = ({
	entityId,
	botBuilderAppId
}) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			dispatch({
				type: GET_ALL_ENTITY_REGEX,
				payload: {
					regex: responseData?.data,
				},
			});
		};
		const onError = (e) => {
			dispatch({ type: ENTITY_ERROR, payload: { e } });
			throw new Error(e.message.toString());
		};

		// TODO: pending on the backend to filter only with lang
		apiRequest.get({
			url: `/api/${entityId}/entity_regexes?bot_builder_app_id=${botBuilderAppId}`,
			onSuccess,
			onError,
		});
	};
};

// ------------------------ Create regex ---------------------------------

export const addNewEntityRegexAction = ({
	entityId,
	language,
	name,
	enqueueSnackbar,
	setUiLoadingError,
}) => {
	return async (dispatch) => {
		const requestPayload = {
			lang: language,
			name: name
		};

		const onSuccess = async (responseData) => {
			enqueueSnackbar(` Regex created successfully`, {
				variant: 'success',
				autoHideDuration: 3000,
			});


			dispatch({
				type: ADD_NEW_ENTITIY_REGEX,
				payload: {
					regex: responseData?.data,
				},
			});
		};
		const onError = (e) => {
			enqueueSnackbar(getErrorMessageAndCode(e)?.message, {
				variant: 'error',
				autoHideDuration: 3000,
			});
			dispatch({ type: ENTITY_ERROR, payload: { e } });
		};

		apiRequest.post({
			url: `/api/${entityId}/entity_regexes`,
			bodyData: requestPayload,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};

// ------------------------ Update entity regex ---------------------------------

export const updateEntityRegexAction = ({
	entityId,
	regexId,
	language,
	name,
	enqueueSnackbar,
	setUiLoadingError,
}) => {
	const requestPayload = {
		lang: language,
		name: name
	};
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			dispatch({ type: UPDATE_ENTITY_REGEX, payload: { updatedRegex: responseData?.data } });
			enqueueSnackbar(`Regex updated successfully`, {
				variant: 'success',
				autoHideDuration: 3000,
			});
		};
		const onError = (e) => {
			enqueueSnackbar(getErrorMessageAndCode(e)?.message, {
				variant: 'error',
				autoHideDuration: 3000,
			});
			dispatch({
				type: UPDATE_ENTITY_REGEX_FAIL,
			});
		};
		apiRequest.patch({
			url: `/api/${entityId}/entity_regexes/${regexId}`,
			bodyData: requestPayload,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};

// ------------------------ Delete regex ---------------------------------

export const deleteEntityRegexAction = ({
	entityId,
	regexId,
	setUiLoadingError,
	enqueueSnackbar,
}) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			enqueueSnackbar(`Regex deleted successfully`, {
				variant: 'success',
				autoHideDuration: 3000,
			});

			dispatch({ type: DELETE_ENTITY_REGEX, payload: { regexId: responseData?.data?.id } })

		};
		const onError = (e) => {
			enqueueSnackbar(getErrorMessageAndCode(e)?.message, {
				variant: 'error',
				autoHideDuration: 3000,
			});
			dispatch({
				type: DELETE_ENTITY_REGEX_FAIL,
			});
		};
		apiRequest.delete({
			url: `/api/${entityId}/entity_regexes/${regexId}`,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};


export const getAllRegexValuesAction = ({
	regexId,
	setUiLoadingError = () => { },
}) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			dispatch({
				type: GET_ALL_REGEX_VALUES,
				payload: {
					regexValues: responseData?.data,
					regexId,
				},
			});
		};
		const onError = (e) => {
			dispatch({ type: ENTITY_ERROR, payload: { e } });
			throw new Error(e.message.toString());
		};

		// TODO: pending on the backend to filter only with lang
		apiRequest.get({
			url: `/api/${regexId}/entity_regex_values`,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};


export const addNewRegexValueAction = ({
	regexId,
	value,
	enqueueSnackbar,
	setUiLoadingError,
}) => {
	return async (dispatch) => {
		const requestPayload = {
			value
		};

		const onSuccess = async (responseData) => {
			enqueueSnackbar(` Value created successfully`, {
				variant: 'success',
				autoHideDuration: 3000,
			});


			dispatch({
				type: ADD_NEW_REGEX_VALUE,
				payload: {
					value: responseData?.data,
					regexId,
				},
			});
		};
		const onError = (e) => {
			enqueueSnackbar(getErrorMessageAndCode(e)?.message, {
				variant: 'error',
				autoHideDuration: 3000,
			});
			dispatch({ type: ENTITY_ERROR, payload: { e } });
		};

		apiRequest.post({
			url: `/api/${regexId}/entity_regex_values`,
			bodyData: requestPayload,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};

// ------------------------ Update regex value ---------------------------------

export const updateRegexValueAction = ({
	regexId,
	valueId,
	value,
	enqueueSnackbar,
	setUiLoadingError,
}) => {
	const requestPayload = {
		value
	};
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			dispatch({ type: UPDATE_REGEX_VALUE, payload: { regexId, valueId, updatedValue: responseData?.data } });
			enqueueSnackbar(`Value updated successfully`, {
				variant: 'success',
				autoHideDuration: 3000,
			});
		};
		const onError = (e) => {
			enqueueSnackbar(getErrorMessageAndCode(e)?.message, {
				variant: 'error',
				autoHideDuration: 3000,
			});
		};
		apiRequest.patch({
			url: `/api/${regexId}/entity_regex_values/${valueId}`,
			bodyData: requestPayload,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};

// ------------------------ Delete regex value ---------------------------------

export const deleteRegexValueAction = ({
	regexId,
	valueId,
	setUiLoadingError,
	enqueueSnackbar,
}) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			enqueueSnackbar(`Value deleted successfully`, {
				variant: 'success',
				autoHideDuration: 3000,
			});

			dispatch({ type: DELETE_REGEX_VALUE, payload: { regexId, valueId } })

		};
		const onError = (e) => {
			enqueueSnackbar(getErrorMessageAndCode(e)?.message, {
				variant: 'error',
				autoHideDuration: 3000,
			});
		};
		apiRequest.delete({
			url: `/api/${regexId}/entity_regex_values/${valueId}`,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};

//! ********************************* Entity REGEX End *******************************************


//! ********************************* Config Priority start *****************************************

//-------------------------------------- get priority list -------------------------------------------

export const getPriorityListAction = ({
	botBuilderAppId,
	entityId,
	setUiLoadingError = () => { },
}) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			dispatch({
				type: GET_PRIORITY_LIST,
				payload: {
					priorityList: responseData?.data,
					entityId,
				},
			});
		};
		const onError = (e) => {
			dispatch({ type: ENTITY_ERROR, payload: { e } });
			throw new Error(e.message.toString());
		};

		// TODO: pending on the backend to filter only with lang
		apiRequest.get({
			url: `/api/${botBuilderAppId}/entities/${entityId}/priority_list`,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};

//-------------------------------------- change priority -------------------------------------------

export const changePriorityAction = ({
	botBuilderAppId,
	entityId,
	entityPriorityId,
	position,
	enqueueSnackbar,
	setUiLoadingError = () => { },
}) => {
	const requestPayload = {
		entity_priority_id: entityPriorityId,
		position,
	}
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			dispatch({
				type: CHANGE_PRIORITY_POSITION,
				payload: {
					priorityList: responseData?.data,
					entityId,
				},
			});

		};
		const onError = (e) => {
			dispatch({ type: ENTITY_ERROR, payload: { e } });
			throw new Error(e.message.toString());
		};

		// TODO: pending on the backend to filter only with lang
		apiRequest.patch({
			url: `/api/${botBuilderAppId}/entities/${entityId}/priority_list`,
			bodyData: requestPayload,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};


//! ********************************* Config Priority End *******************************************


//! ********************************* Predefined start *****************************************

export const getPredefinedListAction = () => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			dispatch({
				type: GET_PREDEFINED_LIST,
				payload: {
					predefinedList: responseData?.data,
				},
			});
		};
		const onError = (e) => {
			dispatch({ type: ENTITY_ERROR, payload: { e } });
			throw new Error(e.message.toString());
		};

		// TODO: pending on the backend to filter only with lang
		apiRequest.get({
			url: `/api/entities/predefined_entity_items`,
			onSuccess,
			onError,
		});
	};
};

export const addPredefinedAction = ({
	botBuilderAppId,
	entityId,
	predefinedId,
	enqueueSnackbar,
	setUiLoadingError,
}) => {
	return async (dispatch) => {

		const formData = {
			predefined_entity_item_id: predefinedId,
		}
		const onSuccess = async (responseData) => {
			dispatch({
				type: ADD_PREDEFINED,
				payload: {
					predefined: responseData?.data,
				},
			});
			enqueueSnackbar(`Predefined added successfully`, {
				variant: 'success',
				autoHideDuration: 3000,
			});
		};
		const onError = (e) => {
			dispatch({ type: ENTITY_ERROR, payload: { e } });
			throw new Error(e.message.toString());
		};

		// TODO: pending on the backend to filter only with lang
		apiRequest.post({
			url: `/api/${botBuilderAppId}/entities/${entityId}/add_predefined_item`,
			bodyData: formData,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};


//! ********************************* Predefined End *******************************************
