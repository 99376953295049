import {
	GET_ALL_NOTIFICATIONS,
	NOTIFY_USERS_TO_TAKE_OVER_CONVERSATION,
	NOTIFICATION_STATUS_CHANGED,
} from 'store/actions/types/notifications';

const initialState = {
	notifications: [],
	notificationsCount: '',
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case GET_ALL_NOTIFICATIONS: {
			const { notifications, notificationsCount } = payload;
			return {
				...state,
				notifications: notifications,
				notificationsCount,
			};
		}
		// TODO: serialize new notification to be as the body of notification in notifications list
		// case NOTIFY_USERS_TO_TAKE_OVER_CONVERSATION: {
		// 	const { notification } = payload;
		// 	return {
		// 		notifications: [...state.notifications, notification],
		// 	};
		// }

		case NOTIFICATION_STATUS_CHANGED: {
			const { id, seen } = payload;
			return {
				notifications: state.notifications.map((notification) => {
					if (notification.id == id) {
						return {
							...notification,
							attributes: {
								...notification.attributes,
								seen: seen,
							},
						};
					}
					return notification;
				}),
			};
		}
		default:
			return state;
	}
};
