import React from 'react';
import { CardContent, Typography, Grid } from '@material-ui/core';
import renderDate from 'helpers/renderDate';
import useStyles from './styles';

const AssignTeamMemberBody = ({ userName, personName, content, byBot }) => {
	// console.log('userName', userName)
	console.log('content', content)
	// console.log('personName', personName)
	const classes = useStyles();
	const hourFormate = 'h:mm am/pm';
	// Mai Salah has assigned shrouk ahmed to you on CRM
	return (
		<Grid container className={classes.root}>
			<Grid item xs={9} className={classes.statusWrapper}>
				<CardContent className={classes.content}>
					<Typography variant="body" className={classes.activity}>
						<Typography
							display="inline"
							className={classes.action}
							title={userName || ''}
						>
							{`${userName}  `}
						</Typography>
						has assigned{' '}
						<Typography
							display="inline"
							className={classes.action}
							title={personName || ''}
						>
							{`${personName} `}
						</Typography>
						{content?.to && content?.to?.attributes?.user_profile && (
							<>
								{' '}
								to{' '}
								<Typography
									display="inline"
								// className={classes.action}
								>
									{content?.to?.attributes?.user_profile
										.name === userName
										? 'you'
										: content?.to?.attributes?.user_profile
											.name}
									{/* you */}
								</Typography>
							</>
						)}
					</Typography>
				</CardContent>
			</Grid>
			<Grid item xs={3} className={classes.dateWrapper}>
				<CardContent className={classes.content}>
					<Typography variant="body2" className={classes.date}>
						{renderDate(content.date, hourFormate)}
					</Typography>
				</CardContent>
			</Grid>
		</Grid>
	);
};

export default AssignTeamMemberBody;
