import { combineReducers } from 'redux';
import { withReduxStateSync } from 'redux-state-sync';
import auth from './auth';
// import user from './user';
import talkybots from './talkybots';
import activeTalkybot from './activeTalkybot';
import flows from './pathsflows';
import scenarios from './scenarios';
import savedReplies from './savedReplies';
import currentFlowState from './flowpathsdata';
import tenants from './tenants';
import crm from './crm';
import adminPanel from './adminPanel';
import members from './members';
import roles from './roles';
import accountsReducer from './accounts';
import conversations from './conversations';
import faqsReducer from './faqs';
import notifications from './notifications';
import completeUserProfile from './completeUserProfile';
import topicFlowsErrors from './topicFlowsErrors';
import crmFilters from './crm-filters';
import usage from './usage';
import stopWords from './stopWords';
import botGates from './botGates';
import dashboardReducer from './dashboard';
import botFields from './bot-fields';
import train from './train';
import tabs from './tabs';
import integratons from './integrations';
import entities from './entitis'
import synonyms from './synonyms'


const fakeReducer = (state = {}) => state;
export default withReduxStateSync(
	combineReducers({
		auth,
		adminPanel,
		talkybots,
		activeTalkybot,
		flows,
		scenarios,
		savedReplies: savedReplies,
		currentFlowState,
		tenants,
		crm: crm,
		crmFilters: crmFilters,
		people: fakeReducer,
		currentMembers: members,
		roles,
		accounts: accountsReducer,
		conversations,
		faqs: faqsReducer,
		notifications,
		completeUserProfile,
		topicFlowsErrors,
		usage,
		stopWords,
		botGates: botGates,
		dashboard: dashboardReducer,
		botFields,
		train,
		tabs,
		integratons,
		entities,
		synonyms,
	})
);
