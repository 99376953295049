import React from 'react';
import { CardContent, Typography, makeStyles, Grid } from '@material-ui/core';
import renderDate from 'helpers/renderDate';

// 🐶🐶🐶🐶 i don't know what is the purpose of this function
const handleShowUserOrPerson = (isByPerson, userName, personName, isSender) => {
	if (isSender) {
		return isByPerson ? personName : userName;
	}
	return !isByPerson ? personName : userName;
};

const SmsOrCallBody = ({ userName, personName, content, byPerson, action }) => {
	const classes = useStyles();
	const hourFormate = 'h:mm am/pm';
	return (
		<Grid container className={classes.root}>
			<Grid item xs={9} className={classes.statusWrapper}>
				<CardContent className={classes.content}>
					<Typography variant="body" className={classes.activity}>
						<Typography display="inline" className={classes.action}>
							{handleShowUserOrPerson(
								byPerson,
								userName,
								personName,
								true
							)}
						</Typography>
						{` ${action} `}
						<Typography display="inline" className={classes.action}>
							{handleShowUserOrPerson(
								byPerson,
								userName,
								personName,
								false
							)}
						</Typography>
					</Typography>
				</CardContent>

				{content.body && (
					<CardContent className={classes.content}>
						<div
							className={classes.messageContainer}
							title={content.body}
						>
							{content.body}
						</div>
					</CardContent>
				)}
			</Grid>
			<Grid item xs={3} className={classes.dateWrapper}>
				<CardContent className={classes.content}>
					<Typography variant="body2" className={classes.date}>
						{renderDate(content.date, hourFormate)}
					</Typography>
				</CardContent>
			</Grid>
		</Grid>
	);
};

export default SmsOrCallBody;
const useStyles = makeStyles((theme) => ({
	root: {
		padding: '0.5rem',
	},
	statusWrapper: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	dateWrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
	},
	button: {
		margin: theme.spacing(1),
	},
	large: {
		width: theme.spacing(5),
		height: theme.spacing(5),
	},
	medium: {
		width: theme.spacing(4),
		height: theme.spacing(4),
	},
	content: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'end',
		padding: 0,
		// paddingTop: '0.3125rem',
		paddingBottom: '0px !important',
		// paddingLeft: '0.625rem',
		// background: 'green',
	},
	avatarPosition: {
		padding: '0.625rem',
		paddingTop: '1rem',
	},
	action: {
		fontWeight: '600',
		fontFamily: 'poppins',
		fontSize: '1rem !important',
		color: '#000',
		overflowWrap: 'anywhere',
	},

	activity: {
		color: '#1D1D1D',
		fontSize: '1rem',
	},
	date: {
		color: '#909090',
		fontSize: '1rem',
		fontWeight: 'normal',
	},
	avatarRoot: {
		background:
			'transparent linear-gradient(180deg, #6418C3 0%, #922580 100%) 0% 0% no-repeat padding-box',
		color: '#fff',
	},
	messageContainer: {
		overflowWrap: 'anywhere',
		backgroundColor: 'white',
		padding: '0.3125rem',
		borderRadius: '0.625rem',
		fontSize: '1rem',
	},
}));
