import { Grid, makeStyles, Paper } from '@material-ui/core';
import goBack from 'assets/icons/goBack.svg';
import { useHistory } from 'react-router-dom';
import SynonymList from '../components/SynonymList';

const Header = ({
  synonyms,
  activeTalkybot,
  activateSynonym,
  addSynonym,
  activeSynonym,
  getAllSynonyms,
  setActiveTab,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const talkybotId = activeTalkybot?.attributes?.bot_builder_id;



  const handleBackToTopicFlow = () => {
    history.push(`/talkybots/${talkybotId}/bot-builder`)
    // localStorage.setItem('dashboardTabIndex', 1)
    setActiveTab(7);
  };

  return (
    <Paper elevation={0} className={classes.headerWrapper}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Grid
            container
            alignItems="center"
            style={{
              fontSize: '18px',
              fontWeight: '500',
              fontFamily: 'poppins',
            }}
          >
            <img
              className={classes.goBackIcon}
              src={goBack}
              alt="back to intent lists"
              onClick={handleBackToTopicFlow}
            />
            {activeSynonym?.attributes?.title}
          </Grid>
        </Grid>
        <Grid item>
          <Grid container alignItems="center">
            {/* Done: CRUD : list all synonyms */}
            <SynonymList
              synonyms={synonyms}
              activateSynonym={activateSynonym}
              talkybotId={talkybotId}
              addSynonym={addSynonym}
              activeTalkybot={activeTalkybot}
              getAllSynonyms={getAllSynonyms}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
export default Header;

const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    padding: theme.spacing(10),
    width: '100%',

    // boxShadow: '0px 3px 16px #CECECE29',
    borderRadius: theme.borderRadius.borderRadius10px,
  },

  editDeleteIcon: {
    height: theme.spacing(53),
    cursor: 'pointer',
    marginRight: theme.spacing(16),
  },
  goBackIcon: {
    cursor: 'pointer',
    marginRight: theme.spacing(16),
  },
  addPredefined: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    height: theme.spacing(53),
    padding: `0 ${theme.spacing(25)}`,
    borderRadius: '8px',
    boxShadow: 'none',
    marginLeft: '10px'
  },
}));
