import Typography from '@material-ui/core/Typography';
import { Container, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ContactForm from './contactForm';

const useStyles = makeStyles((theme) => ({
	headerSection: {
		marginTop: '9.375rem',
		padding: '4.375rem',
		textAlign: 'center',
		backgroundColor: theme.palette.background.primaryOpacity,
	},
	contactTitle: {
		...theme.typography.title,
		color: theme.palette.text.primary,
	},
	contactSubTitle: {
		...theme.typography.subtitle7,
		color: theme.palette.text.primary,
	},
}));
const ContactHeader = () => {
	const classes = useStyles();
	return (
		<Paper elevation={0}>
			<Paper className={classes.headerSection} elevation={0}>
				<Typography className={classes.contactTitle}>
					contact
				</Typography>
				<Typography className={classes.contactSubTitle}>
					home / contact
				</Typography>
			</Paper>
			<ContactForm />
		</Paper>
	);
};
export default ContactHeader;
