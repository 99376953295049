import {
  CREATE_ORGANIZATION_SUCCESS,
  GET_USAGE_OF_SINGLE_TENANT_SUCCESS, RESEND_OTP_SUCCESS, SEND_OTP_TO_ADMIN_SUCCESS,SEND_OTP_TO_ADMIN_FAILED, TOGGLE_ACTIVITY_SUCCESS,
  GET_CURRENT_ADMIN_USER_SUCCESS,
  TOGGLE_BLOCKED_SUCCESS,
  VALIDATE_OTP_SUCCESS,
  EDIT_CURRENT_SUBSCRIBTION_SUCCESS,
  EDIT_NEXT_SUBSCRIBTION_SUCCESS,
  GET_ALL_ORGANIZATIONS_SUCCESS,
  GET_NEXT_SUBSCRIBTION_USAGE_SUCCESS,
  LOGOUT_ADMIN_SUCCESS
} from '../actions/types/adminPanel';

const INIT_STATE = {
  isAdmin: null,
  validOtp: null,
  isAuthenticatedAdmin: false,
  organizations: [],
  count: 0,
  tenantCurrentUsage: null,
  tenantNextUsage: null,
  tenantActivityState: false,
  adminUser: null,
}

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    // log out admin
    case LOGOUT_ADMIN_SUCCESS: {
      return {
        ...state,
        isAdmin: null,
        validOtp: null,
        isAuthenticatedAdmin: false,
        organizations: [],
        count: 0,
        tenantCurrentUsage: null,
        tenantNextUsage: null,
        tenantActivityState: false,
        adminUser: null,
      }
    }
    // send otp to admin
    case SEND_OTP_TO_ADMIN_SUCCESS: {
      const { isAdmin } = payload;
      return {
        ...state,
        isAdmin: isAdmin
      }
    }
    // send otp to admin
    case SEND_OTP_TO_ADMIN_FAILED: {
      return {
        ...state,
        isAdmin: null
      }
    }
    // resend otp to admin
    case RESEND_OTP_SUCCESS: {
      const { validOtp } = payload;
      return {
        ...state,
        validOtp: validOtp
      }
    }
    // validate otp
    case VALIDATE_OTP_SUCCESS: {
      const { isAuthenticatedAdmin } = payload;
      return {
        ...state,
        isAuthenticatedAdmin: isAuthenticatedAdmin
      }
    }
    // create organization
    case CREATE_ORGANIZATION_SUCCESS: {
      const { organization } = payload;
      return {
        ...state,
        organizations: {
          ...state.organizations,
          [organization.id]: organization
        }
      }
    }
    // get usage of single tenant
    case GET_USAGE_OF_SINGLE_TENANT_SUCCESS: {
      const { tenantCurrentUsage } = payload;
      return {
        ...state,
        tenantCurrentUsage: tenantCurrentUsage
      }
    }
    // get the next usage of tenant
    case GET_NEXT_SUBSCRIBTION_USAGE_SUCCESS: {
      const { tenantNextUsage } = payload;
      return {
        ...state,
        tenantNextUsage: tenantNextUsage
      }
    }
    // toggle activity
    case TOGGLE_ACTIVITY_SUCCESS: {
      // get object of tenant activity state
      const { tenantActivityState, source } = payload;
      if (source === "single_tenant") {
        // update tenant usage state
        const updatedTenantUsage = state.tenantCurrentUsage;
        updatedTenantUsage.is_active = tenantActivityState.is_active;
      }
      // update tenant activity state
      const newOrganizations = {
        ...state.organizations,
        [` ${tenantActivityState.id}`]: {
          ...state.organizations[` ${tenantActivityState.id}`],
          attributes: { ...state.organizations[` ${tenantActivityState.id}`].attributes, is_active: tenantActivityState.is_active }
        }
      }
      return {
        ...state,
        organizations: newOrganizations
      }
    }
    // toggle blocked
    case TOGGLE_BLOCKED_SUCCESS: {
      // get object of tenant blocked state
      const { tenantBlockedState } = payload;
      // update tenant usage state
      //TODO: update tenant usage state
      // const updatedTenantUsage = state.tenantCurrentUsage;
      // updatedTenantUsage.is_blocked = tenantBlockedState.is_blocked;
      // update tenant blocked state
      const newOrganizations = {
        ...state.organizations,
        [` ${tenantBlockedState.id}`]: {
          ...state.organizations[` ${tenantBlockedState.id}`],
          attributes: { ...state.organizations[` ${tenantBlockedState.id}`].attributes, is_blocked: tenantBlockedState.is_blocked }
        }
      }
      return {
        ...state,
        organizations: newOrganizations
      }
    }
    // get all organizations
    case GET_ALL_ORGANIZATIONS_SUCCESS: {
      const { organizations, count } = payload;
      return {
        ...state,
        organizations: organizations,
        count: count
      }
    }
    // get current admin user
    case GET_CURRENT_ADMIN_USER_SUCCESS: {
      const { adminUser } = payload;
      return {
        ...state,
        adminUser: adminUser
      }
    }

    // edit current subscribtion
    case EDIT_CURRENT_SUBSCRIBTION_SUCCESS: {
      const { newData } = payload;
      let updatedTenantUsage = state.tenantCurrentUsage;
      updatedTenantUsage = {
        ...updatedTenantUsage,
        bots_number: {
          ...updatedTenantUsage.bots_number,
          total: newData.number_of_bots,
        },
        users_number: {
          ...updatedTenantUsage.users_number,
          total: newData.number_of_team_members,
        },
        engagements_number: {
          ...updatedTenantUsage.engagements_number,
          total: newData.number_of_engagements,
        },
        sessions_number: {
          ...updatedTenantUsage.sessions_number,
          total: newData.number_of_sessions,
        },
        variations_number: {
          ...updatedTenantUsage.variations_number,
          total: newData.number_of_custom_attributes,
        },
        mobile_api: newData.mobile
      }
      return {
        ...state,
        tenantCurrentUsage: updatedTenantUsage
      }
    }
    // edit next subscribtion
    case EDIT_NEXT_SUBSCRIBTION_SUCCESS: {
      const { newData } = payload;
      let updatedTenantUsage = state.tenantNextUsage;
      updatedTenantUsage = {
        ...updatedTenantUsage,
        number_of_bots: newData.number_of_bots,
        number_of_team_members: newData.number_of_team_members,
        number_of_engagements: newData.number_of_engagements,
        number_of_sessions: newData.number_of_sessions,
        number_of_custom_attributes: newData.number_of_custom_attributes,
        mobile: newData.mobile
      }
      return {
        ...state,
        tenantNextUsage: updatedTenantUsage
      }
    }
    default:
      return state;
  }
}