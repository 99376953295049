import {
	GET_TENANT_ROLES_FAILS,
	GET_TENANT_ROLES_SUCCESS,
	GET_TENANT_ROLES_REQUEST,
} from 'store/actions/types/roles';

export default (
	state = {
		tenantRoles: null,
		loading: false,
		error: false,
	},
	{ type, payload }
) => {
	switch (type) {
		case GET_TENANT_ROLES_REQUEST: {
			return {
				loading: true,
			};
		}
		case GET_TENANT_ROLES_SUCCESS: {
			const { tenantRoles } = payload;
			return {
				tenantRoles,
				loading: false,
				error: false,
			};
		}
		case GET_TENANT_ROLES_FAILS: {
			return {
				tenantRoles: null,
				loading: false,
				error: true,
			};
		}

		default:
			return state;
	}
};
