import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import LinearProgress, {
	linearProgressClasses,
} from '@mui/material/LinearProgress';

// local styles
import styles from './index.module.scss';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
	height: 10,
	borderRadius: 15,
	flex: 1,
	[`&.${linearProgressClasses.colorPrimary}`]: {
		backgroundColor:'#000',
	},
	[`& .${linearProgressClasses.bar}`]: {
		borderRadius: 15,
		backgroundColor:'#B7DBFF',
	},
}));

const AssignedCustomersProgressBar = ({ value, total }) => {
  const assignedPercentage = (value / total) * 100;
  const unassignedPercentage = 100 - assignedPercentage;
	

	return (
    <div style={{ display: 'flex', flexDirection: 'column-reverse', width: '20px', height: '120px' }}>
      <div
        style={{
          backgroundColor: '#000',
          height: `${unassignedPercentage}%`,
					borderRadius: '4px',
					width: '10px',
        }}
      ></div>
      <div
        style={{
          backgroundColor: '#B7DBFF', // Blue color
          height: `${assignedPercentage}%`,
					borderRadius: '4px',
					width: '10px',
          marginBottom: '2px',
        }}
      ></div>
    </div>
  );
};

export default AssignedCustomersProgressBar;

// function AssignedCustomersProgressBar({value}) {
// 	return (
// 		<div className={styles.container}>
// 			<BorderLinearProgress variant="determinate" value={value} />
// 		</div>
// 	);
// }

// export default AssignedCustomersProgressBar;
