import storeConfig from 'store/config';

import {
	CAN_CHANGE_BILLING_ACCOUNT,
	CAN_CHANGE_PRODUCT_OWNER,
	CAN_EDIT_BOT_BUILDER,
	CAN_EDIT_BOT_CONFIGURATION,
	CAN_EDIT_CONVERSATION,
	CAN_EDIT_CRM,
	CAN_INVITE_NORMAL_USER,
	CAN_INVITE_SUPER_ADMIN,
	CAN_PUBLISH_BOT,
	CAN_VIEW_BOT,
	CAN_VIEW_BOT_BUILDER,
	CAN_VIEW_CONVERSATION,
	CAN_VIEW_CRM,
	CAN_VIEW_DASHBOARD,
	CAN_VIEW_STORE,
	CAN_CREATE_BOT,
	CAN_INVITE_ADMIN,
	CAN_VIEW_SETTINGS,
	CAN_VIEW_TALKYBOTS,
	CAN_VIEW_TENANT_MEMBERS,
	CAN_VIEW_NOTIFICATIONS,
	CAN_INVITE_IN_GENERAL,
	CAN_EDIT_PERMISSION,
	CAN_EDIT_PERSON_PROFILE,
	CAN_VIEW_USAGE,
	CAN_VIEW_BILLING,
	CAN_VIEW_SUBSCRIPTION,
	CAN_VIEW_PROFILE
} from 'constants/permissions';

export const TENANT_PRODUCT_OWNER = 'PRODUCT_OWNER';
export const TENANT_SUPER_ADMIN = 'SUPER_ADMIN';
export const TENANT_ADMIN = 'ADMIN';
export const TENANT_NORMAL_USER = 'NORMAL_USER';

export const TALKYBOT_ADMIN = 'ADMIN';
export const TALKYBOT_CUSTOM_ROLE = 'CUSTOM_ROLE';

export const BOT_BUILDER_ADMIN = 'BOT_BUILDER_ADMIN';
export const BOT_BUILDER_VIEWER = 'BOT_BUILDER_VIEWER';
export const CONVERSATION_ADMIN = 'CONVERSATION_ADMIN';
export const CONVERSATION_VIEWER = 'CONVERSATION_VIEWER';
export const CRM_ADMIN = 'CRM_ADMIN';
export const CRM_VIEWER = 'CRM_VIEWER';
export const ADMIN = 'ADMIN';

const globalPermissions = [
	CAN_VIEW_TALKYBOTS,
	CAN_VIEW_TENANT_MEMBERS,
	CAN_VIEW_NOTIFICATIONS,
	CAN_VIEW_PROFILE
	// CAN_VIEW_DASHBOARD,
];

const applicationsRolesPermissions = {
	[BOT_BUILDER_VIEWER]: [CAN_VIEW_BOT_BUILDER],
	[BOT_BUILDER_ADMIN]: [CAN_EDIT_BOT_BUILDER, CAN_VIEW_BOT_BUILDER],
	[CONVERSATION_ADMIN]: [
		CAN_EDIT_CONVERSATION,
		CAN_VIEW_CONVERSATION,
		CAN_EDIT_PERSON_PROFILE,
	],
	[CONVERSATION_VIEWER]: [CAN_VIEW_CONVERSATION],
	[CRM_ADMIN]: [CAN_EDIT_CRM, CAN_VIEW_CRM, CAN_EDIT_PERSON_PROFILE],
	[CRM_VIEWER]: [CAN_VIEW_CRM],
};

const botRolesPermissions = {
	[TALKYBOT_ADMIN]: [
		CAN_VIEW_BOT,

		CAN_EDIT_BOT_CONFIGURATION,
		CAN_PUBLISH_BOT,
			CAN_VIEW_DASHBOARD,

		CAN_VIEW_STORE,
		CAN_VIEW_SETTINGS,
		...applicationsRolesPermissions.BOT_BUILDER_ADMIN,
		...applicationsRolesPermissions.CRM_ADMIN,
		...applicationsRolesPermissions.CONVERSATION_ADMIN,
		,
	],
};

const tenantRolesPermissions = {
	[TENANT_PRODUCT_OWNER]: [
		CAN_INVITE_NORMAL_USER,
		CAN_INVITE_IN_GENERAL,
		CAN_CREATE_BOT,
		CAN_INVITE_ADMIN,
		CAN_INVITE_SUPER_ADMIN,
		CAN_CHANGE_BILLING_ACCOUNT,
		CAN_CHANGE_PRODUCT_OWNER,
		...botRolesPermissions.ADMIN,
		,
		CAN_VIEW_BILLING,
		CAN_VIEW_USAGE,
		CAN_VIEW_SUBSCRIPTION,
	],
	[TENANT_SUPER_ADMIN]: [
		CAN_INVITE_IN_GENERAL,
		CAN_INVITE_NORMAL_USER,
		CAN_CREATE_BOT,
		CAN_INVITE_ADMIN,
		...botRolesPermissions.ADMIN,
		CAN_VIEW_USAGE,
		,
	],
	[TENANT_ADMIN]: [
		CAN_INVITE_IN_GENERAL,
		CAN_INVITE_NORMAL_USER,
		CAN_CREATE_BOT,
		...botRolesPermissions.ADMIN,
		,
	],
};

function userHasPermission(
	action,
	user,
	activeTalkybot = null /** for actions that related to bot */,
	requiredApp = null /** for actions that related to app  :: applicationable_type*/,
	anotherMemberToVisit = null
) {
	const tenant_role = user?.tenant_role?.name;
	const talkybots_roles = user?.talkybots_roles || [];

	if (action === CAN_EDIT_PERMISSION) {
		if (!anotherMemberToVisit)
			throw new Error('no member found ! for edit permission action !');
		else {
			const other_user = anotherMemberToVisit;
			return userWithRoleCanAccessOtherUserWithRole(user, other_user);
		}
	}

	if (globalPermissions.includes(action))
		return true; /** all Users has permission of global */

	if (tenant_role !== TENANT_NORMAL_USER) {
		const isActionAllowedForUser =
			tenantRolesPermissions[tenant_role].includes(action);
		return isActionAllowedForUser;
	} else {
		/** NO TENANT ROLE SO LETS GO TO TALKYBOT LEVEL */
		const talkybotName = activeTalkybot?.attributes?.name;
		const selectedTalkybot = talkybots_roles.find(
			(bot) => bot?.attributes?.talkybot.name === talkybotName
		);
		const talkybotRole = selectedTalkybot
			? selectedTalkybot?.attributes?.bot_role.name
			: null;
		if (talkybotRole !== TALKYBOT_CUSTOM_ROLE && talkybotRole) {
			return botRolesPermissions[talkybotRole].includes(action);
		} else {
			/** NO TALKYBOT ROLE SO LETS GO TO APPLICATION LEVEL */
			const selectedApplication =
				selectedTalkybot?.attributes?.bot_role?.applications_roles.find(
					(app) =>
						app.attributes.application.applicationable_type ===
						requiredApp
				);
			if (!selectedApplication) return false;

			const appRole = selectedApplication
				? selectedApplication?.attributes?.application_role?.name
				: null;

			return applicationsRolesPermissions[appRole].includes(action);
		}
	}
}

function userWithRoleCanAccessOtherUserWithRole(user, other_user) {
	const otherRole = other_user.tenant_role?.name;
	const role = user?.tenant_role?.name;
	const rolesPriorities = {
		[TENANT_PRODUCT_OWNER]: 4,
		[TENANT_SUPER_ADMIN]: 3,
		[TENANT_ADMIN]: 2,
		[TENANT_NORMAL_USER]: 1,
	};
	return rolesPriorities[role] > rolesPriorities[otherRole];
}

export default userHasPermission;
