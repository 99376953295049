import { connect } from 'react-redux';
import {
	clearActiveIntentAction,
} from 'store/actions/creators/faqs';

const mapStateToProps = (state) => {
	return {
		activeTalkybot: state?.activeTalkybot,
		activeEntity: state?.entities?.activeEntity,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		clearActiveIntent: () => dispatch(clearActiveIntentAction()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps);
