import styles from './styles.module.css';
import { Container, Button, Grid, Paper, Typography } from '@material-ui/core';

import { makeStyles, withStyles } from '@material-ui/core/styles';
// import footerLogo from 'assets/images/landingImgs/footerLogo.png';
import logo from 'assets/images/landingImgs/newLogoFooter.svg';

import tooltipFame from 'assets/images/landingImgs/tooltipFame.png';
import * as mediaLinks from './mediaConstant';
import { Link, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
	navLogo: {
		width: '11.1875rem',
		height: '4.1875rem',
		// marginBottom: '1rem',
	},
	footer: {
		backgroundColor: '#43348B ',
		color: '#FFFFFF',
		position: 'relative',
		paddingTop: '1px',
	},
	footerLogo: {
		width: '14.375rem',
		height: '4.375rem',
		marginBottom: '1rem',
	},
	headerList: {
		fontFamily: 'Poppins',
		fontSize: '1.125rem',
		lineHeight: '1.6875rem',
		fontWeight: 'bold',
		textTransform: 'uppercase',
	},
	listItem: {
		fontFamily: 'Poppins',
		fontSize: '1.125rem',
		lineHeight: '2.6875rem',
		fontWeight: 'normal',
		textTransform: 'capitalize',
		color: '#FFF',
		textDecoration: 'none',
	},
	listItemContact: {
		fontFamily: 'Poppins',
		fontSize: '1.125rem',
		marginTop: '.5rem',
		lineHeight: '1.6875rem',
		fontWeight: 'normal',
		textTransform: 'capitalize',
		color: '#FFF',
		textDecoration: 'none',
	},
	inputFiled: {
		outline: 'none',
		border: 0,
		fontFamily: 'Poppins',
		fontSize: '0.9375rem',
		// lineHeight: '1.5rem',
		marginTop: '1rem',
		fontWeight: 'normal',

		padding: '0.9375rem 0px 0.9375rem 1.25rem',
		marginBottom: '1.4375rem',
		width: '95%',
		backgroundColor: '#FFFFFF',
		borderRadius: '0.25rem',
		opacity: '0.8',
		color: '#777777',
	},
	submitBtn: {
		color: '#43348B',
		backgroundColor: '#FFFFFF',
		boxShadow: 'none',
		marginBottom: '2rem',
	},
	copyRights: {
		backgroundColor: '#382788',
		padding: '1rem 0px 1.25rem 0px',
		font: 'normal normal 600 1.125rem/1.6875rem',
		color: ' #FFFFFF',
		textTransform: 'capitalize',
		marginTop: '2rem',
	},
	tooltipFrame: {
		margin: 'auto',
		marginTop: '-10.625rem',
		// transform:"translateY(-50%)",
		padding: '3.125rem  2.5rem 5.75rem 2.5rem',
		[theme.breakpoints.down('sm')]: {
			marginTop: '-5.625rem',
			padding: '4rem  5.5rem 5.75rem 2.5rem',
			backgroundSize: '100% 75%',
			width: '50%',
		},
		width: '37%',
		backgroundColor: 'transparent',
		backgroundImage: `url(${tooltipFame})`,
		backgroundSize: '100% 100%',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center center',
	},
	tooltipHeader: {
		...theme.typography.subtitle5,
		color: theme.palette.text.primary,
	},
	tooltipDesc: {
		...theme.typography.body4,
		color: theme.palette.text.dark,
		margin: '0.9375rem 0px',
	},
	tooltipBtnReq: {
		outline: 'none',
		backgroundColor: theme.palette.background.primary,
		color: 'white',
		boxShadow: ' 0px 3px 6px #0000004D',
		borderRadius: '4px',
		textTransform: 'capitalize',
		padding: '0.4375rem',
		fontSize: '0.75rem',
		marginRight: '1rem',
		marginBottom: '0.3125rem',
		width: '7.5rem',
	},
	tooltipBtnBuy: {
		outline: 'none',
		backgroundColor: theme.palette.background.primary,
		color: 'white',
		boxShadow: ' 0px 3px 6px #0000004D',
		borderRadius: '4px',
		textTransform: 'capitalize',
		padding: '0.4375rem',
		fontSize: '0.75rem',
		marginRight: '1rem',
		marginBottom: '0.3125rem',
		width: '7.5rem',
	},
	mediaIcons: {
		marginBottom: '1.5rem',
		marginTop: '1.5rem',
	},
	mediaIcon: {
		borderRadius: '50%',
		width: '3.125rem',
		height: '3.125rem',
		lineHeight: '3.125rem',
		textAlign: 'center',
		// padding: '10px 12px',
		marginRight: '0.9375rem',
		// paddingTop: '0.6375rem',
		color: theme.palette.text.primary,
		backgroundColor: theme.palette.background.white,
		textDecoration: 'none',
	},
	containerStyle: {
		[theme.breakpoints.up('md')]: {
			paddingRight: theme.spacing(15),
			paddingLeft: theme.spacing(15),
		},
		paddingRight: theme.spacing(4),
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	containerStylePadding: {
		paddingTop: '4rem',
	},
}));

const Footer = () => {
	const classes = useStyles();
	const { pathname } = useLocation();
	const { url } = useRouteMatch();
	const history = useHistory();

	const paddingWhenInContact = classNames(classes.containerStyle, {
		[classes.containerStylePadding]: pathname === `${url}/contact`,
	});

	return (
		<footer className={classes.footer}>
			{pathname !== `${url}/contact` && (
				<Paper elevation={0} className={classes.tooltipFrame}>
					<Typography className={classes.tooltipHeader}>
						Ready To Get Started?
					</Typography>
					<Typography className={classes.tooltipDesc}>
						Get in touch or create an account
					</Typography>
					<Button
						color="primary"
						className={classes.tooltipBtnReq}
						variant="contained"
						href={`${url}/contact`}
					>
						Request a demo
					</Button>
					{/* <Button
						color="primary"
						className={classes.tooltipBtnBuy}
						variant="contained"
						href="/register"
					>
						sign up
					</Button> */}
				</Paper>
			)}

			<Container className={paddingWhenInContact} maxWidth={false}>
				<Grid
					container
					direction="row"
					spacing={1}
					justifyContent="space-between"
				>
					<Grid item xs={5} md={3}>
						<img className={classes.navLogo} src={logo} alt="" />

						<h3 className={classes.headerList}>contact us</h3>
						<p className={classes.listItemContact}>
							No-code CRM chatbot with AI capabilities to generate
							leads, support customers and grow businesses with
							few steps.
						</p>
					</Grid>

					<Grid item xs={5} md={3}>
						<div>
							<h3 className={classes.headerList}>Site Items</h3>
							<p>
								<Link className={classes.listItem} to={url}>
									Home
								</Link>
							</p>
							<p>
								<Link
									className={classes.listItem}
									to={`${url}/about`}
								>
									About
								</Link>
							</p>
							<p>
								<Link
									className={classes.listItem}
									to={`${url}/features`}
								>
									Features
								</Link>
							</p>
							{/* <p>
								<Link
									className={classes.listItem}
									to={`${url}/pricing`}
								>
									Pricing
								</Link>
							</p> */}
							<p>
								<Link className={classes.listItem} to="/login">
									Login
								</Link>
							</p>
						</div>
					</Grid>
					{/* <Grid item xs={5} md={3}>
						<h3 className={classes.headerList}>channel</h3>

						<p>
							<a className={classes.listItem} href={mediaLinks.FACEBOOK} target='_blank'>Facebook</a>
						</p>
						<p>
							<a className={classes.listItem} href={mediaLinks.LINKEDIN} target='_blank'>Linkedin</a>
						</p>
						<p>
							<Link
								className={classes.listItem}
								to={mediaLinks.WHATSAPP}
							>
								Whatsapp
							</Link>
						</p>
						<p>
							<Link
								className={classes.listItem}
								to={mediaLinks.TWITTER}
							>
								Twitter
							</Link>
						</p>
					</Grid> */}
					<Grid item xs={5} md={3}>
						<h3 className={classes.headerList}>
							You Can Find Us On
						</h3>
						{/* <form noValidate autoComplete="off">
							<input
								placeholder="Email"
								className={classes.inputFiled}
								type="email"
							/>
							<Button
								variant="contained"
								classes={{ root: classes.submitBtn }}
								color="white"
								onClick={() => history.push('/home/contact')}
							>
								Submit
							</Button>
						</form> */}
						<Typography className={classes.mediaIcons}>
							<a className={classes.mediaIcon + ' fab fa-facebook-f fa-2x'} href={mediaLinks.FACEBOOK} target='_blank'></a>
							<a className={classes.mediaIcon + ' fab fa-youtube fa-2x'} href={mediaLinks.YOUTUBE} target='_blank'></a>

							{/* <Link
								to={mediaLinks.TWITTER}
								className={
									classes.mediaIcon + ' fab fa-twitter fa-2x'
								}
							></Link> */}
							{/* <Link
								to={mediaLinks.WHATSAPP}
								className={
									classes.mediaIcon + ' fab fa-whatsapp fa-2x'
								}
							></Link> */}
							<a className={classes.mediaIcon + ' fab fa-linkedin-in fa-2x'} href={mediaLinks.LINKEDIN} target='_blank'></a>
						</Typography>
					</Grid>
				</Grid>
			</Container>
			<div className={classes.copyRights}>
				<Container className={classes.containerStyle} maxWidth={false}>
					Copyright 2021 , Talkybot , All Rights Reserved
					<Grid xs={3}>
						<Link className={classes.listItem} to={`/home/policy`}>
							Privacy Policy
						</Link>
					</Grid>
				</Container>
			</div>
		</footer>
	);
};

export default Footer;
