import { makeStyles } from '@material-ui/core';
import React from 'react';

const ErrorView = ({ error }) => {
	const classes = useStyles();
	return <div className={classes.errorStyle}>{error}</div>;
};

export default ErrorView;
const useStyles = makeStyles((theme) => ({
	errorStyle: {
		...theme.typography.subtitle4,
		color: theme.palette.error.main,
		marginTop: theme.spacing(12),
		display: 'flex',
		justifyContent: 'center',
	},
}));
