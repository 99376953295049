import parseSavedReply from 'helpers/parseSavedReply';
import {
	GET_ALL_SAVED_REPLIES,
	CREATE_NEW_SAVED_REPLIES,
	UPDATE_SAVED_REPLIES,
	DELETE_SAVED_REPLIES,
	GET_ALL_SAVED_REPLIES_ERROR,
	CREATE_NEW_SAVED_REPLIES_ERROR,
	GET_SINGLE_SAVED_REPLY,
	GET_SINGLE_SAVED_REPLY_ERROR,
	RESOLVE_SINGLE_SAVED_REPLY,
} from 'store/actions/types/savedReplies';

const INIT_STATE = {
	savedReplies: [],
	singleSavedReply: {},
	resolvedMention: '',
};

export default (state = INIT_STATE, { type, payload }) => {
	switch (type) {
		case GET_ALL_SAVED_REPLIES: {
			const data = parseSavedReply(payload);

			return {
				...state,
				savedReplies: data,
			};
		}

		case GET_ALL_SAVED_REPLIES_ERROR: {
			return {
				...state,
				savedReplies: [],
			};
		}

		case GET_SINGLE_SAVED_REPLY: {
			return {
				...state,
				singleSavedReply: payload,
			};
		}

		case GET_SINGLE_SAVED_REPLY_ERROR: {
			return {
				...state,
				singleSavedReply: {},
			};
		}

		case RESOLVE_SINGLE_SAVED_REPLY: {
			return {
				...state,
				resolvedMention: payload,
			};
		}

		case CREATE_NEW_SAVED_REPLIES: {
			const data = parseSavedReply(payload);

			return {
				...state,
				savedReplies: [...state.savedReplies, payload],
			};
		}

		case CREATE_NEW_SAVED_REPLIES_ERROR: {
			return {
				...state,
				savedReplies: [...state.savedReplies],
			};
		}

		case UPDATE_SAVED_REPLIES: {
			const updatedSavedReplies = state.savedReplies.map((reply) =>
				reply.id == payload.id ? payload : reply
			);

			const data = parseSavedReply(updatedSavedReplies);

			return {
				...state,
				savedReplies: data,
			};
		}

		case DELETE_SAVED_REPLIES: {
			return {
				...state,
				savedReplies: state.savedReplies.filter(
					(reply) => reply.id != payload.id
				),
			};
		}

		default:
			return state;
	}
};
