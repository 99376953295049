import { completeUserProfile } from 'constants/apis';
import talkybotAxios from 'helpers/axios';
import * as actionType from 'store/actions/types/completeUserProfile';

const completeUserProfileAction = (userData) => async (dispatch) => {
	try {
		dispatch({ type: actionType.COMPLETE_USER_PROFILE_REQUEST });
		const { data } = await talkybotAxios.put(
			`${completeUserProfile}`,
			userData
		);
		dispatch({
			type: actionType.COMPLETE_USER_PROFILE_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: actionType.COMPLETE_USER_PROFILE_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		});
	}
};
export default completeUserProfileAction;
