import { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';

import AudioSound from 'assets/audio/notification.mp3';
import { useDispatch } from 'react-redux';

import { Avatar, Grid, Typography } from '@material-ui/core';

import { onMessageListener } from 'config/firebase.js';
import { useSnackbar } from 'notistack';
import {
	changeLoginUserNotificationStatus
} from 'store/actions/creators/auth';
import './App.css';

import { useHistory, useLocation } from 'react-router-dom';
import { logoutAdmin } from 'store/actions/creators/adminPanel';
import EntryPoint from './pages/EntryPoint';
import Routes from './routes';

function App() {
	// Hooks
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const { enqueueSnackbar } = useSnackbar();
	useEffect(() => {
		onMessageListener(onMessage);
	}, []);
	//######################################################## handle the push notification click ####################################
	navigator.serviceWorker.ready.then((registration) => {
		const handleNotificationClick = (event) => {
			const clickedAction = event.notification.data.click_action;
			if (clickedAction) {
				window.location.href = clickedAction;
			}
		};

		registration.addEventListener('notificationclick', handleNotificationClick);

	});



	// audio of the notification
	const audio = new Audio(AudioSound);

	const NotificationCard = ({ bot, person, sender }) => {
		return (
			<Grid container alignItems="center">
				<Avatar src={sender?.image} />
				<Typography style={{ marginLeft: '1rem' }}>
					New Notification From {sender?.name}
				</Typography>
			</Grid>
		);
	};
	// when notification is received from firebase cloud messaging service then this function is called
	// to show the notification and play the audio
	const onMessage = (payload) => {
		audio.play();
		enqueueSnackbar(
			<NotificationCard
				bot={JSON.parse(payload?.data?.bot)}
				person={JSON.parse(payload?.data?.person)}
				sender={JSON.parse(payload?.data?.sender)}
			/>,
			{
				variant: 'info',
				autoHideDuration: 4000,
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
				},
			}
		);
		// set user notification_status false to show bell icon with red dot
		dispatch(changeLoginUserNotificationStatus());
	};
	const [adminTokenData, setAdminTokenData] = useState(
		localStorage.getItem('adminToken')
			? JSON.parse(localStorage.getItem('adminToken'))
			: null
	);

	const entries = performance.getEntriesByType("navigation");
	useEffect(() => {
		if (entries.map(nav => nav.type)[0] === 'navigate' && location.pathname.includes('admin') && !adminTokenData) {
			dispatch(logoutAdmin(history))
		}
		if (entries.map(nav => nav.type)[0] === 'navigate' && location.pathname.includes('admin') && +localStorage.getItem('AdminTabsOpen') === 0) {
			dispatch(logoutAdmin(history))
			localStorage.setItem('AdminTabsOpen', 0)
		}

		if (location.pathname.includes('admin')) {

			const tabsOpen = localStorage.getItem('AdminTabsOpen')
			// define increment counter part
			if (tabsOpen == null) {
				localStorage.setItem('AdminTabsOpen', 1)
			} else {
				localStorage.setItem('AdminTabsOpen', parseInt(tabsOpen) + parseInt(1))
			}

			// define decrement counter part
			window.onunload = function () {
				const newTabCount = localStorage.getItem('AdminTabsOpen')
				if (newTabCount !== null) {
					localStorage.setItem('AdminTabsOpen', parseInt(newTabCount) - parseInt(1))
				}
			}
		}
	}, [])



	return (
		<div className="App">
			<EntryPoint />
			<Routes />
		</div>
	);
}
export default process.env.NODE_ENV === 'development' ? hot(App) : App;
