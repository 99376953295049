import { useEffect, useRef } from "react";
// local styles
import styles from "./index.module.scss";

const Dropdown = ({ setShow, menu, setSelected, statusRef }) => {
  let menuRef = useRef()

  useEffect(() => {
    let handler = (e) => {
      if (menuRef.current) {
        if (!menuRef.current.contains(e.target) && !statusRef.current.contains(e.target)) {
          setShow(false)
        }
      }
    }
    document.addEventListener("mousedown", handler)
    return () => {
      document.removeEventListener("mousedown", handler)
    }
  })

  const handleChangeValue = (e) => {
    setShow(false)
    setSelected(e)
  }

  return (
    <div ref={menuRef} className={styles.dropdown}>
      <ul>
        {
          menu.map((menuItem) => (
            <li key={menuItem.id} onClick={() => handleChangeValue(menuItem.value)}>
              {menuItem?.icon}
              <span>{menuItem?.title}</span>
            </li>
          ))
        }
      </ul>
    </div>
  )
}

export default Dropdown
