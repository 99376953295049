import React from 'react';
import { Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ActivityTypeImage from 'components/PersonProfile/Activities/ActivityImageType/ActivityTypeImage';
import CardBody from './CardBody';

const Component = ({
	id,
	userAvatar,
	userName,
	personName,
	byPerson,
	byBot,
	content,
	action = 'make an action',
	type,
}) => {
	const classes = useStyles();
	return (
		<Card className={classes.root} key={id} elevation={0}>
			<CardContent className={classes.avatarPosition}>
				<ActivityTypeImage
					alt="alt"
					type={type}
					userAvatar={userAvatar}
					byPerson={byPerson}
					byBot={byBot}
				/>
			</CardContent>
			<CardBody
				userName={userName}
				personName={personName}
				byPerson={byPerson}
				byBot={byBot}
				content={content}
				action={action}
				type={type}
			/>
		</Card>
	);
};

export default Component;

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		minHeight: '5rem',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		// whiteSpace: 'nowrap',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'start',
		alignItems: 'center',
		border: 0,
		outline: 0,
		borderBottom: '1px solid #eee',
		borderRadius: '0px',
	},
	avatarPosition: {
		padding: '0.5rem',
	},
}));
