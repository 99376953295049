import { Fragment, useEffect, useState } from 'react';
// local component
import AnalyticsCard from 'components/dashboard/Shared/AnalyticsCard';

import AssignedCustomersProgressBar from 'components/dashboard/Shared/AssignedCustomersProgressBar/ProgressBar';
import ProgressBar from 'components/dashboard/Shared/ConversationProgressBar/ProgressBar';
import OverViewCharts from 'components/dashboard/Shared/overViewCharts/OverViewCharts';
// local styles
import useSetUiLoadingError from 'hooks/useSetUiLoadingError';
import styles from './index.module.scss';
import { Skeleton } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import GenderBar from 'components/dashboard/Shared/GenderBar/GenderBar';
import SentimentCard from 'components/dashboard/Shared/SentimentCard/SentimentCard';
import { ReactComponent as NegativeIcon } from 'assets/negative.svg';
import { ReactComponent as PositiveIcon } from 'assets/positive.svg';
import { ReactComponent as NeutralIcon } from 'assets/neutral.svg';

const Component = ({
	statusDurationValue,
	statusChannelValue,
	talkybotId,
	// action from redux
	getGender,
	getNumberOfNewCustomers,
	getNumberOfConversationSessions,
	getNumberOfConversation,
	getConversationRating,
	getCustomerStatus,
	getAssignedCustomers,
	getCustomerAnalytics,
	// state from reducer
	analyticsCardsInfo, // render analytics Cards Info 
	gender,
	ChartsInfo,
	conversationRating,
	assignedCustomersInfo,
	getSentiment,
	sentiment,
}) => {
	// total_number_of_customers
	const { total_number_of_customers, number_of_assigned_customers } = useSelector(state => state?.dashboard?.assigned_customers);
	const assignedCustomersProgressBarValue = (number_of_assigned_customers / total_number_of_customers) * 100;
	// get current media query
	const [matches, setMatches] = useState(
		window.matchMedia('(max-width: 1600px)').matches
	)

	useEffect(() => {
		window
			.matchMedia('(max-width: 1600px)')
			.addEventListener('change', (e) => setMatches(e.matches));

		return () => window.removeEventListener('change', (e) => setMatches(e.matches));
	}, []);
	const [isLoadingAnalyticsCards, successDataAnalyticsCards, backendErrorAnalyticsCards, setUiLoadingErrorAnalyticsCards] =
		useSetUiLoadingError();
	const [isLoadingGender, successDataGender, backendErrorGender, setUiLoadingErrorGender] =
		useSetUiLoadingError();
	const [isLoadingCharts, successDataCharts, backendErrorCharts, setUiLoadingErrorCharts] =
		useSetUiLoadingError();
	const [isLoadingNumberOfConversation, successDataNumberOfConversation, backendErrorNumberOfConversation, setUiLoadingErrorNumberOfConversation] =
		useSetUiLoadingError();
	const [isLoadingConversationRating, successDataConversationRating, backendErrorConversationRating, setUiLoadingErrorConversationRating] =
		useSetUiLoadingError();
	const [isLoadingCustomerStatus, successDataCustomerStatus, backendErrorCustomerStatus, setUiLoadingErrorCustomerStatus] =
		useSetUiLoadingError();
	const [isLoadingAssignedCustomer, successDataAssignedCustomer, backendErrorAssignedCustomer, setUiLoadingErrorAssignedCustomer] =
		useSetUiLoadingError();
	const [isLoadingSentiment, successDataSentiment, backendErrorSentiment, setUiLoadingErrorSentiment] =
		useSetUiLoadingError();


	useEffect(() => {
		getCustomerAnalytics({
			talkyBotID: talkybotId,
			duration_filter: statusDurationValue,
			provider_filter: statusChannelValue,
			setUiLoadingError: setUiLoadingErrorAnalyticsCards
		})
		getGender({
			talkyBotID: talkybotId,
			duration_filter: statusDurationValue,
			provider_filter: statusChannelValue,
			setUiLoadingError: setUiLoadingErrorGender
		})
		getNumberOfNewCustomers({
			talkyBotID: talkybotId,
			duration_filter: statusDurationValue,
			provider_filter: statusChannelValue,
			setUiLoadingError: setUiLoadingErrorGender
		})
		getNumberOfConversationSessions({
			talkyBotID: talkybotId,
			duration_filter: statusDurationValue,
			provider_filter: statusChannelValue,
			setUiLoadingError: setUiLoadingErrorGender
		})
		getNumberOfConversation({
			talkyBotID: talkybotId,
			duration_filter: statusDurationValue,
			provider_filter: statusChannelValue,
			setUiLoadingError: setUiLoadingErrorNumberOfConversation
		})
		getConversationRating({
			talkyBotID: talkybotId,
			duration_filter: statusDurationValue,
			provider_filter: statusChannelValue,
			setUiLoadingError: setUiLoadingErrorConversationRating
		})
		getCustomerStatus({
			talkyBotID: talkybotId,
			duration_filter: statusDurationValue,
			provider_filter: statusChannelValue,
			setUiLoadingError: setUiLoadingErrorCustomerStatus
		})
		getAssignedCustomers({
			talkyBotID: talkybotId,
			duration_filter: statusDurationValue,
			provider_filter: statusChannelValue,
			setUiLoadingError: setUiLoadingErrorAssignedCustomer
		})
		getSentiment({
			talkyBotID: talkybotId,
			duration_filter: statusDurationValue,
			provider_filter: statusChannelValue,
			setUiLoadingError: setUiLoadingErrorSentiment
		})
	}, [statusDurationValue, statusChannelValue])



	return (
		<div className={styles.Container}>
			{/* upper Container */}
			<div className={styles.upperContainer}>
				{analyticsCardsInfo.map((card) => (
					<Fragment key={card.id}>
						<AnalyticsCard
							cardInfo={card}
							isLoading={isLoadingAnalyticsCards}
						/>
					</Fragment>
				))}
			</div>

			{/* lower Container */}
			<div className={styles.lowerContainer}>
				<div className={styles.ChartsContainer}>
					{ChartsInfo?.map((card, index) => (
						card.message ?
							<div className={styles.wrapper} key={index}>
								<h3>{card.title}</h3>
								<div className={styles.message}>
									<p>{card.message}</p>
								</div>
							</div>
							:
							<OverViewCharts key={index} ChartsInfo={card} isLoading={isLoadingCharts} />
					))
					}
				</div>

				<div className={styles.ProgressContainer}>
					{/* Conversation rating */}
					{
						isLoadingConversationRating ? (
							<Skeleton className={styles.genderSkeleton} variant="rect" animation="wave" height={matches ? 240 : 340} />
						) : (
							<div className={styles.ConversationRatingCard}>
								<div className={styles.header}>
									<h3>Conversation rating</h3>
									<h3>{conversationRating?.average ?? '0'}</h3>
								</div>
								{conversationRating?.data?.map((card) => (
									<Fragment key={card.id}>
										<ProgressBar cardInfo={card} />
									</Fragment>
								))}
							</div>
						)
					}
					{/* Gender Container */}

					<div className={styles.sentimentGenderContainer}>

					
					{
						isLoadingSentiment ? (
							<Skeleton className={styles.genderSkeleton} variant="rect" animation="wave" height={matches ? 109 : 140} />
						) : (
							<div className={styles.sentimentContainer}>
								<h3>Sentiment Analysis</h3>
								<SentimentCard
									positive={sentiment?.positive_sentiment_percentage}
									negative={sentiment?.negative_sentiment_percentage}
									neutral={sentiment?.neutral_sentiment_percentage}
								/>
								<div className={styles.sentimentInfoContainer}>
									<div className={styles.sentimentInfoWrapper}>
										<div className={styles.sentimentIconText}>
											<NegativeIcon />
											<p>Negative</p>
										</div>
										<p className={styles.result}>{`${sentiment?.negative_sentiment_percentage == null ? 0 : sentiment?.negative_sentiment_percentage?.toFixed(1)}%`}</p>
									</div>
									<div className={styles.sentimentInfoWrapper}>
										<div className={styles.sentimentIconText}>
											<NeutralIcon />
											<p>Neutral</p>
										</div>
										<p className={styles.result}>{`${sentiment?.neutral_sentiment_percentage == null ? 0 : sentiment?.neutral_sentiment_percentage?.toFixed(1)}%`}</p>
									</div>
									<div className={styles.sentimentInfoWrapper}>
										<div className={styles.sentimentIconText}>
											<PositiveIcon />
											<p>Positive</p>
										</div>
										<p className={styles.result}>{`${sentiment?.positive_sentiment_percentage == null ? 0 : sentiment?.positive_sentiment_percentage?.toFixed(1)}%`}</p>
									</div>
								</div>
							</div>
						)
					}
					{/* Assigned customers Container */}
					{
						isLoadingAssignedCustomer ? (
							<Skeleton className={styles.genderSkeleton} variant="rect" animation="wave" height={matches ? 109 : 184} />
						) : (
							<div className={styles.AssignedCustomersContainer}>
								<h3>Contacts {total_number_of_customers}</h3>
								<div className={styles.assignedGenderContainer}>
									<GenderBar male={gender[0]?.result} female={gender[1]?.result} unknown={gender[2]?.result} />
									<div className={styles.genderInfoContainer}>
									{gender?.map((item) => (
										<div className={styles.item} key={item.id}>
											<span
												className={styles.colorBox}
												style={{
													backgroundColor: `${item.title === 'Unknown' ? '#909090' : item.background}`,
												}}
											></span>
											<div className={styles.genderInfo}>
												<span className={styles.title}>
													{item.title}
												</span>
												<span
													className={styles.result}
												>{`${item.result ?? '0'}%`}</span>
											</div>
										</div>
									))}
								</div>
								<AssignedCustomersProgressBar value={number_of_assigned_customers} total={total_number_of_customers} />
								
								<div className={styles.itemsContainer}>
									{assignedCustomersInfo.map((item) => (
										<div className={styles.item} key={item.id}>
											<span
												className={styles.colorBox}
												style={{
													backgroundColor: `${item.background}`,
												}}
											></span>
											<div className={styles.itemInfo}>
												<span className={styles.title}>
													{item.title}
												</span>
												<span className={styles.result}>
													{item.result ?? 0}
												</span>
											</div>
										</div>
									))}
								</div>
								</div>
								{/* <div className={styles.itemsContainer}>
									{assignedCustomersInfo.map((item) => (
										<div className={styles.item} key={item.id}>
											<span
												className={styles.colorBox}
												style={{
													backgroundColor: `${item.background}`,
												}}
											></span>
											<div className={styles.itemInfo}>
												<span className={styles.title}>
													{item.title}
												</span>
												<span className={styles.result}>
													{item.result ?? 0}
												</span>
											</div>
										</div>
									))}
								</div> */}
							</div>
						)}
						</div>
				</div>
			</div>
		</div>
	);
};

export default Component;