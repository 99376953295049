export const GET_CURRENT_USAGE_REQUEST = 'GET_CURRENT_USAGE_REQUEST';
export const GET_CURRENT_USAGE_SUCCESS = 'GET_CURRENT_USAGE_SUCCESS';
export const GET_CURRENT_USAGE_FAIL = 'GET_CURRENT_USAGE_FAIL';
export const GET_CURRENT_SESSIONS = 'GET_CURRENT_SESSIONS'
export const GET_CURRENT_SESSIONS_SUCCESS = 'GET_CURRENT_SESSIONS_SUCCESS'
export const GET_CURRENT_SESSIONS_FAIL = 'GET_CURRENT_SESSIONS_FAIL'
export const GET_CURRENT_ENGAGEMENT = 'GET_CURRENT_ENGAGEMENT'
export const GET_CURRENT_ENGAGEMENT_SUCCESS = 'GET_CURRENT_ENGAGEMENT_SUCCESS'
export const GET_CURRENT_ENGAGEMENT_FAIL = 'GET_CURRENT_ENGAGEMENT_FAIL'
export const GET_CURRENT_MOBILE_APP_USAGE = 'GET_CURRENT_MOBILE_APP_USAGE'
export const GET_CURRENT_MOBILE_APP_USAGE_SUCCESS = 'GET_CURRENT_MOBILE_APP_USAGE_SUCCESS'
export const GET_CURRENT_MOBILE_APP_USAGE_FAIL = 'GET_CURRENT_MOBILE_APP_USAGE_FAIL'
export const GET_CURRENT_BOT_USAGE = 'GET_CURRENT_BOT_USAGE'
export const GET_CURRENT_BOT_USAGE_SUCCESS = 'GET_CURRENT_BOT_USAGE_SUCCESS'
export const GET_CURRENT_BOT_USAGE_FAIL = 'GET_CURRENT_BOT_USAGE_FAIL'
