import { ACTIVATE_SYNONYM, ADD_NEW_SYNONYM, ADD_NEW_SYNONYM_VALUE, CLEAR_ACTIVATE_SYNONYM, DELETE_SYNONYM, DELETE_SYNONYM_VALUE, GET_ALL_SYNONYMS, GET_ALL_SYNONYM_VALUES, UPDATE_SYNONYM, UPDATE_SYNONYM_VALUE } from "store/actions/types/synonyms";

const initialState = {
  synonyms: [],
  synonymsCount: 0,
  meta: {},
  activeSynonym: {},
  values: [],
  meta: {},
};


export default (state = initialState, { type, payload }) => {
  switch (type) {

    case ADD_NEW_SYNONYM:
      const { synonym } = payload;
      return {
        ...state,
        synonyms: [synonym, ...state.synonyms],
      };

    case GET_ALL_SYNONYMS:
      const {
        synonyms,
        meta,
        isScrolling,
      } = payload;

      if (!isScrolling) {
        return {
          ...state,
          synonyms,
          meta
        };
      } else {
        return {
          ...state,
          synonyms: [...state.synonyms, ...synonyms],
          meta: meta,
        };
      }


    case UPDATE_SYNONYM: {
      const { updatedSynonym } = payload;
      const newSynonyms = [...state.synonyms];
      const updatedSynonymIndex = newSynonyms.findIndex(
        (synonym) => synonym.id === updatedSynonym.id
      );

      newSynonyms[updatedSynonymIndex] = updatedSynonym;
      return {
        ...state,
        synonyms: newSynonyms,
        activeEntity: updatedSynonym,
      };
    }
    case DELETE_SYNONYM:
      const { synonymId } = payload;
      const updatedSynonyms = [...state.synonyms];
      const synonymIndex = updatedSynonyms.findIndex(
        (synonym) => synonym.id === synonymId
      );
      if (synonymIndex >= 0) {
        updatedSynonyms.splice(synonymIndex, 1);
      }
      return {
        ...state,
        synonyms: [...updatedSynonyms],
        synonymsCount: state.synonymsCount - 1,
      };

    case ACTIVATE_SYNONYM:
      const { activatedSynonym } = payload;

      return {
        ...state,
        activeSynonym: activatedSynonym,
      };

    case CLEAR_ACTIVATE_SYNONYM:
      return {
        ...state,
        activeSynonym: {},
      }

    case GET_ALL_SYNONYM_VALUES: {
      const { values, synonymId } = payload;
      return {
        ...state,
        values,
      }
    }
    case ADD_NEW_SYNONYM_VALUE: {
      const { value } = payload
      return {
        ...state,
        values: [value, ...state.values]
      }
    }
    case UPDATE_SYNONYM_VALUE: {
      const { valueId, updatedValue } = payload;
      const updatedValues = [...state.values]
      updatedValues.map(value => {
        if (value.id === valueId) {
          return updatedValue
        }
        return value
      })
      return {
        ...state,
        values: updatedValues,
      }
    }
    case DELETE_SYNONYM_VALUE: {
      const { valueId } = payload;
      const updatedValues = [...state.values];
      const valueIndex = updatedValues.findIndex(value => value.id === valueId);

      if (valueIndex !== -1) {
        updatedValues.splice(valueIndex, 1);
      }

      return {
        ...state,
        values: updatedValues,
      };
    }
    default:
      return state;
  }
}