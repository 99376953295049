/* eslint-disable prefer-template */
import {
	GET_PEOPLE,
	GET_PERSON,
	DELETE_PERSON,
	ADD_PERSON,
	GET_CUSTOM_FIELDS,
	ACTIVATE_PERSON,
	EDIT_CUSTOM_FIELD,
	ADD_CUSTOM_FIELD,
	DELETE_CUSTOM_FIELD_SUCCESS,
	EDIT_PERSON,
	ADD_NOTE_TO_PERSON,
	GET_PERSON_NOTES,
	GET_EDITOR_MEMBERS_SUCCESS,
	GET_EDITOR_MEMBERS_REQUEST,
	GET_CRM_STATUS_SUCCESS,
	ADD_CRM_STATUS_REQUEST,
	ADD_CRM_STATUS_SUCCESS,
	ADD_CRM_STATUS_FAIL,
	REMOVE_CRM_STATUS_REQUEST,
	REMOVE_CRM_STATUS_SUCCESS,
	REMOVE_CRM_STATUS_FAIL,
	UPDATE_CRM_STATUS_REQUEST,
	UPDATE_CRM_STATUS_SUCCESS,
	UPDATE_CRM_STATUS_FAIL,
	ADD_CUSTOM_FIELD_FAIL,
	UPDATE_CRM_PEOPLE_ASSIGN_FAIL,
	UPDATE_CRM_PEOPLE_ASSIGN_REQUEST,
	UPDATE_CRM_PEOPLE_ASSIGN_SUCCESS,
	UPDATE_PERSON_NOTE_SUCCESS,
	DELETE_PERSON_NOTE_SUCCESS,
	BULK_DELETE_PEOPLE,
	GET_PERSON_INCIDENTS,
	GET_ALL_CRM_APP_FIELDS_SUCCESS,
	GET_BOT_SCENARIOS_SUCCESS,
	GET_BOT_SCENARIOS_FAILED,
	GET_PERSON_SCENARIOS,
	GET_PERSON_SCENARIOS_FAILED,
	GET_SCENARIO_VARIATION,
	GET_SCENARIO_VARIATION_FAILED,
	GET_CRM_CHANNEL_SUCCESS,
} from 'store/actions/types/crm';

import { FILTER_CRM_PEOPLE } from 'store/actions/types/crm-filters';
import filterPeopleHelper from 'helpers/filter-people-helper';

const INIT_STATE = {
	people: {},
	peopleSearch: {},
	person: {},
	personScenarios: [],
	scenarioVariations: [],
	customFields: [],
	editorMembers: null,
	loading: false,
	error: null,
	statusList: {},
	state: null,
	crmApp: null,
	peopleCount: 0,
	result: 0,
	incidents: {},
	botScenarios: [],
	channelList: [],
	// to Trigger fetching incidents when actions are dispatched
	// actions like add note, Edit note, delete note,
	// add status, edit status, delete status
	// add custom field, edit custom field, delete custom field
	fetchIncidents: false,
};

export default (state = INIT_STATE, { type, payload }) => {
	switch (type) {
		case GET_PEOPLE: {
			return {
				...state,
				people: { ...payload.people },
				peopleCount: payload.count,
				result: payload.result,
			};
		}

		case GET_PERSON_INCIDENTS: {
			return {
				...state,
				incidents: payload.incidents,
			};
		}
		case UPDATE_CRM_PEOPLE_ASSIGN_REQUEST: {
			return {
				...state,
				loading: true,
			};
		}

		case GET_EDITOR_MEMBERS_REQUEST: {
			return {
				...state,
				loading: true,
			};
		}

		case FILTER_CRM_PEOPLE: {
			const { people } = state;
			const updatedPeople = filterPeopleHelper({
				filters: payload.filters,
				people: people,
			});
			return {
				...state,
				people: updatedPeople,
			};
		}

		case GET_EDITOR_MEMBERS_SUCCESS: {
			return {
				...state,
				loading: false,
				error: false,
				editorMembers: payload.editorMembers,
			};
		}

		case UPDATE_CRM_PEOPLE_ASSIGN_SUCCESS: {
			return {
				...state,
				crmApp: payload,
				loading: false,
				error: false,
			};
		}

		case GET_CRM_STATUS_SUCCESS: {
			return {
				...state,
				loading: false,
				error: false,
				statusList: payload.statusList,
				crmApp: payload.crmApp,
			};
		}
		case EDIT_PERSON: {
			const { id } = payload;
			const newState = { ...state };
			const newPeople = {
				...newState.people,
				[' ' + id]: {
					...payload,
					...payload.attributes,
				},
			};

			// I need to update the scenarioVariations with the updated data coming from custom_fields
			// first check custom_fields[id] === scenarioVariations[id] then replace it with the new data
			const updatededScenarioVariation =
				payload?.attributes?.custom_fields.filter((item) =>
					newState?.scenarioVariations?.some(
						(it) => item.variation_id === it.variation_id
					)
				);

			return {
				...state,
				people: { ...newPeople },
				person: {
					...payload,
				},
				scenarioVariations: updatededScenarioVariation,
			};
		}
		case DELETE_PERSON_NOTE_SUCCESS: {
			const { noteID } = payload;
			const newState = { ...state };
			const newPerson = newState?.person;
			const newPersonData = newState?.person?.attributes?.notes?.data;
			const newPersonNotes = newPersonData?.filter(
				(note) => note.id !== noteID
			);
			const newPersonObj = {
				...state.person,
				attributes: {
					...newPerson.attributes,
					notes: {
						...newPerson.attributes.notes,
						data: [...newPersonNotes],
					},
				},
			};
			return {
				...state,
				person: { ...newPersonObj },
				fetchIncidents: !state.fetchIncidents,
			};
		}

		case GET_PERSON: {
			return {
				...state,
				person: { ...payload },
			};
		}
		case ADD_PERSON: {
			return {
				...state,
				people: {
					...state.people,
					// [TODO:] test nextline  later why not working as expect .... expected to be ordered  as the backend send it
					// [' ' + payload.id]: { ...payload, ...payload.attributes },
					[payload.id]: { ...payload },
					// ...payload,
				},
				peopleCount: state.peopleCount + 1,
				fetchIncidents: !state.fetchIncidents,
			};
		}
		case DELETE_PERSON: {
			const { personId } = payload;
			const newState = { ...state };
			const newPeople = newState.people;
			delete newPeople[' ' + personId];
			return {
				...state,
				people: { ...newPeople },
				peopleCount: state.peopleCount - 1,
				fetchIncidents: !state.fetchIncidents,
			};
		}
		case BULK_DELETE_PEOPLE: {
			const { peopleIds } = payload;
			const newState = { ...state };
			const newPeople = newState.people;
			// [delete] people depending on the ids
			peopleIds.forEach((id) => {
				delete newPeople[' ' + id];
			});
			return {
				...state,
				peopleCount: Object.entries(newPeople).length,
				people: { ...newPeople },
			};
		}
		case ACTIVATE_PERSON: {
			return {
				...state,
				// customFields: payload?.attributes?.crm_variations,
				person: { ...payload },
			};
		}
		case GET_CUSTOM_FIELDS: {
			return {
				...state,
				customFields: [...payload],
			};
		}
		case ADD_CUSTOM_FIELD: {
			return {
				...state,
				error: null,
				customFields: [...state.customFields, payload],
			};
		}

		case ADD_CUSTOM_FIELD_FAIL: {
			return {
				...state,
				error: payload,
			};
		}
		case EDIT_CUSTOM_FIELD: {
			const { id } = payload;
			const newState = { ...state };
			const newCustomFields = [...newState.customFields];
			const fieldIndex = newCustomFields.findIndex(
				(field) => field.id === id
			);
			newCustomFields[fieldIndex] = {
				...payload,
			};
			return {
				...state,
				customFields: [...newCustomFields],
			};
		}
		case DELETE_CUSTOM_FIELD_SUCCESS: {
			const { id } = payload;
			const newState = { ...state };
			const newCustomFields = newState.customFields.filter(
				(field) => field.id !== id
			);
			return {
				...state,
				customFields: [...newCustomFields],
			};
		}
		case ADD_NOTE_TO_PERSON: {
			const { personId, note } = payload;
			const newState = { ...state };

			const newPeople = {
				...newState.people,
				[personId]: {
					...newState.people[personId],
					notes: {
						...newState.people[personId]?.notes,
						[note?.attributes?.id]: { ...note },
					},
				},
			};
			let newPerson = state.person;
			if (personId === state.person.id) {
				newPerson = {
					...state.person,
					attributes: {
						...newPerson.attributes,
						notes: {
							...newPerson.attributes.notes,
							data: [
								...newPerson.attributes.notes.data,
								{ ...note },
							],
						},
						// incidents: {
						// 	...newPerson.attributes.incidents,
						// 	data: [
						// 		...newPerson.attributes.incidents.data,
						// 		{ ...note },
						// 	],
						// },
					},
				};
			}

			return {
				...state,
				people: { ...newPeople },
				person: newPerson,
				fetchIncidents: !state.fetchIncidents,
			};
		}
		case UPDATE_PERSON_NOTE_SUCCESS: {
			const { personId, note } = payload;
			const newState = { ...state };
			const newPeople = {
				...newState.people,
				[personId]: {
					...newState.people[personId],
					notes: {
						...newState.people[personId]?.notes,
						// [note?.attributes?.id]: { ...note },
						[note?.attributes?.id]: { ...note },
					},
				},
			};
			let newPerson = state.person;
			if (personId === state.person.id) {
				const newPersonData = newState?.person?.attributes?.notes?.data;
				const newPersonNoteIndex = newPersonData?.findIndex(
					(noteItem) => noteItem.id === note?.id
				);

				newPersonData[newPersonNoteIndex] = { ...note };

				newPerson = {
					...state.person,
					attributes: {
						...newPerson.attributes,
						notes: {
							...newPerson.attributes.notes,
							data: [...newPersonData],
						},
					},
				};
			}

			return {
				...state,
				people: { ...newPeople },
				person: { ...newPerson },
				fetchIncidents: !state.fetchIncidents,
			};
		}
		case GET_PERSON_NOTES: {
			const { personId, notes } = payload;
			const newState = { ...state };
			const newPeople = {
				...newState.people,
				[personId]: {
					...newState.people[personId],
					notes: {
						...notes,
					},
				},
			};
			let newPerson = state.person;
			if (personId === state.person.id) {
				newPerson = {
					...state.person,
					notes: {
						...notes,
					},
				};
			}
			return {
				...state,
				people: { ...newPeople },
				person: { ...newPerson },
				fetchIncidents: !state.fetchIncidents,
			};
		}

		// crm status
		case ADD_CRM_STATUS_REQUEST: {
			return { ...state, loading: true };
		}

		case ADD_CRM_STATUS_SUCCESS: {
			return {
				...state,
				loading: false,
				error: false,
				statusList: {
					[' ' + payload.data.id]: { ...payload.data },
					...state.statusList,
				},
			};
		}

		case ADD_CRM_STATUS_FAIL: {
			return {
				...state,
				loading: false,
				error: payload,
			};
		}

		// crm status
		case REMOVE_CRM_STATUS_REQUEST: {
			return { ...state, loading: true };
		}

		case REMOVE_CRM_STATUS_SUCCESS: {
			const { [' ' + payload]: _, ...statusList } = state.statusList;
			return {
				...state,
				loading: false,
				error: false,
				statusList: { ...statusList },
			};
		}

		case REMOVE_CRM_STATUS_FAIL: {
			return {
				...state,
				loading: false,
				error: payload,
			};
		}

		case UPDATE_CRM_PEOPLE_ASSIGN_FAIL: {
			return {
				...state,
				loading: false,
				error: payload,
			};
		}

		case UPDATE_CRM_STATUS_REQUEST: {
			return { ...state, loading: true };
		}

		case UPDATE_CRM_STATUS_SUCCESS: {
			const { statusId, status } = payload;

			return {
				...state,
				loading: false,
				error: false,
				statusList: {
					...state.statusList,
					[' ' + statusId]: status.data,
				},
			};
		}

		case UPDATE_CRM_STATUS_FAIL: {
			return {
				...state,
				loading: false,
				error: payload,
			};
		}

		case GET_ALL_CRM_APP_FIELDS_SUCCESS: {
			return {
				...state,
				crmAppFields: payload,
			};
		}

		case GET_BOT_SCENARIOS_SUCCESS: {
			return {
				...state,
				botScenarios: [...payload.scenarios],
			};
		}

		case GET_BOT_SCENARIOS_FAILED: {
			return {
				...state,
				botScenarios: [],
			};
		}

		case GET_PERSON_SCENARIOS: {
			return {
				...state,
				personScenarios: payload,
			};
		}

		case GET_PERSON_SCENARIOS_FAILED: {
			return {
				...state,
				personScenarios: [],
			};
		}

		case GET_SCENARIO_VARIATION: {
			return {
				...state,
				scenarioVariations: payload,
			};
		}

		case GET_SCENARIO_VARIATION_FAILED: {
			return {
				...state,
				scenarioVariations: [],
			};
		}

		case GET_CRM_CHANNEL_SUCCESS: {
			return {
				...state,
				loading: false,
				error: false,
				channelList: payload.data,
			}
		}
		default:
			return state;
	}
};
