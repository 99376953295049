import React from 'react';
import { CardContent, Typography, Grid } from '@material-ui/core';
import renderDate from 'helpers/renderDate';
import useStyles from './styles';

const CreatePersonBody = ({
	userName,
	personName,
	// byPerson,
	content,
	// classes,
}) => {
	const classes = useStyles();
	const hourFormate = 'h:mm am/pm';

	return (
		<Grid container className={classes.root}>
			<Grid item xs={9} className={classes.statusWrapper}>
				<CardContent className={classes.content}>
					<Typography variant="body" className={classes.activity}>
						{userName ? (
							<Typography
								display="inline"
								className={classes.action}
								title={userName || ''}
							>
								{`${userName}  `}
							</Typography>
						) : (
							<Typography
								display="inline"
								className={classes.action}
							>
								Talkybot
							</Typography>
						)}{' '}
						imported{' '}
						<Typography
							display="inline"
							className={classes.action}
							title={personName || ''}
						>
							{`${personName}  `}
						</Typography>
					</Typography>
				</CardContent>
			</Grid>
			<Grid item xs={3} className={classes.dateWrapper}>
				<CardContent className={classes.content}>
					<Typography variant="body2" className={classes.date}>
						{/* {content.date} */}
						{renderDate(content.date, hourFormate)}
					</Typography>
				</CardContent>
			</Grid>
		</Grid>
	);
};

export default CreatePersonBody;
