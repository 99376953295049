import { connect } from 'react-redux';
import { addNewSynonymAction, getAllSynonymsAction } from 'store/actions/creators/synonyms';
import { setBotBuilderTab } from 'store/actions/creators/tabs';

const mapStateToProps = (state) => {
	return {
		activeTalkybot: state?.activeTalkybot,
		categories: state?.entities?.categories,
		synonyms: state?.synonyms?.synonyms,
		defaultCategory: state.entities.defaultCategory,
		predefinedList: state?.entities?.predefinedList,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setActiveTab: (tabIndex) => dispatch(setBotBuilderTab(tabIndex)),
		addSynonym: ({
			title,
			threshold,
			botBuilderAppId,
			enqueueSnackbar,
			setUiLoadingError,
		}) =>
			dispatch(
				addNewSynonymAction({
					title,
					threshold,
					botBuilderAppId,
					enqueueSnackbar,
					setUiLoadingError,
				})
			),
			getAllSynonyms: ({
				botBuilderAppId,
				keyword,
				scrolling,
				page,
				perPage,
				setUiLoadingError
			}) => {
				dispatch(getAllSynonymsAction({
					botBuilderAppId,
					keyword,
					scrolling,
					page,
					perPage,
					setUiLoadingError
				}))
			},
	};
};

export default connect(mapStateToProps, mapDispatchToProps);
