/* eslint-disable no-case-declarations */
/* eslint-disable no-restricted-syntax */
import {
	DELETE_INTENT,
	FAQS_ERROR,
	GET_ALL_CATEGORIES,
	GET_ALL_INTENT_FAQS,
	GET_ALL_CATEGORY_INTENTS,
	FLIP_INTENT_STATUS,
	ADD_NEW_CATEGORY,
	ADD_NEW_INTENT,
	ACTIVATE_INTENT,
	GET_ALL_INTENT,
	RESET_INTENTS_STATE,
	DELETE_FAQ,
	ADD_NEW_FAQ,
	UPDATE_FAQ,
	RESET_ACTIVE_INTENT,
	DELETE_REPLY,
	UPDATE_REPLY,
	ADD_REPLY,
	UPDATE_INTENT,
	UPDATE_CATEGORY,
	GET_ALL_INTENT_REQUEST,
	GET_ALL_CATEGORY_INTENT_REQUEST,
	DELETE_INTENT_CATEGORY,
	SET_ACTIVE_TAB,
	CHANGE_INTENT_PERCENTAGE_SUCCESS,
	GET_INTENT_PERCENTAGE_SUCCESS,
	SET_DEFAULT_CATEGORY,
	SET_DEFAULT_LANGUAGE,
	ADD_NEW_BULK_FAQ,
	// clear active intent
	CLEAR_ACTIVE_INTENT_SUCCESS,
} from 'store/actions/types/faqs';

const initialState = {
	intents: [],
	categories: [],
	activeIntent: {
		faqs: [],
		replies: [],
	},
	activeTab: 0,
	intentsCount: 0,
	filteredCount: 0,
	defaultCategory: null,
	defaultLanguage: '',
	intentPercentage: '',
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case CLEAR_ACTIVE_INTENT_SUCCESS:
			return {
				...state,
				activeIntent: {
					faqs: [],
					replies: [],
				},
			}
		case GET_ALL_CATEGORIES:
			const { categories } = payload;
			return {
				...state,
				categories,
			};
		case GET_ALL_CATEGORY_INTENT_REQUEST:
			return {
				...state,
				loading: true,
			};
		case GET_ALL_CATEGORY_INTENTS:
			const {
				intents,
				categoryIntentsCount,
				categoryIntentsFilteredCount,
			} = payload;

			return {
				...state,
				intents,
				intentsCount: 0,
				filteredCount: categoryIntentsFilteredCount,
			};
		case GET_ALL_INTENT_FAQS:
			const { faqs } = payload;
			return {
				...state,
				faqs,
			};
		case ACTIVATE_INTENT:
			const { activatedIntent } = payload;

			return {
				...state,
				activeIntent: activatedIntent,
			};
		case GET_ALL_INTENT_REQUEST:
			return {
				...state,
				loading: true,
			};
		case GET_ALL_INTENT:
			const { Intents, count, filteredCount } = payload;
			return {
				...state,
				intents: Intents,
				intentsCount: count,
				filteredCount: 0,
			};
		case DELETE_INTENT_CATEGORY:
			const { deletedCategory } = payload;
			const updatedCategories = [...state.categories];
			const categoryIndex = updatedCategories.findIndex(
				(category) => category.id === deletedCategory.id
			);
			if (categoryIndex >= 0) {
				updatedCategories.splice(categoryIndex, 1);
			}
			return {
				...state,
				categories: [...updatedCategories],
			};

		case DELETE_INTENT:
			const { deletedIntent } = payload;
			const updatedIntents = [...state.intents];
			const intentIndex = updatedIntents.findIndex(
				(intent) => intent.id === deletedIntent.id
			);
			if (intentIndex >= 0) {
				updatedIntents.splice(intentIndex, 1);
			}
			return {
				...state,
				intents: [...updatedIntents],
				intentsCount: state.intentsCount - 1,
				filteredCount: state.filtered_count - 1,
			};

		case UPDATE_CATEGORY:
			const { updatedCategory } = payload;
			const updatedCategoriesArr = state.categories;
			const updatedCategoryIndex = updatedCategoriesArr.findIndex(
				(category) => Number(category.id) === Number(updatedCategory.id)
			);
			updatedCategoriesArr[updatedCategoryIndex] = updatedCategory;
			return {
				...state,
				categories: [...updatedCategoriesArr],
			};
		case ADD_NEW_FAQ:
			const { faq: newFaq } = payload;
			// TODO is to add faq to intent in all intents
			const updatedActiveIntentFaqs = [
				newFaq,
				...state.activeIntent.faqs,
			];
			return {
				...state,
				activeIntent: {
					...state.activeIntent,
					faqs: updatedActiveIntentFaqs,
				},
			};
		case ADD_NEW_BULK_FAQ:
			const { faqs: newFaqs } = payload;
			const updatedActiveIntentBulkFaqs = [
				// ...state.activeIntent.faqs,
				...newFaqs,
			];
			return {
				...state,
				activeIntent: {
					...state.activeIntent,
					faqs: updatedActiveIntentBulkFaqs,
				},
			};
		case DELETE_FAQ:
			const { deletedFaq } = payload;
			let updatedFaqs = [];
			const updatedActiveIntent = state.activeIntent;
			const oldFaqs = [...updatedActiveIntent.faqs];
			const faqIndex = oldFaqs.findIndex(
				(faq) => faq.id === Number(deletedFaq.id)
			);
			if (faqIndex >= 0) {
				updatedFaqs = oldFaqs.filter(
					(faq) => faq.id !== Number(deletedFaq.id)
				);
				// TODO: think about this later ... what if faqIndex is not found? ... does updatedFaqs=[] or updatedFaqs=oldFaqs
				updatedActiveIntent.faqs = updatedFaqs;
			}
			return {
				...state,
				activeIntent: updatedActiveIntent,
			};

		case DELETE_REPLY:
			const { deletedReply } = payload;
			const newState = {
				...state,
				activeIntent: { ...state.activeIntent },
			};

			// const updatedActIntent = state.activeIntent;
			const updatedActIntent = newState.activeIntent;
			const oldReplies = [...updatedActIntent.replies];
			let updatedReplies = [];
			const replyIndex = oldReplies.findIndex(
				(reply) => Number(reply.id) === Number(deletedReply.id)
			);
			if (replyIndex >= 0) {
				updatedReplies = oldReplies.filter(
					(reply) => Number(reply.id) !== Number(deletedReply.id)
				);
				updatedActIntent.replies = updatedReplies;
			}
			return {
				...state,
				activeIntent: updatedActIntent,
			};

		case UPDATE_REPLY:
			const { updatedReply } = payload;

			// Get Updated Reply Index
			const updatedReplyIndex = state.activeIntent.replies.findIndex(
				(reply) => Number(reply.id) === Number(updatedReply.id)
			);

			const repliesUpdated = [...state.activeIntent.replies];

			repliesUpdated[updatedReplyIndex] = updatedReply;

			return {
				...state,
				activeIntent: {
					...state.activeIntent,
					replies: repliesUpdated,
				},
			};

		case ADD_REPLY:
			const { addedReply } = payload;
			const updatedActiveIntentReplies = [
				addedReply,
				...state.activeIntent.replies,
			];
			return {
				...state,
				activeIntent: {
					...state.activeIntent,
					replies: updatedActiveIntentReplies,
				},
			};
		case UPDATE_FAQ:
			const { faq: updatedFaq } = payload;

			// Get Updated Faq Index
			const updatedFaqIndex = state.activeIntent.faqs.findIndex(
				(faq) => faq.id === updatedFaq.id
			);

			// Override Old faq with updated
			const faqsUpdated = [...state.activeIntent.faqs];
			faqsUpdated[updatedFaqIndex] = updatedFaq;

			return {
				...state,
				activeIntent: {
					...state.activeIntent,
					faqs: faqsUpdated,
				},
			};

		case UPDATE_INTENT:
			const { updatedIntent } = payload;
			const newIntents = [...state.intents];
			// : -> update newintents with updated intent
			const updatedIntentIndex = newIntents.findIndex(
				(intent) => intent.id === updatedIntent.id
			);
			newIntents[updatedIntentIndex] = updatedIntent;

			return {
				...state,
				intents: [...newIntents],
				activeIntent: updatedIntent,
			};

		case FLIP_INTENT_STATUS:
			const { intentId } = payload;
			const intentIdx = state.intents.findIndex(
				(intent) => intent.id === intentId
			);
			const uIntents = [...state.intents];
			uIntents[intentIdx].status = !uIntents[intentIdx].status;
			return {
				...state,
				intents: uIntents,
			};

		case ADD_NEW_CATEGORY:
			const { category } = payload;
			return {
				...state,
				categories: [...state.categories, category],
			};
		case RESET_INTENTS_STATE:
			return {
				...state,
				intents: [],
			};
		case RESET_ACTIVE_INTENT:
			return {
				...state,
				activeIntent: {
					faqs: [],
					replies: [],
				},
			};
		case ADD_NEW_INTENT:
			const { intent } = payload;
			return {
				...state,
				intents: [intent, ...state.intents],
			};
		case SET_ACTIVE_TAB:
			const { activeTab } = payload;
			return {
				...state,
				activeTab,
			};

		case SET_DEFAULT_CATEGORY:
			const { categoryId: defaultCategory } = payload;
			return {
				...state,
				defaultCategory,
			};
		case SET_DEFAULT_LANGUAGE:
			const { lang: defaultLanguage } = payload;
			return {
				...state,
				defaultLanguage,
			};

		case CHANGE_INTENT_PERCENTAGE_SUCCESS:
		case GET_INTENT_PERCENTAGE_SUCCESS:
			const { value } = payload;
			return {
				...state,
				intentPercentage: value,
			};
		default:
			return state;
	}
};
