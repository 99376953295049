import React from 'react';
import ReactDOM from 'react-dom';
import { SnackbarProvider } from 'notistack';
import Slide from '@material-ui/core/Slide';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { PersistGate } from 'redux-persist/integration/react';

import theme from 'theme';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './App';
import { registerServiceWorker } from './serviceWorker';
import storeConfig from './store/config';

const render = () => {
	ReactDOM.render(
		<React.StrictMode>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<BrowserRouter
					getUserConfirmation={(message, callback) => {
						// this is the default behavior
						const allowTransition = window.confirm(message);
						callback(allowTransition);
					}}
				>
					<Provider store={storeConfig().store}>
						<PersistGate
							loading={null}
							persistor={storeConfig().persistor}
						>
							<SnackbarProvider
								maxSnack={5}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'right',
								}}
								TransitionComponent={Slide}
							>
								<App />
							</SnackbarProvider>
						</PersistGate>
					</Provider>
				</BrowserRouter>
			</ThemeProvider>
		</React.StrictMode>,
		document.getElementById('root')
	);
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
registerServiceWorker();

render();
// applicationContainer patch
//
// This patch is used to make the application container
// to be able to hot reload the application
