import Typography from '@material-ui/core/Typography';
import { Container, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BotAnalyticsImg from 'assets/images/landingImgs/BotAnalyticsImg.jpg';

const useStyles = makeStyles((theme) => ({
    content: {
        padding: '3.75rem 0px',
    },
    BotAnalyticsImg: {
        width: '20.5625rem',
        height: '20.4375rem',
    },
    contentHeader: {
        ...theme.typography.subtitle3,
        color: theme.palette.text.primary,
        margin: '0.9375rem 0px',
    },
    contentDesc: {
        ...theme.typography.body3,
        color: theme.palette.text.primary,
    },
    containerStyle: {
        [theme.breakpoints.up('md')]: {
            paddingRight: theme.spacing(15),
            paddingLeft: theme.spacing(15),
        },
        paddingRight: theme.spacing(4),
    },
}));
const BotAnalytics = () => {
    const classes = useStyles();
    return (
        <Paper elevation={0}>
            <Paper className={classes.content} elevation={0}>
                <Container className={classes.containerStyle} maxWidth={false}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                    >
                        <Grid item md={6}>
                            <Typography className={classes.contentHeader}>
                                4- Bot Analytics
                            </Typography>
                            <Typography className={classes.contentDesc}>
                                Your Dashboard gives you a full overview of what's happening with your
                                Chatbots and ongoing chats. We'd like you to focus on what matters, your
                                Bot, that's why Chatbot is a user-friendly and easy to understand the
                                Environment. Welcome to your dashboard. Here you can find all your
                                Stories grouped by integrations.
                            </Typography>
                        </Grid>
                        <Grid item md={6} align="end">
                            <img
                                className={classes.BotAnalyticsImg}
                                src={BotAnalyticsImg}
                                alt=""
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Paper>
        </Paper>
    );
};

export default BotAnalytics;
