import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: '0.5rem',
	},
	statusWrapper: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	dateWrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
	},
	action: {
		// fontWeight: '700',
		fontFamily: 'poppins',
		fontSize: '1rem !important',
		color: '#000',
		overflowWrap: 'anywhere',
		fontStyle: 'normal',
		fontVariantLigatures: 'normal',
		fontVariantCaps: 'normal',
		fontVariantNumeric: 'normal',
		fontWeight: 600,
		fontStretch: 'normal',
		// fontSize: '0.875rem',
		lineHeight: '1.0625rem',
	},
	activity: {
		color: '#707070',
		fontSize: '1rem',
		fontWeight: 500,
	},
	date: {
		color: '#909090',
		fontSize: '1rem',
		fontWeight: 'normal',
		textAlign: 'end',
	},
	content: {
		padding: '0px !important',
		margin: '0px',
	},
	messageContainer: {
		padding: '0px',
		// color: '#909090',
		fontFamily: 'Poppins',
		// fontSize: '13px',
		fontStyle: 'normal',
		// fontWeight: '500',
		color: '#000000',
		overflowWrap: 'anywhere',
		display: '-webkit-box',
		'-webkit-line-clamp': '1',
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden',
	},
}));

export default useStyles;
