import { connect } from 'react-redux';
import { activateSynonymAction, clearActiveSynonymAction } from 'store/actions/creators/synonyms';

const mapStateToProps = (state) => {
	return {
		activeTalkybot: state?.activeTalkybot,
		activeSynonym: state?.synonyms?.activeSynonym,
		priorityList: state?.entities?.priorityList,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		clearActiveSynonym: () => dispatch(clearActiveSynonymAction()),
		activateSynonym: (synonymId, botBuilderId) =>
			dispatch(activateSynonymAction(synonymId, botBuilderId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps);
