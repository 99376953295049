/* eslint-disable no-case-declarations */
/* eslint-disable no-restricted-syntax */
import {
	GET_CRM_FILTERS,
	DELETE_CRM_FILTER,
	GET_CRM_COLUMN_NAMES,
	GET_CRM_DISTINCT_COLUMN_VALUES,
} from 'store/actions/types/crm-filters';

const initialState = {
	filters: [],
	columnNames: [],
	columnOptions: {},
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case GET_CRM_FILTERS:
			return {
				...state,
			};
		case DELETE_CRM_FILTER:
			return {
				...state,
			};
		case GET_CRM_COLUMN_NAMES:
			return {
				...state,
				columnNames: payload,
			};
		case GET_CRM_DISTINCT_COLUMN_VALUES:
			return {
				...state,
				columnOptions: {
					...state.columnOptions,
					...payload,
				},
			};
		default:
			return state;
	}
};
