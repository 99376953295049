export default {
	control: {
		backgroundColor: '#fff',
		fontSize: 14,
		fontWeight: 'normal',
	},

	'&multiLine': {
		control: {
			fontFamily: 'Poppins',
			minHeight: '6rem',
		},

		highlighter: {
			padding: '1rem',
			lineHeight: '2.5rem',
		},
		input: {
			padding: '8px',
			border: '1px solid #ddd',
			lineHeight: '2.5rem',
			borderRadius: '5px',
			overflow: 'hidden',
		},
	},

	// '&singleLine': {
	// 	display: 'inline-block',
	// 	width: 180,

	// 	highlighter: {
	// 		padding: 1,
	// 		border: '2px inset transparent',
	// 	},
	// 	input: {
	// 		padding: 1,
	// 		border: '2px inset',
	// 	},
	// },

	suggestions: {
		list: {
			backgroundColor: 'white',
			border: '1px solid rgba(0,0,0,0.15)',
			fontSize: 14,
			overflow: 'auto',
			height: '220px'
		},
		item: {
			padding: '5px 15px',
			borderBottom: '1px solid rgba(0,0,0,0.15)',
			'&focused': {
				backgroundColor: '#cee4e5',
			},
		},
	},
};
