import React from 'react';

import { Grid } from '@material-ui/core';
import EntityDetailsComponent from 'components/BotBuilder/Entities/Entity/EntityDetails';

const CreateEntity = () => {
	return (
		<div style={{ marginTop: '1rem' }}>
			<Grid container>
				<Grid item xs={12}>
					<EntityDetailsComponent />
				</Grid>
			</Grid>
		</div>
	);
};

export default CreateEntity;
