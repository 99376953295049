// ingegrations reducer for CRUD operations

import {
	CREATE_INTEGRATION_SUCCESS,
	DELETE_INTEGRATION_SUCCESS,
	GET_INTEGRATIONS_SUCCESS,
	GET_SINGLE_INTEGRATION_SUCCESS,
	RESET_ACTIVE_INTEGRAION,
	UPDATE_INTEGRATION_SUCCESS,
	TEST_INTEGRATION_RESPONSE_SUCCESS,
} from 'store/actions/types/integrations';

const initialState = {
	integrations: {},
	activeIntegration: {},
	jsonIntegrationResponse: {
		success: null,
		response: null,
		integrationId: null,
	},
};
export default (state = initialState, { type, payload }) => {
	switch (type) {
		case GET_INTEGRATIONS_SUCCESS:
			const { integrations } = payload;
			return {
				...state,
				// put the integrations in an object with the id as the key
				integrations: integrations.reduce((acc, integration) => {
					acc[integration.id] = integration;
					return acc;
				}, {}),
			};
		case CREATE_INTEGRATION_SUCCESS:
			const { integration } = payload;
			return {
				...state,
				// add integration to integrations
				integrations: {
					...state.integrations,
					[integration.id]: integration,
				},
			};
		case UPDATE_INTEGRATION_SUCCESS:
			const { updatedIntegration } = payload;
			return {
				...state,
				// update integration in integrations
				jsonIntegrationResponse: {},
				integrations: {
					...state.integrations,
					[updatedIntegration.id]: updatedIntegration,
				},
			};
		case DELETE_INTEGRATION_SUCCESS:
			const { deletedIntegrationId } = payload;
			let count = 0;
			return {
				...state,
				// delete integration from integrations
				integrations: Object.keys(state.integrations).reduce(
					(acc, integrationId) => {
						if (
							integrationId.toString() !==
							deletedIntegrationId.toString()
						) {
							acc[integrationId] =
								state.integrations[integrationId];
						}
						return acc;
					},
					{}
				),
			};
		// get single integration success
		case GET_SINGLE_INTEGRATION_SUCCESS:
			const { newIntegration } = payload;
			return {
				...state,
				// active integration
				activeIntegration: newIntegration,
				jsonIntegrationResponse: {
					success: null,
					response: null,
					integrationId: null
				},

			};
		case RESET_ACTIVE_INTEGRAION:
			return {
				...state,
				// reset active integration
				activeIntegration: {},
			};
		case TEST_INTEGRATION_RESPONSE_SUCCESS:
			const { jsonResponse } = payload;

			return {
				...state,
				jsonIntegrationResponse: {
					success: jsonResponse.success,
					response: jsonResponse.response,
					integrationId: jsonResponse?.json_integraion_id
				},
			};
		default:
			return state;
	}
};
