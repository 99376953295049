import React, { useState, useEffect, useRef } from 'react'
import PopupContent from './PopupContent';
import styles from './index.module.scss';
import { ReactComponent as Facebook } from 'assets/icons/Usage/Facebook.svg';
import { ReactComponent as Whatsapp } from 'assets/icons/Usage/Whatsapp.svg';
import { ReactComponent as Instagram } from 'assets/images/Dashboard/instagram.svg';

const ClickableText = ({ numbers, formatNumberWithCommas }) => {

  const [showTooltip, setShowTooltip] = useState(false);
	const containerRef = useRef(null);

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };

	const handleOutsideClick = (e) => {
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      setShowTooltip(false);
    }
  };

	useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);


  return (
    <div className={styles.tooltipContainer} ref={containerRef}>
      <span className={styles.texts} onClick={toggleTooltip}>
        {formatNumberWithCommas(numbers?.total_number)}
      </span>
      {showTooltip && (
        <div className={styles.tooltip}>
					<div className={styles.channelsContainer}>
						<div className={styles.singleChannelContainer}>
							<div className={styles.iconNameContainer}>
								<Facebook className={styles.channelIcon} />
								<span>Facebook</span>
							</div>
							<span>{formatNumberWithCommas(numbers?.facebook)}</span>
						</div>
						<div className={styles.singleChannelContainer}>
							<div className={styles.iconNameContainer}>
								<Instagram className={styles.channelIcon} />
								<span>Instagram</span>
							</div>
							<span>{formatNumberWithCommas(numbers?.instagram)}</span>
						</div>
						<div className={styles.singleChannelContainer}>
								<div className={styles.iconNameContainer}>
								<Whatsapp className={styles.channelIcon} />
							<span>Whatsapp</span>
							</div>
							<span>{formatNumberWithCommas(numbers?.whatsapp)}</span>
						</div>
				</div>
      </div>
      )}
    </div>
  );
}

export default ClickableText