import {
	CartesianGrid,
	Line,
	LineChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';

// mui components
import { Card } from '@material-ui/core';

// local styles
import styles from './index.module.scss';
import moment from 'moment';

const CustomTooltip = ({ active, payload, label }) => {
	if (active && payload && payload.length) {
		return (
			<Card elevation={6} style={{ textAlign: 'center', padding: 8, borderRadius: 8 }}>
				<p><span style={{ fontWeight: 'bold' }}>{`${payload[0]?.value}%`}</span> Completion</p>
				<p>{label}</p>
			</Card>
		);
	}

	return null;
};

function ChatbotLineCharts({ setCurrentView, currentView, InfoData, statusDurationValue }) {
	// modify data to use it in the chart as only the day will be shown on the x-axis not the full day
	const modifiedData = InfoData?.daily_scenario_scores?.scores?.map((item) => {
		return {
			...item,
			name: item.name,
			date: item.date.split('-').slice(1).join('/'),	// i want the day and the month from the date
			score: item.score !== null ? item.score : 0
		};
	});

	// in OVERALL filter, i want to check if the difference <= 30 days then render dates else render names
	const differenceBetweenDates = moment(InfoData?.daily_scenario_scores?.global_to_date).diff(moment(InfoData?.daily_scenario_scores?.global_from_date), 'days');

	return (
		<div className={styles.container}>
			<div
				className={styles.header}
				style={{
					justifyContent: currentView !== 'main' && 'flex-start',
				}}
			>
				<h3>Completion scenarios &nbsp; ({InfoData?.daily_scenario_scores?.chart_type} Chart)&nbsp;</h3>
				<h5>
					From ({InfoData?.daily_scenario_scores?.global_from_date}) To ({InfoData?.daily_scenario_scores?.global_to_date})
				</h5>

				{currentView === 'main' && (
					<button
						className={styles.viewMoreBtn}
						onClick={() => {
							setCurrentView('details');
							localStorage.setItem('dashboardView', 'details')
						}}
					>
						View more
					</button>
				)}
			</div>

			<div className={styles.wrapper}>
				<ResponsiveContainer width="84%" height="100%">
					<LineChart
						data={modifiedData}
						margin={{ top: 35, right: 50, left: 0, bottom: 35 }}
					>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis
							dataKey={`${statusDurationValue === 'this_month' || statusDurationValue === 'last_month' || differenceBetweenDates <= 30 ? 'date' : 'name'}`}
							interval={0}
							angle={`${statusDurationValue === 'this_month' || statusDurationValue === 'last_month' || statusDurationValue === 'overall' ? 270 : 0}`}
							tickMargin={`${statusDurationValue === 'this_month' || statusDurationValue === 'last_month' || statusDurationValue === 'overall' ? 28 : 10}`}
						/>
						<YAxis />
						<Tooltip content={<CustomTooltip />} />
						<Line
							type="monotone"
							dataKey="score"
							stroke="#5B26BB"
							strokeWidth="3"
							activeDot={{ r: 6 }}
						/>
					</LineChart>
				</ResponsiveContainer>

				<div className={styles.details}>
					<div className={styles.item}>
						<p>Completion Score</p>
						<p>{InfoData?.completion_score ?? 0} %</p>
					</div>

					<div className={styles.item}>
						<p>Close Scenarios</p>
						<p>{InfoData?.completed_bot_scenarios}</p>
					</div>

					<div className={styles.item}>
						<p>Incomplete Scenarios</p>
						<p>{InfoData?.not_completed_bot_scenarios > 0 ? InfoData?.not_completed_bot_scenarios : 0}</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ChatbotLineCharts;
