import React from 'react';
import {
	Avatar,
	Box,
	Card,
	CardHeader,
	CardMedia,
	IconButton,
	makeStyles,
	Divider,
	Typography,
} from '@material-ui/core';
import styles from './index.module.scss';
import usageMobileIcon from 'assets/images/usageMobileIcon.svg';
import { ReactComponent as UsageMobilePhoto } from 'assets/images/usageMobilePhoto.svg';
import { ReactComponent as UsageMobileTrue } from 'assets/images/usageMobileTrue.svg';



function MobileAppCard({ sessions }) {
	const classes = useStyles();

	const progressColor = Math.round(((sessions?.total_number_of_sessions - sessions?.remaining_sessions) / sessions?.total_number_of_sessions) * 100) > 75 ? 'red' : 'green';
	return (
		<Card elevation={0} className={classes.cardStyle}>
				<div className={styles.headerContainer}>
        
				<CardMedia
          image={usageMobileIcon}
					style={{
						width: '1rem',
						height: '1.8rem',
					}}
				/>
        <Typography className={classes.titleStyle}>
					Mobile App
				</Typography>
        </div>
				<Divider className={classes.divider} />
        
        <div className={styles.mediaContainer}>
        <UsageMobileTrue />
        
        <UsageMobilePhoto />
        </div>


			</Card>
	);
}

export default MobileAppCard;

const useStyles = makeStyles(() => ({
	cardStyle: {
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		boxShadow: ' 0px 9px 28px #CCCCCC2E',
		borderRadius: '0.5rem',
		height: '32rem',
		border: '1px solid #E4E4E2 '
	},
	cardTitle: {
		fontSize: '1.1rem',
		fontFamily: 'Poppins',
		fontWeight: '600',
		color: ' #222222',
		textTransform: 'capitalize',
		marginBottom: '.8rem',
	},
	cardSubHeader: {
		fontSize: '1rem',
		fontFamily: 'Poppins',
		fontWeight: 'normal',
		color: ' #222222',
		textTransform: 'capitalize',
		opacity: 0.6,
		textAlign: 'center',
	},
	divider: {
		margin: '0 auto',
		width: '90%',
	},
	titleStyle: {

		color: '#6418c3',
		fontSize: '1.2rem',
		fontFamily: 'Poppins',
		fontWeight: '600',
	},
	cardHeader: {
		display: 'flex',
		flexDirection: 'column',
		marginTop: '2rem'
	},
  bottomDivider: {
		margin: '1rem auto',
		width: '90%',
	}
}));
