import React from 'react';
import { Paper } from '@material-ui/core';
import PolicyHeader from './policyHeader';

const Policy = () => {
	return (
		<Paper elevation={0}>
			<PolicyHeader />
		</Paper>
	);
};

export default Policy;
