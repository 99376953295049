import { languagesArray } from "constants/supportedLanguages"

const languageName = (langCode) => {
  if (langCode === null) {
    return 'All '
  }
  const language = languagesArray.find(lang => lang.code === langCode)
  return language.name
}


export default languageName;