/* eslint-disable import/prefer-default-export */
export const TRAINING_SUCCESS = '🟢 [Training] fetch all messages success';
export const TRAINING_ERROR = '🔴 [Training] fetch all messages error';
export const TRAINING_ACTIVE_MESSAGE =
	'🟢 [Training] set active message success';
export const ADD_MSG_TO_FAQ_SUCCESS =
	'🟢 [Training] add message to FAQ success';
export const ADD_MSG_TO_FAQ_ERROR = '🔴 [Training] add message to FAQ error';
export const TRAINING_MSG_REFRESH_SUCCESS =
	'🟢 [Training] refresh messages success';
export const TRAINING_MSG_REFRESH_ERROR =
	'🔴 [Training] refresh messages error';
	// delete item from training
export const DELETE_TRAINING_ITEM_SUCCESS = '🟢 [Training] delete item success';
export const DELETE_TRAINING_ITEM_ERROR = '🔴 [Training] delete item error';

