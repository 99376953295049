import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextField, makeStyles } from '@material-ui/core';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete-list-icon.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit-list-icon.svg';
import useSetUiLoadingError from 'hooks/useSetUiLoadingError';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

const ValueRow = ({
  value,
  deleteRegexValue,
  regexId,
  EditRegexValue,
  i,
}) => {

  const classes = useStyles()

  const [confirmEditValue, setConfirmEditValue] = useState(false)
  const [confirmDeleteValue, setConfirmDeleteValue] = useState(false)

  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, successData, backendError, setUiLoadingError] =
    useSetUiLoadingError();


  const validationSchema = yup.object().shape({
    value: yup
      .string().required('You should write a value').test({
        name: 'notOnlySpaces',
        message: 'Value cannot be only spaces',
        test: (note) => {
          // Check if the value is not only spaces
          return !(/^\s+$/.test(note));
        },
      }).min(3).max(60)
    ,
  });

  const {
    handleSubmit,
    formState: { errors },
    setError,
    control,
    watch,
    reset,
    getValues,
    setValue,
    clearErrors,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    defaultValues: {
    },
  });



  const handleSubmitEditValue = (payload) => {
    if (
      Object.keys(errors).length === 0) {
      EditRegexValue({
        regexId,
        valueId: value?.id,
        value: payload?.value?.replace(/\s+/g, ' ').trim(),
        enqueueSnackbar,
        setUiLoadingError,
      })
      setConfirmEditValue(false)
    }
  };


  const handleDelete = () => {
    deleteRegexValue({ regexId, valueId: value?.id, enqueueSnackbar, setUiLoadingError })
    setConfirmDeleteValue(false)
  };


  return (
    <form onSubmit={handleSubmit(handleSubmitEditValue)} className={classes.InputsContainer}>

      <Controller
        name='value'
        control={control}
        defaultValue={value.attributes?.value}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            variant="outlined"
            placeholder='Write Value'
            size='small'
            className={classes.valueInput}
            InputProps={{ readOnly: !confirmEditValue }}
            error={errors?.value?.message}
            helperText={errors?.value?.message}
          />
        )}
      />

      <div className={classes.valueActionsContainer}>
        {confirmEditValue ? (
          <div className={classes.editPrompt}>
            <Button className={classes.saveButton} type='submit'>Save</Button>
            <Button className={classes.cancelButton} onClick={() => {
              setConfirmEditValue(false)
              setValue('value', value?.attributes?.value)
              // clearErrors('value')
            }}>Cancel</Button>
          </div>
        ) :
          confirmDeleteValue ? (
            <div className={classes.editPrompt}>
              <Button className={classes.deleteButton} onClick={handleDelete}>delete</Button>
              <Button className={classes.cancelButton} onClick={() => {
                setConfirmDeleteValue(false)
              }}>Cancel</Button>
            </div>
          )
            : (
              <>
                <div className={classes.editValue} onClick={() => setConfirmEditValue(true)} >
                  <EditIcon style={{ width: '15px' }} />
                </div>
                <div className={classes.deleteValue} onClick={() => {
                  setConfirmDeleteValue(true)
                }} >
                  <DeleteIcon style={{ width: '15px' }} />
                </div>
              </>
            )}
      </div>
    </form>
  )
}

export default ValueRow

const useStyles = makeStyles((theme) => ({
  InputsContainer: {
    padding: '10px',
    display: 'flex',
    flexDirection: 'row',
    gap: '15px',
    height: '50px',
    alignItems: 'center',
    marginTop: '10px',
  },
  valueActionsContainer: {
    display: 'flex',
    flexDirection: 'rown',
    gap: '25px',
    width: '100%',
    justifyContent: 'center'

  },
  editValue: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '45px',
    height: '40px',
    backgroundColor: 'rgba(144, 144, 144, 0.08)',
    borderRadius: '7px',
    cursor: 'pointer',
    '@media screen and (max-width: 1536px) and (max-height: 738px)': {
      width: '40px',
      height: '35px',
    },
  },
  deleteValue: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '45px',
    height: '40px',
    backgroundColor: 'rgba(209, 26, 68, 0.08)',
    cursor: 'pointer',
    borderRadius: '7px',
    '@media screen and (max-width: 1536px) and (max-height: 738px)': {
      width: '40px',
      height: '35px',
    },
  },
  editPrompt: {
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
  },
  saveButton: {
    backgroundColor: '#6418C3',
    color: 'white',
    fontSize: '12px',
    borderRadius: '8px',
    minWidth: '70px',
    height: '30px',
    '@media screen and (max-width: 1536px) and (max-height: 738px)': {
      minWidth: '55px',
      height: '24px',
    },
    '&:hover': {
      backgroundColor: '#6418C3',
    },
  },
  deleteButton: {
    backgroundColor: '#D11A44',
    color: 'white',
    fontSize: '12px',
    borderRadius: '8px',
    minWidth: '70px',
    height: '30px',
    '&:hover': {
      backgroundColor: '#D11A44',
    },
    '@media screen and (max-width: 1536px) and (max-height: 738px)': {
      minWidth: '55px',
      height: '24px',
    },
  },
  cancelButton: {
    backgroundColor: 'white',
    color: 'black',
    fontSize: '12px',
    borderRadius: '8px',
    border: '1px solid black',
    minWidth: '70px',
    height: '30px',
    '@media screen and (max-width: 1536px) and (max-height: 738px)': {
      minWidth: '55px',
      height: '24px',
    },
  },
  valueInput: {
    flexBasis: '84.65%',
    flexShrink: '0',
    borderRadius: '5px',
    '@media (max-width: 1363px)': {
      flexBasis: '81%',
    },
  },
  synonymInput: {
    flexBasis: '32%',
    flexShrink: '0',
    borderRadius: '5px',
    '@media (max-width: 1363px)': {
      flexBasis: '30%',
    },
  }
}));