import Typography from '@material-ui/core/Typography';
import { Container, Grid, Button, Paper } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import homeBackGround from 'assets/images/landingImgs/homeBackGround.png';
import talkybotGif from 'assets/images/landingImgs/talkybot-gif.gif';
import waveImg from 'assets/images/landingImgs/waveImg.png';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	homeBackGround: {
		position: 'relative',
		backgroundImage: 'url(' + homeBackGround + ')',
		backgroundSize: '72% 80%',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: '40rem 0rem',
	},
	homeContent: {
		paddingTop: '9.375rem',
	},
	talkybotGif: {
		backgroundColor: 'none',
		width: '44.1875rem',
		[theme.breakpoints.up('md')]: {
			height: '44.0625rem',
		},
		height: '60.0625rem',

		marginTop: '3rem',
	},
	headerSolution: {
		paddingTop: '100px',
		// [theme.breakpoints.down('sm')]:{
		//   },
		...theme.typography.subtitle3,
		color: theme.palette.text.primary,
		lineHeight: 1.5,
	},
	headerBusiness: {
		...theme.typography.title2,
		color: theme.palette.text.primary,
	},
	headerDesc: {
		...theme.typography.body3,
		color: theme.palette.text.primary,
		margin: '2.1875rem 0px',
	},
	waveImg: {
		width: '100%',
		position: 'absolute',
		bottom: '5rem',
	},
	containerStyle: {
		[theme.breakpoints.up('md')]: {
			paddingRight: theme.spacing(15),
			paddingLeft: theme.spacing(15),
		},
		paddingRight: theme.spacing(4),
	},
}));

const HomeHeader = () => {
	// const theme = useTheme();
	// const matches = useMediaQuery(theme.breakpoints.down('sm'));
	// const containerSizeHandler=()=>{
	//   if(matches){
	//     return "xl"
	//   }
	//   else
	// }
	const classes = useStyles();
	const theme = useTheme();
	const history = useHistory();

	return (
		<Paper elevation={0} className={classes.homeBackGround}>
			<Container className={classes.containerStyle} maxWidth={false}>
				<Grid
					className={classes.homeContent}
					container
					justifyContent="space-between"
					alignItems="flex-start"
				>
					<Grid item xs={12} md={5}>
						<Typography className={classes.headerSolution}>
							Solution For
						</Typography>
						<Typography className={classes.headerBusiness}>
							Business
						</Typography>
						<Typography className={classes.headerDesc}>
							Get the best from your chatbots by building a
							chatbot for any use case using our main and various
							features
						</Typography>
						<Button
							variant="contained"
							color="primary"
							size="large"
							onClick={() => history.push('/home/features')}
						>
							read more
						</Button>
					</Grid>
					<Grid className={classes.talkybotGif} item xs={12} md={6}>
						<img
							width="100%"
							height="100%"
							src={talkybotGif}
							alt=""
						/>
					</Grid>
				</Grid>
			</Container>
			<img className={classes.waveImg} src={waveImg} alt="" />
		</Paper>
	);
};

export default HomeHeader;
