import { Accordion, AccordionDetails, AccordionSummary, Button, FormControlLabel, Grid, Switch, TextField, Typography, makeStyles } from '@material-ui/core'
import plusIcon from 'assets/icons/plusIcon.svg';
import React, { useEffect, useState } from 'react'
import ListCard from './Components/ListCard';
import ModalContainer from 'components/common/ModalContainer/Modal';
import AddList from './Components/CRUD/AddList';
import NoDataComponent from 'components/NoData/Component';
import noLists from 'assets/images/empty-lists.svg';

const Component = ({
  getAllEntityLists,
  lists,
  entityId,
  activeTalkybot,
  addNewEntityList,
  updateEntityList,
  deleteEntityList,
  addNewListValue,
  deleteListValue,
  EditListValue,
  handleRefreshPriority,
  getAllSynonyms,
  synonyms
}) => {
  const classes = useStyles();
  console.log('synonyms', synonyms);

  const [accordionExpanded, setAccordionExpanded] = useState({});
  const [activeKeys, setActiveKeys] = useState([])
  const [expanded, setExpanded] = useState([])
  const [openAddListModal, setOpenAddListModal] = React.useState(false);


  const handleChange = panel => (event, isExpanded) => {
    if (expanded.includes(panel)) {
      setExpanded(expanded.filter(item => item !== panel))
    } else {
      setExpanded(prev => [...prev, panel])
    }

  }


  const renderAddNewList = () => (
    <ModalContainer
      title="Add List"
      onClose={() => setOpenAddListModal(false)}
      open={openAddListModal}
      component={
        <AddList
          activeTalkybot={activeTalkybot}
          handleCloseModal={() => setOpenAddListModal(false)}
          addNewEntityList={addNewEntityList}
          entityId={entityId}
          handleRefreshPriority={handleRefreshPriority}
        />
      }
    />
  )

  useEffect(() => {
    setExpanded([])
  }, [])
  useEffect(() => {
    const botBuilderAppId = activeTalkybot?.bot_builder_id;
    getAllEntityLists({ entityId, botBuilderAppId })
    getAllSynonyms({
      botBuilderAppId,
    })
  }, [])

  const handleCheckAllChange = (e) => {
    if (e.target.checked === true) {
      setExpanded([])
    } else {
      setExpanded(lists.map(list => list?.attributes?.lang))
    }
  };



  return (
    <div style={{ width: '100%', marginTop: '23px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <div className={classes.headerContainer}>
        <Button
          variant="contained"
          color="primary"
          className={classes.addListButton}
          onClick={() => setOpenAddListModal(true)}
        >
          <img
            src={plusIcon}
            alt="add list"
            className={classes.plusIcon}
            style={{}}
          />
          Add List
        </Button>
        <FormControlLabel
          control={<Switch color='primary' checked={expanded.length === 0} onChange={(value) => handleCheckAllChange(value)} name="collapseAll" inputProps={{ 'aria-label': 'collapse all' }} />}
          label="Collapse All"
          labelPlacement='start'
        />
      </div>
      <div className={classes.listsContainer}>
        {lists && lists.length > 0 ? (
          <>
            {lists.map((list, index) => (
              <ListCard
                key={`list-${index}`}
                list={list}
                expanded={expanded}
                handleChange={handleChange}
                index={index}
                activeTalkybot={activeTalkybot}
                updateEntityList={updateEntityList}
                entityId={entityId}
                deleteEntityList={deleteEntityList}
                addNewListValue={addNewListValue}
                deleteListValue={deleteListValue}
                EditListValue={EditListValue}
                handleRefreshPriority={handleRefreshPriority}
                synonyms={synonyms}
              />
            ))}</>
        ) : (
          <NoDataComponent
            image={noLists}
            title="No Lists Yet !"
          />
        )}

      </div>
      {renderAddNewList()}
    </div>
  )
}

export default Component

const useStyles = makeStyles((theme) => ({

  addListButton: {
    border: `1px dashed ${theme.palette.primary.main}`,
    color: '#6418C3',
    background: 'white',
    height: theme.spacing(53),
    borderRadius: '8px',
    width: '84.6%',
    height: '63px',
    boxShadow: 'none',
    fontSize: '18px',
    fontWeight: '600',
    width: "84.6%",
    width: '84.6%',
    '@media (max-width: 1363px) or (max-height: 760)': {
      width: '81%',
      fontSize: '16px',
      height: '50px'
    },
    '&:hover': {
      backgroundColor: '#F0F0F0',
    },

  },
  tabsContainer: {
    width: '84.6%',
  },
  plusIcon: {
    width: '18px',
    marginRight: '0.6rem',
    '@media (max-width: 1363px)': {
      width: '16px',
    },
  },
  headerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    marginBottom: '30px',
    flexBasis: '10%'
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    flexBasis: '20%',
    flexShrink: '0',
    justifyContent: 'center',
    gap: '20px'
  },
  actionButton: {
  },
  listsContainer: {
    width: '84.6%',
    '@media (max-width: 1363px) or (max-height: 760)': {
      width: '81%',
      maxHeight: '36vh',
    },
    overflowX: 'hidden',
    overflowY: 'auto',
    scrollbarWidth: "none", // Hide the scrollbar for firefox
    '&::-webkit-scrollbar': {
      display: 'none', // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
    },
    '&-ms-overflow-style:': {
      display: 'none', // Hide the scrollbar for IE
    },
    padding: '0px 0px',
    maxHeight: '43vh',

    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    '& .MuiAccordionSummary-root.Mui-expanded ': {
      borderTopRightRadius: '12px',
      borderTopLeftRadius: '12px',
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      height: '53px',
      minHeight: '0',
      border: 'none',
      boxShadow: 'none',
      borderTopRightRadius: '12px',
      borderTopLeftRadius: '12px',
    },
    '& .MuiAccordion-root.Mui-expanded': {
      border: '1px solid #4E4E4E',
      boxShadow: 'none',
    },
    '& .MuiAccordion-rounded': {
      borderRadius: '12px',
    },
    '& .MuiPaper-elevation1': {
      boxShadow: 'none',
    },
    '& ::-webkit-scrollbar': {
      width: '0'
    },
    '& .MuiAccordion-root:before': {
      height: '0px',
    }
  },
  collapseHeader: {
    display: 'flex',
    padding: '0 10px',
    backgroundColor: '#F0F0F0',
    height: '53px',
    minHeight: '53px',
    border: 'none',
    borderTopRightRadius: '12px',
    borderTopLeftRadius: '12px',

  },
  listNameContainer: {
    display: 'flex',
    alignItems: 'center',
    flexBasis: '22%',
    flexShrink: '0',
    justifyContent: 'space-between',
  },
  listName: {
    fontSize: '16px',
    fontWeight: '500',
  },
  listThreshold: {
    fontSize: '16px',
    color: '#909090',
  },
  valuesCount: {
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: '500',

  },
  divider: {
    width: '0px',
    height: '53px',
    backgroundColor: '#E3E3E3',
    border: '1px solid #E3E3E3',
    opacity: '1',
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginBottom: '30px'
  },
  inputLabelsContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '10px',
    padding: '0 10px'
  },
  valueText: {
    flexBasis: '60%',
    flexShrink: '0'
  },
  InputsContainer: {
    padding: '10px',
    display: 'flex',
    flexDirection: 'row',
    gap: '15px',
    height: '50px',
    alignItems: 'center',
    marginTop: '10px',
  },
  addButton: {
    backgroundColor: 'rgba(100, 24, 195, 0.08)',
    backgroundColorOpacity: '0.08',
    borderRadius: '7px',
    color: '#6418C3',
    height: '40px',
    width: '111px',
    fontSize: '16px'

  },
  inputsWrapper: {
    display: 'flex',
    gap: '25px',
    flexDirection: 'column',
  },
  valueActionsContainer: {
    display: 'flex',
    flexDirection: 'rown',
    gap: '10px',
  },
  editValue: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '45px',
    height: '40px',
    backgroundColor: 'rgba(144, 144, 144, 0.08)',
    borderRadius: '7px'
  },
  deleteValue: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '45px',
    height: '40px',
    backgroundColor: 'rgba(209, 26, 68, 0.08)',
    borderRadius: '7px'
  },
}));
