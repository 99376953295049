import React from 'react';
import CompleteUserProfile from 'components/CompleteUserProfile';

const CompleteUserProfilePage = () => {
	return (
		<div>
			<CompleteUserProfile />
		</div>
	);
};

export default CompleteUserProfilePage;
