import { connect } from 'react-redux';
// local assets
import { ReactComponent as Esclations } from 'assets/images/Dashboard/Esclations.svg';
import { ReactComponent as NoOfMessageIn } from 'assets/images/Dashboard/NoOfMessageIn.svg';
import { ReactComponent as HandOver } from 'assets/images/Dashboard/HandOver.svg';
import {
	getBotActivitesAction,
	getTakeoverAndEscalationAction,
	getTeamHandoverAction,
	getTeamMembersMessagesAction,
} from 'store/actions/creators/dashboard';

const mapStateToProps = (state) => {
	return {
		incidents: state?.crm?.incidents
			? Object.values(state?.crm?.incidents)
			: [],
		talkybotId: state?.activeTalkybot?.id,
		TeamMemberCardInfo: TeamMemberCardInfo(state),
		team_members_messages: state?.dashboard?.team_members_messages,
		bot_activites: state?.dashboard?.bot_activites,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getTeamMembersMessages: ({
			talkyBotID,
			page,
			duration_filter,
			provider_filter,
			setUiLoadingError,
		}) =>
			dispatch(
				getTeamMembersMessagesAction({
					talkyBotID,
					page,
					duration_filter,
					provider_filter,
					setUiLoadingError,
				})
			),
		getTakeoverAndEscalation: ({
			talkyBotID,
			duration_filter,
			provider_filter,
			setUiLoadingError,
		}) =>
			dispatch(
				getTakeoverAndEscalationAction({
					talkyBotID,
					duration_filter,
					provider_filter,
					setUiLoadingError,
				})
			),
		getTeamHandover: ({
			talkyBotID,
			duration_filter,
			provider_filter,
			setUiLoadingError,
		}) =>
			dispatch(
				getTeamHandoverAction({
					talkyBotID,
					duration_filter,
					provider_filter,
					setUiLoadingError,
				})
			),
		getBotActivites: ({
			talkyBotID,
			page,
			duration_filter,
			provider_filter,
			setUiLoadingError,
		}) =>
			dispatch(
				getBotActivitesAction({
					talkyBotID,
					page,
					duration_filter,
					provider_filter,
					setUiLoadingError,
				})
			),
	};
};

const TeamMemberCardInfo = (state) => {
	return [
		{
			id: 1,
			icon: <HandOver />,
			cardTitle: 'No. Of Internal Team Handover',
			result: state?.dashboard?.team_handover?.internal_team_handover,
		},
		{
			id: 2,
			icon: <Esclations />,
			cardTitle: 'No. Of Take Overs / Escalations',
			result: `${state?.dashboard?.takeover_and_escalation?.takeover ?? 0} / ${state?.dashboard?.takeover_and_escalation?.escalation ?? 0}`,
		},
		{
			id: 3,
			icon: <NoOfMessageIn />,
			cardTitle: 'No. Of Closed Conversation',
			result: state?.dashboard?.closed_conversation
				?.conversations_closed_by_user,
		},
	];
};

export default connect(mapStateToProps, mapDispatchToProps);
