import { Paper } from '@material-ui/core';
import PriciengHeader from './pricingHeader';
import PricingTable from './pricingTable';
import PricingCalc from './pricingCalc';
import PricingFAQS from './pricingFAQS';

const Pricing = () => {
	return (
		<Paper elevation={0}>
			<PriciengHeader />
			{/* <PricingTable /> */}
			{/* <PricingCalc /> */}
			<PricingFAQS />
		</Paper>
	);
};
export default Pricing;
