import {
	ADD_MSG_TO_FAQ_SUCCESS,
	TRAINING_ACTIVE_MESSAGE,
	TRAINING_ERROR,
	TRAINING_MSG_REFRESH_SUCCESS,
	TRAINING_SUCCESS,
	DELETE_TRAINING_ITEM_SUCCESS,
} from 'store/actions/types/train';

// training Reducer
const initialState = {
	Messages: [],
	data_count: null,
	activeMessage: null,
	meta: {},
	isScrolling: false,
};
export default function training(state = initialState, action) {
	switch (action.type) {
		case TRAINING_MSG_REFRESH_SUCCESS:
			return {
				...state,
				Messages: action.payload,
				meta: action.payload.meta
			};
		case ADD_MSG_TO_FAQ_SUCCESS:
			return {
				...state,
				//  remove this message from the Messages
				Messages: state.Messages.filter(
					(message) => message.id !== action.payload.id.toString()
				),
				activeMessage: null,
			};
		case TRAINING_ACTIVE_MESSAGE:
			return {
				...state,
				activeMessage: action.payload.activeMessage,
			};
			case TRAINING_SUCCESS:
				const scrolling = action.payload.isScrolling
				if (!scrolling) {
					return {
						...state,
						Messages: action.payload.data,
						data_count: action.payload.data_count,
						meta: action.payload.meta,
					};
				} else {
					// If no query was applied, replace existing messages
					return {
						...state,
						Messages: [...state.Messages, ...action.payload.data],
						data_count: action.payload.data_count,
						meta: action.payload.meta,
					};
				}
			
		case TRAINING_ERROR:
			return {
				...state,
			};
		case DELETE_TRAINING_ITEM_SUCCESS:
			const updatedMessages = state.Messages.filter(
        (message) => message.id !== action.payload
    );
		return {
			...state,
			Messages: updatedMessages,
			data_count: updatedMessages.length,
	};
		default:
			return state;
	}
}
