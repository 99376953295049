import React from 'react';
import Usage from 'components/Usage';
import useTitle from 'hooks/useTitle';

const UsagePage = () => {
	useTitle('usage');
	return <Usage />;
};

export default UsagePage;
