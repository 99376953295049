import {
	Box,
	Grid,
	makeStyles,
	Paper,
	Divider,
	useTheme,
	Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import BotsTable from './BotsTable';
import MeterCard from './MeterCard/component';

const MeterInfo = ({ meterInfo, talkybots }) => {
	const theme = useTheme();
	const classes = useStyles();
	return (
		<Paper elevation={0} className={classes.meterContainer}>
			<Grid container direction="row" alignItems="stretch" spacing={3}>
				<Grid
					item
					// md={8}
					container
					justifyContent="space-between"
					spacing={10}
				>
					{meterInfo?.map((bot, i) => (
						<Grid key={i} item xs={3}>
							<MeterCard
								totalNumOfMeter={bot.totalNumOfMeter}
								currentNumOfMeter={bot.currentNumOfMeter}
								nameOfMeter={bot.nameOfMeter}
							/>
						</Grid>
					))}
				</Grid>
				{/* <Grid item xs={10} md={4} align="center">
					<Box className={classes.activeUsersWrapper}>
						Monthly Active Users
					</Box>
				</Grid> */}
				<Divider light classes={{ root: classes.divider }} />
				<Typography className={classes.botUsage}>Bot Usage</Typography>
				<Grid item xs={12}>
					<BotsTable talkybots={talkybots} />
				</Grid>
			</Grid>
		</Paper>
	);
};

export default MeterInfo;

const useStyles = makeStyles((theme) => ({
	meterContainer: {
		backgroundColor: 'transparent',
	},
	activeUsersWrapper: {
		background: '#37295E 0% 0% no-repeat padding-box',
		boxShadow: '0px 9px 28px #37295E4D',
		borderRadius: '0.5rem',
		height: '100%',
		color: '#fff',
		paddingTop: '6.5rem',
	},

	divider: {
		width: '100%',
		marginTop: '2rem',
		backgroundColor: '#D3D3D370',
		// marginBottom: '3rem',
	},
	botUsage: {
		...theme.typography.subtitle2,
		color: '#000000',
		padding: '1rem 0rem',
	},
}));
