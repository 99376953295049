import * as actionType from 'store/actions/types/completeUserProfile';

const initialState = {
	pending: false,
	userData: null,
	error: null,
};
export default (state = initialState, { type, payload }) => {
	switch (type) {
		case actionType.COMPLETE_USER_PROFILE_REQUEST: {
			return { ...state, pending: true, error: null };
		}
		case actionType.COMPLETE_USER_PROFILE_SUCCESS: {
			return { ...state, pending: false, error: null, userData: payload };
		}
		case actionType.COMPLETE_USER_PROFILE_FAIL: {
			return { ...state, pending: false, error: payload };
		}
		default:
			return state;
	}
};
