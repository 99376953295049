import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core';
import Header from './Header';
import EntityLists from './Components/EntityLists';
import EntityRegex from './Components/EntityRegex';
import EntityAi from './Components/Ai';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import ConfigurePriority from './Components/ConfigurePriority';
import useSetUiLoadingError from 'hooks/useSetUiLoadingError';
import { useSnackbar } from 'notistack';

export default function Component({
  activeEntity,
  clearActiveEntity,
  activateEntity,
  activeTalkybot,
  getPriorityList,
  priorityList,
  changePriority,
}) {
  const classes = useStyles();
  const [tabsValue, setTabsValue] = useState(0);
  const [refreshPriority, setRefreshPriority] = useState(0)
  const [openConfigPriorityMenu, setopenConfigPriorityMenu] = useState(false)

  const { entityId, talkybotId } = useParams();
  const botBuilderAppId = activeTalkybot?.bot_builder_id

  const [isLoading, successData, backendError, setUiLoadingError] =
    useSetUiLoadingError();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    activateEntity(entityId, talkybotId);
    return () => {
      clearActiveEntity()
    }
  }, [entityId, talkybotId]);

  const handleChange = (event, newValue) => {
    setTabsValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }

  const handleRefreshPriority = () => {
    setRefreshPriority((prev) => prev + 1)
  }

  useEffect(() => {
    getPriorityList({ entityId, botBuilderAppId })
  }, [refreshPriority, openConfigPriorityMenu])


  return (
    <Paper style={{ padding: '20px', height: '98vh' }}>
      <Grid container>
        <Header />
      </Grid>
      <Grid container>
        <Grid
          item
          xs
          style={{
            fontFamily: 'poppins',
            fontWeight: '400',
          }}
        >
          <Typography variant="h6" className={classes.headingStyle}>
            Set up The Entity
          </Typography>
          <p className={classes.paragraphStyle}>
            User Entities help your chatbot identify and extract specific information, that’s important for your business, from user replies.
          </p>
          <Divider />
        </Grid>
      </Grid>
      <Grid container>
        <div
          className={classes.contentHeader}
        >
          <div className={classes.tabsContainer}>

            <AppBar position="static" className={classes.appBar}>
              <Tabs
                value={tabsValue}
                onChange={handleChange}
                classes={{ indicator: classes.indicator }} // Hide the indicator line
              >
                <Tab label="Lists" classes={{ selected: classes.selectedTab }} {...a11yProps(0)} />
                <Tab label="Regex" classes={{ selected: classes.selectedTab }} {...a11yProps(0)} />
                <Tab label="Ai" classes={{ selected: classes.selectedTab }} {...a11yProps(0)} />
              </Tabs>
            </AppBar>
          </div>
          <ConfigurePriority
            getPriorityList={getPriorityList}
            botBuilderAppId={botBuilderAppId}
            entityId={entityId}
            priorityList={priorityList}
            changePriority={changePriority}
            refreshPriority={refreshPriority}
            successDataGetList={successData}
            setopenConfigPriorityMenu={setopenConfigPriorityMenu}
            openConfigPriorityMenu={openConfigPriorityMenu}
          />
        </div>
        <TabPanel value={tabsValue} index={0} style={{ width: '100%' }}>
          <EntityLists
            entityId={entityId}
            handleRefreshPriority={handleRefreshPriority}
          />
        </TabPanel>
        <TabPanel value={tabsValue} index={1} style={{ width: '100%' }}>
          <EntityRegex
            entityId={entityId}
            handleRefreshPriority={handleRefreshPriority}
          />
        </TabPanel>
        <TabPanel value={tabsValue} index={2} style={{ width: '100%' }}>
          <EntityAi />
        </TabPanel>
        <Grid item xs={6}>
        </Grid>
      </Grid>
    </Paper>
  );
}
const useStyles = makeStyles(() => ({
  headingStyle: {
    fontWeight: 500,
    fontSize: '15px',
    color: '#000000',
    fontFamily: 'Poppins',
    marginTop: '30px',
    textTransform: 'none',
  },
  paragraphStyle: {
    fontSize: '13px',
    color: '#626262',
    fontFamily: 'Poppins',
    marginTop: '10px',
    marginBottom: '20px',
    userSelect: 'none',
  },
  contentHeader: {
    marginTop: '30px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',

  },
  tabsContainer: {
    width: '84.4%',
    '@media (max-width: 1363px)': {
      width: '81%',
    },
  },
  appBar: {
    backgroundColor: '#F0F0F0', // Background color of the AppBar
    color: '#000000',
    display: 'flex',
    justifyContent: 'center',
    padding: '10px 10px 10px 10px',
    maxHeight: '56px',
    borderRadius: '12px',
    alignItems: 'start',
    boxShadow: 'none', // Remove shadow

    '& .MuiTabs-root': {
      alignItems: 'center'
    },
    '& .MuiTab-root': {
      minWidth: '105px',
      fontSize: '16px',
      maxHeight: '35px',
      minHeight: '35px',
      '@media (max-width: 1363px)': {
        fontSize: '14px',
      },

    },
    '& .MuiButtonBase-root': {
    }
  },
  selectedTab: {
    backgroundColor: '#6418C3', // Background color of the selected tab
    color: 'white', // Text color of the selected tab
    borderRadius: '11px',
  },

  indicator: {
    display: 'none', // Hide the indicator line
  },
  configurePriorityButton: {
    border: `1px solid #6418C3`,
    color: '#6418C3',
    backgroundColor: 'white',
    height: '56px',
    borderRadius: '8px',
    fontWeight: '600',
    fontSize: '14px',
    boxShadow: 'none',
    flexBasis: '13.5%',
    '&:hover': {
      backgroundColor: '#F0F0F0',
    },
    '@media (max-width: 1363px)': {
      fontSize: '12px',
    },
  },
}));
