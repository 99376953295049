import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from 'components/common/Grid/GridContainer';
import GridItem from 'components/common/Grid/GridItem';
import FormSelect from 'controllers/FormSelect';
import FormTextInput from 'controllers/FormTextInput';
import mapCategories from 'helpers/MapCategories';
import useSetUiLoadingError from 'hooks/useSetUiLoadingError';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

export default function EditEntity({
	entityToEdit,
	setEntityToEdit,
	editEntity,
	activeTalkybot,
	handleCloseModal,
	categories,
	defaultCategory,
}) {
	const [open, setOpen] = React.useState(true);
	return (

		<EditEntityContent
			entityToEdit={entityToEdit}
			setEntityToEdit={setEntityToEdit}
			editEntity={editEntity}
			handleCloseModal={handleCloseModal}
			activeTalkybot={activeTalkybot}
			categories={categories}
			defaultCategory={defaultCategory}
		/>

	);
}

const EditEntityContent = ({
	entityToEdit,
	setEntityToEdit,
	editEntity,
	activeTalkybot,
	handleCloseModal,
	categories,
	defaultCategory,
}) => {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();


	const editEntitySchema = {
		name: 'name',
	}

	// 💥 validation schema for {field and field_type}
	const validationSchema = yup.object().shape({
		name: yup.string().required().min(3).max(30),
	});

	const {
		handleSubmit,
		formState: { errors },
		setError,
		control,
		watch,
		reset,
		getValues,
	} = useForm({
		resolver: yupResolver(validationSchema),
		mode: 'all',
		defaultValues: {
			name: entityToEdit ? entityToEdit?.attributes?.name : ' ',
			entity_category_id: entityToEdit && entityToEdit?.attributes?.entity_category_id !== null ? entityToEdit?.attributes?.entity_category_id : 0 ,
		},
	});

	// 🔁 Loading and 🔴error handling
	const [isLoading, successData, backendError, setUiLoadingError] =
		useSetUiLoadingError();


	const handleSubmitEditEntity = async (payload) => {
		if (Object.keys(errors).length === 0) {
			const entityId = entityToEdit?.id;
			const botBuilderAppId = activeTalkybot?.bot_builder_id;
			editEntity({
				entityId,
				botBuilderAppId,
				selectedCategory: defaultCategory,
				payload,
				setUiLoadingError,
				enqueueSnackbar,
			});
		}
	};

	useEffect(() => {
		if (successData) {
			handleCloseModal();
			setEntityToEdit(null);
		}
	}, [successData]);

	return (
		<div className={classes.root}>
			<form onSubmit={handleSubmit(handleSubmitEditEntity)}>
				<GridContainer
					spacing={10}
					justifyContent="space-evenly"
					className={classes.row}
					style={{ marginTop: '-10px' }}
				>
					<GridItem md={12} xs={12}>
						<Controller
							name='name'
							control={control}
							render={({ field }) => (
								<FormTextInput
									{...field}
									error={errors?.name?.message}
									type="text"
									placeHolder="Entity Name"
								/>
							)}
						/>
					</GridItem>
					<GridItem md={12} xs={12}>
						<Controller
							name="entity_category_id"
							control={control}
							render={({ field }) => (
								<FormSelect
									{...field}
									error={errors?.entity_category_id?.message}
									select
									smallHeight
									// array of categories
									options={mapCategories(categories)}
									style={{
										padding: '7px',
										border: '1px solid #6418C3',
									}}
									className={classes.categoryList}
								/>
							)}
						/>
					</GridItem>
				</GridContainer>

				<GridContainer
					justifyContent="flex-end"
					className={classes.row}
					style={{ marginTop: '20px', marginBottom: '10px' }}
				>
					<Button
						// onClick={() => onClose()}
						style={{ marginRight: '20px', padding: '10px' }}
						onClick={handleCloseModal}
					>
						Cancel
					</Button>
					<Button variant="contained" color="primary" type="submit">
						{isLoading ? (
							<CircularProgress color="white" size={15} />
						) : (
							'Edit'
						)}
					</Button>
				</GridContainer>
			</form>
		</div>
	);
};
const useStyles = makeStyles((theme) => ({
	// buttonContainer: {
	// 	display: 'flex',
	// 	justifyContent: 'flex-end',
	// 	marginTop: theme.spacing(20),
	// },
	categoryList: {
		'& .MuiList-list': {
			height: '200px'
		}
	}
}));
